<template>

    <b-container fluid class="dashboard_content">

      <Header />

      <div v-show="show_surveillance_view==true" class="surveillance_data_viz">

        <div class="stats_content">
            <Stats />
        </div>

        <div class="map_content">
            <Map />
          <div class="legend">
            <span class="legend_title">Légende</span>
            <div class="legend_elt">
              <div class="circle_elt1"></div>
              <div class="elt_label">100 -  1000</div>
            </div>
            <div class="legend_elt">
              <div class="circle_elt2"></div>
              <div class="elt_label">10 - 100</div>
            </div>
            <div class="legend_elt">
              <div class="circle_elt3"></div>
              <div class="elt_label">1 - 10</div>
            </div>
          </div>
        </div>

        <div class="charts_content">
            <Charts />
        </div>

        <div v-show="role=='ICP'" class="pie_chart_content">
          <PieChart />
        </div>

        <div class="filter_content">
          <DashboardFilter />
        </div>

        <div v-show="isLoading==true || isLoadingIndicators==true " class="spinner_block">
          <b-spinner class="loading_spinner" variant="primary" label="Text Centered"></b-spinner>
          <span class="loading_text">Chargement...</span>
        </div>

      </div>

    </b-container>
    
</template>
  
  

  <script>

  import { mapState } from 'vuex';
  import Header from '@/components/dashboard/Header.vue';
  import Charts from '@/components/dashboard/Charts.vue';
  import DashboardFilter  from '@/components/dashboard/Filter.vue';
  import Map from '@/components/dashboard/Map.vue';
  import PieChart from '@/components/dashboard/PieChart.vue';
  import Stats from '@/components/dashboard/Stats.vue';
  import { eventBus } from '@/main.js';


  export default {
    name: 'Dashboard',
    components: {
        Header,
        Charts,
        DashboardFilter,
        Map,
        PieChart,
        Stats
    },
    data() {
      return {
        role:"",
        isLoadingIndicators: false
      };
    },
    computed: {
      ...mapState({
          show_surveillance_view : "show_surveillance_view",
          show_alerts_view : "show_alerts_view",
          show_samples_view : "show_samples_view",
          isLoading: "isLoading",
          filter_date_debut: "filter_date_debut",
          filter_date_fin: "filter_date_fin"
      })
    },
    async mounted() {

      eventBus.$on('getIndicators', () => {
        this.$nextTick(() => {
          let agent_district = localStorage.getItem("nom_district");
          let agent_site = localStorage.getItem("nom_site");
          let user = JSON.parse(localStorage.getItem("user"));
          let user_role = user.role.nom_role;
          this.role= user_role;
          let user_name = user.nom_utilisateur;
          this.getIndicators(agent_district, agent_site, user_role,user_name);
        });
      });

      this.$nextTick(() => {
        // let agent_district = localStorage.getItem("nom_district");
        // let agent_site = localStorage.getItem("nom_site");
        let user = JSON.parse(localStorage.getItem("user"));
        let user_role = user.role.nom_role;
        this.role= user_role;
        // let user_name = user.nom_utilisateur;

        this.getUrgentSamples(user_role);
        this.getStructuresSanitaires(user_role);
        setInterval(() => {
          this.getUrgentSamples(user_role);
      }, 60000);
      })
      

    },
    methods: {

      checkIndex(indexArray, index){
        let result=false;
        for (let i = 0; i < indexArray.length; i++) {
          if(index==indexArray[i]){
            result=true;
            break;
          }
        }
        return result;
      },
      async getIndicators(agent_district, agent_site, user_role, user_name){

        this.isLoadingIndicators = true;
        
        let request_url;
        let date_debut = this.filter_date_debut;
        let date_fin = this.filter_date_fin;

        if(agent_district && agent_district && agent_site){
          request_url = '/api/get-indicators/?district='+ agent_district+'&site='+agent_site+'&role='+user_role+'&agent='+user_name+'&date_debut='+date_debut+'&date_fin='+date_fin;
        }else{
          request_url = '/api/get-indicators/?role='+user_role+'&date_debut='+date_debut+'&date_fin='+date_fin;
        }

        await this.axios
        .get(request_url)
        .then(response => {
          this.$store.commit('setTotalPatientConsultes', response.data.total_patient_consultes);
          this.$store.commit('setTotalFebrilesConsultes', response.data.total_febriles_consultes);
          this.$store.commit('setTotalTdrPaluRealises', response.data.total_tdr_palu_realises);
          this.$store.commit('setTotalCasPaluConfirmes', response.data.total_cas_palu_confirmes);
          this.$store.commit('setTotalSyndromesGrippaux', response.data.total_syndromes_grippaux);
          this.$store.commit('setTotalSuspicionsArbos', response.data.total_suspicions_arbos);
          this.$store.commit('setTotalCasDiarrhees', response.data.total_cas_diarrhees);
          this.$store.commit('setTotalCasDiarrheesFebriles', response.data.total_cas_diarrhees_febriles);
          this.$store.commit('setTotalCasDiarrheesSanglantes', response.data.total_cas_diarrhees_sanglantes);
          this.isLoadingIndicators=false;
        })
        .catch(error => {
          this.$store.commit("setIsLoading",false);
          console.log(JSON.stringify(error));
        });
      },
      
      async getUrgentSamples(role){
        let agent_district = localStorage.getItem("nom_district");
        let agent_site = localStorage.getItem("nom_site");
        let agent_region = localStorage.getItem("nom_region");


        let request_url="";

        if(role=="ICP"){
          request_url = '/api/urgent-samples-count/?district='+ agent_district+'&site='+agent_site;
        }
        else if(role=="MCD"){
          request_url = '/api/urgent-samples-count/?district='+ agent_district;
        }
        else if(role=="MCR"){
          request_url = '/api/urgent-samples-count/?region='+ agent_region;
        }

        await this.axios
        .get(request_url)
        .then(response => {
            this.$store.commit("setTotalUrgentSamples",response.data);
        })
        .catch(error => {
          console.log(JSON.stringify(error));
        });
      },
      async getStructuresSanitaires(role){

        let agent_district;
        let agent_site;
        let agent_region;

      if(role !== "SUPERVISEUR"){
        if (localStorage.getItem('nom_district')) {
          agent_district = localStorage.getItem("nom_district").trim();
        }
        if (localStorage.getItem('nom_site')) {
          agent_site = localStorage.getItem("nom_site").trim();
        }
        if (localStorage.getItem('nom_region')) {
          agent_region = localStorage.getItem("nom_region").trim();
        }
      }


      let request_url="";

      if(role=="ICP"){
        request_url = '/api/structure-sanitaire/?type=Site%20communautaire&district='+agent_district+'&site='+agent_site;
      }
      else if(role=="MCD"){
        request_url = '/api/structure-sanitaire/?type=Site%20communautaire&district='+agent_district;
      }
      else if(role=="MCR"){
        request_url = '/api/structure-sanitaire/?type=Site%20communautaire&region='+agent_region;
      }else if(role=="SUPERVISEUR"){
        request_url = '/api/structure-sanitaire/?type=Site%20communautaire';
      }


      await this.axios
          .get(request_url)
          .then(response => {

              let districts_list = [];
              let sites_list = [];
              let selected_sites = [];

              let results = response.data.results;
              results.forEach((item,index)=> {
                sites_list.push({
                  id: index, 
                  nom: item.nom_structure, 
                  lat: item.localisation.lat, 
                  lng: item.localisation.lng, 
                  total_suspecte: 0,
                  total_confirme: 0,
                  district: item.district.nom_district
                })

                let i = districts_list.indexOf(item.district.nom_district);
                if (i < 0) {
                  districts_list.push(item.district.nom_district);
                }
              });

              this.$store.commit('setSitesList', sites_list);
              this.$store.commit('setDistrictsList', districts_list);

              //select randomly n sites 
              // Create an array of indices
              let n;
              if(results.length<=5){
                n=5;
              }else{
                n = Math.round(results.length/3);
              }
              let indices = Array.from({length: results.length}, (_, i) => i);

              if(n>5){
                // Shuffle the array using the Fisher-Yates shuffle algorithm
                for (let i = indices.length - 1; i > 0; i--) {
                    let j = Math.floor(Math.random() * (i + 1));
                    [indices[i], indices[j]] = [indices[j], indices[i]];
                }
              }

              // Get the first n indices from the shuffled array
              let selectedIndices = indices.slice(0, n);

              sites_list.forEach(site => {
                if(this.checkIndex(selectedIndices,site.id) ){
                  selected_sites.push(site);
                }
              });
              this.$store.commit('setSelectedSites', selected_sites);


              //set a variable that indicates the loading is done :

              this.$store.commit('setIsStructuresLoaded', true);

          })
          .catch(error => {
            console.log(JSON.stringify(error));
          });
      }
    }
  };
  </script>
  
  <style scoped>

.dashboard_content{
  overflow-x: hidden;
}

.surveillance_data_viz{
  display: flex;
  flex-direction: column;
}

.filter_content{
  position: fixed;
  top: 49%;
  left: 0;
  width: 24%;
  z-index: 999;
  margin-left: 0.5%;
}

.spinner_block{
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 60%;
  left: 48%;
  z-index: 999999999999999;
}

.loading_text{
  font-family: 'Eastman Trial';
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 14px;
  color: #6A778A;
  padding: 5px;
}

.map_content,
.charts_content{
  position: relative;
  left: 4%;
  margin-top: 1.5%;
}

.pie_chart_content{
  position: relative;
  left: 8.5% !important;
  margin-top: 1.5% !important;
}

  .legend{
    box-sizing: border-box;
    background: #FFFFFF;
    border: 1px solid #DADADA;
    border-radius: 10px;
    width: 40%;
    position: relative;
    left: 35%;
    margin-top:2%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
  }

  .legend_title{
    font-family: 'Eastman Trial';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 23px;
    color: #000000;
    margin-left: 1%;
    margin-right: 4%;
  }

  .legend_elt{
    display: flex;
    align-items: center;
  }

  .circle_elt1{
    background: rgba(177, 222, 255, 0.6);
    border: 2px solid #48ADF7;
    margin-top: 2%;
    margin-bottom: 2%;
    width : 100px;
    height: 100px;
    border-radius: 50%;
  }

  .circle_elt2{
    background: rgba(177, 222, 255, 0.6);
    border: 2px solid #48ADF7;
    width : 60px;
    height: 60px;
    border-radius: 50%;
  }

  .circle_elt3{
    background: rgba(177, 222, 255, 0.6);
    border: 2px solid #48ADF7;
    margin-top: 2%;
    margin-bottom: 2%;
    width : 20px;
    height: 20px;
    border-radius: 50%;
  }

  .elt_label{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    color: #000000;
    position: relative;
    left: 2%;
  }

  </style>