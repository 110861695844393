<template>
  <b-navbar toggleable="md" class="py-0 my-0 menu" type="light" variant="light">
    <b-nav-toggle target="nav_collapse"></b-nav-toggle>
    <b-navbar-brand class="p-0 m-0" href="#">
      <img class="header_logo" width="70" src="@/assets/logo4s.png" />
    </b-navbar-brand>

    <b-collapse is-nav id="nav_collapse">
      <b-navbar-nav class="search_item">
        <b-nav-item style="margin-bottom:1%;" class="searchBar">
          <SearchBar />
        </b-nav-item>
      </b-navbar-nav>

      <b-navbar-nav class="user_infos">
        <b-nav-item class="avatar">
          <img class="avatar_img" src="@/assets/avatar.png" alt="avatar" />
        </b-nav-item>
        <b-nav-item class="avatar_infos">
          <div>
            <span class="welcoming">Hey, {{this.username}}</span>
          </div>
        </b-nav-item>

        <b-nav-item @click="logoutUser()" class="logout_item">
          <div class="logout_elt">
            <img class="logout_icon" src="@/assets/logout.png" alt="logout" />
          </div>
        </b-nav-item>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
//#ff0000
import SearchBar from '@/components/virologie/SearchBar.vue';

import { mapState } from 'vuex';

export default {
  name: 'Header',
  components: { SearchBar },
  props: {
    msg: String
  },
  data() {
    return {
      data: [],
      show_spinner: false
    };
  },

  methods: {
    async logoutUser() {
      await this.axios
        .post('/api/logout/', { identifiant: this.user.nom_utilisateur })
        .then(response => {
          console.log(response.data);
          this.$vs.notification({
            duration: 500,
            color: '#484EF7',
            position: 'bottom-left',
            title: 'Au revoir',
            text: '',
            buttonClose: false
          });
          //redirecting
          setInterval(() => {
            window.location.href = '/login';
            window.history.pushState(null, '', window.location.href);
            window.onpopstate = function() {
              window.history.pushState(null, '', window.location.href);
            };
          }, 1000);
          let lastest_identifiant = this.$store.state.user.nom_utilisateur;
          //saving user informations
          localStorage.removeItem('user');
          localStorage.setItem('nom_utilisateur', lastest_identifiant);
          this.$store.commit('setIsAuthenticated', false);
          localStorage.removeItem('isAuthenticated');
          localStorage.removeItem('token');
          this.$store.commit('setToken', null);
        })
        .catch(error => {
          if (error.response) {
            for (const property in error.response.data) {
              this.errors.push(`${property}: ${error.response.data[property]}`);
            }
          } else {
            this.errors.push(
              'Quelque chose ne marche pas. Veuillez réessayer.'
            );
            console.log(JSON.stringify(error));
          }
        });
    }
  },
  computed: {
    ...mapState({
      username: 'username',
      user: 'user'
    })
  }
};
</script>

<style scoped>
.menu {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 2%;
  padding-bottom: 1%;
  height: 15%;
  background: #ffffff;
}
.header_logo {
  border-radius: 70%;
  padding: 0;
  margin-top: 10%;
  display: block;
  margin-bottom: 10%;
  margin-left: 16%;
}

.search_item {
  display: flex;
  margin-left: 30%;
  padding: 0;
  width: 28%;
}

.searchBar {
  width: 100%;
  position: relative;
  left: 7%;
}

.user_infos {
  margin-left: 20%;
  width: 24%;
}

.avatar {
  height: 40%;
  border-radius: 100%;
  margin-top: 0%;
}

.avatar_infos {
  margin-left: 1%;
  color: black;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.avatar_img {
  transform: scale(0.95);
}
.welcoming {
  font-family: 'Eastman Condensed Trial';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #425169;
}

.logout_icon {
  color: #425169;
  font-weight: 900;
  margin-left: 6%;
  transform: scale(0.6);
}
.logout_icon:hover {
  transform: scale(0.65);
}

.logout_item {
}

.logout_elt {
}
</style>