<template>
  <b-container fluid class="treatment_content">
    <span class="prescribed_treatment_title">Traitement prescrit ?</span>
    <b-row class="treatment">
      <b-col cols="5" class="treatment_question">
        <SwitchBox
          :values="['Non','Oui']"
          :icons="['blank.png','blank.png']"
          :box_style="{label1Left:'-5%',label1Right:'0%',label2Left:'0%',label2Right:'0%',selectBoxHeight:'65%', boxMarginLeft:'0%',selectBoxWidth:'18.3%'}"
          :variableName="'traitement_prescrit'"
          :null="false"
        />
      </b-col>
    </b-row>
    <b-row v-show="traitement_prescrit=='Oui'" class="prescribed_treatment">
      <b-col cols="4" class="treatment_element">
        <b-form-checkbox
          class="antibiotiques_checkbox treatment_checkbox"
          v-model="antibiotiques"
          name="checkbox-antibiotiques"
          value="Oui"
          unchecked-value="Non"
          size="lg"
        >
          <span class="checkbox_text">Antibiotiques</span>
        </b-form-checkbox>
      </b-col>
      <b-col cols="2"></b-col>
      <b-col cols="4" class="treatment_element">
        <b-form-checkbox
          class="antibiotiques_checkbox treatment_checkbox"
          v-model="antipaludiques"
          name="checkbox-antibiotiques"
          value="Oui"
          unchecked-value="Non"
          size="lg"
        >
          <span class="checkbox_text">Antipaludiques</span>
        </b-form-checkbox>
      </b-col>
    </b-row>
    <b-row v-show="traitement_prescrit=='Oui'" class="prescribed_treatment">
      <b-col cols="4" class="treatment_element">
        <b-form-checkbox
          class="antipyretiques_checkbox treatment_checkbox"
          v-model="antipyretiques"
          name="checkbox-antipyretiques"
          value="Oui"
          unchecked-value="Non"
          size="lg"
        >
          <span class="checkbox_text">Antipyrétiques</span>
        </b-form-checkbox>
      </b-col>
      <b-col cols="2"></b-col>

      <b-col cols="4" class="treatment_element">
        <b-form-checkbox
          class="antihistaminiques_checkbox treatment_checkbox"
          v-model="antihistaminiques"
          name="checkbox-antihistaminiques"
          value="Oui"
          unchecked-value="Non"
          size="lg"
        >
          <span class="checkbox_text">Antihistaminiques</span>
        </b-form-checkbox>
      </b-col>
    </b-row>
    <b-row
      v-show="traitement_prescrit=='Oui'"
      style="margin-bottom:3%;"
      class="prescribed_treatment"
    >
      <b-col cols="4" class="treatment_element">
        <b-form-checkbox
          class="broncodilatateurs_checkbox treatment_checkbox"
          v-model="broncodilatateurs"
          name="checkbox-broncodilatateurs"
          value="Oui"
          unchecked-value="Non"
          size="lg"
        >
          <span class="checkbox_text">Broncodilatateurs</span>
        </b-form-checkbox>
      </b-col>
      <b-col cols="2"></b-col>
      <b-col cols="4" class="treatment_element">
        <b-form-checkbox
          class="antihistaminiques_checkbox treatment_checkbox"
          v-model="autres_traitements"
          name="checkbox-antihistaminiques"
          size="lg"
        >
          <span class="checkbox_text">Autres</span>
        </b-form-checkbox>
      </b-col>
    </b-row>

    <b-row
      v-show="this.showOtherTreatmentInputField==true"
      style="margin-top:3%;"
      class="other_symptomes"
    >
      <b-col cols="4" class="first_name">
        <b-form-input
          class="other_symptomes_input input_field"
          v-model="autres_traitements_prescrits"
          type="text"
          name="other_symptomes"
          size="md"
          placeholder="Autres traitements"
        ></b-form-input>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapState } from 'vuex';
import SwitchBox from '@/components/fiche-individuelle/general/SwitchBox.vue';

export default {
  name: 'TreatmentSection',
  components: {
    SwitchBox
  },
  data: () => ({
    antibiotiques: '',
    antipaludiques: '',
    antipyretiques: '',
    antihistaminiques: '',
    broncodilatateurs: '',
    autres_traitements: '',
    autres_traitements_prescrits: '',
    showOtherTreatmentInputField: false
  }),
  mounted() {},
  computed: {
    ...mapState({
      traitement_prescrit: 'traitement_prescrit'
    })
  },
  watch: {
    autres_traitements(val) {
      if (val === true) {
        this.showOtherTreatmentInputField = true;
      } else {
        this.showOtherTreatmentInputField = false;
      }
    },
    antibiotiques(val) {
      this.$store.commit('setAntibiotiques', val);
    },
    antipaludiques(val) {
      this.$store.commit('setAntipaludiques', val);
    },
    antipyretiques(val) {
      this.$store.commit('setAntipyretiques', val);
    },
    antihistaminiques(val) {
      this.$store.commit('setAntihistaminiques', val);
    },
    broncodilatateurs(val) {
      this.$store.commit('setBroncodilatateurs', val);
    },
    autres_traitements_prescrits(val) {
      this.$store.commit('setAutresTraitementsPrescrits', val);
    }
  },
  methods: {}
};
</script>

<style scoped>
.treatment_content {
  margin-bottom: 2%;
}

.treatment {
  display: flex;
  justify-content: center;
  align-items: center;
}

.prescribed_treatment {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 62%;
  margin-left: 18%;
}

.treatment_element {
  box-shadow: none !important;
  outline: 0 !important;
  -webkit-box-shadow: none !important;
  box-sizing: border-box !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: flex-start !important;
  align-items: center !important;
  padding: 2px 0px !important;
  gap: 5px !important;
  background: #ebfcfd !important;
  border-radius: 5px !important;
  margin-top: 4% !important;
  padding-left: 15px !important;
  transform: scale(1);
  margin-left: -3%;
}

.treatment_element:active {
  border: none !important;
  box-shadow: none !important;
  outline: 0 !important;
  -webkit-box-shadow: none;
}

.treatment_checkbox {
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.checkbox_text {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: #425169;
  opacity: 0.85;
}

.prescribed_treatment_title {
  font-family: 'Eastman Roman Trial';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #425169;
  width: 95%;
  margin-bottom: 1%;
}

.other_symptomes {
  display: flex;
  margin-bottom: 1%;
  justify-content: center;
  width: 90%;
}

.input_field {
  width: 90%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 10px 10px 20px;
  gap: 10px;
  background: #ffffff;
  border: 2px solid #425169;
  border-radius: 5px;
  margin-left: 15%;
}
</style>
