<template>
  <b-container fluid class="page_content">

    <div class="header no_print">
      <Header />
    </div>

    <div v-show="show_no_results==true" class="result_section no_print">
      <div class="result_title">
        <span class="no_result_title_text">Numéro Epid non trouvé !</span>
      </div>
    </div>

    <div v-show="show_results==true && result!=null" class="result_section to_download_section">

        <div v-if="result" class="infos_fiche_block">
          <span class="date_label">Date de consultation : &nbsp; <span class="date_label"> {{result.date_envoie}} </span></span> 
          <span v-if="result.suspicion_et_diagnostic.prelevement.date_prelevement" class="date_label">Date de prélèvement : &nbsp; <span class="date_label"> {{result.suspicion_et_diagnostic.prelevement.date_prelevement}} </span></span>

          <span v-if="result.no_registre" style="margin-top:1%;" class="date_label">Numéro régistre : &nbsp; <span class="date_label"> 
            {{result.no_registre}} 
          </span></span>
        </div>


      <div class="result_title no_print">

        <div class="epid_block">
          <span v-if="result!=null" class="result_title_text">Numéro Epid {{result.epid}} trouvé !</span>

          <div @click="downloadRecord()" class="print_btn_row">
            <b-button class="print_btn shadow-none">
              <div class="print_btn_text">
                Imprimer
              </div>
              <img class="print_icn" src="@/assets/print_icn.png" alt="imprimer la fiche" />
            </b-button>
          </div>
        </div>

      </div>

        <div v-if="result" class="site_infos site_block">
          <span class="date_label">District - Site : </span> &nbsp; <span class="date_label"> {{result.nom_district}} / {{result.nom_site}} </span>
        </div>


      <div v-if="result!=null && show_record_title==true" class="record_title">

        <div class="s4_logo">
          <img class="s4_logo_img" width="70" src="@/assets/logo4s.png" />
        </div>

        <div class="title_text">
          4S - Fiche patient : {{result.epid}}
        </div>

      </div>

      <div class="personal_infos">
        <span class="personal_infos_title_text">Informations personnelles</span>
      </div>
      <div v-if="result!=null" class="personal_infos_content">
        <div style="margin-left:4%;" class="record_infos_block">
          <span style="position:relative; right:10%;" class="record_infos_block_title">Civilité</span>

          <div class="block_data">
            <div class="record_data">
              <span class="record_data_label">Nom patient </span>
              <span v-if="result.informations_personnelles.civilite.prenom && result.informations_personnelles.civilite.nom" class="record_data_value">
                {{result.informations_personnelles.civilite.prenom}} &nbsp; {{result.informations_personnelles.civilite.nom}}
              </span>
              <span v-else class="record_data_value">
                Non renseigné
              </span>
            </div>

            <div class="record_data age_data">
              <span class="record_data_label">Age</span>

              <span v-if="result.informations_personnelles.civilite.age_en_annee || result.informations_personnelles.civilite.age_en_mois" style="width:24%; display:flex;" class="record_data_value">
                  {{result.informations_personnelles.civilite.age_en_annee}} ans
                <span v-if="result.informations_personnelles.civilite.age_en_mois" class="record_data_value">
                  {{result.informations_personnelles.civilite.age_en_mois}} mois
                </span>
              </span>


              <span v-if="!result.informations_personnelles.civilite.age_en_annee && !result.informations_personnelles.civilite.age_en_mois" class="record_data_value">
                Non renseigné
              </span>
              
            </div>

          </div>
        </div>
        <div class="record_infos_block">
          <span
            style="position:relative; right:4%;"
            class="record_infos_block_title"
          >Adresses et contacts</span>
          <div class="block_data">
            <div class="record_data">
              <span class="record_data_label">Téléphone patient</span>
              <span v-if="result.informations_personnelles.adresse_et_contact.telephone1_patient" class="record_data_value">
                {{result.informations_personnelles.adresse_et_contact.telephone1_patient}}
              </span>
              <span v-else class="record_data_value">
                Non renseigné
              </span>
            </div>
            <div class="record_data">
              <span class="record_data_label">Profession patient</span>
              <span v-if="result.informations_personnelles.adresse_et_contact.profession" class="record_data_value">
                {{result.informations_personnelles.adresse_et_contact.profession}}
              </span>
              <span v-else class="record_data_value">
                Non renseigné
              </span>
            </div>
            <div class="record_data">
              <span class="record_data_label">Adresse patient</span>
              <span v-if="result.informations_personnelles.adresse_et_contact.adresse_patient" class="record_data_value">
                {{result.informations_personnelles.adresse_et_contact.adresse_patient}}
              </span>
              <span class="record_data_value" v-else>
                Non renseigné
              </span>
            </div>
          </div>
        </div>
        <div class="record_infos_block">
          <span class="record_infos_block_title">Situation matrimonial</span>
          <div style="position:relative; left:13%" class="block_data">
            <div class="record_data">
              <span class="record_data_label">statut du patient</span>
              <span v-if="result.informations_personnelles.statut_matrimonial.statut_matrimonial" class="record_data_value">
                {{result.informations_personnelles.statut_matrimonial.statut_matrimonial}}
              </span>
              <span v-else class="record_data_value">
                Non renseigné
              </span>
            </div>
            <div class="record_data">
              <span class="record_data_label">Genre</span>
              <span v-if="result.informations_personnelles.civilite.genre" class="record_data_value">
                {{result.informations_personnelles.civilite.genre}}
              </span>
              <span v-else class="record_data_value">
                Non renseigné
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="suspision_and_diagnostic">
        <span class="suspision_and_diagnostics_title_text">Suspicions et diagnostics</span>
      </div>

      <!-- Suspicion et diagnostic ligne 1 -->


      <div v-if="result!=null" class="suspision_and_diagnostic_content">

              <div class="record_infos_block">
          <span
            style="position:relative; right:10%;"
            class="record_infos_block_title"
          >Fievre & diarrhée</span>
          <div class="block_data">
            <div class="record_data">
              <span class="record_data_label">Fièvre</span>
              <span v-if="result.suspicion_et_diagnostic.diagnostic_et_prescription.fievre" class="record_data_value">
                {{result.suspicion_et_diagnostic.diagnostic_et_prescription.fievre}}
              </span>
              <span v-else class="record_data_value">
                Non
              </span>
            </div>
            <div class="record_data">
              <span class="record_data_label">Température</span>
              <span v-if="result.suspicion_et_diagnostic.diagnostic_et_prescription.temperature_fievre" class="record_data_value">
                {{result.suspicion_et_diagnostic.diagnostic_et_prescription.temperature_fievre}}
              </span>
              <span v-else class="record_data_value">
                Non
              </span>
            </div>
            <div class="record_data">
              <span class="record_data_label">Durée</span>
              <span v-if="result.suspicion_et_diagnostic.diagnostic_et_prescription.duree_fievre" class="record_data_value">
                {{result.suspicion_et_diagnostic.diagnostic_et_prescription.duree_fievre}}
              </span>
              <span v-else class="record_data_value">
                Non
              </span>
            </div>

            <hr style="width:68%; background:#000000;" />

            <div class="record_data">
              <span class="record_data_label">Diarrhée</span>
              <span v-if="result.suspicion_et_diagnostic.autres_syndromes.diarrhee" class="record_data_value">
                {{result.suspicion_et_diagnostic.autres_syndromes.diarrhee}}
              </span>
              <span v-else class="record_data_value">
                Non
              </span>
            </div>
            <div class="record_data">
              <span class="record_data_label">Diarrhée sanglante</span>
              <span v-if="result.suspicion_et_diagnostic.autres_syndromes.diarrhee_sanglante" class="record_data_value">
                {{result.suspicion_et_diagnostic.autres_syndromes.diarrhee_sanglante}}
              </span>
              <span v-else class="record_data_value">
                Non
              </span>
            </div>
          </div>
        </div>
        <div style="margin-left:4%;" class="record_infos_block">
          <span style="position:relative; right:10%;" class="record_infos_block_title">Syndrome grippal / Covid19</span>
          <div class="block_data">
            <div class="record_data">
              <span class="record_data_label">Toux</span>
              <span
                v-if="result.suspicion_et_diagnostic.syndrome_grippal_covid19.toux"
                class="record_data_value"
              >{{result.suspicion_et_diagnostic.syndrome_grippal_covid19.toux}}</span>
              <span v-else class="record_data_value">Non</span>
            </div>
            <div class="record_data">
              <span class="record_data_label">Dyspnée</span>
              <span
                v-if="result.suspicion_et_diagnostic.syndrome_grippal_covid19.dyspnee"
                class="record_data_value"
              >{{result.suspicion_et_diagnostic.syndrome_grippal_covid19.dyspnee}}</span>
              <span v-else class="record_data_value">Non</span>
            </div>
            <div class="record_data">
              <span class="record_data_label">Anorexie</span>
              <span
                v-if="result.suspicion_et_diagnostic.syndrome_grippal_covid19.anorexie"
                class="record_data_value"
              >{{result.suspicion_et_diagnostic.syndrome_grippal_covid19.anorexie}}</span>
              <span v-else class="record_data_value">Non</span>
            </div>
            <div class="record_data">
              <span class="record_data_label">Céphalées</span>
              <span
                v-if="result.suspicion_et_diagnostic.syndrome_grippal_covid19.cephalees"
                class="record_data_value"
              >{{result.suspicion_et_diagnostic.syndrome_grippal_covid19.cephalees}}</span>
              <span v-else class="record_data_value">Non</span>
            </div>
            <div class="record_data">
              <span class="record_data_label">Nausées</span>
              <span
                v-if="result.suspicion_et_diagnostic.syndrome_grippal_covid19.nausees"
                class="record_data_value"
              >{{result.suspicion_et_diagnostic.syndrome_grippal_covid19.nausees}}</span>
              <span v-else class="record_data_value">Non</span>
            </div>
            <div class="record_data">
              <span class="record_data_label">Vomissements</span>
              <span
                v-if="result.suspicion_et_diagnostic.syndrome_grippal_covid19.vomissement"
                class="record_data_value"
              >{{result.suspicion_et_diagnostic.syndrome_grippal_covid19.vomissement}}</span>
              <span v-else class="record_data_value">Non</span>
            </div>
            <div class="record_data">
              <span class="record_data_label">Perte d'odorat</span>
              <span
                class="record_data_value"
                v-if="result.suspicion_et_diagnostic.syndrome_grippal_covid19.perte_odorat"
              >{{result.suspicion_et_diagnostic.syndrome_grippal_covid19.perte_odorat}}</span>
              <span v-else class="record_data_value">Non</span>
            </div>
            <div class="record_data">
              <span class="record_data_label">Perte de goût</span>
              <span
                v-if="result.suspicion_et_diagnostic.syndrome_grippal_covid19.perte_de_gout"
                class="record_data_value"
              >{{result.suspicion_et_diagnostic.syndrome_grippal_covid19.perte_de_gout}}</span>
              <span v-else class="record_data_value">Non</span>
            </div>
          </div>
        </div>

        <div style="margin-right:5%;" class="record_infos_block">
          <span style="position:relative; right:10%;" class="record_infos_block_title">Arboviroses</span>
          <div class="block_data">
            <div class="record_data">
              <span class="record_data_label">Arthralgies</span>
              <span
                v-if="result.suspicion_et_diagnostic
                .arboviroses
                .arthralgies"
                class="record_data_value"
              >
                {{result.suspicion_et_diagnostic
                .arboviroses
                .arthralgies
                }}
              </span>
              <span v-else class="record_data_value">Non</span>
            </div>
            <div class="record_data">
              <span class="record_data_label">Céphalées</span>
              <span
                v-if="result.suspicion_et_diagnostic
                .arboviroses.cephalees"
                class="record_data_value"
              >
                {{result.suspicion_et_diagnostic
                .arboviroses.cephalees}}
              </span>
              <span v-else class="record_data_value">Non</span>
            </div>
            <div class="record_data">
              <span class="record_data_label">Myalgies</span>
              <span
                v-if="result.suspicion_et_diagnostic
                .arboviroses.myalgies"
                class="record_data_value"
              >
                {{result.suspicion_et_diagnostic
                .arboviroses.myalgies}}
              </span>
              <span v-else class="record_data_value">Non</span>
            </div>
            <div class="record_data">
              <span class="record_data_label">Eruption cutanée</span>
              <span
                v-if="result.suspicion_et_diagnostic.arboviroses
                .eruption_cutanee"
                class="record_data_value"
              >
                {{result.suspicion_et_diagnostic
                .arboviroses.eruption_cutanee}}
              </span>
              <span v-else class="record_data_value">Non</span>
            </div>
            <div class="record_data">
              <span class="record_data_label">Douleurs rétro orbitaire</span>
              <span v-if="result.suspicion_et_diagnostic.arboviroses.douleurs_retro_orbitaires" class="record_data_value">
                {{result.suspicion_et_diagnostic.arboviroses.douleurs_retro_orbitaires}}
              </span>
              <span v-else class="record_data_value">
                Non
              </span>
            </div>
            <div class="record_data">
              <span class="record_data_label">Manifestations hemorragiques</span>
              <span v-if="result.suspicion_et_diagnostic.arboviroses.manifestations_hemorragiques" class="record_data_value">
                {{result.suspicion_et_diagnostic.arboviroses.manifestations_hemorragiques}}
              </span>
              <span v-else class="record_data_value">
                Non
              </span>
            </div>
          </div>
        </div>

      </div>
      <br />

      <!-- Suspicion et diagnostic ligne 2 -->

      <div v-if="result!=null" class="suspision_and_diagnostic_content">
        <div style="margin-left:4%;" class="record_infos_block">
          <span style="position:relative; right:10%;" class="record_infos_block_title">Autres signes</span>
          <div class="block_data">
            <div class="record_data">
              <span class="record_data_label">Stridor</span>
              <span
                v-if="result.suspicion_et_diagnostic
                .autres_signes
                .stridor"
                class="record_data_value"
              >
                {{result.suspicion_et_diagnostic
                .autres_signes
                .stridor}}
              </span>
              <span v-else class="record_data_value">Non</span>
            </div>

            <div class="record_data">
              <span class="record_data_label">Tirage sous costal</span>
              <span
                v-if="result.suspicion_et_diagnostic
                .autres_signes
                .tirage_sous_costal"
                class="record_data_value"
              >
                {{result.suspicion_et_diagnostic
                .autres_signes
                .tirage_sous_costal}}
              </span>
              <span v-else class="record_data_value">Non</span>
            </div>
            <div class="record_data">
              <span class="record_data_label">Raideur de la nuque</span>
              <span
                v-if="result.suspicion_et_diagnostic
                .autres_signes
                .raideur_nuque"
                class="record_data_value"
              >
                {{result.suspicion_et_diagnostic
                .autres_signes
                .raideur_nuque}}
              </span>
              <span v-else class="record_data_value">Non</span>
            </div>
            <div class="record_data">
              <span class="record_data_label">Frissons</span>
              <span
                v-if="result.suspicion_et_diagnostic
                .autres_signes
                .frissons"
                class="record_data_value"
              >
                {{result.suspicion_et_diagnostic
                .autres_signes
                .frissons}}
              </span>
              <span v-else class="record_data_value">Non</span>
            </div>
            <div class="record_data">
              <span class="record_data_label">Convulsions</span>
              <span
                v-if="result.suspicion_et_diagnostic.autres_signes
                .convulsions"
                class="record_data_value"
              >
                {{result.suspicion_et_diagnostic
                .autres_signes
                .convulsions}}
              </span>
              <span v-else class="record_data_value">Non</span>
            </div>
            <div class="record_data">
              <span class="record_data_label">ictère</span>
              <span
                v-if="result.suspicion_et_diagnostic
                .autres_signes
                .ictere"
                class="record_data_value"
              >
                {{result.suspicion_et_diagnostic
                .autres_signes
                .ictere
                }}
              </span>
              <span v-else class="record_data_value">Non</span>
            </div>
            <div class="record_data">
              <span class="record_data_label">Refus de teter</span>
              <span
                v-if="result.suspicion_et_diagnostic
                .autres_signes
                .refus_de_teter"
                class="record_data_value"
              >
                {{result.suspicion_et_diagnostic
                .autres_signes
                .refus_de_teter}}
              </span>
              <span v-else class="record_data_value">Non</span>
            </div>

            <div class="record_data">
              <span class="record_data_label">Splénomégalie</span>
              <span
                v-if="result.suspicion_et_diagnostic
                .autres_signes
                .splenomegalie"
                class="record_data_value"
              >
                {{result.suspicion_et_diagnostic
                .autres_signes
                .splenomegalie}}
              </span>
              <span v-else class="record_data_value">Non</span>
            </div>
            <div class="record_data">
              <span class="record_data_label">Anémie clinique</span>
              <span
                v-if="result.suspicion_et_diagnostic
                .autres_signes
                .anemie_clinique"
                class="record_data_value"
              >
                {{result.suspicion_et_diagnostic
                .autres_signes
                .anemie_clinique}}
              </span>
              <span v-else class="record_data_value">Non</span>
            </div>

            <div class="record_data">
              <span class="record_data_label">Autres symptômes</span>
              <span
                v-if="result.suspicion_et_diagnostic
                .autres_signes
                .autres_symptomes"
                class="record_data_value"
              >
                {{result.suspicion_et_diagnostic
                .autres_signes
                .autres_symptomes}}
              </span>
              <span v-else class="record_data_value">Non</span>
            </div>
          </div>
        </div>

        <div style="margin-right:5%;" class="record_infos_block">
          <span
            style="position:relative; right:10%;"
            class="record_infos_block_title"
          >Facteurs de risque</span>
          <div class="block_data">
            <div class="record_data">
              <span class="record_data_label">Asthme</span>
              <span
                v-if="result.suspicion_et_diagnostic
                .facteurs_de_risque.asthme"
                class="record_data_value"
              >
                {{result.suspicion_et_diagnostic
                .facteurs_de_risque.asthme}}
              </span>
              <span v-else class="record_data_value">Non</span>
            </div>
            <div class="record_data">
              <span class="record_data_label">Diabète</span>
              <span
                v-if="result.suspicion_et_diagnostic
                .facteurs_de_risque.diabete"
                class="record_data_value"
              >
                {{result.suspicion_et_diagnostic
                .facteurs_de_risque.diabete}}
              </span>
              <span v-else class="record_data_value">Non</span>
            </div>
            <div class="record_data">
              <span class="record_data_label">Drépanocytose</span>
              <span
                v-if="result.suspicion_et_diagnostic
                .facteurs_de_risque.drepanocytose"
                class="record_data_value"
              >
                {{result.suspicion_et_diagnostic
                .facteurs_de_risque.drepanocytose}}
              </span>
              <span v-else class="record_data_value">Non</span>
            </div>
            <div class="record_data">
              <span class="record_data_label">Malnutrition</span>
              <span
                v-if="result.suspicion_et_diagnostic
                .facteurs_de_risque.malnutrition"
                class="record_data_value"
              >
                {{result.suspicion_et_diagnostic
                .facteurs_de_risque.malnutrition}}
              </span>
              <span v-else class="record_data_value">Non</span>
            </div>
            <div class="record_data">
              <span class="record_data_label">Obésité</span>
              <span
                v-if="result.suspicion_et_diagnostic
                .facteurs_de_risque.obesite"
                class="record_data_value"
              >
                {{result.suspicion_et_diagnostic
                .facteurs_de_risque.obesite}}
              </span>
              <span v-else class="record_data_value">Non</span>
            </div>

            <div class="record_data">
              <span class="record_data_label">HTA</span>
              <span
                v-if="result.suspicion_et_diagnostic
                .facteurs_de_risque.hta"
                class="record_data_value"
              >
                {{result.suspicion_et_diagnostic
                .facteurs_de_risque.hta}}
              </span>
              <span v-else class="record_data_value">Non</span>
            </div>
            <div class="record_data">
              <span class="record_data_label">Pneumopathie chronique</span>
              <span
                v-if="result.suspicion_et_diagnostic
                .facteurs_de_risque.pneumopathie_chronique"
                class="record_data_value"
              >
                {{result.suspicion_et_diagnostic
                .facteurs_de_risque.pneumopathie_chronique}}
              </span>
              <span v-else class="record_data_value">Non</span>
            </div>

            <div class="record_data">
              <span class="record_data_label">Autres facteurs</span>
              <span
                v-if="result.suspicion_et_diagnostic
                .facteurs_de_risque.autres_risques"
                class="record_data_value"
              >
                {{result.suspicion_et_diagnostic
                .facteurs_de_risque.autres_risques}}
              </span>
              <span v-else class="record_data_value">Non</span>
            </div>
          </div>
        </div>
        <div class="record_infos_block">

            <div v-if="result.suspicion_et_diagnostic.paludisme.tdr_palu" style="margin-bottom:3%; margin-top:3%;" class="paludisme">
              <span class="paludisme_title_text">Paludisme</span>
            </div>

            <div v-if="result.suspicion_et_diagnostic.paludisme.tdr_palu" class="record_data">
              <span class="record_data_label">TDR paludisme</span>
              <span
                v-if="result.suspicion_et_diagnostic.paludisme.tdr_palu"
                class="record_data_value"
              >{{result.suspicion_et_diagnostic.paludisme.tdr_palu}}</span>
              <span v-else class="record_data_value">Non</span>
            </div>

            <div v-if="result.suspicion_et_diagnostic.paludisme.tdr_palu" class="record_data">
              <span class="record_data_label">Résultat TDR palu</span>
              <span
                v-if="result.suspicion_et_diagnostic.paludisme.resultat_tdr_palu"
                class="record_data_value"
              >{{result.suspicion_et_diagnostic.paludisme.resultat_tdr_palu}}</span>
              <!-- <span v-else class="record_data_value">Non renseigné</span> -->
            </div>

            <hr v-if="result.suspicion_et_diagnostic.paludisme.tdr_palu" style="width:85%;" />

          <span
            style="position:relative; right:10%;"
            class="record_infos_block_title"
          >TDR Covid19</span>
          <div style="margin-top:1%;" class="block_data">
            <div class="record_data">
              <span class="record_data_label">TDR anticorps</span>
              <span v-if="result.suspicion_et_diagnostic.syndrome_grippal_covid19.tdr_covid_resultat_anticorps" class="record_data_value">
                  {{result.suspicion_et_diagnostic.syndrome_grippal_covid19.tdr_covid_resultat_anticorps}}
              </span>
              <span v-else class="record_data_value">
                  Non
              </span>
            </div>
            <div class="record_data">
              <span class="record_data_label">TDR antigènes</span>
              <span v-if="result.suspicion_et_diagnostic.syndrome_grippal_covid19.tdr_covid_resultat_antigenes" class="record_data_value">
                {{result.suspicion_et_diagnostic.syndrome_grippal_covid19.tdr_covid_resultat_antigenes}}
              </span>
              <span v-else class="record_data_value">
                Non
              </span>
            </div>
            <div class="record_data">
              <span class="record_data_label">IGA positif</span>
              <span
                v-if="result.suspicion_et_diagnostic.syndrome_grippal_covid19.iga_positif"
                class="record_data_value"
              >{{result.suspicion_et_diagnostic.syndrome_grippal_covid19.iga_positif}}</span>
              <span v-else class="record_data_value">Non</span>
            </div>
            <div class="record_data">
              <span class="record_data_label">IGG positif</span>
              <span
                v-if="result.suspicion_et_diagnostic.syndrome_grippal_covid19.igg_positif"
                class="record_data_value"
              >{{result.suspicion_et_diagnostic.syndrome_grippal_covid19.igg_positif}}</span>
              <span v-else class="record_data_value">Non</span>
            </div>
            <div class="record_data">
              <span class="record_data_label">IGM positif</span>
              <span
                v-if="result.suspicion_et_diagnostic.syndrome_grippal_covid19.igm_positif"
                class="record_data_value"
              >{{result.suspicion_et_diagnostic.syndrome_grippal_covid19.igm_positif}}</span>
              <span v-else class="record_data_value">Non</span>
            </div>
          </div>

        </div>
      </div>

      <!-- Suspicion et diagnostic ligne 3 -->

      <div
        v-if="result!=null"
        style="margin-top:2%;"
        class="suspision_and_diagnostic_content"
      >
        <div style="margin-left:4%; width:32%;" class="record_infos_block">
          <span style="position:relative; right:10%;" class="record_infos_block_title">Prescriptions</span>
          <div class="block_data">
            <div class="record_data">
              <span class="record_data_label">Antibiotiques</span>
              <span
                v-if="result.suspicion_et_diagnostic
                .traitements
                .antibiotiques"
                class="record_data_value"
              >
                {{result.suspicion_et_diagnostic
                .traitements
                .antibiotiques}}
              </span>
              <span v-else class="record_data_value">Non</span>
            </div>

            <div class="record_data">
              <span class="record_data_label">Antihistaminiques</span>
              <span
                v-if="result.suspicion_et_diagnostic
                .traitements
                .antihistaminiques"
                class="record_data_value"
              >
                {{result.suspicion_et_diagnostic
                .traitements
                .antihistaminiques
                }}
              </span>
              <span v-else class="record_data_value">Non</span>
            </div>
            <div class="record_data">
              <span class="record_data_label">Antipaludiques</span>
              <span
                v-if="result.suspicion_et_diagnostic
                .traitements
                .antipaludiques"
                class="record_data_value"
              >
                {{result.suspicion_et_diagnostic
                .traitements
                .antipaludiques}}
              </span>
              <span v-else class="record_data_value">Non</span>
            </div>
            <div class="record_data">
              <span class="record_data_label">Antipyretiques</span>
              <span
                v-if="result.suspicion_et_diagnostic
                .traitements
                .antipyretiques"
                class="record_data_value"
              >
                {{result.suspicion_et_diagnostic
                .traitements
                .antipyretiques}}
              </span>
              <span v-else class="record_data_value">Non</span>
            </div>
            <div class="record_data">
              <span class="record_data_label">Broncodilatateurs</span>
              <span
                v-if="result.suspicion_et_diagnostic.traitements
                .broncodilatateurs"
                class="record_data_value"
              >
                {{result.suspicion_et_diagnostic
                .traitements
                .broncodilatateurs}}
              </span>
              <span v-else class="record_data_value">Non</span>
            </div>

            <div class="record_data">
              <span class="record_data_label">Autres prescriptions</span>
              <span
                v-if="result.suspicion_et_diagnostic
                .traitements
                .autres_traitements_prescrits"
                class="record_data_value"
              >
                {{result.suspicion_et_diagnostic
                .traitements
                .autres_traitements_prescrits}}
              </span>
              <span v-else class="record_data_value">Non</span>
            </div>
          </div>
        </div>

        <div style="margin-right:5%; width:38%" class="record_infos_block">
          <span style="position:relative; right:10%;" class="record_infos_block_title">Vaccins</span>
          <div class="block_data">
            <div class="record_data">
              <span class="record_data_label">Fièvre jaune</span>
              <span
                v-if="result.suspicion_et_diagnostic
                .vaccins.vaccination_fievre_jaune"
                class="record_data_value"
              >
                {{result.suspicion_et_diagnostic
                .vaccins.vaccination_fievre_jaune}}
              </span>
              <span v-else class="record_data_value">Non</span>
            </div>
            <div class="record_data">
              <span class="record_data_label">Date vaccin fj</span>
              <span
                v-if="result.suspicion_et_diagnostic
                .vaccins.date_vaccin_fievre_jaune"
                class="record_data_value"
              >
                {{result.suspicion_et_diagnostic
                .vaccins.date_vaccin_fievre_jaune}}
              </span>
              <span v-else class="record_data_value">Non</span>
            </div>
            <div class="record_data">
              <span class="record_data_label">Vaccination Covid</span>
              <span
                v-if="result.suspicion_et_diagnostic
                .vaccins.vaccination_covid"
                class="record_data_value"
              >
                {{result.suspicion_et_diagnostic
                .vaccins.vaccination_covid}}
              </span>
              <span v-else class="record_data_value">Non</span>
            </div>
            <div class="record_data">
              <span class="record_data_label">Type vaccin Covid</span>
              <span
                v-if="result.suspicion_et_diagnostic
                .vaccins.type_vaccin_covid"
                class="record_data_value"
              >
                {{result.suspicion_et_diagnostic
                .vaccins.type_vaccin_covid}}
              </span>
              <span v-else class="record_data_value">Non</span>
            </div>
            <div class="record_data">
              <span class="record_data_label">Date 1ère dose covid</span>
              <span
                v-if="result.suspicion_et_diagnostic
                .vaccins.date_vaccination_premiere_dose"
                class="record_data_value"
              >
                {{result.suspicion_et_diagnostic
                .vaccins.date_vaccination_premiere_dose}}
              </span>
              <span v-else class="record_data_value">Non</span>
            </div>
            <div class="record_data">
              <span class="record_data_label">Date 2e dose covid</span>
              <span
                v-if="result.suspicion_et_diagnostic
                .vaccins.date_vaccination_deuxieme_dose && result.suspicion_et_diagnostic
                .vaccins.date_vaccination_deuxieme_dose!=null"
                class="record_data_value"
              >
                {{result.suspicion_et_diagnostic
                .vaccins.date_vaccination_deuxieme_dose}}
              </span>
              <span v-else class="record_data_value">Non</span>
            </div>

            <div class="record_data">
              <span class="record_data_label">Date 3e dose covid</span>
              <span
                v-if="result.suspicion_et_diagnostic
                .vaccins.date_vaccination_troisieme_dose && result.suspicion_et_diagnostic
                .vaccins.date_vaccination_troisieme_dose!=null"
                class="record_data_value"
              >
                {{result.suspicion_et_diagnostic
                .vaccins.date_vaccination_troisieme_dose}}
              </span>
              <span v-else class="record_data_value">Non</span>
            </div>
          </div>
        </div>
        <div class="record_infos_block">
          <span
            v-if="result.suspicion_et_diagnostic.syndrome_grippal_covid19.tdr_covid=='Oui'"
            style="position:relative; right:10%;"
            class="record_infos_block_title"
          >Résultat TDR Covid19</span>
          <div v-if="result.suspicion_et_diagnostic.syndrome_grippal_covid19.tdr_covid=='Oui'" class="block_data">
            <div class="record_data">
              <span class="record_data_label">TDR anticorps</span>
              <span v-if="result.suspicion_et_diagnostic.syndrome_grippal_covid19.tdr_covid_resultat_anticorps" class="record_data_value">
                {{result.suspicion_et_diagnostic.syndrome_grippal_covid19.tdr_covid_resultat_anticorps}}
              </span>
              <span v-else class="record_data_value">
                Non
              </span>
            </div>
            <div class="record_data">
              <span class="record_data_label">TDR antigènes</span>
              <span v-if="result.suspicion_et_diagnostic.syndrome_grippal_covid19.tdr_covid_resultat_antigenes" class="record_data_value">
                {{result.suspicion_et_diagnostic.syndrome_grippal_covid19.tdr_covid_resultat_antigenes}}
              </span>
              <span v-else class="record_data_value">
                Non
              </span>
            </div>
            <div class="record_data">
              <span class="record_data_label">IGA positif</span>
              <span
                v-if="result.suspicion_et_diagnostic.syndrome_grippal_covid19.iga_positif"
                class="record_data_value"
              >{{result.suspicion_et_diagnostic.syndrome_grippal_covid19.iga_positif}}</span>
              <span v-else class="record_data_value">Non</span>
            </div>
            <div class="record_data">
              <span class="record_data_label">IGG positif</span>
              <span
                v-if="result.suspicion_et_diagnostic.syndrome_grippal_covid19.igg_positif"
                class="record_data_value"
              >{{result.suspicion_et_diagnostic.syndrome_grippal_covid19.igg_positif}}</span>
              <span v-else class="record_data_value">Non</span>
            </div>
            <div class="record_data">
              <span class="record_data_label">IGM positif</span>
              <span
                v-if="result.suspicion_et_diagnostic.syndrome_grippal_covid19.igm_positif"
                class="record_data_value"
              >{{result.suspicion_et_diagnostic.syndrome_grippal_covid19.igm_positif}}</span>
              <span v-else class="record_data_value">Non</span>
            </div>
          </div>
        </div>
      </div>

      <div class="symptomalogie">
        <span class="symptomalogie_title_text">Cas similaire</span>
      </div>

      <div v-if="result!=null" class="symptomalogie_content">
        <span>
          Présence d'autres personnes avec même symptomalogie dans l'entourage :
          <span v-if="result.symptomalogie_entourage" style="font-weight:600;">
            {{result.symptomalogie_entourage}}
          </span>
          <span v-else style="font-weight:600;">
            Non renseigné
          </span>
        </span>
      </div>

      <div class="conduct_held">
        <span class="conduct_held_title_text">Conduite tenue</span>
      </div>
      <div v-if="result!=null" class="conduct_held_content">
        <span style="position: relative; right:1.8%; top:2%;">
          Conduite tenue :
          <span v-if="result.conduite_tenue" style="font-weight:600;">
            {{result.conduite_tenue}}
          </span>
          <span v-else style="font-weight:600;">
            Non renseigné
          </span>
        </span>
      </div>

      <div class="conduct_held">
        <span class="conduct_held_title_text">Notion de voyage</span>
      </div>
      <div style="margin-top:0.5%; margin-bottom:1%;" v-if="result!=null" class="conduct_held_content">

        <span style="position: relative; right:1.8%; top:2%;">
          Le patient a voyagé les 15 derniers jours :
          <span v-if="result.suspicion_et_diagnostic.notion_de_voyage" style="font-weight:600;">
            {{result.suspicion_et_diagnostic.notion_de_voyage.voyage_15jrs}}
          </span>
          <span v-else style="font-weight:600;">
            Non
          </span>
        </span>
        &nbsp;  &nbsp;  &nbsp;  &nbsp;
        <span v-if="result.suspicion_et_diagnostic.notion_de_voyage" style="position: relative; right:1.8%; top:2%;">
          Lieu de voyage :
          <span v-if="result.suspicion_et_diagnostic.notion_de_voyage.lieu_voyage" style="font-weight:600;">
            {{result.suspicion_et_diagnostic.notion_de_voyage.lieu_voyage}}
          </span>
        </span>

        &nbsp;  &nbsp;  &nbsp;  &nbsp;
        <span v-if="result.suspicion_et_diagnostic.notion_de_voyage" style="position: relative; right:1.8%; top:2%;">
          Date de voyage :
          <span v-if="result.suspicion_et_diagnostic.notion_de_voyage.date_voyage" style="font-weight:600;">
            {{result.suspicion_et_diagnostic.notion_de_voyage.date_voyage}}
          </span>
        </span>
      </div>

    </div>
    <div v-show="show_results==false" class="scrolling_text">
      <b-icon
        style="margin-right:4%; margin-left:2%; margin-top:1%;"
        font-scale="1.5"
        icon="info-circle"
      ></b-icon>
      <textra
        style="color:#272f89; font-size:1.2em; margin-top:1%;"
        :infinite="true"
        :data="texts"
        :timer="3"
        filter="bottom-top"
      />
    </div>
  </b-container>
</template>


<script>
import { mapState } from 'vuex';
import { eventBus } from '@/main.js';
import Header from '@/components/virologie/Header.vue';

export default {
  name: 'ViroPatientRecord',
  components: {
    Header
  },
  async mounted() {

    window.scrollTo(0, 0);

    this.$store.commit('initialize');

    eventBus.$on('showResult', record => {
      this.result = record;
      this.show_results = true;
      this.show_no_results = false;
    });

    eventBus.$on('showNoResult', () => {
      this.show_no_results = true;
      this.show_results = false;
      this.result=null;

      setTimeout(() => {
        this.show_no_results = false;
      }, 3000);
    });
  },
  methods: {
    downloadRecord() {

      this.show_record_title = true;

      let noPrintableElts = document.querySelectorAll(".no_print");

      noPrintableElts.forEach(element => {
        element.style.display = "none";
      });


      document.querySelector(".infos_fiche_block").style.display="flex";

      document.querySelector(".site_block").classList.add("site_default_style");

      setTimeout(() => {
        print();
        location.reload();
      }, 500);
    }
  },
  computed: {
    ...mapState({
      user: 'user',
      username: 'username'
    })
  },
  data() {
    return {
      texts: [
        'Institut Pasteur de Dakar : réseau digital 4S ',
        'Pour consulter un fiche patient suivez ces instructions :',
        'Etape 1 : Appuyer sur la barre de recherche tout en haut',
        'Etape 2 : Saisir le numéro EPID du patient',
        "Etape 3 : Appuyer sur l'icone de recherche pour voir les résultats"
      ],
      show_results: false,
      show_no_results: false,
      show_record_title: false,
      result: null
    };
  }
};
</script>

<style scoped >

@import url('http://fonts.cdnfonts.com/css/product-sans');
.page_content {
  width: 100%;
  padding: 0;
  background: linear-gradient(0deg, #eff2f7, #eff2f7),
    linear-gradient(180deg, #ffffff 61.09%, #eefbff 100%);

  height: 100vh;
}

.scrolling_text {
  display: flex;
  position: absolute;
  top: 42%;
  margin-left: 27%;
  background: #ffffff;
  border-radius: 10px;
  width: 45%;
  height: 9%;
}

.result_section {
  display: flex;
  flex-direction: column;
  display: flex;
  align-items: unset;
  height: 100%;
}

.result_title {
  width: 100%;
  background: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.result_title_text,
.no_result_title_text {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Product Sans', sans-serif;
  font-size: 22px;
  font-weight: 600;
  color: #3cb691;
  margin-right: 3.5%;
}

.no_result_title_text {
  color: #ff0000;
}

.personal_infos,
.suspision_and_diagnostic,
.symptomalogie,
.conduct_held,
.paludisme {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.symptomalogie{
  margin-top: 2%;
}

.conduct_held{
  margin-top: 2%;
}

.personal_infos_title_text,
.suspision_and_diagnostics_title_text,
.symptomalogie_title_text,
.conduct_held_title_text,
.paludisme_title_text {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Product Sans', sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: #0d1c2e;
  margin-right: 3.5%;
  opacity: 0.8;
}

.paludisme_title_text {
  opacity: 1;
  margin-right: 18%;
  font-weight: 600;
  color: #0d1c2e;
}

.personal_infos_content,
.suspision_and_diagnostic_content,
.symptomalogie_content,
.conduct_held_content {
  width: 100%;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding-bottom: 8px;
}

.suspision_and_diagnostic_content {
  align-items: flex-start;
  padding-bottom: 10px;
}

.personal_infos_content {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.record_infos_block {
  margin-top: 2%;
  font-family: 'Product Sans', sans-serif;
  font-size: 17px;
  font-weight: 600;
  color: #0d1c2e;
  opacity: 0.8;
  width: 30%;
  text-align: center;
}
.record_infos_block:nth-child(1) {
  margin-left: 2%;
}

.record_infos_block:nth-child(2) {
  position: relative;
  left: 3%;
}
.record_infos_block:nth-child(3) {
  margin-right: 2%;
}

.block_data {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 3%;
  font-family: 'Product Sans', sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #0d1c2e;
  opacity: 0.9;
  margin-top: 11%;
}

.record_infos_block{
  width: 30%;
}

.record_data {
  display: flex;
  width: 100%;
  justify-content: baseline;
  align-items: baseline;
}

.record_data_label {
  width: 38%;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: normal;
}

.record_data_value {
  margin-left: 25%;
  align-self: baseline;
}

.separator {
  position: relative;
  bottom: 2%;
}

.print_btn{
  background: #24BA73 !important;
  border-radius: 5px;
}

.print_btn_text{
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: flex-end;
  text-align: center;
  color: #FFFFFF;
}

.print_btn_row{
  margin-top: 1%;
  margin-bottom: 1%;
  margin-right: 2%;
}

.print_btn_row:active{
  transform: scale(0.96);
}

.print_icn{
  transform: scale(0.6);
}

.record_title{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 1%;
  margin-bottom: 1%;
}

.title_text{
  margin-left: 2%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Product Sans', sans-serif;
  font-size: 22px;
  font-weight: 600;
  color: #0D1C2E;
  margin-right: 3.5%;
}

.epid_block{
  width: 35%;
}

.site_infos{
  position: relative;
  right: -32%;
  top: -8%;
}

.infos_fiche_block{
  position: relative;
  right: 6%;
  top: 8%;
  display: flex;
  flex-direction: column;
  width: 35%;
}

.date_label{
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}

.date_prelev{
  border: 2px solid red;
  margin-left: 32%;
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  text-align: start;
  width: 40%;
}

.date_prelev_wrapper{
  border: 2px solid red;
  position: relative;
  right: 6%;
  top: 8%;
  width: 35%;

}

.site_default_style{
  position: relative;
  right: -35%;
  top: 5%;
}
</style>