import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {

    //global variables state
    isAuthenticated: false,
    token: "",
    user: "",
    showResult:false,
    username:"",
    notification_number:0,
    challenges:["Remplir le récapitulatif journalier"],
    percentage_of_success:100,
    rotation_angle:0,
    uncompleted_records:[], 
    individual_records_number:0,
    agregated_records_number:0,



    //fever_form and consultation behavior state
    isFeverForm:true,
    consulting_date:null,
    isConsulting : false,
    showHealerSection : false,
    showPersonalInfosSection : true,
    showSuspisionSection  : false,
    showSuspisionAndDiagnosticInfos:false,
    showSuspisionOnboardSection:false,
    showSymptomalogieSection  : false,
    showConductSection  : false,
    showCivilitySection : true,
    showAdressAndContactSection :false,
    showMatrimonialStatusSection :false,
    showCommandButtons:true,

    //civility section behavior variables
    showPregnantSelectBox:false,
    showGestationalAgeField:false,

    //personal infos information variables
    epid :"",
    no_registre:"",
    prenom:"",
    nom:"",
    date_de_naissance: "",
    genre:"",
    age_year:"",
    age_month:"",
    femme_enceinte:"",
    age_gestationnel:"",
    telephone1_patient:"",
    profession:"",
    adresse_patient:"",
    statut_matrimonial:"",

    //diagnostic et prescription
    showDiagnosticAndPrescriptionSection: false,
    fievre:'',
    temperature_fievre:'',
    duree_fievre:'',
    diarrhee_sanglante:'',
    traitement_prescrit:'',
    antibiotiques:'',
    antipaludiques:'',
    antipyretiques:'',
    antihistaminiques:'',
    broncodilatateurs:'',
    autres_traitements_prescrits:'',
    showDiarrheeSanglanteQuestion: false,

    //paludism variables
    tdr_palu: null,
    resultat_tdr_palu:'',
    tdr_covid: null,
    resultat_covid_19:'',
    showPaludismSection: false,

    //influenza variables
    showFluSyndromSection: false,
    tdr_covid_antigene_result:'',
    tdr_covid_anticorps_result:'',
    igm_positif:'',
    iga_positif:'',
    igg_positif:'',

    //covid-19 variables 
    showCovid19Section:false,
    anorexie: '',
    cephalees: '',
    dyspnee: '',
    ecoulement_nasal: '',
    nausees: '',
    perte_odorat: '',
    perte_de_gout: '',
    toux: '',
    vomissement: '',
    mal_de_gorge:'',

    //arbovirose variables : (+cephalees)
    showArboSection:false,
    arthralgies: '',
    eruption_cutanee: '',
    douleurs_retro_orbitaires: '',
    myalgies: '',
    manifestations_hemorragiques: '',

    //other symptomes variables :

    showOtherSymptomesSection:false,
    convulsion: '',
    frisson: '',
    stridor: '',
    tirage_sous_costal: '',
    ictere: '',
    raideur_nuque: '',
    anemie_clinique: '',
    refus_de_teter: '',
    splenomegalie: '',
    autres_symptomes: '',

    //risk factors variables

    showRiskFactorsSection:false,
    diabete: '',
    asthme: '',
    drepanocytose: '',
    hta: '',
    malnutrition: '',
    obesite: '',
    pneumopathie_chronique: '',
    autres_risques: '',

    //vaccines variables

    showVaccinesSection:false,
    vaccination_fj: '',
    date_vaccin_fj:null,
    vaccination_covid:'',
    type_vaccin:'',
    etat_vaccin_fj:'',
    etat_vaccin_covid:'',
    info_vaccin_covid:'',
    first_dose_date:null,
    second_dose_date:null,
    third_dose_date:null,

    // travel notion
    voyage_15jrs: null,
    lieu_voyage: null,
    date_voyage: null,

    //tests / prélèvements

    showTestsSection:false,
    prelevement:'',
    prelevement_urgent:'',
    type_prelevement:'',
    date_prelevement:null,
    suggest_nasal_sampling: false,
    suggest_throat_sampling: false,
    suggest_blood_sampling: false,

    //symptomalogie section variables
    symptomalogie_entourage:'',

    //conduct held variables
    conduite_tenue:'',

    //dashboard truth state
    show_surveillance_view : true,
    show_alerts_view : false,
    isStructuresLoaded : false,
    isStructuresSelected : false,

    //chart js : bar group chart 

    //Bar chart list
    bar_chart_list :[
      {
      data: {
        labels: [],
        datasets: [
        ]
      },
      options: {
        plugins:{
          title: [{
            display: true,
            text: 'Stacked Group Bar Chart'
          }],
          tooltips: {
            mode: 'index',
            intersect: true
          },
          responsive: true,
          legend: {
            display: true,
            position: 'bottom'
            },
          scales: {
            x: {
              stacked: true,
            },
            y: {
              stacked: true
            }
          }	
      }
      }
    }
  ],

    //Line chart
    line_chart_list: [
      {
      data: {
        labels: [],
        datasets: []
      },
      options: {
        fill: true,
        plugins:{
          responsive: true,
          maintainAspectRatio : false,
          legend: {
            "position" : 'bottom'
            },
          title: {
            display: false,
            text: 'mettre le titre ici'
          },
          tooltips: {
            mode: 'index',
            intersect: false
          },
          hover: {
            mode: 'nearest',
            intersect: true
          },
          scales: {
            xAxes: [{
              labelAngle: 0,
              ticks: {
                padding: 0,
              },
              labelTextAlign: 'center',
              display: true,
              scaleLabel: {
                display: false,
                labelString: 'x_label'
              },
              align:'center',
              gridLines: {
                offsetGridLines: false,
                drawOnChartArea: false,
              }
            }],
            yAxes: [{
              display: true,
              scaleLabel: {
                display: false,
                labelString: 'y_label'
              }
            }]		
          }	
      }
      }
    }
  ],
    //pie chart

    pie_chart: {
      data: {
        labels: [],
        datasets: [{
            data: [],
            backgroundColor: [
            '#8E8BF4',
            '#A8FFCB'
            ],
            hoverOffset: 4
        }]
      },
      options: {
        plugins : {
          labelOffset:-5,
          responsive: true,
          legend: {
            display: false,
            "position" : "bottom"
            },
          title: {
            display: true,
            text: ''
          },
          tooltips: {
            mode: 'index',
            intersect: false
          },
          hover: {
            mode: 'point',
            intersect: true
          },
          scales: {
            xAxes: [{
              ticks: {
                  display: false
              },
              labelTextAlign: 'center',
              display: true,
              scaleLabel: {
                display: false,
                labelString: 'x_label'
              },
              align:'center',
            }],
            yAxes: [{
                          ticks: {
                  display: false
              },
              display: true,
              scaleLabel: {
                display: false,
                labelString: 'y_label'
              }
            }]		
          }	
        }
      }
    },
    total_patient_consultes: 0,
    total_febriles_consultes: 0,
    total_tdr_palu_realises: 0,
    total_cas_palu_confirmes: 0,
    total_syndromes_grippaux: 0,
    total_suspicions_arbos: 0,
    total_cas_diarrhees: 0,
    total_cas_diarrhees_febriles: 0,
    total_cas_diarrhees_sanglantes: 0,
    age_interval_list:[],
    sites_list:[],
    selected_sites:[],
    syndromes_list:[
      {id:0, nom:"Paludisme"},
      {id:1, nom:"Fièvre"},
      {id:2, nom:"Grippe/Covid19"},
      {id:3, nom:"Arbovirose"},
      {id:4, nom:"Diarrhées"}
    ],
    districts_list:[],
    current_syndrome : 'Paludisme',
    total_urgent_samples :0,

    sites_to_filter: [],
    districts_to_filter: [],

    //charts params :

    chart_view: '',
    chart_group_by: '',
    report_group_by: '',

    //carousel

    show_carousel_right_btn: false,
    show_carousel_left_btn: false,

    //loading states
    isIndicatorsLoaded: false,
    isHealthStructureLoaded: false,
    isMapUpdated: false,
    isFilterLoaded: false,
    isLoading: true, //loading until the last operation is done
    filter_date_debut: new Date(new Date().getFullYear(),new Date().getMonth(),1).toISOString().substr(0, 10),
    filter_date_fin: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)

  },
  mutations: {
   async initialize(state) {
      if (localStorage.getItem("token")) {
        state.token = localStorage.getItem("token");
        state.isAuthenticated = true;
        state.user = JSON.parse(localStorage.getItem("user"));
        // state.username=state.user.nom_utilisateur.split(/[ ,-/_]+/)[0];
        state.username=state.user.nom_utilisateur;
        // state.username=state.user.prenom + " " + state.user.nom;
      }
      else {
        state.token = "";
        state.isAuthenticated = false;
      }

      if(!localStorage.getItem("age_interval_list")){
        let age_interval_list = [
          {id:0, value : "moins de 1 an"},
          {id:1, value : "1 - 4 ans"},
          {id:2, value : "5 - 14 ans"},
          {id:3, value : "15 - 24 ans"},
          {id:4, value : "plus de 25 ans"},
          {id:5, value : "non définie"}
        ];
        localStorage.setItem("age_interval_list", JSON.stringify(age_interval_list));
        state.age_interval_list = age_interval_list;
      }else{
        let age_interval_list = JSON.parse(localStorage.getItem("age_interval_list"));
        state.age_interval_list=age_interval_list;
      }
    },
    setToken(state, token) {
      state.token = token;
    },
    removeToken(state) {
      state.token = '';
    },
    setUser(state, user) {
      state.user = user;
    },
    removeUser(state) {
      state.user = [];
      localStorage.removeItem("user");
    },
    setDistrict(state,value) {
      state.district=value
    },
    setSite(state,value) {
      state.site=value
    },
    setShowCommandButtons(state,value){
      state.showCommandButtons=value
    },
    setShowResult(state, status) {
      state.showResult = status;
    },
    changeArrowAngle(state, value){
      state.rotation_angle = value;
    },
    setUsername(state, value){
      state.username = value;
    },
    setIsAuthenticated(state, value){
      state.isAuthenticated = value;
    },
    setIsConsulting(state, value){
      state.isConsulting=value
    },
    setConsultingDate(state, value){
      state.consulting_date=value
    },
    setShowHealerSection(state, value){
      state.showHealerSection=value
    },
    hidePersonalInfosSection(state){
      state.showPersonalInfosSection=false
    },
    hideSuspisionSection(state){
      state.showSuspisionSection=false
    },
    hideSymptomalogieSection(state){
      state.showSymptomalogieSection=false
    },
    hideConductSection(state){
      state.showConductSection=false
    },
    setShowPersonalInfosSection(state, value){
      state.showPersonalInfosSection=value
    },
    setShowSuspisionOnboardSection(state, value){
      state.showSuspisionOnboardSection=value
    },
    setShowSuspisionSection(state, value){
      state.showSuspisionSection=value
    },
    setShowSuspisionAndDiagnosticInfos(state,value){
      state.showSuspisionAndDiagnosticInfos=value
    },
    setShowSymptomalogieSection(state, value){
      state.showSymptomalogieSection=value
    },
    setShowConductSection(state, value){
      state.showConductSection=value
    },
    hideCivilitySection(state){
      state.showCivilitySection=false
    },
    setShowCivilitySection(state, value){
      state.showCivilitySection=value
    },
    hideAdressAndContactSection(state){
      state.showAdressAndContactSection=false
    },
    setShowAdressAndContactSection(state, value){
      state.showAdressAndContactSection=value
    },
    hideMatrimonialStatusSection(state){
      state.showMatrimonialStatusSection=false
    },
    setShowMatrimonialStatusSection(state, value){
      state.showMatrimonialStatusSection=value
    },
    setShowCivilityIncompleteIcon(state, value){
      state.showCivilityIncompleteIcon=value
    },
    setShowPregnantSelectBox(state,value){
      state.showPregnantSelectBox=value
    },
    setShowGestationalAgeField(state,value){
      state.showGestationalAgeField=value
    },
    setShowChildrenNumberField(state,value){
      state.showChildrenNumberField=value
    },
    setPrenom(state,value){
      state.prenom=value
    },
    setNom(state,value){
      state.nom=value
    },
    setDateDeNaissance(state,value){
      state.date_de_naissance=value
    },
    setGenre(state,value){
      state.genre=value
    },
    setAgeYear(state,value){
      state.age_year=value
    },
    setAgeMonth(state,value){
      state.age_month=value
    },
    setFemmeEnceinte(state,value){
      state.femme_enceinte=value
    },
    setAgeGestationnel(state,value){
      state.age_gestationnel=value
    },
    setTelephone1Patient(state,value){
      state.telephone1_patient=value
    },
    setTelephone2Patient(state,value){
      state.telephone2_patient=value
    },
    setAdressePatient(state,value){
      state.adresse_patient=value
    },
    setStatutMatrimonial(state,value){
      state.statut_matrimonial=value
    },
    setShowSuspisionSidebar(state,value){
      state.showSuspisionSidebar=value
    },
    setFievre(state,value){
      state.fievre=value
    },
    setDiarrhee(state,value){
      if(value=='Oui'){
        state.showDiarrheeSanglanteQuestion=true;
      }else{
        state.showDiarrheeSanglanteQuestion=false;
        state.diarrhee_sanglante='Non'
      }
      state.diarrhee=value
    },
    setTemperatureFievre(state,value){
      state.temperature_fievre=value
    },
    setFeverDuration(state,value){
      state.duree_fievre=value
    },
    setDiarrheeSanglante(state,value){
      state.diarrhee_sanglante=value
    },
    setTraitementPrescrit(state,value){
      state.traitement_prescrit=value
    },
    setAntibiotiques(state,value){
      state.antibiotiques=value
    },
    setAntipaludiques(state,value){
      state.antipaludiques=value
    },
    setAntipyretiques(state,value){
      state.antipyretiques=value
    },
    setAntihistaminiques(state,value){
      state.antihistaminiques=value
    },
    setBroncodilatateurs(state,value){
      state.broncodilatateurs=value
    },
    setAutresTraitementsPrescrits(state,value){
      state.autres_traitements_prescrits=value
    },
    setTdrPalu(state,value){
      state.tdr_palu=value
    },
    setResultatTdrPalu(state,value){
      state.resultat_tdr_palu=value
    },
    setShowDiagnosticAndPrescriptionSection(state,value){
      state.showDiagnosticAndPrescriptionSection=value
    },
    setShowPaludismSection(state,value){
      state.showPaludismSection=value
    },
    hideDiagnosticAndPrescriptionSection(state){
      state.showDiagnosticAndPrescriptionSection=false
    },
    hidePaludismSection(state){
      state.showPaludismSection=false
    },
    setShowFluSyndromSection(state,value){
      state.showFluSyndromSection=value
    },
    setTdrCovid(state,value){
      state.tdr_covid=value
    },
    setResultatCovid19(state,value){
      state.resultat_covid_19=value
    },
    hideFluSyndromeSection(state){
      state.showFluSyndromSection=false;
    },
    setIsTdrCovidVariableUpdated(state,value){
      state.is_tdr_covid_variable_updated=value
    },
    setTdrCovidAntigeneResult(state,value){
      state.tdr_covid_antigene_result=value
    },
    setTdrCovidAnticorpsResult(state,value){
      state.tdr_covid_anticorps_result=value
    },
    setIgmPositif(state,value){
      state.igm_positif=value
    },
    setIgaPositif(state,value){
      state.iga_positif=value
    },
    setIggPositif(state,value){
      state.igg_positif=value
    },
    setShowInfluenzaCompleteIcon(state,value){
      state.showInfluenzaCompleteIcon=value
    },
    setShowInfluenzaIncompleteIcon(state,value){
      state.showInfluenzaIncompleteIcon=value
    },
    setShowCovid19Section(state,value){
      state.showCovid19Section=value
    },
    hideCovid19Section(state){
      state.showCovid19Section=false
    },
    setAnorexie(state,value){
      state.anorexie=value
    },
    setCephalees(state,value){
      state.cephalees=value
    },
    setDyspnee(state,value){
      state.dyspnee=value
    },
    setEcoulementNasal(state,value){
      state.ecoulement_nasal=value
    },
    setNausees(state,value){
      state.nausees=value
    },
    setPerteOdorat(state,value){
      state.perte_odorat=value
    },
    setPerteDeGout(state,value){
      state.perte_de_gout=value
    },
    setToux(state,value){
      state.toux=value
    },
    setVomissement(state,value){
      state.vomissement=value
    },
    setMalDeGorge(state,value){
      state.mal_de_gorge=value
    },
    setShowArboSection(state,value){
      state.showArboSection=value
    },
    setShowArboCompleteIcon(state,value){
      state.showArboCompleteIcon=value
    },
    setShowArboIncompleteIcon(state,value){
      state.showArboIncompleteIcon=value
    },
    hideArboviroseSection(state){
      state.showArboSection=false
    },
    setArthralgies(state,value){
      state.arthralgies=value
    },
    setEruptionCutanee(state,value){
      state.eruption_cutanee=value
    },
    setDouleursRetroOrbitaires(state,value){
      state.douleurs_retro_orbitaires=value
    },
    setMyalgies(state,value){
      state.myalgies=value
    },
    setManifestationsHemorragiques(state,value){
      state.manifestations_hemorragiques=value
    },
    setIsArboviroseSectionVisited(state,value){
      state.is_arbovirose_section_visited=value
    },
    setShowOtherSymptomesSection(state,value){
      state.showOtherSymptomesSection=value
    },
    setIsOtherSymptomesSectionVisited(state,value){
      state.is_other_symptomes_section_visited=value
    },
    hideOtherSymptomesSection(state){
      state.showOtherSymptomesSection=false
    },
    setConvulsion(state,value){
      state.convulsion=value
    },
    setFrisson(state,value){
      state.frisson=value
    },
    setStridor(state,value){
      state.stridor=value
    },
    setTirageSousCostal(state,value){
      state.tirage_sous_costal=value
    },
    setIctere(state,value){
      state.ictere=value
    },
    setRaideurNuque(state,value){
      state.raideur_nuque=value
    },
    setAnemieClinique(state,value){
      state.anemie_clinique=value
    },
    setRefusDeTeter(state,value){
      state.refus_de_teter=value
    },
    setSplenomegalie(state,value){
      state.splenomegalie=value
    },
    setAutresSymptomes(state,value){
      state.autres_symptomes=value
    },
    setShowRiskFactorsSection(state,value){
      state.showRiskFactorsSection=value
    },
    setIsRiskFactorsSectionVisited(state,value){
      state.is_risk_factors_section_visited=value
    },
    setDiabete(state,value){
      state.diabete=value
    },
    setAsthme(state,value){
      state.asthme=value
    },
    setDrepanocytose(state,value){
      state.drepanocytose=value
    },
    setHta(state,value){
      state.hta=value
    },
    setMalnutrition(state,value){
      state.malnutrition=value
    },
    setObesite(state,value){
      state.obesite=value
    },
    setPneumopathieChronique(state,value){
      state.pneumopathie_chronique=value
    },
    setAutresRisques(state,value){
      state.autres_risques=value
    },
    hideRiskFactorsSection(state){
      state.showRiskFactorsSection=false
    },
    setShowVaccinesSection(state,value){
      state.showVaccinesSection=value
    },
    setVaccinationFj(state,value){
      state.vaccination_fj=value
    },
    setEtatVaccinFJ(state,value){
      state.etat_vaccin_fj=value
    },
    setEtatVaccinCovid(state,value){
      state.etat_vaccin_covid=value
    },
    setDateVaccinFj(state,value){
      state.date_vaccin_fj=value
    },
    setVaccinationCovid(state,value){
      state.vaccination_covid=value
    },
    setCovidFirstDoseDate(state,value){
      state.first_dose_date=value
    },
    setCovidSecondDoseDate(state,value){
      state.second_dose_date=value
    },
    setCovidThirdDoseDate(state,value){
      state.third_dose_date=value
    },
    setTypeVaccin(state,value){
      state.type_vaccin=value
    },
    hideVaccinesSection(state){
      state.showVaccinesSection=false
    },
    setShowTestsSection(state,value){
      state.showTestsSection=value
    },
    setShowTestsCompleteIcon(state,value){
      state.showTestsCompleteIcon=value
    },
    setIsTestsSectionVisited(state,value){
      state.is_tests_section_visited=value
    },
    hideTestsSection(state){
      state.showTestsSection=false
    },
    setPrelevement(state,value){
      state.prelevement=value
    },
    setPrelevementUrgent(state,value){
      state.prelevement_urgent=value
    },
    setTypePrelevement(state,value){
      state.type_prelevement=value
    },
    setDatePrelevement(state,value){
      state.date_prelevement=value
    },
    setSymptomalogieEntourage(state,value){
      state.symptomalogie_entourage=value
    },
    setConduiteTenue(state,value){
      state.conduite_tenue=value
    },
    setIndividualRecordsNumber(state,value){
      state.individual_records_number=value
    },
    setAgregatedRecordsNumber(state,value){
      state.agregated_records_number=value
    },
    setEpid(state,value){
      state.epid=value
    },
    setNoRegistre(state,value){
      state.no_registre=value
    },
    setShowSurveillanceView(state,value){
      state.show_surveillance_view=value
    },
    setShowAlertsView(state,value){
      state.show_alerts_view=value
    },
    setTotalPatientConsultes(state,value){
      state.total_patient_consultes=value
    },
    setTotalFebrilesConsultes(state,value){
      state.total_febriles_consultes=value
    },
    setTotalTdrPaluRealises(state,value){
      state.total_tdr_palu_realises=value
    },
    setTotalCasPaluConfirmes(state,value){
      state.total_cas_palu_confirmes=value
    },
    setTotalSyndromesGrippaux(state,value){
      state.total_syndromes_grippaux=value
    },
    setTotalSuspicionsArbos(state,value){
      state.total_suspicions_arbos=value
    },
    setTotalCasDiarrhees(state,value){
      state.total_cas_diarrhees=value
    },
    setTotalCasDiarrheesFebriles(state,value){
      state.total_cas_diarrhees_febriles=value
    },
    setTotalCasDiarrheesSanglantes(state,value){
      state.total_cas_diarrhees_sanglantes=value
    },
    setSitesList(state,value){
      state.sites_list=value
    },
    setDistrictsList(state,value){
      state.districts_list=value
    },
    setSelectedSites(state,value){
      state.selected_sites=value
    },
    setChartView(state,value){
      state.chart_view=value
    },
    setChartGroupBy(state,value){
      state.chart_group_by=value
    },
    setReportGroupBy(state,value){
      state.report_group_by=value
    },
    setBarChart(state,value){
      state.bar_chart=value
    },
    setShowCarouselRightBtn(state,value){
      state.show_carousel_right_btn=value
    },
    setShowCarouselLeftBtn(state,value){
      state.show_carousel_left_btn=value
    },
    setIsIndicatorsLoaded(state,value){
      state.isIndicatorsLoaded=value
    },
    setIsHealthStructureLoaded(state,value){
      state.isHealthStructureLoaded=value
    },
    setIsMapUpdated(state,value){
      state.isMapUpdated=value
    },
    setIsFilterLoaded(state,value){
      state.isFilterLoaded=value
    },
    setIsLoading(state,value){
      state.isLoading=value
    },
    setCurrentSyndrome(state,value){
      state.current_syndrome=value
    },
    setTotalUrgentSamples(state,value){
      state.total_urgent_samples=value
    },
    setSuggestNasalSampling(state,value){
      state.suggest_nasal_sampling=value
    },
    setSuggestThroatSampling(state,value){
      state.suggest_throat_sampling=value
    },
    setSuggestBloodSampling(state,value){
      state.suggest_blood_sampling=value
    },
    setIsStructuresLoaded(state,value){
      state.isStructuresLoaded = value
    },
    setIsStructuresSelected(state,value){
      state.isStructuresSelected = value
    },
    setSitesToFilter(state,value){
      state.sites_to_filter = value
    },
    setDistrictsToFilter(state,value){
      state.districts_to_filter = value
    },
    setFilterDateDebut(state,value){
      state.filter_date_debut = value
    },
    setFilterDateFin(state,value){
      state.filter_date_fin = value
    },
    setVoyage15jrs(state,value){
      state.voyage_15jrs = value
    },
    setLieuVoyage(state,value){
      state.lieu_voyage = value
    },
    setDateVoyage(state,value){
      state.date_voyage = value
    },
    setProfession(state,value){
      state.profession = value
    },
    setShowDiarrheeSanglanteQuestion(state, value){
      state.showDiarrheeSanglanteQuestion = value
    }

  },
  actions: {

    resetVariables(context){
      context.commit('setEpid','')
      context.commit('setNoRegistre','')
      context.commit('setPrenom','')
      context.commit('setNom','')
      context.commit('setDateDeNaissance','')
      context.commit('setGenre','')
      context.commit('setAgeYear','')
      context.commit('setAgeMonth','')
      context.commit('setFemmeEnceinte','')
      context.commit('setAgeGestationnel','')
      context.commit('setTelephone1Patient','')
      context.commit('setTelephone2Patient','')
      context.commit('setAdressePatient','')
      context.commit('setStatutMatrimonial','')
      context.commit('setFievre','')
      context.commit('setDiarrhee','')
      context.commit('setTemperatureFievre','')
      context.commit('setFeverDuration','')
      context.commit('setDiarrheeSanglante','')
      context.commit('setTraitementPrescrit','')
      context.commit('setAntibiotiques','')
      context.commit('setAntipaludiques','')
      context.commit('setAntipyretiques','')
      context.commit('setAntihistaminiques','')
      context.commit('setBroncodilatateurs','')
      context.commit('setAutresTraitementsPrescrits','')
      context.commit('setTdrPalu','')
      context.commit('setResultatTdrPalu','')
      context.commit('setTdrCovid','')
      context.commit('setResultatCovid19','')
      context.commit('setTdrCovidAntigeneResult','')
      context.commit('setTdrCovidAnticorpsResult','')
      context.commit('setIgmPositif','')
      context.commit('setIgaPositif','')
      context.commit('setIggPositif','')
      context.commit('setVoyage15jrs','')
      context.commit('setLieuVoyage','')
      context.commit('setDateVoyage','')
      context.commit('setProfession','')
      context.commit('setShowDiarrheeSanglanteQuestion',false)
      // reset des autres variables
    },

    resetDashboardVariablesState(context){
      
      context.commit('setShowSurveillanceView',false)
      context.commit('setShowAlertsView',false)
    }
  },
  modules: {
  }
})
