<template>
  <b-container fluid class="content"></b-container>
</template>

<script>
// import HealerInformations from "@/components/HealerInformations.vue";
export default {
  name: "AgregatedRecord",
  components: {
    // HealerInformations
  },
  data: () => ({}),

  methods: {}
};
</script>

<style scoped>
</style>
