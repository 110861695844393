<template>
  <div class="arbo_content">
    <div class="signes_mineurs">
      <b-row class="arbo_symptomes">
        <span style="margin-bottom:2%;" class="arbo_symptomes_title">Signes mineurs</span>
      </b-row>

      <b-row style="margin-top:10%;" class="symptomes_row">
        <b-col class="symptomes_question_checkbox" cols="5">
          <b-form-checkbox
            class="symptome_checkbox"
            v-model="arthralgies"
            name="symptome_checkbox"
            value="Oui"
            unchecked-value="Non"
          >
            <span class="checkbox_label">arthralgies</span>
          </b-form-checkbox>
        </b-col>
        <b-col cols="1"></b-col>

        <b-col class="symptomes_question_checkbox" cols="5">
          <b-form-checkbox
            class="symptome_checkbox"
            v-model="myalgies"
            name="symptome_checkbox"
            value="Oui"
            unchecked-value="Non"
          >
            <span class="checkbox_label">Myalgies</span>
          </b-form-checkbox>
        </b-col>
      </b-row>
      <b-row style="margin-top:5%;" class="symptomes_row">
        <b-col class="symptomes_question_checkbox" cols="5">
          <b-form-checkbox
            id="cephalees_checkbox"
            class="symptome_checkbox"
            v-model="cephalees"
            name="symptome_checkbox"
            value="Oui"
            unchecked-value="Non"
          >
            <span class="checkbox_label">Céphalées</span>
          </b-form-checkbox>
        </b-col>
        <b-col cols="1"></b-col>
        <b-col class="symptomes_question_checkbox" cols="5">
          <b-form-checkbox
            class="symptome_checkbox"
            v-model="eruption_cutanee"
            name="symptome_checkbox"
            value="Oui"
            unchecked-value="Non"
          >
            <span class="checkbox_label">Eruption cutanée</span>
          </b-form-checkbox>
        </b-col>
      </b-row>

      <b-row style="margin-top:5%;" class="symptomes_row">
        <b-col style="margin-top:2%;" class="symptomes_question_checkbox" cols="7">
          <b-form-checkbox
            class="symptome_checkbox"
            v-model="douleurs_retro_orbitaires"
            name="symptome_checkbox"
            value="Oui"
            unchecked-value="Non"
          >
            <span class="checkbox_label">Douleurs rétro orbitaires</span>
          </b-form-checkbox>
        </b-col>
      </b-row>
    </div>
    <div class="delimiter1"></div>

    <div class="signes_majeurs">
      <b-row class="arbo_symptomes">
        <span class="arbo_symptomes_title">Signes majeurs</span>
        <!-- <span
          class="arbo_symptomes_description"
        >Saignement sous la peau, saignement nasal, saignements génitaux, saignement de la gencive…</span>-->
      </b-row>

      <b-row style="margin-top:10%; margin-bottom:2%;" class="hemorrhagic_manifestations">
        <b-col class="hemorrhagic_manifestations_checkbox" cols="10">
          <b-form-checkbox
            class="symptome_checkbox"
            v-model="manifestations_hemorragiques"
            name="symptome_checkbox"
            value="Oui"
            unchecked-value="Non"
          ></b-form-checkbox>
          <span
            @click="checkHemorrhagicManifestationsCheckbox"
            class="hemorrhagic_manifestations_label"
          >Manifestations hémorragiques</span>
        </b-col>
      </b-row>
    </div>

    <div class="delimiter2"></div>

    <div class="autres_syndromes">
      <b-row class="arbo_symptomes">
        <span class="arbo_symptomes_title">Autres syndrômes</span>
      </b-row>

      <b-row style="margin-top:14%;" class="symptomes_row">
        <b-col class="symptomes_question_checkbox" cols="5">
          <b-form-checkbox
            class="symptome_checkbox"
            v-model="diarrhee"
            name="symptome_checkbox"
            value="Oui"
            unchecked-value="Non"
          >
            <span class="checkbox_label">Diarrhée</span>
          </b-form-checkbox>
        </b-col>
        <b-col cols="1"></b-col>
        <b-col class="symptomes_question_checkbox" cols="6">
          <b-form-checkbox
            class="symptome_checkbox"
            v-model="diarrhee_sanglante"
            name="symptome_checkbox"
            value="Oui"
            unchecked-value="Non"
          >
            <span class="checkbox_label">Diarrhée sanglante</span>
          </b-form-checkbox>
        </b-col>
      </b-row>
    </div>
  </div>
</template>


<script>
import { mapState } from 'vuex';
import { eventBus } from '@/main.js';

export default {
  name: 'Covid19Section',
  components: {},
  data: () => ({
    arthralgies: '',
    cephalees: '',
    eruption_cutanee: '',
    douleurs_retro_orbitaires: '',
    myalgies: '',
    manifestations_hemorragiques: '',
    diarrhee: '',
    diarrhee_sanglante: '',
    selected_minor_symptomes: [],
    selected_major_symptomes: []
  }),
  computed: {
    ...mapState({
      cephalees_cov: 'cephalees'
    })
  },
  mounted(){
  },
  watch: {
    cephalees_cov(val){
      if(val=='Oui'){
        document.getElementById("cephalees_checkbox").checked=true;
      }else{
        document.getElementById("cephalees_checkbox").checked=false;
      }
    },
    cephalees(val) {
      this.$store.commit('setCephalees', val);
      if (val == 'Oui') {
        this.selected_minor_symptomes.push('cephalees');
      } else {
        let index = this.selected_minor_symptomes.indexOf('cephalees');
        if (index > -1) {
          this.selected_minor_symptomes.splice(index, 1);
        }
      }
    },
    arthralgies(val) {
      this.$store.commit('setArthralgies', val);
      if (val == 'Oui') {
        this.selected_minor_symptomes.push('arthralgies');
      } else {
        let index = this.selected_minor_symptomes.indexOf('arthralgies');
        if (index > -1) {
          this.selected_minor_symptomes.splice(index, 1);
        }
      }
    },
    eruption_cutanee(val) {
      this.$store.commit('setEruptionCutanee', val);
      if (val == 'Oui') {
        this.selected_minor_symptomes.push('eruption_cutanee');
      } else {
        let index = this.selected_minor_symptomes.indexOf('eruption_cutanee');
        if (index > -1) {
          this.selected_minor_symptomes.splice(index, 1);
        }
      }
    },
    douleurs_retro_orbitaires(val) {
      this.$store.commit('setDouleursRetroOrbitaires', val);
      if (val == 'Oui') {
        this.selected_minor_symptomes.push('douleurs_retro_orbitaires');
      } else {
        let index = this.selected_minor_symptomes.indexOf(
          'douleurs_retro_orbitaires'
        );
        if (index > -1) {
          this.selected_minor_symptomes.splice(index, 1);
        }
      }
    },
    myalgies(val) {
      this.$store.commit('setMyalgies', val);
      if (val == 'Oui') {
        this.selected_minor_symptomes.push('myalgies');
      } else {
        let index = this.selected_minor_symptomes.indexOf('myalgies');
        if (index > -1) {
          this.selected_minor_symptomes.splice(index, 1);
        }
      }
    },
    manifestations_hemorragiques(val) {
      this.$store.commit('setManifestationsHemorragiques', val);
      if (val == 'Oui') {
        this.selected_major_symptomes.push('manifestations_hemorragiques');
      } else {
        let index = this.selected_major_symptomes.indexOf(
          'manifestations_hemorragiques'
        );
        if (index > -1) {
          this.selected_major_symptomes.splice(index, 1);
        }
      }
    },
    diarrhee(val) {
      this.$store.commit('setDiarrhee', val);
    },
    diarrhee_sanglante(val) {
      this.$store.commit('setDiarrheeSanglante', val);
    }
  },
  methods: {
    goToNextSection() {
      eventBus.$emit('triggerOtherSymptomesSection');
    },
    checkHemorrhagicManifestationsCheckbox() {
      if (this.manifestations_hemorragiques == 'Oui') {
        this.manifestations_hemorragiques = 'Non';
      } else {
        this.manifestations_hemorragiques = 'Oui';
      }
    }
  }
};
</script>

<style scoped>
.arbo_content {
  display: flex;
  width: 100%;
  margin-bottom: 2%;
}
.arbo_symptomes {
  display: flex;
  flex-direction: column;
  width: 96%;
  justify-content: center;
  align-items: center;
  margin-top: 1%;
}

.arbo_symptomes_title {
  font-family: 'Eastman Roman Trial';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #425169;
  margin-bottom: 0.5%;
}

.arbo_symptomes_description {
  font-family: 'Eastman Roman Trial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #425169;
  margin-bottom: 3%;
}

.symptomes_row {
  width: 100%;
  position: relative;
  top: -5%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.hemorrhagic_manifestations {
  width: 60%;
  position: relative;
  left: 18%;
  top: -5%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 10px;
}

.symptomes_question_checkbox {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 6px 15px;
  gap: 10px;
  background: #ebfcfd;
  border-radius: 5px;
}

.hemorrhagic_manifestations_checkbox {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 25px;
  gap: 10px;
  background: #ebfcfd;
  border-radius: 5px;
  transform: scale(1.1);
}
.symptomes_question_infos {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 6px 10px;
  gap: 10px;
  border-radius: 5px;
}

.icon_info {
  transform: scale(1.1);
}
.info_label_text {
  position: relative;
  left: 4%;
  font-family: 'Eastman Roman Trial';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #484ef7;
}
.symptome_checkbox {
  transform: scale(1.1);
}

.checkbox_label {
  font-family: 'Eastman Roman Trial';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #425169;
  position: relative;
  left: 4%;
}
.hemorrhagic_manifestations_label {
  font-family: 'Eastman Roman Trial';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #425169;
  position: relative;
  left: -2%;
}

.signes_mineurs {
  width: 35%;
  position: relative;
  left: 5%;
  padding: 10px 0px;
}
.signes_majeurs {
  width: 35%;
  padding: 10px 0px;
}

.autres_syndromes {
  width: 32%;
  position: relative;
  right: 4%;
  padding: 10px 0px;
}

.delimiter1 {
  border: 1px solid lightgray;
  position: relative;
  left: 5%;
}

.delimiter2 {
  border: 1px solid lightgray;
  position: relative;
  left: -7%;
}
</style>