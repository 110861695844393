<template>
  <b-container class="samples_results_content m-0 p-0" fluid>

        <div v-show="show_loading_section==true" class="loading_section">
            <b-spinner variant="primary" label="Spinning"></b-spinner>
            <div class="loading_text">Chargement...</div>
        </div>
     
    <Header :show_search="false" />

    <p class="title"><span @click="triggerAccueil()" class="home_link">Accueil</span>&nbsp; > Résultats des prélèvements</p>

     <div class="datatable">

      <vs-table class="table">

        <template #header>
          <div class="table_header_container">
            <b-form-input class="search_bar" v-model="search" placeholder="Saisir le numéro epid du patient"/>
          </div>
        </template>

        <template #notFound>
            Aucun résultat pour le moment
        </template>

        <template #thead>
          <vs-tr>
            <vs-th sort @click="samples_results_list = $vs.sortData($event ,samples_results_list, 'epid')">
              <span class="column_name">Epid</span>
            </vs-th>
            <vs-th sort @click="samples_results_list = $vs.sortData($event ,samples_results_list, 'prenom')">
              <span class="column_name">Nom patient</span>
            </vs-th>
            <vs-th sort @click="samples_results_list = $vs.sortData($event ,samples_results_list, 'telephone')">
              <span class="column_name">Telephone</span>
            </vs-th>
            <vs-th sort @click="samples_results_list = $vs.sortData($event ,samples_results_list, 'genre')">
              <span class="column_name">Genre</span>
            </vs-th>
            <vs-th sort @click="samples_results_list = $vs.sortData($event ,samples_results_list, 'age')">
             <span class="column_name">Age</span>
            </vs-th>
          </vs-tr>
        </template>

        <template #tbody>
          <vs-tr
            :key="i"
            v-for="(tr, i) in $vs.getPage($vs.getSearch(samples_results_list, search), page, max)"
            :data="tr"
            not-click-selected
            open-expand-only-td
          >
            <vs-td class="row_elt">
              {{ tr.epid }}
            </vs-td>
            <vs-td v-if="tr.informations_personnelles.civilite.prenom" class="row_elt">
              {{ tr.informations_personnelles.civilite.prenom }} {{tr.informations_personnelles.civilite.nom}}
            </vs-td>
            <vs-td v-else class="row_elt">
              {{ tr.informations_personnelles.civilite.prenom }} {{tr.informations_personnelles.civilite.nom}}
            </vs-td>
            <vs-td v-if="tr.informations_personnelles.adresse_et_contact.telephone1_patient" class="row_elt">
              {{ tr.informations_personnelles.adresse_et_contact.telephone1_patient}}
            </vs-td>
            <vs-td v-else-if="tr.informations_personnelles.adresse_et_contact.telephone2_patient" class="row_elt">
              {{ tr.informations_personnelles.adresse_et_contact.telephone2_patient }}
            </vs-td>
            <vs-td v-else class="row_elt">
              Non renseigné
            </vs-td>
            <vs-td v-if="tr.informations_personnelles.civilite.genre" class="row_elt">
              {{ tr.informations_personnelles.civilite.genre }}
            </vs-td>
            <vs-td v-else class="row_elt">
              Non renseigné
            </vs-td>
            <vs-td v-if="tr.informations_personnelles.civilite.age_en_annee" class="row_elt">
              {{ tr.informations_personnelles.civilite.age_en_annee }}
            </vs-td>
            <vs-td v-else-if="tr.informations_personnelles.civilite.age_en_mois" class="row_elt">
              {{ tr.informations_personnelles.civilite.age_en_mois }}
            </vs-td>
            <vs-td v-else class="row_elt">
              Non renseigné
            </vs-td>

            <template #expand>
                <!-- v-if="tr.resultat_dispo=='2'" -->
              <div class="expand-content">
                <div class="user_actions">
                  <vs-button :disabled="tr.suspicion_et_diagnostic.prelevement.type_prelevement=='sanguin'" @click="fillArboResults(tr)" v-b-modal.arboResultsModal flat icon>
                    Voir résultats Arboviroses
                  </vs-button>
                  <vs-button :disabled="tr.suspicion_et_diagnostic.prelevement.type_prelevement!='sanguin'" @click="fillIraResults(tr)" v-b-modal.iraResultsModal flat icon>
                    Voir les résultats IRA
                  </vs-button>
                </div>
              </div>
            </template>
          </vs-tr>
        </template>


        <template #footer>
          <vs-pagination v-model="page" :length="$vs.getLength($vs.getSearch(samples_results_list, search), max)" />
        </template>
      </vs-table>
    </div>

    <b-modal id="arboResultsModal" hide-footer size="xl" title="">
        <div v-if="currentPatient" class="modal_content">
                        <div class="section_title_container">
                <span class="results_title">RECHERCHE D’ARBOVIRUS ET DE VIRUS DE FIEVRE HEMORRAGIQUES</span>
            </div>

            <div class="results_infos_container">
                <div class="right_section_infos">

                    <div class="infos_line">
                        <div class="info_name">
                            Pays 
                        </div>

                        <div class="info_value">
                            Sénégal
                        </div>
                    </div>
                    <div class="infos_line">
                        <div class="info_name">
                            Service demandeur
                        </div>
                        <div class="info_value">
                          MSAS / District {{currentPatient.nom_district}}
                        </div>
                    </div>
                    <div class="infos_line">
                        <div class="info_name">
                            Etat des échantillons
                        </div>
                        <div class="info_value">
                            {{currentPatient.etat_echantillon}}
                        </div>
                    </div>
                    <div class="infos_line">
                        <div class="info_name">
                            Type de Prélèvement
                        </div>
                        <div class="info_value">
                            {{currentPatient.suspicion_et_diagnostic.prelevement.type_prelevement}}
                        </div>
                    </div>
                    <div class="infos_line">
                        <div class="info_name">
                            Date de réception
                        </div>
                        <div class="info_value">
                            {{currentPatient.date_reception_echantillon}}
                        </div>
                    </div>
                    <div class="infos_line">
                        <div class="info_name">
                            Date d’envoi des réultats
                        </div>
                        <div class="info_value">
                            {{currentPatient.date_envoie_resultats}}
                        </div>
                    </div>
                    <div class="infos_line">
                        <div class="info_name">
                            ELISA Différentiel
                        </div>
                        <div class="info_value">
                            {{currentPatient.elisa_differentiel}}
                        </div>
                    </div>
                    <div class="infos_line">
                        <div class="info_name">
                            PCR Différentiel
                        </div>
                        <div class="info_value">
                            {{currentPatient.pcr_differentiel}}
                        </div>
                    </div>

                </div>
            </div>

            <div style="margin-top:3%; width:90%;" class="virus_table">
                <div class="table_row">
                    <div class="table_element table_column">NUM IPD</div>
                    <div class="table_element table_column">NOM</div>
                    <div class="table_element table_column">N REGISTRE</div>
                    <div class="table_element table_column">CENTRE</div>
                    <div class="table_element table_column">PCR EBOLA ZAIRE</div>
                    <div class="table_element table_column">PCR EBOLA SOUDAN</div>
                    <div class="table_element table_column">PCR LASSA</div>
                    <div class="table_element table_column">PCR MARBURG</div>
                    <div class="table_element table_column">ELISA IgM DIFFERENTIEL</div>
                </div>

                <div class="table_row">
                    <div class="table_element table_column_value">{{currentPatient.no_ipd}}</div>
                    <div class="table_element table_column_value">
                            {{currentPatient.informations_personnelles.civilite.prenom}} &nbsp; {{currentPatient.informations_personnelles.civilite.nom}}
                    </div>
                    <div class="table_element table_column_value">{{currentPatient.no_registre}}</div>
                    <div class="table_element table_column_value">{{currentPatient.nom_district}}</div>
                    <div v-if="currentPatient.arn_ebolav_zaire=='1'" class="table_element table_column_value">
                        Positif
                    </div>
                    <div v-else-if="currentPatient.arn_ebolav_zaire=='2'" class="table_element table_column_value">
                        Négatif
                    </div>
                    <div v-else class="table_element table_column_value">
                        NA
                    </div>
                    <div v-if="currentPatient.arn_ebolav_soudan=='1'" class="table_element table_column_value">
                        Positif
                    </div>
                    <div v-else-if="currentPatient.arn_ebolav_soudan=='2'" class="table_element table_column_value">
                        Négatif
                    </div>
                    <div v-else class="table_element table_column_value">
                        NA
                    </div>
                    <div v-if="currentPatient.arn_lassa=='1'" class="table_element table_column_value">
                        Positif
                    </div>
                    <div v-else-if="currentPatient.arn_lassa=='2'" class="table_element table_column_value">
                        Négatif
                    </div>
                    <div v-else class="table_element table_column_value">
                        NA
                    </div>
                    <div v-if="currentPatient.arn_marburg=='1'" class="table_element table_column_value">
                        Positif
                    </div>
                    <div v-if="currentPatient.arn_marburg=='2'" class="table_element table_column_value">
                        Négatif
                    </div>
                    <div v-else class="table_element table_column_value">
                        NA
                    </div>
                    <div v-if="currentPatient.igm_differentiel_elisa=='1'" class="table_element table_column_value">
                        Positif
                    </div>
                    <div v-if="currentPatient.igm_differentiel_elisa=='2'" class="table_element table_column_value">
                        Négatif
                    </div>
                    <div v-else class="table_element table_column_value">
                        NA
                    </div>
                </div>
            </div>

            <div class="autres_infos">

                <div class="infos_line">
                    <div class="info_name">
                        Résultats
                    </div>
                    <div class="info_value">
                        {{currentPatient.resultats}}
                    </div>
                </div>

                <div class="infos_line">
                    <div class="info_name">
                        Conclusion
                    </div>
                    <div class="info_value">
                        {{currentPatient.conclusions}}
                    </div>
                </div>

                <div class="infos_line">
                    <div class="info_name">
                        Résultat validé par :
                    </div>
                    <div class="info_value">
                        {{currentPatient.resultats_arbos_valides_par}}
                    </div>
                </div>

            </div>
        </div>
    </b-modal>

    <b-modal v-if="currentPatient" id="iraResultsModal" hide-footer size="xl" title="">
        <div class="modal_content">

            <div class="section_title_container">
                <span class="results_title">RESULTATS DU RESEAU SENTINELLE SURVEILLANCE HOSPITALIERE DES IRA</span>
            </div>

            <div class="results_infos_container">
                <div class="right_section_infos">

                    <div class="infos_line">
                        <div class="info_name">
                            Service demandeur 
                        </div>

                        <div class="info_value">
                          Ministère de la santé / District {{currentPatient.nom_district}}
                        </div>
                    </div>
                    <div class="infos_line">
                        <div class="info_name">
                            Structure sanitaire
                        </div>
                        <div class="info_value">
                            {{currentPatient.nom_site}}
                        </div>
                    </div>
                    <div class="infos_line">
                        <div class="info_name">
                            Département
                        </div>
                        <div class="info_value">
                            
                        </div>
                    </div>
                    <div class="infos_line">
                        <div class="info_name">
                            Type de Prélèvement
                        </div>
                        <div class="info_value">
                            {{currentPatient.suspicion_et_diagnostic.prelevement.type_prelevement}}
                        </div>
                    </div>
                    <div class="infos_line">
                        <div class="info_name">
                            Date de Prélèvement
                        </div>
                        <div class="info_value">
                            {{currentPatient.suspicion_et_diagnostic.prelevement.date_prelevement}}
                        </div>
                    </div>
                    <div class="infos_line">
                        <div class="info_name">
                            Date de réception au laboratoire
                        </div>
                        <div class="info_value">
                            {{currentPatient.suspicion_et_diagnostic.prelevement.date_reception}}
                        </div>
                    </div>
                    <div class="infos_line">
                        <div class="info_name">
                            Diagnostic
                        </div>
                        <div class="info_value">
                            {{currentPatient.suspicion_et_diagnostic.prelevement.diagnostic}}
                        </div>
                    </div>
                    <div class="infos_line">
                        <div class="info_name">
                            Critère
                        </div>
                        <div class="info_value">
                            {{currentPatient.suspicion_et_diagnostic.prelevement.critere}}
                        </div>
                    </div>

                </div>
                <div class="left_section_infos">

                    <div class="infos_line">
                        <div class="info_name">
                            Nom du Patient(e)  
                        </div>

                        <div class="info_value">
                            {{currentPatient.informations_personnelles.civilite.prenom}} &nbsp; {{currentPatient.informations_personnelles.civilite.nom}}
                        </div>
                    </div>

                    <div class="infos_line">
                        <div class="info_name">
                            Numéro dossier  
                        </div>

                        <div class="info_value">
                            {{currentPatient.numero_dossier}}
                        </div>
                    </div>

                    <div class="infos_line">
                        <div class="info_name">
                            Sexe  
                        </div>

                        <div class="info_value">
                            {{currentPatient.informations_personnelles.civilite.genre}}
                        </div>
                    </div>

                    <div class="infos_line">
                        <div class="info_name">
                            Age 
                        </div>

                        <div class="info_value">
                            {{currentPatient.informations_personnelles.civilite.age_en_annee}}
                        </div>
                    </div>

                    <div class="infos_line">
                        <div class="info_name">
                            Téléphone
                        </div>

                        <div v-if="currentPatient.informations_personnelles.civilite.telephone1" class="info_value">
                            {{currentPatient.informations_personnelles.civilite.telephone1}}
                        </div>
                        <br/>
                        <div v-if="currentPatient.informations_personnelles.civilite.telephone2" class="info_value">
                            {{currentPatient.informations_personnelles.civilite.telephone2}}
                        </div>

                    </div>
                </div>
            </div>

            <div style="margin-top:3%; width:50%;" class="section_title_container">
                <span class="results_title">RESULTATS VIROLOGIQUES</span>
            </div>

                <div class="special_info">
                    <div class="special_info_title">
                        Méthodes de diagnostic
                    </div>

                    <div class="special_info_value">
                        {{currentPatient.methode_de_diagnostic_viro}}
                    </div>
                </div>

            <div class="results_infos_container">

                <div class="right_section_infos">

                    <div class="infos_line">
                        <div class="info_name">
                            Influenza A 
                        </div>

                        <div v-if="currentPatient.arn_influenza_a=='1'" class="info_value">
                            Positif
                        </div>
                        <div v-else-if="currentPatient.arn_influenza_a=='2'" class="info_value">
                            Négatif
                        </div>
                        <div v-else class="info_value">
                            NA
                        </div>
                    </div>
                    <div class="infos_line">
                        <div class="info_name">
                            Influenza B
                        </div>
                        <div v-if="currentPatient.arn_influenza_b=='1'" class="info_value">
                            Positif
                        </div>
                        <div v-else-if="currentPatient.arn_influenza_b=='2'" class="info_value">
                            Négatif
                        </div>
                        <div v-else class="info_value">
                            NA
                        </div>
                    </div>
                    <div class="infos_line">
                        <div class="info_name">
                            Rhinovirus
                        </div>
                        <div v-if="currentPatient.arn_aut_v_resp_rhinovirus=='1'" class="info_value">
                            Positif
                        </div>
                        <div v-else-if="currentPatient.arn_aut_v_resp_rhinovirus=='2'" class="info_value">
                            Négatif
                        </div>
                        <div v-else class="info_value">
                            NA
                        </div>
                    </div>
                    <div class="infos_line">
                        <div class="info_name">
                            Adenovirus
                        </div>
                        <div v-if="currentPatient.arn_aut_v_resp_adenovirus=='1'" class="info_value">
                            Positif
                        </div>
                        <div v-if="currentPatient.arn_aut_v_resp_adenovirus=='2'" class="info_value">
                            Négatif
                        </div>
                        <div v-else class="info_value">
                            NA
                        </div>
                    </div>
                    <div class="infos_line">
                        <div class="info_name">
                            Bocavirus
                        </div>
                        <div v-if="currentPatient.arn_aut_v_resp_bocavirus=='1'" class="info_value">
                            Positif
                        </div>
                        <div v-else-if="currentPatient.arn_aut_v_resp_bocavirus=='2'" class="info_value">
                            Négatif
                        </div>
                        <div v-else class="info_value">
                            NA
                        </div>
                    </div>
                    <div class="infos_line">
                        <div class="info_name">
                            VRS A
                        </div>
                        <div v-if="currentPatient.arn_aut_v_resp_vrs_a=='1'" class="info_value">
                            Positif
                        </div>
                        <div v-else-if="currentPatient.arn_aut_v_resp_vrs_a=='2'" class="info_value">
                            Négatif
                        </div>
                        <div v-else class="info_value">
                            NA
                        </div>
                    </div>
                    <div class="infos_line">
                        <div class="info_name">
                            VRS B
                        </div>
                        <div v-if="currentPatient.arn_aut_v_resp_vrs_b=='1'" class="info_value">
                            Positif
                        </div>
                        <div v-else-if="currentPatient.arn_aut_v_resp_vrs_b=='2'" class="info_value">
                            Négatif
                        </div>
                        <div v-else class="info_value">
                            NA
                        </div>
                    </div>
                    <div class="infos_line">
                        <div class="info_name">
                            Metapneumovirus
                        </div>
                        <div v-if="currentPatient.arn_aut_v_resp_metapneumovirus=='1'" class="info_value">
                            Positif
                        </div>
                        <div v-else-if="currentPatient.arn_aut_v_resp_metapneumovirus=='2'" class="info_value">
                            Négatif
                        </div>
                        <div v-else class="info_value">
                            NA
                        </div>
                    </div>

                </div>
                <div class="left_section_infos">

                    <div class="infos_line">
                        <div class="info_name">
                            Coronavirus NL63 
                        </div>

                        <div v-if="currentPatient.arn_aut_v_resp_corovirus_nl63=='1'" class="info_value">
                            Positif
                        </div>
                        <div v-else-if="currentPatient.arn_aut_v_resp_corovirus_nl63=='2'" class="info_value">
                            Négatif
                        </div>
                        <div v-else class="info_value">
                            NA
                        </div>
                    </div>

                    <div class="infos_line">
                        <div class="info_name">
                            Coronavirus  
                        </div>

                        <div v-if="currentPatient.arn_aut_v_resp_corovirus=='1'" class="info_value">
                            Positif
                        </div>
                        <div v-else-if="currentPatient.arn_aut_v_resp_corovirus=='2'" class="info_value">
                            Négatif
                        </div>
                        <div v-else class="info_value">
                            NA
                        </div>
                    </div>

                    <div class="infos_line">
                        <div class="info_name">
                            Coronavirus OC43  
                        </div>

                        <div v-if="currentPatient.arn_aut_v_resp_corovirus_oc43=='1'" class="info_value">
                            positif
                        </div>
                        <div v-else-if="currentPatient.arn_aut_v_resp_corovirus_oc43=='2'" class="info_value">
                            Négatif
                        </div>
                        <div v-else class="info_value">
                            NA
                        </div>
                    </div>

                    <div class="infos_line">
                        <div class="info_name">
                            Coronavirus 229E
                        </div>

                        <div v-if="currentPatient.arn_aut_v_resp_corovirus_229e=='1'" class="info_value">
                            Positif
                        </div>
                        <div v-else-if="currentPatient.arn_aut_v_resp_corovirus_229e=='2'" class="info_value">
                            Négatif
                        </div>
                        <div v-else class="info_value">
                            NA
                        </div>
                    </div>

                    <div class="infos_line">
                        <div class="info_name">
                            Parainfluenzavirus 1
                        </div>

                        <div v-if="currentPatient.arn_aut_v_resp_parainfluenza1=='1'" class="info_value">
                            Positif
                        </div>
                        <div v-else-if="currentPatient.arn_aut_v_resp_parainfluenza1=='2'" class="info_value">
                            Négatif
                        </div>
                        <div v-else class="info_value">
                            NA
                        </div>
                    </div>

                    <div class="infos_line">
                        <div class="info_name">
                            Parainfluenzavirus 2
                        </div>

                        <div v-if="currentPatient.arn_aut_v_resp_parainfluenza2=='1'" class="info_value">
                            Positif
                        </div>
                        <div v-else-if="currentPatient.arn_aut_v_resp_parainfluenza2=='2'" class="info_value">
                            Négatif
                        </div>
                        <div v-else class="info_value">
                            NA
                        </div>
                    </div>

                    <div class="infos_line">
                        <div class="info_name">
                            Parainfluenzavirus 3
                        </div>

                        <div v-if="currentPatient.arn_aut_v_resp_parainfluenza3=='1'" class="info_value">
                            Positif
                        </div>
                        <div v-else-if="currentPatient.arn_aut_v_resp_parainfluenza3=='2'"  class="info_value">
                            Négatif
                        </div>
                        <div v-else class="info_value">
                            NA
                        </div>
                    </div>

                    <div class="infos_line">
                        <div class="info_name">
                            Parainfluenzavirus 4
                        </div>

                        <div v-if="currentPatient.arn_aut_v_resp_parainfluenza4=='1'" class="info_value">
                            Positif
                        </div>
                        <div v-else-if="currentPatient.arn_aut_v_resp_parainfluenza4=='2'" class="info_value">
                            Négatif
                        </div>
                        <div v-else class="info_value">
                            NA
                        </div>
                    </div>

                    <div class="infos_line">
                        <div class="info_name">
                            SARS COV-2
                        </div>

                        <div v-if="currentPatient.sars_cov2=='1'" class="info_value">
                            Positif
                        </div>
                        <div v-else-if="currentPatient.sars_cov2=='2'" class="info_value">
                            Négatif
                        </div>
                        <div v-else class="info_value">
                            NA
                        </div>
                    </div>

                </div>
            </div>

            <div style="margin-top:3%; width:50%;" class="section_title_container">
                <span class="results_title">RESULTATS BACTERIOLOGIQUES</span>
            </div>

                <div class="special_info">
                    <div class="special_info_title">
                        Méthodes de diagnostic
                    </div>

                    <div class="special_info_value">
                        {{currentPatient.methode_diagnostic_bacterio}}
                    </div>
                </div>

            <div class="results_infos_container">

                <div class="right_section_infos">

                    <div class="infos_line">
                        <div class="info_name">
                            Haemophilus influenza 
                        </div>

                        <div v-if="currentPatient.haemophilus_influenza=='1'" class="info_value">
                            Positif
                        </div>

                        <div v-else-if="currentPatient.haemophilus_influenza=='2'" class="info_value">
                            Négatif
                        </div>

                        <div v-else class="info_value">
                            NA
                        </div>
                    </div>

                    <div class="infos_line">
                        <div class="info_name">
                            Staemophilus influenza
                        </div>
                        <div v-if="currentPatient.staemophilus_influenza=='1'" class="info_value">
                            Positif
                        </div>
                        <div v-else-if="currentPatient.staemophilus_influenza=='2'" class="info_value">
                            Négatif
                        </div>
                        <div v-else class="info_value">
                            NA
                        </div>
                    </div>

                    <div class="infos_line">
                        <div class="info_name">
                            Legionella pneumophila
                        </div>
                        <div v-if="currentPatient.legionella_pneumophila=='1'" class="info_value">
                            Positif
                        </div>
                        <div v-else-if="currentPatient.legionella_pneumophila=='2'" class="info_value">
                            Négatif
                        </div>
                        <div v-else class="info_value">
                            NA
                        </div>
                    </div>

                    <div class="infos_line">
                        <div class="info_name">
                            Mycoplasma pneumoniae
                        </div>
                        <div v-if="currentPatient.mycoplasma_pneumoniae=='1'" class="info_value">
                            Positif
                        </div>
                        <div v-else-if="currentPatient.mycoplasma_pneumoniae=='2'" class="info_value">
                            Négatif
                        </div>
                        <div v-else class="info_value">
                            NA
                        </div>
                    </div>

                </div>
                <div class="left_section_infos">

                    <div class="infos_line">
                        <div class="info_name">
                            Bordetella pertussis  
                        </div>

                        <div v-if="currentPatient.bordetella_pertussis=='1'" class="info_value">
                            Positif
                        </div>
                        <div v-else-if="currentPatient.bordetella_pertussis=='2'" class="info_value">
                            Négatif
                        </div>
                        <div v-else class="info_value">
                            NA
                        </div>
                    </div>

                    <div class="infos_line">
                        <div class="info_name">
                            Bordetella parapertitussis  
                        </div>

                        <div v-if="currentPatient.bordetella_parapertitussis=='1'" class="info_value">
                            Positif
                        </div>
                        <div v-else-if="currentPatient.bordetella_parapertitussis=='2'" class="info_value">
                            Négatif
                        </div>
                        <div v-else class="info_value">
                            NA
                        </div>
                    </div>

                    <div class="infos_line">
                        <div class="info_name">
                            Chlamydophila pneumoniae
                        </div>

                        <div v-if="currentPatient.chlamydophila_pneumoniae=='1'" class="info_value">
                            Positif
                        </div>
                        <div v-else-if="currentPatient.chlamydophila_pneumoniae=='2'" class="info_value">
                            Négatif
                        </div>
                        <div v-else class="info_value">
                            NA
                        </div>
                    </div>

                </div>
            </div> 

            <div class="autres_infos">

                <div class="infos_line">
                    <div class="info_name">
                        Date d’envoi des résultats
                    </div>
                    <div class="info_value">
                        {{currentPatient.date_envoie_resultats}}
                    </div>
                </div>

                <div class="infos_line">
                    <div class="info_name">
                        Résultats validés par :
                    </div>
                    <div class="info_value">
                        {{currentPatient.resultats_ira_valides_par}}
                    </div>
                </div>

            </div>
        </div>
    </b-modal>

  </b-container>

</template>

<script>

import { mapState } from 'vuex';
import Header from '@/components/fiche-individuelle/general/Header.vue';
// import { eventBus } from '@/main.js';


export default {
  name: 'SampleResults',
  components: {
    Header
  },
  methods: {
    async getSamplesResults(){

        this.show_loading_section=true;

        let role = JSON.parse(localStorage.getItem("user")).role.nom_role

        let request_url = "";

        let agent_site = localStorage.getItem("nom_site");
        
        if(agent_site && role == "ICP" ){
            request_url = '/api/fiche-fievre/?limit=99999999&site='+agent_site+'&having_sample=true';
        }else{
            request_url = '/api/fiche-fievre/?limit=99999999'+'&having_sample=true';
        }

    await this.axios
      .get(request_url)
      .then(response => {
        this.samples_results_list = response.data.results;
        this.show_loading_section=false;
      })
      .catch(error => {
        console.log(JSON.stringify(error));
      });
    },
    fillArboResults(tr){
        this.currentPatient=tr;
    },
    fillIraResults(tr){
        this.currentPatient=tr;
    },
    triggerAccueil(){
        this.$router.push('/home').catch(() => {});
    }
  },
  data() {
    return {
        samples_results_list: [],
        search: '',
        page: 1,
        max: 8,
        currentPatient:'',
        show_loading_section: false,
    };
  },
  mounted(){

    let indicators = document.querySelectorAll(".vs-pagination__active");

    for (const indicator of indicators) {
        indicator.style.backgroundColor="#484EF7";
        indicator.style.boxShadow="none";
    }

    let columnNames = document.querySelectorAll(".column_name");

    columnNames.forEach(element => {
      element.parentNode.style.display = "flex";
      element.parentNode.style.justifyContent = "center";
    });
    
    this.getSamplesResults();

    setInterval(() => {
        this.getSamplesResults();
    }, 60000);
  },
  computed: {
    ...mapState({
    })
  },
  watch:{
  }
};
</script>

<style scoped>
.samples_results_content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.title{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 27px;
  line-height: 18px;
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;
  text-decoration-line: underline;
  color: #000000;
  margin-left: 2%;
  margin-top: 4%;
}

.recent_actions_title{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.5px;
  color: #000000;
  margin-left: 2%;
  margin-top: 2%;
}

.datatable{
  width: 100%;
  padding: 10px;
  margin-top: 3%;
  background: #FFFFFF;
}


.table_header_container{
  width: 100% !important;
  display: flex !important; 
  justify-content: center !important; 
  align-items: center !important;
  color: #000000 !important;
  font-size: 25px !important;
  margin-bottom: 2%;
}

.search_bar{
  width: 50% !important;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  padding: 5px 2px 5px 5px;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  font-size: 25px !important;
}

.search_bar::placeholder{
  color: #484EF7;
  text-align: center;
}

.search_icon{
  color: #484EF7;
  position: relative;
  right: 3%;
}

.search_icon:active{
  transform: scale(0.95);
}

.check_all_icn{
  transform: scale(0.7);
}

.check_icn{
  transform: scale(0.85);
}

.column_name{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.1px;
  text-transform: uppercase;
  color: #000000;
  text-align: center !important;
}

.expand-content{
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.patient_infos{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 7%;
}

.user_actions{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 20%;
  position: relative;
  right: 5%;

}

.sample_img{
  margin-left: 5%;
}

.row_elt{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.1px;
  text-transform: uppercase;
  color: #757575;
  text-decoration: none;
}

.user_image_row{
  display: flex;
  justify-content: center;
  margin-top: 2%;
  margin-bottom: 4%;
}

.user_img{
  width: 100%;
  padding: 0 !important;
}

.sample_img_img{
  width: 100%;
  height: 100%;
}

.modal_content{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section_title_container{
  box-sizing: border-box;
  border: 1px solid #425169;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  padding: 6px 0px;
  width: 80%;
}

.results_title{
  font-family: 'Eastman Trial';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  color: rgba(66, 81, 105, 0.78);
}

.results_infos_container{
  width: 95%;
  margin-top: 3%;
  display: flex;
  justify-content: space-between;
}

.right_section_infos{
  width: 42%;
}

.left_section_infos{
  width: 42%;
}

.infos_line{
    margin-top: 1%;
}

.special_info{
    margin-top: 2%;
}

.autres_infos{
    width: 95%;
    margin-top: 5%;
}

.virus_table{
    box-sizing: border-box;
    border: 1px solid #425169;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
}

.table_row{
    display: flex;
    flex-direction: row;
}

.table_column{
    border: 1px solid #425169;
    width: 13%;
    display: flex;
    justify-content: center;
    text-align: center;
}

.table_column_value{
    border: 1px solid #425169;
    width: 13%;
    display: flex;
    justify-content: center;
    text-align: center;
}

.infos_line{
    display: flex;
    justify-content: space-between;
}

.home_link{
    color: #484EF7;
    cursor: pointer;
}

.loading_section{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 10%;
    left: 46%;
}


.loading_text{
    margin-left: 0.5%;
    font-family: 'Eastman Trial';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 14px;
    color: #425169;
}

.info_value{
    display: flex;
    justify-content: flex-end;
}
</style>