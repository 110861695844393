<template>
  <b-container fluid class="popup_content">
    <div class="title">
      <span style="color:#ffffff;" class="title">{{this.title}}</span>
      <img src="@/assets/icon-info.png" />
    </div>
    <span style="color:#ffffff;" v-html="textBoldNumbers" class="content"></span>
    <div v-show="this.command=='1'">
      <b-button
        @click="stopConsulting"
        id="abortConsultation"
        ref="stopConsultationButton"
        class="flat_button1"
        variant="outline-primary"
      >Non, arreter</b-button>
      <b-button
        @click="continueConsulting()"
        class="flat_button2"
        variant="outline-primary"
      >Continuer</b-button>
    </div>
    <div class="command"></div>
  </b-container>
</template>


<script>
import { mapState } from 'vuex';
import { eventBus } from '@/main.js';

export default {
  name: 'PopUp',
  components: {},
  data() {
    return {
    };
  },
  props: {
    title: String,
    content: String,
    command: String
  },
  mounted() {
    const button = document.getElementById('abortConsultation');
    
    button.addEventListener('click', () => {
      
      button.disabled = true;
      setTimeout(() => {
      button.disabled = false;

      }, 2000);
    });
  },
  computed: {
    textBoldNumbers() {
      return this.content.replace(/([0-9])/g, '<b>$1</b>');
    },
    ...mapState({
      //informations générales
      consulting_date: 'consulting_date',
      user: 'user',
      epid: 'epid',
      no_registre: 'no_registre',

      //informations personnelles
      prenom: 'prenom',
      nom: 'nom',
      date_de_naissance: 'date_de_naissance',
      genre: 'genre',
      age_year: 'age_year',
      age_month: 'age_month',
      femme_enceinte: 'femme_enceinte',
      age_gestationnel: 'age_gestationnel',
      telephone1_patient: 'telephone1_patient',
      telephone2_patient: 'telephone2_patient',
      adresse_patient: 'adresse_patient',
      statut_matrimonial: 'statut_matrimonial',

      //diagnostic et prescription base infos

      fievre: 'fievre',
      diarrhee: 'diarrhee',
      temperature_fievre: 'temperature_fievre',
      duree_fievre: 'duree_fievre',
      diarrhee_sanglante: 'diarrhee_sanglante',

      individual_records_number: 'individual_records_number'
    })
  },
  methods: {

    stopConsulting() {
      this.saveConsultationRecord();
    },
    continueConsulting() {
      eventBus.$emit('triggerContinueConsulting');
    },
    hidePopUp() {
      document.querySelector('.popup_content').parentElement.style.display = 'none';
    },
    showPopUp() {
      document.querySelector('.popup_content').parentElement.style.display = 'block';
    },
    getAge(birthdate) {
      // Get the current date
      var today = new Date();

      // Split the birthdate into its component parts
      var birthdateParts = birthdate.split("-");
      var birthyear = parseInt(birthdateParts[0], 10);
      var birthmonth = parseInt(birthdateParts[1], 10);
      var birthday = parseInt(birthdateParts[2], 10);

      // Create a new date object for the person's birthdate
      var birthdateObj = new Date(birthyear, birthmonth - 1, birthday);

      // Get the difference between the current date and the person's birthdate, in milliseconds
      var ageInMilliseconds = today - birthdateObj;

      // Convert the difference from milliseconds to years
      var ageInYears = ageInMilliseconds / (365.25 * 24 * 60 * 60 * 1000);
      
      // Round age to the nearest whole number
      return Math.round(ageInYears);

    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${year}-${month}-${day}`;
    },
    async saveConsultationRecord(){

      this.showPopUp = false;
      this.errors = [];

      let sending_date = this.formatDate(
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10)
      );

      let age;
      let month;
      let date_naissance;

      if(this.age_year=="" && this.date_de_naissance!="" && this.age_month==""){
        age=this.getAge(this.date_de_naissance);
      }else{
        age = this.age_year;
      }

      if(this.age_month==""){
        month=0;
      }else{
        month=this.age_month;
      }

      if(this.age_month=="" && this.age_year==""){
        date_naissance = this.date_de_naissance;
      }else{
        date_naissance = null;
      }

      const formData = {
        epid: this.epid,
        no_registre: this.no_registre,
        agent: { nom_utilisateur: this.user.nom_utilisateur },
        date_consultation: sending_date,
        date_envoie: sending_date,
        informations_personnelles: {
          civilite: {
            prenom: this.prenom,
            nom: this.nom,
            date_de_naissance: date_naissance,
            genre: this.genre,
            age_en_annee: age,
            age_en_mois: month,
            femme_enceinte: this.femme_enceinte,
            age_gestationnel: this.age_gestationnel
          },
          adresse_et_contact: {
            telephone1_patient: this.telephone1_patient,
            telephone2_patient: this.telephone2_patient,
            adresse_patient: this.adresse_patient
          },
          statut_matrimonial: {
            statut_matrimonial: this.statut_matrimonial
          }
        },
        suspicion_et_diagnostic: {
          diagnostic_et_prescription: {
            fievre: this.fievre,
            temperature_fievre: this.temperature_fievre,
            duree_fievre: this.duree_fievre
          },
          paludisme: {},
          syndrome_grippal_covid19: {},
          arboviroses: {},
          autres_syndromes: {
            diarrhee: this.diarrhee,
            diarrhee_sanglante: this.diarrhee_sanglante
          },
          autres_signes: {},
          facteurs_de_risque: {},
          vaccins: {},
          prelevement: {},
          traitements: {}
        },
        symptomalogie_entourage: '',
        conduite_tenue: ''
      };


      await this.axios
        .post('/api/fiche-fievre/', formData)
        .then( () => {
          this.$store.commit('setIsConsulting', false);
          const toPath = this.$route.query.to || '/home';
          this.$router.push(toPath).catch(() => {});
          this.$store.commit('setShowSuspisionOnboardSection', false);
          this.$store.commit('setShowDiagnosticAndPrescriptionSection', false);
          this.$store.commit('setShowSuspisionAndDiagnosticInfos', false);
          this.$store.commit('setShowPersonalInfosSection', true);
          this.$store.commit('setFemmeEnceinte', 'Non');
          this.$store.dispatch('resetVariables');
          this.$vs.notification({
            progress: 'auto',
            color: '#3CB691',
            position: 'top-left',
            title: 'Enregistrement fiche patient',
            text: `Les informations saisies ont été envoyées avec succès. Merci.`
          })

        })
        .catch(error => {
          if (error.response) {
            for (const property in error.response.data) {
              this.errors.push(`${property}: ${error.response.data[property]}`);
            }
          } else {
            this.errors.push(
              'Quelque chose ne marche pas. Veuillez réessayer encore.'
            );
            console.log(JSON.stringify(error));
          }
          this.$store.commit('setIsConsulting', false);
          const toPath = this.$route.query.to || '/home';
          this.$router.push(toPath).catch(() => {});
          this.$store.commit('setShowSuspisionOnboardSection', false);
          this.$store.commit('setShowDiagnosticAndPrescriptionSection', false);
          this.$store.commit('setShowSuspisionAndDiagnosticInfos', false);
          this.$store.commit('setShowPersonalInfosSection', true);
          this.$store.commit('setFemmeEnceinte', 'Non');
          this.$store.dispatch('resetVariables');
        });
    }
  }
};
</script>

<style scoped >
.popup_content {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #0d1c2e;
  border-radius: 20px;
  padding-bottom: 5px;
}

.flat_button2 {
  border: None;
  color: #49f78f;
  margin-top: 6%;
  font-family: 'Eastman Trial';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
}

.flat_button1 {
  border: None;
  color: #aec0de;
  margin-top: 6%;
  font-family: 'Eastman Trial';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  margin-right: 4%;
}

.title {
  display: flex;
  justify-content: space-between;
  margin-top: 2%;
  font-family: 'Eastman Trial';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #dadada;
  margin-bottom: 2%;
}
</style>