<template>
  <div class="civility_section_content">
    <keep-alive>
    <div class="civility_infos">
      <b-row class="name row_elt">
        <b-col cols="5" class="first_name">
          <b-form-input
            autocomplete="off"
            class="first_name_input input_field"
            v-model="prenom"
            type="text"
            name="first_name"
            size="md"
            placeholder="Prénom"
          ></b-form-input>
        </b-col>
        <b-col cols="5" class="last_name">
          <b-form-input
            autocomplete="off"
            class="last_name_input input_field"
            v-model="nom"
            type="text"
            name="last_name"
            size="md"
            placeholder="Nom"
          ></b-form-input>
        </b-col>
      </b-row>
      <b-row class="gender_and_birthdate row_elt">
        <b-col cols="5" class="birthdate">
          <transition name="slide-fade" mode="out-in">
            <v-date-picker
              close-on-content-click
              id="birthdate_picker"
              color="#3CB691"
              locale="fr"
              class="birthdate_picker"
              v-model="date_de_naissance"
              width="100%"
              no-title
            ></v-date-picker>
          </transition>

          <span class="birthdate_label">Date de naissance</span>
          <b-form-input
            id="birthdate_input"
            autocomplete="off"
            @click="showDatePicker"
            class="birthdate_input"
            readonly
            type="text"
            v-model="birthdate_formatted"
            name="birthdate"
            size="md"
            placeholder="Date de naissance"
          ></b-form-input>
          <img @click="showDatePicker()" class="date_picker_icon" src="@/assets/date_picker.png" />
        </b-col>

        <b-col cols="1"></b-col>
        <b-col cols="5" class="age">
          <b-row class="age_row">
            <b-col class="age_label" cols="1">
              <span class="age_label_text">Age</span>
            </b-col>
            <b-col cols="4">
              <b-form-input
                autocomplete="off"
                class="year_number_input input_field"
                v-model="age_year"
                type="number"
                name="year_number"
                size="md"
                placeholder="(ans)"
                :formatter="formatYear"
              ></b-form-input>
            </b-col>
            <b-col class="age_delimiter" cols="1">
              <span class="delimiter_elt">/</span>
            </b-col>
            <b-col cols="4">
              <b-form-input
                autocomplete="off"
                class="month_number_input input_field"
                v-model="age_month"
                type="number"
                name="month_number"
                size="md"
                placeholder="(mois)"
                :formatter="formatMonth"
              ></b-form-input>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row class="gender row_elt">
        <b-col cols="5" class="gender_info">
          <b-row>
            <b-col class="gender_label" cols="2">
              <span class="gender_label_text">Genre</span>
            </b-col>

            <b-col class="gender_male" cols="5">
              <div class="gender_select_box">
                <b-form-checkbox
                  class="checkbox_male"
                  v-model="genre"
                  name="gender_male"
                  value="Masculin"
                >
                  <span class="checkbox_text">Masculin</span>
                </b-form-checkbox>
              </div>
            </b-col>
            <b-col class="gender_female" cols="5">
              <div class="gender_select_box">
                <b-form-checkbox v-model="genre" name="gender_female" value="Feminin">
                  <span class="checkbox_text">Feminin</span>
                </b-form-checkbox>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row v-show="this.showPregnantSelectBox==true" class="pregnancy row_elt">
        <b-col cols="6" class="pregnancy_info">
          <SwitchBox
            :values="['Non','Oui']"
            :icons="['woman.png','pregnant_woman.png']"
            :label="'Femme enceinte?'"
            :box_style="{label1Left:'-5%',label1Right:'0%', boxMarginLeft:'9%', label2Left:'0%',label2Right:'0%',labelDistance:'20%',iconWidth:'25%',iconDistance:'35%',selectBoxWidth:'18%'}"
            :variableName="'femme_enceinte'"
            :null="false"
          />
        </b-col>
        <b-col cols="1"></b-col>
        <b-col v-show="showGestationalAgeField==true" cols="4" class="pregnancy_age">
          <b-col class="pregnancy_age_label" cols="3">
            <span class="pregnancy_age_label_text">Age gestationnel</span>
          </b-col>
          <b-col cols="7">
            <b-form-input
              autocomplete="off"
              class="pregnancy_age_input input_field"
              v-model="age_gestationnel"
              type="number"
              name="pregnancy_age"
              size="md"
              placeholder="(semaines)"
              :formatter="formatWeeks"
            ></b-form-input>
          </b-col>
        </b-col>
      </b-row>
    </div>
  </keep-alive>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import SwitchBox from '@/components/fiche-individuelle/general/SwitchBox.vue';
import { eventBus } from '@/main.js';

export default {
  name: 'Civility',
  components: {
    SwitchBox
  },
  data: vm => ({
    date_de_naissance: new Date(new Date().getFullYear(),new Date().getMonth(),1).toISOString().substr(0, 10),
    birthdate_formatted: vm.formatDate(new Date(new Date().getFullYear(),new Date().getMonth(),1).toISOString().substr(0, 10)),
    showPregnantSelectBox: false,
    showGestationalAgeField: false
  }),
  mounted() {

    this.$store.commit('setDateDeNaissance', this.date_de_naissance);
    window.addEventListener('click', function(e) {
      let datePickerElt = document.getElementById('birthdate_picker');
      if (datePickerElt != null) {
        if (document.getElementById('birthdate_picker').contains(e.target)) {
          // Clicked in box
        } else {
          if (document.getElementById('birthdate_input').contains(e.target) || e.target.classList.contains("date_picker_icon")) {
            document.getElementById('birthdate_picker').style.display = 'block';
          } else {
            document.getElementById('birthdate_picker').style.display = 'none';
          }
        }
      }
    });
  },
  computed: {
    ...mapState({
      femme_enceinte: 'femme_enceinte'
    }),
    prenom : {
      get() {
        return this.$store.state.prenom;
      },
      set(value){
        this.$store.commit('setPrenom', value);
      }
    },
    nom : {
      get() {
        return this.$store.state.nom;
      },
      set(value){
        this.$store.commit('setNom', value);
      }
    },
    age_year : {
      get() {
        return this.$store.state.age_year;
      },
      set(value){
        this.$store.commit('setAgeYear', value);
      }
    },
    age_month : {
      get() {
        return this.$store.state.age_month;
      },
      set(value){
        this.$store.commit('setAgeMonth', value);
      }
    },
    age_gestationnel : {
      get() {
        return this.$store.state.age_gestationnel;
      },
      set(value){
        this.$store.commit('setAgeGestationnel', value);
      }
    },
    genre : {
      get() {
        return this.$store.state.genre;
      },
      set(value){
        this.$store.commit('setGenre', value);
      }
    },
  },
  watch: {
    nom(val) {
      this.$store.commit('setNom', val);
    },
    prenom(val) {
      this.$store.commit('setPrenom', val);
    },
    date_de_naissance(val) {
      let dateElt = document.getElementById('birthdate_picker');
      setTimeout(() => {
        //
      }, 100);
      dateElt.style.display = 'none';
      this.birthdate_formatted = this.formatDate(val);
      this.date_de_naissance = val;
      this.$store.commit('setDateDeNaissance', val);
    },
    genre(val) {
      if (val == 'Feminin') {
        this.showPregnantSelectBox = true;
      } else {
        this.showPregnantSelectBox = false;
      }
      this.$store.commit('setGenre', val);
    },
    femme_enceinte(val) {
      if (val == 'Oui') {
        this.showGestationalAgeField = true;
      } else {
        this.showGestationalAgeField = false;
      }
      this.$store.commit('setFemmeEnceinte', val);
    },
    age_gestationnel(val) {
      this.$store.commit('setAgeGestationnel', val);
    }
  },
  methods: {
    formatYear(e) {
      return String(e).substring(0, 4);
    },
    formatMonth(e) {
      return String(e).substring(0, 3);
    },
    formatWeeks(e) {
      return String(e).substring(0, 2);
    },
    showDatePicker() {
      let dateElt = document.getElementById('birthdate_picker');
      dateElt.style.display = 'block';
    },
    showDatePicker2() {
      let dateElt = document.getElementById('birthdate_picker');
      dateElt.style.display = 'block';
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split('-');
      return `${month}/${day}/${year}`;
    },
    goToNextSection() {
      eventBus.$emit('triggerAdressAndContactSection');
    }
  }
};
</script>

<style scoped>
.civility_section_content {
  height: 100%;
}

.row_elt {
  display: flex;
  margin-bottom: 1%;
}
.input_field,
.birthdate_input {
  width: 100%;
  height: 120%;
  margin-left: 10%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 10px 10px 20px;
  gap: 10px;
  background: #ffffff;
  border: 2px solid #425169;
  border-radius: 5px;
}

.last_name_input {
  text-align: left;
  margin-left: 25%;
}

.birthdate_input {
  background: #ffffff;
}
.birthdate_input[readonly] {
  background: #ffffff;
}
.gender {
  margin-left: 3.5%;
}

.gender_info {
  display: flex;
  justify-content: center;
  align-items: center;
}

.gender_label {
  display: flex;
  justify-content: center;
  align-items: center;
}

.gender_label_text {
  font-family: 'Eastman Trial';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: rgba(66, 81, 105, 0.78);
}

.gender_select_box {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  gap: 15px;
  width: 100%;
  background: #ebfcfd;
  border: 1px solid #425169;
  border-radius: 5px;
}

.checkbox_text {
  font-family: 'Eastman Trial';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #425169;
  position: relative;
  left: 5%;
}

.age {
  margin-left: 5%;
}

.age_label {
  display: flex;
  align-items: center;
  padding-left: 0;
}

.age_label_text,
.pregnancy_age_label_text {
  font-family: 'Eastman Trial';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: rgba(66, 81, 105, 0.78);
  text-align: justify;
}
.age_delimiter {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  margin-left: 3%;
}
.delimiter_elt {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Eastman Roman Trial';
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 18px;
  color: rgba(66, 81, 105, 0.78);
}

.pregnancy {
  width: 98%;
  margin-left: 0%;
}
.pregnancy_age {
  margin-left: 3%;
  display: flex;
  justify-content: space-between;
}
.pregnancy_age_label {
  text-align: justify;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.next_btn_row {
  display: flex;
  justify-content: flex-end;
  width: 98%;
  margin-bottom: 2%;
  margin-top: 1%;
}
.next_button {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  gap: 10px;
  background: #484ef7 !important;
  border-radius: 5px;
  box-shadow: none !important;
}
.next_button:active {
  transform: scale(0.98);
  opacity: 0.9;
}
.next_button_text {
  font-family: 'Eastman Roman Trial';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
}
.chevron_right_icon {
  background-color: #484ef7;
  color: #484ef7;
}
.next_right_icon {
  position: relative;
  left: 10%;
}
.extra_placeholder {
  position: absolute;
  bottom: 30%;
  right: 10%;
  font-family: 'Eastman Roman Trial';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: rgba(66, 81, 105, 0.78);
}
.date_picker_icon {
  position: absolute;
  bottom: 30%;
  right: -2%;
  z-index: 99999999999 !important;
}
.slide-fade-enter-active {
  transition: all 0.4s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(20px);
  opacity: 0;
}

.birthdate_picker {
  position: absolute;
  top: -105%;
  left: 110%;
  width: 80%;
  box-sizing: border-box;
  border: 2px solid #979797;
  border-radius: 10px;
  color: #262847 !important;
  z-index: 10000000;
  display: none;
}

.birthdate_input{
  height: 65%;
}

.birthdate_label{
  position: relative;
  right: 27%;
  font-family: 'Comfortaa';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 13px;
  letter-spacing: 0.01em;
  color: #425169;
}
</style>
