<template>
  <div class="conduct_content">
    <b-row class="symptomes_row">
      <b-col class="symptomes_question_checkbox" cols="2">
        <b-form-checkbox
          class="conduct_checkbox"
          v-model="retour_a_domicile"
          name="conduct_checkbox"
        >
          <span class="checkbox_label">retour à domicile</span>
        </b-form-checkbox>
      </b-col>
      <b-col cols="1"></b-col>
      <b-col class="symptomes_question_checkbox" cols="2">
        <b-form-checkbox
          class="conduct_checkbox"
          v-model="mise_en_observation"
          name="conduct_checkbox"
        >
          <span class="checkbox_label">mise en observation</span>
        </b-form-checkbox>
      </b-col>
      <b-col cols="1"></b-col>

      <b-col class="symptomes_question_checkbox" cols="2">
        <b-form-checkbox class="conduct_checkbox" v-model="hospitalise" name="conduct_checkbox">
          <span class="checkbox_label">hospitalisé(e)</span>
        </b-form-checkbox>
      </b-col>
      <b-col cols="1"></b-col>
      <b-col class="symptomes_question_checkbox" cols="2">
        <b-form-checkbox class="conduct_checkbox" v-model="refere" name="conduct_checkbox">
          <span class="checkbox_label">référé(e)</span>
        </b-form-checkbox>
      </b-col>
    </b-row>
  </div>
</template>


<script>
import { mapState } from 'vuex';

export default {
  name: 'ConductHeldSection',
  components: {},
  data: () => ({
    retour_a_domicile: '',
    mise_en_observation: '',
    hospitalise: '',
    refere: ''
  }),
  computed: {
    ...mapState({
      genre: 'genre'
    })
  },
  watch: {
    retour_a_domicile(val) {
      let checkboxes = document.querySelectorAll('.conduct_checkbox');
      for (let i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].firstChild.checked) {
          checkboxes[i].firstChild.click();
        }
      }
      if (val == true) {
        this.$store.commit('setConduiteTenue', 'retour à domicile');
      }
    },
    mise_en_observation(val) {
      let checkboxes = document.querySelectorAll('.conduct_checkbox');
      for (let i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].firstChild.checked) {
          checkboxes[i].firstChild.click();
        }
      }
      if (val == true) {
        this.$store.commit('setConduiteTenue', 'mise en observation');
      }
    },
    hospitalise(val) {
      let checkboxes = document.querySelectorAll('.conduct_checkbox');
      for (let i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].firstChild.checked) {
          checkboxes[i].firstChild.click();
        }
      }
      if (val == true) {
        let checkboxes = document.querySelectorAll('.conduct_checkbox');
        for (let i = 0; i < checkboxes.length; i++) {
          if (checkboxes[i].firstChild.checked) {
            checkboxes[i].firstChild.click();
          }
        }
        this.$store.commit(
          'setConduiteTenue',
          this.genre == 'Masculin' ? 'hospitalisé' : 'hospitalisée'
        );
      }
    },
    refere(val) {
      let checkboxes = document.querySelectorAll('.conduct_checkbox');
      for (let i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].firstChild.checked) {
          checkboxes[i].firstChild.click();
        }
      }
      if (val == true) {
        this.$store.commit(
          'setConduiteTenue',
          this.genre == 'Masculin' ? 'référé' : 'référée'
        );
      }
    }
  },
  methods: {}
};
</script>

<style scoped>
.conduct_content {
  margin-top: 1%;
  margin-bottom: 5%;
}
.symptomes_row {
  width: 95%;
  position: relative;
  left: 2%;
  top: -5%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.symptomes_question_checkbox {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 6px 15px;
  gap: 10px;
  background: #ebfcfd;
  border-radius: 5px;
}

.symptomes_question_infos {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 6px 10px;
  gap: 10px;
  border-radius: 5px;
}

.icon_info {
  transform: scale(1.1);
}
.info_label_text {
  position: relative;
  left: 4%;
  font-family: 'Eastman Roman Trial';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #484ef7;
}
.conduct_checkbox {
  transform: scale(1.1);
}

.checkbox_label {
  font-family: 'Eastman Roman Trial';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #425169;
  position: relative;
  left: 4%;
}
</style>