<template>
  <div class="vaccines_content">
    <b-row class="main_questions">
      <b-col cols="6" class="question">
        <div class="question_label">Vaccination Fièvre Jaune</div>
        <div class="question_field">
          <SwitchBox
            :values="['Non','Oui']"
            :icons="['blank.png','blank.png']"
            :box_style="{label1Left:'-5%',label1Right:'0%', boxMarginLeft:'9%', label2Left:'0%',label2Right:'0%',iconWidth:'25%',iconDistance:'0%',selectBoxHeight:'47%',selectBoxWidth:'17.5%'}"
            :variableName="'vaccination_fj'"
            :null="false"
          />
        </div>

        <b-form-checkbox
          v-model="info_vaccin_fj"
          value="Date inconnue"
          unchecked-value="Non renseigné"
          class="etat_vaccin_fj"
        >
          Date inconnue ?
        </b-form-checkbox>

      </b-col>

      <b-col cols="6" class="question">
        <div class="question_label">Vaccination Covid-19</div>
        <div class="question_field">
          <SwitchBox
            :values="['Non','Oui']"
            :icons="['blank.png','blank.png']"
            :box_style="{label1Left:'-5%',label1Right:'0%', boxMarginLeft:'9%', label2Left:'0%',label2Right:'0%',iconWidth:'25%',iconDistance:'0%',selectBoxHeight:'47%',selectBoxWidth:'17.5%'}"
            :variableName="'vaccination_covid'"
            :null="false"
          />
        </div>

        <b-form-checkbox
          v-model="info_vaccin_covid"
          value="Date inconnue"
          unchecked-value="Non renseigné"
          class="etat_vaccin_covid"
        >
          Date inconnue ?
        </b-form-checkbox>

      </b-col>
    </b-row>
    <b-row class="subquestions">
      <b-col v-show="vaccination_fj=='Oui' && show_date_fj==true" class="fj_subquestions" cols="5">
        <span class="subquestion_label">Date</span>
        <transition name="slide-fade" mode="out-in">
          <v-date-picker
            close-on-content-click
            id="fj_vaccine_date_picker"
            color="#3CB691"
            locale="fr"
            class="fj_vaccine_date_picker"
            v-model="date_vaccin_fj"
            width="100%"
            no-title
          ></v-date-picker>
        </transition>
        <b-form-input
          id="fj_vaccine_date_input"
          @click="showDatePicker('fj_vaccine_date_picker')"
          class="fj_vaccine_date_input"
          readonly
          type="text"
          v-model="formatted_fj_vaccine_date"
          size="md"
        ></b-form-input>
        <img
          @click="showDatePicker('fj_vaccine_date_picker')"
          class="date_picker_icon"
          src="@/assets/date_picker.png"
        />
      </b-col>

      <b-col v-show="vaccination_fj=='Oui' && show_date_fj==false" class="fj_subquestions" cols="5">
      </b-col>

      <b-col v-if="(vaccination_fj=='Non' || vaccination_fj=='') && show_date_covid==true" class="fj_subquestions" cols="5"></b-col>
      <b-col v-show="vaccination_covid=='Oui' && show_date_covid==true" class="covid_subquestions" cols="5">
        <b-dropdown
          :text-class="'aaaa'"
          variant="#ffffff"
          id="vaccine_select"
          class="vaccine_select"
          :text="vaccine"
        >
          <b-dropdown-item @click="setVaccine" href="#">Non défini</b-dropdown-item>
          <b-dropdown-item @click="setVaccine" href="#">Sinopharm</b-dropdown-item>
          <b-dropdown-item @click="setVaccine" href="#">Astrazeneca</b-dropdown-item>
          <b-dropdown-item @click="setVaccine" href="#">Johnson & Johnson</b-dropdown-item>
          <b-dropdown-item @click="setVaccine" href="#">Pfizer BioNtec</b-dropdown-item>
        </b-dropdown>
      </b-col>
      <b-col style="visibility:hidden;" v-show="vaccination_covid=='Oui' && show_date_covid==true" cols="5"></b-col>
      <b-col
        style="margin-right:15%;"
        v-show="vaccination_covid=='Oui' && show_date_covid==true"
        class="covid_subquestions"
        cols="4"
      >
        <span style="margin-bottom:2%;" class="subquestion_label">Date Première dose</span>
        <transition name="slide-fade" mode="out-in">
          <v-date-picker
            close-on-content-click
            id="covid_first_dose_date_picker"
            color="#3CB691"
            locale="fr"
            class="covid_first_dose_date_picker"
            v-model="date1_vaccin_covid"
            width="100%"
            no-title
          ></v-date-picker>
        </transition>
        <b-form-input
          id="covid_first_dose_date_input"
          @click="showDatePicker('covid_first_dose_date_picker')"
          class="covid_first_dose_date_input"
          readonly
          type="text"
          v-model="formatted_covid_vaccine_date1"
          size="md"
        ></b-form-input>
        <img
          @click="showDatePicker"
          class="date_picker_icon covid_date"
          src="@/assets/date_picker.png"
        />
      </b-col>
      <b-col style="visibility:hidden;" v-show="vaccination_covid" cols="5"></b-col>
      <b-col
        style="margin-right:15%;"
        v-show="vaccination_covid=='Oui' && show_date_covid==true"
        class="covid_subquestions"
        cols="4"
      >
        <span style="margin-bottom:2%;" class="subquestion_label">Date 2ème dose</span>
        <transition name="slide-fade" mode="out-in">
          <v-date-picker
            close-on-content-click
            id="covid_second_dose_date_picker"
            color="#3CB691"
            locale="fr"
            class="covid_second_dose_date_picker"
            v-model="date2_vaccin_covid"
            width="100%"
            no-title
          ></v-date-picker>
        </transition>
        <b-form-input
          id="covid_second_dose_date_input"
          @click="showDatePicker('covid_second_dose_date_picker')"
          class="covid_second_dose_date_input"
          readonly
          type="text"
          v-model="formatted_covid_vaccine_date2"
          size="md"
        ></b-form-input>
        <img
          @click="showDatePicker"
          class="date_picker_icon covid_date"
          src="@/assets/date_picker.png"
        />
      </b-col>
      <b-col style="visibility:hidden;" v-show="vaccination_covid=='Oui' && show_date_covid==true" cols="5"></b-col>
      <b-col
        style="margin-right:15%;"
        v-show="vaccination_covid=='Oui' && show_date_covid==true"
        class="covid_subquestions"
        cols="4"
      >
        <span style="margin-bottom:2%;" class="subquestion_label">Date 3ème dose</span>
        <transition name="slide-fade" mode="out-in">
          <v-date-picker
            close-on-content-click
            id="covid_third_dose_date_picker"
            color="#3CB691"
            locale="fr"
            class="covid_third_dose_date_picker"
            v-model="date3_vaccin_covid"
            width="100%"
            no-title
          ></v-date-picker>
        </transition>
        <b-form-input
          id="covid_third_dose_date_input"
          @click="showDatePicker('covid_third_dose_date_picker')"
          class="covid_third_dose_date_input"
          readonly
          type="text"
          v-model="formatted_covid_vaccine_date3"
          size="md"
        ></b-form-input>
        <img
          @click="showDatePicker"
          class="date_picker_icon covid_date"
          src="@/assets/date_picker.png"
        />
      </b-col>

      <!-- <b-col
        style="margin-right:15%;"
        v-show="vaccination_covid=='Oui' && show_date_covid==true"
        class="covid_4th_dose"
        cols="4"
      >
        <span style="margin-bottom:2%;" class="subquestion_label">Date 4ème dose</span>

        <b-form-input
          id="covid_third_dose_date_input"
          @click="showDatePicker('covid_third_dose_date_picker')"
          class="covid_third_dose_date_input"
          readonly
          type="text"
          v-model="formatted_covid_vaccine_date3"
          size="md"
        ></b-form-input>
        <img
          @click="showDatePicker"
          class="date_picker_icon covid_date"
          src="@/assets/date_picker.png"
        />
      </b-col> -->
      
    </b-row>
  </div>
</template>


<script>
import { mapState } from 'vuex';
import { eventBus } from '@/main.js';
import SwitchBox from '@/components/fiche-individuelle/general/SwitchBox.vue';

export default {
  name: 'VaccinesSection',
  components: { SwitchBox },
  data: () => ({
    date_vaccin_fj: '2021-12-31',
    formatted_fj_vaccine_date: 'Appuyer pour remplir la date',
    date1_vaccin_covid: '2021-12-31',
    date2_vaccin_covid: '2021-12-31',
    date3_vaccin_covid: '2021-12-31',
    formatted_covid_vaccine_date1: 'Appuyer pour remplir la date',
    formatted_covid_vaccine_date2: 'Appuyer pour remplir la date',
    formatted_covid_vaccine_date3: 'Appuyer pour remplir la date',
    vaccine: 'Type du vaccin',
    info_vaccin_fj: '',
    info_vaccin_covid: '',
    show_date_fj: true,
    show_date_covid: true
  }),
  mounted() {
    window.addEventListener('click', e =>
      this.handlePicker('fj_vaccine_date_picker', 'fj_vaccine_date_input', e)
    );

    window.addEventListener('click', e =>
      this.handlePicker(
        'covid_second_dose_date_picker',
        'covid_second_dose_date_input',
        e
      )
    );
    window.addEventListener('click', e =>
      this.handlePicker(
        'covid_third_dose_date_picker',
        'covid_third_dose_date_input',
        e
      )
    );
    window.addEventListener('click', e =>
      this.handlePicker(
        'covid_first_dose_date_picker',
        'covid_first_dose_date_input',
        e
      )
    );
  },
  computed: {
    ...mapState({
      vaccination_fj: 'vaccination_fj',
      vaccination_covid: 'vaccination_covid',
      is_fj_vaccine_percentage_updated: 'is_fj_vaccine_percentage_updated',
      is_covid_vaccine_percentage_updated:
        'is_covid_vaccine_percentage_updated',
      vaccines_percentage: 'vaccines_percentage',
      suspision_percentage: 'suspision_percentage',
      suspisionAndDiagnosticCategories: 'suspisionAndDiagnosticCategories'
    })
  },
  watch: {
    date_vaccin_fj(val) {
      let dateElt = document.getElementById('fj_vaccine_date_picker');
      setTimeout(() => {
        //
      }, 100);
      dateElt.style.display = 'none';
      this.formatted_fj_vaccine_date = this.formatDate(val);
      this.$store.commit('setDateVaccinFj', this.date_vaccin_fj);
    },
    date1_vaccin_covid(val) {
      let dateElt = document.getElementById('covid_first_dose_date_picker');
      setTimeout(() => {
        //
      }, 100);
      dateElt.style.display = 'none';
      this.formatted_covid_vaccine_date1 = this.formatDate(val);
      this.$store.commit('setCovidFirstDoseDate', this.date1_vaccin_covid);
    },
    date2_vaccin_covid(val) {
      let dateElt = document.getElementById('covid_second_dose_date_picker');
      setTimeout(() => {
        //
      }, 100);
      dateElt.style.display = 'none';
      this.formatted_covid_vaccine_date2 = this.formatDate(val);
      this.$store.commit('setCovidSecondDoseDate', this.date2_vaccin_covid);
    },
    date3_vaccin_covid(val) {
      let dateElt = document.getElementById('covid_third_dose_date_picker');
      setTimeout(() => {
        //
      }, 100);
      dateElt.style.display = 'none';
      this.formatted_covid_vaccine_date3 = this.formatDate(val);
      this.$store.commit('setCovidThirdDoseDate', this.date3_vaccin_covid);
    },
    vaccination_fj() {

    },
    vaccination_covid() {

    },
    vaccines_percentage(val) {
      if (val >= 80) {
        eventBus.$emit('activeVaccineSectionComplete');
      } else {
        eventBus.$emit('deactivateVaccineSectionComplete');
      }
    },
    vaccine() {
      this.$store.commit('setTypeVaccin', this.vaccine);
    },
    info_vaccin_fj(val){
      this.$store.commit('setEtatVaccinFJ', val);
      if (val=="Date inconnue") {
        this.show_date_fj=false;
      }else{
        this.show_date_fj=true;
      }
    },
    info_vaccin_covid(val){
      this.$store.commit('setEtatVaccinCovid', val);
      if (val=="Date inconnue") {
        this.show_date_covid=false;
      }else{
        this.show_date_covid=true;
      }
    }
  },
  methods: {
    goToNextSection() {
      eventBus.$emit('triggerTestsSection');
    },
    showDatePicker(pickerName) {
      // this.handlePicker();
      let dateElt = document.getElementById(pickerName);
      dateElt.style.display = 'block';
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split('-');
      return `${month}/${day}/${year}`;
    },
    handlePicker(pickerName, inputName, e) {
      let datePickerElt = document.getElementById(pickerName);
      if (datePickerElt != null) {
        if (document.getElementById(pickerName).contains(e.target)) {
          // Clicked in box
        } else {
          if (document.getElementById(inputName).contains(e.target)) {
            document.getElementById(pickerName).style.display = 'block';
          } else {
            document.getElementById(pickerName).style.display = 'none';
          }
        }
      }
    },
    setVaccine(e) {
      this.vaccine = e.target.textContent;
    }
  }
};
</script>

<style scoped>
.vaccines_content {
  margin-bottom: 2%;
}
.heading {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 2%;
}

.logos {
  width: 20%;
}

.logo {
  margin-left: 2%;
}

.img_logoIpd {
  margin-left: 6%;
}

.img_logo4s {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.title {
  margin-left: 22%;
  font-family: 'Eastman Roman Trial';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #425169;
}

.next_btn_row {
  display: flex;
  justify-content: flex-end;
  width: 98%;
  margin-bottom: 2%;
  position: relative;
}
.next_button {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  gap: 10px;
  background: #484ef7 !important;
  border-radius: 5px;
  box-shadow: none !important;
}
.next_button:active {
  transform: scale(0.98);
  opacity: 0.9;
}
.next_button_text {
  font-family: 'Eastman Roman Trial';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
}
.chevron_right_icon {
  background-color: #484ef7;
  color: #484ef7;
}
.next_right_icon {
  position: relative;
  left: 10%;
}

.main_questions {
  width: 80%;
  margin-left: 8%;
  display: flex;
  margin-top: 1.5%;
  height: 150px;
}

.question {
  display: flex;
  flex-direction: column;
  width: 40%;
  height: 100%;
  padding: 0;
  justify-content: center;
  align-items: center;
}
.question_field {
  height: 60%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.question_label {
  font-family: 'Eastman Roman Trial';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #000000;
  opacity: 0.9;
  margin-bottom: 5%;
}

.subquestions {
  display: flex;
  width: 98%;
  margin-left: 1%;
  justify-content: space-between;
}

.subquestion_label {
  font-family: 'Eastman Roman Trial';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #425169;
}

.fj_subquestions {
  margin-left: 4%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.covid_subquestions {
  margin-right: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.fj_vaccine_date_input,
.covid_first_dose_date_input,
.covid_second_dose_date_input,
.covid_third_dose_date_input {
  border: 2px solid blue;
  width: 100%;
  margin-left: 10%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 10px 10px 20px;
  gap: 10px;
  background: #ffffff;
  border: 2px solid #425169;
  border-radius: 5px;
  height: 85%;
  position: relative;
  left: -5%;
}

.fj_vaccine_date_input[readonly],
.covid_first_dose_date_input[readonly],
.covid_second_dose_date_input[readonly],
.covid_third_dose_date_input[readonly] {
  background: #ffffff;
}

.date_picker_icon {
  position: absolute;
  bottom: 32%;
  right: 10%;
  z-index: 200;
  transform: scale(0.75);
}
.covid_date {
  position: absolute;
  bottom: 25%;
  right: 10%;
  z-index: 200;
  transform: scale(0.75);
}
.slide-fade-enter-active {
  transition: all 0.4s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(20px);
  opacity: 0;
}

.fj_vaccine_date_picker {
  position: absolute;
  top: 100%;
  left: 14%;
  width: 80%;
  box-sizing: border-box;
  border: 2px solid #979797;
  border-radius: 10px;
  color: #262847 !important;
  z-index: 10000000;
  display: none;
}

.covid_first_dose_date_picker {
  position: absolute;
  top: 5%;
  right: 105%;
  width: 80%;
  box-sizing: border-box;
  border: 2px solid #979797;
  border-radius: 10px;
  color: #262847 !important;
  z-index: 10000000;
  display: none;
}

.covid_second_dose_date_picker {
  position: absolute;
  top: -100%;
  right: 105%;
  width: 80%;
  box-sizing: border-box;
  border: 2px solid #979797;
  border-radius: 10px;
  color: #262847 !important;
  z-index: 10000000;
  display: none;
}
.covid_third_dose_date_picker {
  position: absolute;
  bottom: -35%;
  right: 105%;
  width: 80%;
  box-sizing: border-box;
  border: 2px solid #979797;
  border-radius: 10px;
  color: #262847 !important;
  z-index: 10000000;
  display: none;
}

.vaccine_select {
  height: 90% !important;
  padding: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  border-radius: 10%;
  background-color: #ebfcfd !important;
  margin-right: 10%;
  color: #425169 !important;
  font-family: 'Eastman Roman Trial';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: #425169;
}

.etat_vaccin_fj, 
.etat_vaccin_covid{
  margin-top: 3%;
  margin-bottom: 2%;
  position: relative;
  left: -4%;
  transform: scale(1.2);
  font-family: 'Eastman Trial';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 27px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: rgba(66, 81, 105, 0.78);
}

.etat_vaccin_covid{
  margin-bottom: 2%;
}

.covid_4th_dose{
  border: 2px solid red;
}


</style>