<template>
  <div class="adress_and_contact_section_content">
    <div class="adressAndContact_infos">
      <b-row class="phone1 row_elt">
        <b-col cols="12" class="phone_number">
          <b-form-input
            autocomplete="off"
            class="phone_number1_input input_field"
            v-model="telephone1_patient"
            type="tel"
            name="phone_number2"
            size="md"
            placeholder="Téléphone du patient"
          ></b-form-input>
          <img class="phone_icon" src="@/assets/phone.png" />
        </b-col>
      </b-row>

      <b-row class="phone2 row_elt">
        <b-col cols="12" class="phone_number">
          <b-form-input
            autocomplete="off"
            class="phone_number2_input input_field"
            v-model="profession"
            type="tel"
            name="phone_number2"
            size="md"
            placeholder="Profession du patient"
          ></b-form-input>
          <img class="phone_icon" src="@/assets/profession.png" />
        </b-col>
      </b-row>

      <b-row class="adress row_elt">
        <b-col cols="12" class="patient_adress">
          <b-form-input
            autocomplete="off"
            class="patient_adress_input input_field"
            v-model="adresse_patient"
            type="text"
            name="patient_adress"
            size="md"
            placeholder="Adresse du patient"
          ></b-form-input>
          <img class="location_icon" src="@/assets/location.png" />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { eventBus } from '@/main.js';

export default {
  name: 'AdressAndContactSection',
  components: {},
  data: () => ({
    telephone1_patient: '',
    profession: '',
    adresse_patient: '',
    phone1_percentage: 40,
    patient_adress_percentage: 40,
    phone2_percentage: 5
  }),
  mounted() {},
  computed: {
    ...mapState({})
  },
  methods: {
    goToNextSection() {
      eventBus.$emit('triggerMatrimonialStatusSection');
    }
  },
  watch: {
    telephone1_patient(val) {
      this.$store.commit('setTelephone1Patient', val);
    },
    profession(val) {
      this.$store.commit('setProfession', val);
    },
    adresse_patient(val) {
      this.$store.commit('setAdressePatient', val);
    }
  }
};
</script>

<style scoped>
.adress_and_contact_section_content {
}

.adressAndContact_infos {
  width: 100%;
}

.row_elt {
  display: flex;
  margin-bottom: 1%;
  width: 100%;
}
.input_field {
  width: 100%;
  height: 120%;
  margin-left: 1%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 10px 10px 20px;
  gap: 10px;
  background: #ffffff;
  border: 2px solid #425169;
  border-radius: 5px;
}

.contact_name {
  margin-bottom: 1%;
}

.location_icon,
.phone_icon {
  position: absolute;
  bottom: 25%;
  right: 5%;
  z-index: 200;
  transform: scale(0.9);
}
</style>
