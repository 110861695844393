<template>
  <div class="recap_content">
    <b-row id="header" class="header">
      <b-col class="title_block" cols="6">
        <div class="title_infos">
          Formulaire individuel &nbsp;
          <span class="record_date">| {{this.datetime}} |</span>
          <span class="in_progress">&nbsp; résumé</span>
        </div>
        <div class="patient_num">
          <p>Patient N° {{this.epid}}</p>
        </div>
      </b-col>
      <b-col class="command_buttons" cols="6">
        <b-button
          ref="modify_record_button"
          @click="ModifyRecord()"
          variant="light"
          class="modify_button"
        >
          <span class="modify_label">Modifier</span>

          <img class="modify_icon" src="@/assets/pencil.png" alt="pencil" />
        </b-button>
      </b-col>
    </b-row>

    <b-row class="title">
      <b-col cols="12" class="title_text">Résumé de la consultation, {{this.datetime}}</b-col>
    </b-row>

    <div class="fever_and_diarrhee_infos_resume">
      <div
        v-show="this.nom!='' || this.prenom!='' || this.telephone1_patient!='' || this.adresse_patient!=''"
        class="personal_infos_resume"
      >
        <span style="opacity:0.8;" class="resume_title">{{prenom}} {{nom}}</span>
        <span v-if="telephone1_patient!=''" class="resume_data">{{telephone1_patient}}</span>
        <span v-if="adresse_patient" class="resume_data">{{adresse_patient}}</span>
      </div>
      <div class="fever_resume">
        <span class="resume_title">Fièvre</span>
        <span v-if="temperature_fievre!=''" class="resume_data">
          {{temperature_fievre}}
          <span class="additional_infos">°C</span>
        </span>
        <span v-if="duree_fievre" class="resume_data">
          {{duree_fievre}}
          <span class="additional_infos">Derniers jours</span>
        </span>
      </div>
      <div class="diarrhee_resume">
        <span class="resume_title">Diarrhées</span>
        <span v-if="diarrhee!=''" class="resume_data">
          <span v-if="diarrhee_sanglante!=''" class="diarrhee_infos">Sanglante</span>
          <span v-else-if="diarrhee!=''" class="diarrhee_infos">{{diarrhee}}</span>
        </span>
      </div>
    </div>

    <hr style="width:50%; margin-left:25%; margin-top:2%;" />
    <div class="recap_section">
      <span class="recap_section_title">Symptomes</span>
      <!-- TODO : loop over elements  -->
    </div>

    <hr style="width:50%; margin-left:25%; margin-top:2%;" />
    <div class="recap_section">
      <span class="recap_section_title">Prescriptions</span>
      <!-- TODO : loop over elements  -->
    </div>
    <hr style="width:50%; margin-left:25%; margin-top:2%;" />
    <div class="recap_section">
      <span class="recap_section_title">TDR et PCR</span>
      <!-- TODO : loop over elements  -->
    </div>

    <hr style="width:50%; margin-left:25%; margin-top:2%;" />
    <div class="recap_section">
      <span class="recap_section_title">Autres signes et facteurs de risque</span>
      <!-- TODO : loop over elements  -->
    </div>
    <hr style="width:50%; margin-left:25%; margin-top:2%;" />

    <div class="send_button_row">
      <b-col class="send_record_button" cols="6">
        <b-button  @click="sendRecord()" variant="light" class="send_button">
          <span class="send_button_text">Terminer et envoyer</span>
        </b-button>
      </b-col>
    </div>
  </div>
</template>


<script>
import { mapState } from 'vuex';

export default {
  name: 'FormRecapSection',
  components: {},
  data: () => ({
    datetime: ''
  }),
  mounted() {
    this.getCurrentDateTime();
  },
  computed: {
    ...mapState({
      consulting_date: 'consulting_date',
      user: 'user',
      epid: 'epid',
      //informations personnelles
      prenom: 'prenom',
      nom: 'nom',
      date_de_naissance: 'date_de_naissance',
      genre: 'genre',
      age: 'age',
      femme_enceinte: 'femme_enceinte',
      age_gestationnel: 'age_gestationnel',
      telephone1_patient: 'telephone1_patient',
      telephone2_patient: 'telephone2_patient',
      adresse_patient: 'adresse_patient',
      statut_matrimonial: 'statut_matrimonial',
      //diagnostic et prescription
      fievre: 'fievre',
      temperature_fievre: 'temperature_fievre',
      duree_fievre: 'duree_fievre',
      //traitements
      traitement_prescrit: 'traitement_prescrit',
      antibiotiques: 'antibiotiques',
      antipaludiques: 'antipaludiques',
      antipyretiques: 'antipyretiques',
      antihistaminiques: 'antihistaminiques',
      broncodilatateurs: 'broncodilatateurs',
      autres_traitements_prescrits: 'autres_traitements_prescrits',
      //autre syndromes
      diarrhee: 'diarrhee',
      diarrhee_sanglante: 'diarrhee_sanglante',
      //paludisme
      tdr_palu: 'tdr_palu',
      resultat_tdr_palu: 'resultat_tdr_palu',
      //Syndrome grippal - covid 19
      anorexie: 'anorexie',
      cephalees: 'cephalees',
      dyspnee: 'dyspnee',
      ecoulement_nasal: 'ecoulement_nasal',
      nausees: 'nausees',
      perte_odorat: 'perte_odorat',
      perte_de_gout: 'perte_de_gout',
      mal_de_gorge: 'mal_de_gorge',
      toux: 'toux',
      vomissement: 'vomissement',
      tdr_covid: 'tdr_covid',
      resultat_covid_19: 'resultat_covid_19',
      tdr_covid_antigene_result: 'tdr_covid_antigene_result',
      tdr_covid_anticorps_result: 'tdr_covid_anticorps_result',
      igm_positif: 'igm_positif',
      iga_positif: 'iga_positif',
      igg_positif: 'igg_positif',
      //arboviroses
      arthralgies: 'arthralgies',
      eruption_cutanee: 'eruption_cutanee',
      douleurs_retro_orbitaires: 'douleurs_retro_orbitaires',
      myalgies: 'myalgies',
      manifestations_hemorragiques: 'manifestations_hemorragiques',
      //autres signes
      convulsion: 'convulsion',
      frisson: 'frisson',
      stridor: 'stridor',
      tirage_sous_costal: 'tirage_sous_costal',
      ictere: 'ictere',
      raideur_nuque: 'raideur_nuque',
      anemie_clinique: 'anemie_clinique',
      refus_de_teter: 'refus_de_teter',
      splenomegalie: 'splenomegalie',
      autres_symptomes: 'autres_symptomes',
      //facteurs de risque
      diabete: 'diabete',
      asthme: 'asthme',
      drepanocytose: 'drepanocytose',
      hta: 'hta',
      malnutrition: 'malnutrition',
      obesite: 'obesite',
      pneumopathie_chronique: 'pneumopathie_chronique',
      autres_risques: 'autres_risques',
      //vaccins
      vaccination_fj: 'vaccination_fj',
      date_vaccin_fj: 'date_vaccin_fj',
      vaccination_covid: 'vaccination_covid',
      type_vaccin: 'type_vaccin',
      first_dose_date: 'first_dose_date',
      second_dose_date: 'second_dose_date',
      third_dose_date: 'third_dose_date',
      //tests_et_prelevements
      prelevement: 'prelevement',
      type_prelevement: 'type_prelevement',
      date_prelevement: 'date_prelevement',
      //symptomalogie
      symptomalogie_entourage: 'symptomalogie_entourage',
      //conduite tenue
      conduite_tenue: 'conduite_tenue'
    })
  },
  watch: {},
  methods: {
    getCurrentDateTime() {
      var currentdate = new Date();
      this.datetime =
        currentdate.getDate() +
        '-' +
        (currentdate.getMonth() + 1) +
        '-' +
        currentdate.getFullYear() +
        ' , ' +
        currentdate.getHours() +
        ':' +
        currentdate.getMinutes() +
        ':' +
        currentdate.getSeconds();
    },
    sendRecord() {
    },
    ModifyRecord() {
      // this.$router.go(-1);
    }
  }
};
</script>

<style scoped>
.recap_content {
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 550px;
}
.header {
  width: 100%;
  margin: 0;
  height: 20%;
  padding-top: 10px;
  background: #ffffff !important;
  overflow-y: hidden;
}

.title_block {
  display: flex;
  flex-direction: column;
}
.title_infos {
  margin-top: 2%;
  display: flex;
  font-family: 'Eastman Roman Trial';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: #0d1c2e;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.record_date {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #425169;
  mix-blend-mode: normal;
  flex: none;
  order: 2;
  flex-grow: 0;
}

.in_progress {
  font-family: 'Eastman Roman Trial';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #484ef7;
  mix-blend-mode: normal;
  flex: none;
  order: 4;
  flex-grow: 0;
}

.patient_num {
  margin-top: 2%;
  display: flex;
  font-family: 'Eastman Roman Trial';
  font-style: normal;
  font-weight: 600;
  font-size: 23px;
  line-height: 27px;
  color: #425169;
}

.command_buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.leaving_button {
  box-sizing: border-box !important;
  background: #ffffff !important;
  border: 2px solid #425169 !important;
  border-radius: 5px;
  height: 58%;
  text-align: right;
  width: 34%;
  padding-left: 15px;
  box-shadow: none !important;
}

.leaving_button:active {
  background-color: #ffffff !important;
  border: none !important;
  box-shadow: none !important;
}

.leaving_button_text {
  position: relative;
  left: 10%;
  font-size: 1.05em;
}

.modify_button:active {
  background-color: #3cb691;
  opacity: 0.8;
}

.modify_button {
  box-sizing: border-box;
  background: #ffffff !important;
  border: 2px solid #425169;
  border-radius: 5px;
  height: 56%;
  width: 20%;
  color: #ffffff !important;
  margin-left: 6%;
  margin-right: 2%;
  font-size: 1.1em;
}

.modify_icon {
  position: relative;
  right: 75%;
}

.modify_label {
  position: relative;
  left: 25%;
  color: #425169;
  font-family: 'Eastman Roman Trial';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
}

.title_text {
  font-family: 'Eastman Roman Trial';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #6c778a;
}

.recap_infos {
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  border: 2px solid red;
}

.fever_and_diarrhee_infos_resume {
  width: 50%;
  margin-left: 25%;
  margin-top: 5%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px 40px;
  gap: 40px;
  background: #ebfcfd;
  border: 1px solid #c6ebed;
  border-radius: 5px;
}

.fever_resume,
.personal_infos_resume {
  display: flex;
  flex-direction: column;
  border-right: 1px solid #dadada;
  width: 35%;
}
.diarrhee_resume {
  display: flex;
  flex-direction: column;
  width: 35%;
}

.resume_title {
  font-family: 'Eastman Roman Trial';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #231f20;
  opacity: 0.9;
}

.resume_data,
.diarrhee_infos {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 17px;
  color: #484ef7;
  margin-top: 5%;
}

.additional_infos {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: #425169;
}

.btn_label {
  position: relative;
  left: 24%;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #425169;
}

.recap_section {
  width: 50%;
  margin-left: 25%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.recap_section_title {
  font-family: 'Eastman Roman Trial';
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 21px;
  color: #231f20;
  opacity: 0.9;
}

.send_record_button {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.send_button:active {
  background-color: #3cb691;
  opacity: 0.8;
}

.send_button {
  box-sizing: border-box;
  background: #3cb691 !important;
  border-radius: 5px;
  width: 40%;
  color: #ffffff !important;
  font-size: 1.1em;
  position: relative;
  left: 22%;
}

.send_button_text {
  font-family: 'Eastman Roman Trial';
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
}

.send_button_row {
  margin-top: 2%;
  position: absolute;
  width: 100%;
  top: 85%;
}
</style>