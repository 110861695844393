import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import NotFound from '../views/NotFound.vue'
import IndividualForm from '../views/IndividualForm.vue'
import AgregatedForm from '../views/AgregatedForm.vue'
import SendSuccessfully from '../views/SendSuccessfully.vue'
import FormRecap from '../views/FormRecap.vue'
import FichePatient from '../views/virologie/FichePatient.vue'
import Dashboard from '../views/dashboard/Dashboard.vue'
import Sample from '../views/virologie/Sample.vue'
import store from '../store'

Vue.use(VueRouter)

let isAuthenticated = JSON.parse(localStorage.getItem("isAuthenticated"));

let user = JSON.parse(localStorage.getItem("user"));

const routes = [
  {
    path: '/',
    name: '',
    component: () => {
      if (isAuthenticated === true) {
        return Home
      } else {
        return Login
      }
    }
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: { requireLogin: true }

  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/fiche-individuelle',
    name: 'individual_form',
    component: IndividualForm,
    meta: { requireLogin: true }

  },
  {
    path: '/agregated_form',
    name: 'agregated_form',
    component: AgregatedForm,
    meta: { requireLogin: true }

  },
  {
    path: '/success',
    name: 'send_successfully',
    component: SendSuccessfully,
    meta: { requireLogin: true }

  },
  {
    path: '/form-recap',
    name: 'form-recap',
    component: FormRecap,
    meta: { requireLogin: true }

  },
  {
    path: '/fiche-patient',
    name: 'fiche patient',
    component: FichePatient,
    meta: { requireLogin: true }

  },
  {
    path: '/dashboard-communautaire',
    name: 'dashboard communautaire',
    component: Dashboard,
    meta: { requireLogin: true }

  },
  {
    path: '/resultats-prelevements',
    name: 'resultats prelevements',
    component: Sample,
    meta: { requireLogin: true }

  },
  {
    path: '*',
    component: NotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {

  if (to.path === "/" && (isAuthenticated==false || isAuthenticated==null) ) {
    next("/login");
  } else if (to.path === "/" && isAuthenticated==true) {
    next("/home");
  }
  if (to.path === "/login" && isAuthenticated==true) {
    next("/home");
  }
  if (from.path=="/fiche-individuelle" && store.state.isConsulting==true ) {
    next("/fiche-individuelle");
  }
  if (to.path=="/home" && user.role.nom_role!=="ICP" && user.role.nom_role!=="VIROLOGUE" ) {
    next("/dashboard-communautaire");
  }

  if (to.path=="/dashboard-communautaire" && user.role.nom_role=="VIROLOGUE" ) {
    next("/");
  }
  
  if (to.matched.some(record => record.meta.requireLogin) && !isAuthenticated) {
    next({ name: 'login', query: { to: to.path } });
  } else {
    next()
  }
})
export default router
