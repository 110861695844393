<template>
  <div class="suspision_infos_content">
    <span class="title_elt">Symptômes</span>

    <div class="symptomes">
      <Symptomes />
    </div>

    <div v-show="triggerFluSyndrome==true" class="syndrome_grippal">
      <FluSyndrome />
    </div>
    
    <hr v-show="triggerFluSyndrome==false" style="width:100%; margin-bottom:3%;" />

    <div class="arboviroses">
      <div class="arbo_definition">
        fièvre >=38 et deux signes mineurs (arthralgies, myalgies, céphalées, douleurs rétro-orbitales, éruption cutanée) ou fièvre >=38 + un signe majeur (Manifestations hémorragiques : saignement nasal, saignement de la gencive, saignement génital, vomissement sanguinolant, purpura ….)
      </div>
      <Arboviroses />
    </div>

    <hr style="width:100%;" />

    <span class="title_elt">Prélèvements</span>

    <div class="tests_prelevements">
      <TestsPrelevements />
    </div>

    <hr style="width:100%;" />

    <span class="title_elt">Autres signes</span>

    <div class="other_symptomes">
      <OtherSymptomes />
    </div>

    <hr style="width:100%;" />

    <span class="title_elt">Facteurs de risque</span>

    <div class="risk_factors">
      <RiskFactors />
    </div>

    <hr style="width:100%;" />

    <span class="title_elt">Vaccinations</span>

    <div class="vaccines">
      <Vaccines />
    </div>

    <hr style="width:100%;" />

    <span class="title_elt">Notion de voyage</span>

    <div class="vaccines">
      <TravelNotion />
    </div>

    <hr style="width:100%;" />

    <span
      class="subtitle_elt"
    >Présence d’autres personnes avec meme symptomalogie dans l’entourage ?</span>

    <div class="symptomatologie">
      <Symptomalogie />
    </div>
    <hr style="width:100%;" />

    <span class="title_elt">Traitements</span>
    <div class="vaccines">
      <Treatment />
    </div>
    <hr style="width:100%;" />
    <span class="title_elt">Conduite tenue ?</span>
    <div class="conduct">
      <Conduct />
    </div>
    <div class="footer">
      <b-row class="next_btn_row">
        <b-button @click="goBack()" variant="light" class="back_button">
          <img class="back_right_icon" src="@/assets/back.png" />
          <span class="back_button_text">Retour</span>
        </b-button>
        <b-button id="finishAndSendBtn" v-bind:disabled="sendButtonClicked" @click="sendRecord()" variant="light" class="next_button">
          <span class="next_button_text">Terminer et envoyer</span>
          <img class="next_right_icon" src="@/assets/next.png" />
        </b-button>
      </b-row>
    </div>
  </div>
</template>


<script>
import { mapState } from 'vuex';
import { eventBus } from '@/main.js';
import Symptomes from '@/components/fiche-individuelle/suspicions-diagnostics/Symptomes.vue';
import FluSyndrome from '@/components/fiche-individuelle/suspicions-diagnostics/FluSyndrome.vue';
import Arboviroses from '@/components/fiche-individuelle/suspicions-diagnostics/Arboviroses.vue';
import OtherSymptomes from '@/components/fiche-individuelle/suspicions-diagnostics/OtherSymptomes.vue';
import RiskFactors from '@/components/fiche-individuelle/suspicions-diagnostics/RiskFactors.vue';
import Vaccines from '@/components/fiche-individuelle/suspicions-diagnostics/Vaccines.vue';
import TravelNotion from '@/components/fiche-individuelle/suspicions-diagnostics/TravelNotion.vue';
import TestsPrelevements from '@/components/fiche-individuelle/suspicions-diagnostics/TestsPrelevements.vue';
import Symptomalogie from '@/components/fiche-individuelle/suspicions-diagnostics/Symptomalogie.vue';
import Conduct from '@/components/fiche-individuelle/suspicions-diagnostics/Conduct.vue';
import Treatment from '@/components/fiche-individuelle/suspicions-diagnostics/Treatments.vue';

export default {
  name: 'suspisionAndDiagnosticInfos',
  data: () => ({
    triggerFluSyndrome: false,
    triggerArboSyndrome: false,
    sendButtonClicked: false
  }),
  components: {
    Symptomes,
    FluSyndrome,
    Arboviroses,
    OtherSymptomes,
    RiskFactors,
    Vaccines,
    TestsPrelevements,
    Symptomalogie,
    Conduct,
    Treatment,
    TravelNotion
  },
  mounted() {
    eventBus.$on('triggerFluSuspision', () => {
      this.triggerFluSyndrome = true;
    });
    eventBus.$on('hideFluSuspision', () => {
      this.triggerFluSyndrome = false;
    });
    eventBus.$on('triggerArboSuspision', () => {
      this.triggerArboSyndrome = true;
    });
    eventBus.$on('hideArboSuspision', () => {
      this.triggerArboSyndrome = false;
    });

    const button = document.getElementById('finishAndSendBtn');
    
    button.addEventListener('click', () => {
      
      button.disabled = true;
      setTimeout(() => {
      button.disabled = false;

      }, 2000);
    });
  },
  computed: {
    ...mapState({
      showDiagnosticAndPrescriptionSection:
        'showDiagnosticAndPrescriptionSection',
      showPaludismSection: 'showPaludismSection',
      showFluSyndromSection: 'showFluSyndromSection',
      showCovid19Section: 'showCovid19Section',
      showArboSection: 'showArboSection',
      showOtherSymptomesSection: 'showOtherSymptomesSection',
      showRiskFactorsSection: 'showRiskFactorsSection',
      showVaccinesSection: 'showVaccinesSection',
      showTestsSection: 'showTestsSection',
      personalInfosCategories: 'personalInfosCategories',
      personalInfosSubCategories: 'personalInfosSubCategories',
      showCommandButtons: 'showCommandButtons',
      suspisionAndDiagnosticCategories: 'suspisionAndDiagnosticCategories',
      showSuspisionSidebar: 'showSuspisionSidebar',

      //informations générales
      consulting_date: 'consulting_date',
      user: 'user',
      epid: 'epid',

      //informations personnelles
      prenom: 'prenom',
      nom: 'nom',
      date_de_naissance: 'date_de_naissance',
      genre: 'genre',
      age_year: 'age_year',
      age_month: 'age_month',
      femme_enceinte: 'femme_enceinte',
      age_gestationnel: 'age_gestationnel',
      telephone1_patient: 'telephone1_patient',
      profession: 'profession',
      adresse_patient: 'adresse_patient',
      statut_matrimonial: 'statut_matrimonial',

      //diagnostic et prescription
      fievre: 'fievre',
      diarrhee: 'diarrhee',
      temperature_fievre: 'temperature_fievre',
      duree_fievre: 'duree_fievre',
      diarrhee_sanglante: 'diarrhee_sanglante',
      traitement_prescrit: 'traitement_prescrit',
      antibiotiques: 'antibiotiques',
      antipaludiques: 'antipaludiques',
      antipyretiques: 'antipyretiques',
      antihistaminiques: 'antihistaminiques',
      broncodilatateurs: 'broncodilatateurs',
      autres_traitements_prescrits: 'autres_traitements_prescrits',

      //paludisme
      tdr_palu: 'tdr_palu',
      resultat_tdr_palu: 'resultat_tdr_palu',

      //syndrome grippal
      tdr_covid: 'tdr_covid',
      resultat_covid_19: 'resultat_covid_19',
      tdr_covid_antigene_result: 'tdr_covid_antigene_result',
      tdr_covid_anticorps_result: 'tdr_covid_anticorps_result',
      igm_positif: 'igm_positif',
      iga_positif: 'iga_positif',
      igg_positif: 'igg_positif',

      //covid 19
      anorexie: 'anorexie',
      cephalees: 'cephalees',
      dyspnee: 'dyspnee',
      ecoulement_nasal: 'ecoulement_nasal',
      nausees: 'nausees',
      perte_odorat: 'perte_odorat',
      perte_de_gout: 'perte_de_gout',
      mal_de_gorge: 'mal_de_gorge',
      toux: 'toux',
      vomissement: 'vomissement',

      //arboviroses : NB ajouter cephalees
      arthralgies: 'arthralgies',
      eruption_cutanee: 'eruption_cutanee',
      douleurs_retro_orbitaires: 'douleurs_retro_orbitaires',
      myalgies: 'myalgies',
      manifestations_hemorragiques: 'manifestations_hemorragiques',

      //autres signes
      convulsion: 'convulsion',
      frisson: 'frisson',
      stridor: 'stridor',
      tirage_sous_costal: 'tirage_sous_costal',
      ictere: 'ictere',
      raideur_nuque: 'raideur_nuque',
      anemie_clinique: 'anemie_clinique',
      refus_de_teter: 'refus_de_teter',
      splenomegalie: 'splenomegalie',
      autres_symptomes: 'autres_symptomes',

      //facteurs de risque
      diabete: 'diabete',
      asthme: 'asthme',
      drepanocytose: 'drepanocytose',
      hta: 'hta',
      malnutrition: 'malnutrition',
      obesite: 'obesite',
      pneumopathie_chronique: 'pneumopathie_chronique',
      autres_risques: 'autres_risques',

      //vaccins
      vaccination_fj: 'vaccination_fj',
      date_vaccin_fj: 'date_vaccin_fj',
      infos_vaccin_fj: 'infos_vaccin_fj',
      vaccination_covid: 'vaccination_covid',
      type_vaccin: 'type_vaccin',
      first_dose_date: 'first_dose_date',
      second_dose_date: 'second_dose_date',
      third_dose_date: 'third_dose_date',
      voyage_15jrs: 'voyage_15jrs',
      lieu_voyage: 'lieu_voyage',
      date_voyage: 'date_voyage',

      //tests_et_prelevements
      prelevement: 'prelevement',
      prelevement_urgent: 'prelevement_urgent',
      type_prelevement: 'type_prelevement',
      date_prelevement: 'date_prelevement',

      //symptomalogie
      symptomalogie_entourage: 'symptomalogie_entourage',

      //conduite tenue
      conduite_tenue: 'conduite_tenue',

      individual_records_number: 'individual_records_number'
    })
  },
  methods: {
    goBack() {
      this.$store.commit('setShowSuspisionAndDiagnosticInfos', false);
      this.$store.commit('setShowDiagnosticAndPrescriptionSection', true);
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${year}-${month}-${day}`;
    },
    async sendRecord() {

      this.show_header=false;

      this.$store.commit("setIsConsulting",false);
      this.errors = [];

      let sending_date = this.formatDate(
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10)
      );

      let age;
      if(this.age_year=="" && this.date_de_naissance!=""){
        age=this.getAge(this.date_de_naissance);
      }else{
        age = this.age_year;
      }


      const formData = {
        epid: this.epid,
        agent: { nom_utilisateur: this.user.nom_utilisateur },
        date_consultation: this.consulting_date,
        date_envoie: sending_date,
        informations_personnelles: {
          civilite: {
            prenom: this.prenom,
            nom: this.nom,
            date_de_naissance: this.date_de_naissance,
            genre: this.genre,
            age_en_annee: age,
            age_en_mois: this.age_en_mois,
            femme_enceinte: this.femme_enceinte,
            age_gestationnel: this.age_gestationnel
          },
          adresse_et_contact: {
            telephone1_patient: this.telephone1_patient,
            profession: this.profession,
            adresse_patient: this.adresse_patient
          },
          statut_matrimonial: {
            statut_matrimonial: this.statut_matrimonial
          }
        },
        suspicion_et_diagnostic: {
          diagnostic_et_prescription: {
            fievre: this.fievre,
            temperature_fievre: this.temperature_fievre,
            duree_fievre: this.duree_fievre
          },
          paludisme: {
            tdr_palu: this.tdr_palu,
            resultat_tdr_palu: this.resultat_tdr_palu
          },
          syndrome_grippal_covid19: {
            anorexie: this.anorexie,
            cephalees: this.cephalees,
            dyspnee: this.dyspnee,
            ecoulement_nasal: this.ecoulement_nasal,
            nausees: this.nausees,
            perte_odorat: this.perte_odorat,
            perte_de_gout: this.perte_de_gout,
            mal_de_gorge: this.mal_de_gorge,
            toux: this.toux,
            vomissement: this.vomissement,
            tdr_covid: this.tdr_covid,
            tdr_covid_resultat_antigenes: this.tdr_covid_antigene_result,
            tdr_covid_resultat_anticorps: this.tdr_covid_anticorps_result,
            igm_positif: this.igm_positif,
            iga_positif: this.iga_positif,
            igg_positif: this.igg_positif
          },
          arboviroses: {
            cephalees: this.cephalees,
            arthralgies: this.arthralgies,
            eruption_cutanee: this.eruption_cutanee,
            douleurs_retro_orbitaires: this.douleurs_retro_orbitaires,
            myalgies: this.myalgies,
            manifestations_hemorragiques: this.manifestations_hemorragiques
          },
          autres_syndromes: {
            diarrhee: this.diarrhee,
            diarrhee_sanglante: this.diarrhee_sanglante
          },
          autres_signes: {
            convulsions: this.convulsion,
            frissons: this.frisson,
            stridor: this.stridor,
            tirage_sous_costal: this.tirage_sous_costal,
            ictere: this.ictere,
            raideur_nuque: this.raideur_nuque,
            anemie_clinique: this.anemie_clinique,
            refus_de_teter: this.refus_de_teter,
            splenomegalie: this.splenomegalie,
            autres_symptomes: this.autres_symptomes
          },
          facteurs_de_risque: {
            diabete: this.diabete,
            asthme: this.asthme,
            drepanocytose: this.drepanocytose,
            hta: this.hta,
            malnutrition: this.malnutrition,
            obesite: this.obesite,
            pneumopathie_chronique: this.pneumopathie_chronique,
            autres_risques: this.autres_risques
          },
          vaccins: {
            vaccination_fievre_jaune: this.vaccination_fj,
            date_vaccin_fievre_jaune: this.date_vaccin_fj,
            infos_vaccin_fj: this.infos_vaccin_fj,
            vaccination_covid: this.vaccination_covid,
            type_vaccin_covid: this.type_vaccin,
            date_vaccination_premiere_dose: this.first_dose_date,
            date_vaccination_deuxieme_dose: this.second_dose_date,
            date_vaccination_troisieme_dose: this.third_dose_date
          },
          notion_de_voyage:{
            voyage_15jrs: this.voyage_15jrs,
            lieu_voyage: this.lieu_voyage,
            date_voyage: this.date_voyage
          },
          prelevement: {
            prelevement_fait: this.prelevement,
            prelevement_urgent: this.prelevement_urgent,
            type_prelevement: this.type_prelevement,
            date_prelevement: this.date_prelevement
          },
          traitements: {
            traitement_prescrit: this.traitement_prescrit,
            antibiotiques: this.antibiotiques,
            antipaludiques: this.antipaludiques,
            antipyretiques: this.antipyretiques,
            antihistaminiques: this.antihistaminiques,
            broncodilatateurs: this.broncodilatateurs,
            autres_traitements_prescrits: this.autres_traitements_prescrits
          }
        },
        symptomalogie_entourage: this.symptomalogie_entourage,
        conduite_tenue: this.conduite_tenue
      };


      await this.axios
        .post('/api/fiche-fievre/', formData)
        .then(response => {
          console.log(response.data);

          this.$store.dispatch('resetVariables');

          //redirection to success page
          let to = this.$route.query.to || '/success';
          this.$router.push(to).catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error;
            }
          });

          //wait 3s before redirecting to home page
          setTimeout(() => {
            let to = this.$route.query.to || '/home';
            this.$router.push(to).catch(() => {});
            this.$store.commit('setIsConsulting', false);
            this.$store.commit('setShowHealerSection', true);
            this.$store.commit('setShowSuspisionOnboardSection', false);
            this.$store.commit(
              'setShowDiagnosticAndPrescriptionSection',
              false
            );
            this.$store.commit('setShowSuspisionAndDiagnosticInfos', false);
            this.$store.commit('setShowPersonalInfosSection', true);
          }, 3000);


        })
        .catch(error => {
          if (error.response) {
            for (const property in error.response.data) {
              this.errors.push(`${property}: ${error.response.data[property]}`);
            }
          } else {
            this.errors.push(
              'Quelque chose ne marche pas. Veuillez réessayer encore.'
            );
            console.log(JSON.stringify(error));
          }
        });
    }
  }
};
</script>

<style scoped>
.suspision_infos_content {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.title_elt {
  font-family: 'Eastman Trial';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #425169;
  width: 100%;
  justify-content: center;
  margin-top: 2%;
  margin-bottom: 3%;
  width: 94%;
}

.subtitle_elt {
  font-family: 'Eastman Trial';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #425169;
  width: 100%;
  justify-content: center;
  margin-top: 2%;
  margin-bottom: 3%;
  width: 94%;
}

.next_btn_row {
  display: flex;
  justify-content: space-between;
  width: 98%;
  margin-bottom: 2%;
  margin-top: 1%;
}
.next_button {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  gap: 10px;
  background: #3cb691 !important;
  border-radius: 5px;
  box-shadow: none !important;
}
.next_button:active {
  transform: scale(0.98);
  opacity: 0.9;
}
.next_button_text {
  font-family: 'Eastman Roman Trial';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
}
.chevron_right_icon {
  background-color: #3cb691;
  color: #3cb691;
}
.next_right_icon {
  position: relative;
  left: 10%;
}

.back_button {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  gap: 10px;
  background: #ffffff !important;
  border-radius: 5px;
  box-shadow: none !important;
  margin-left: 4%;
  border: 2px solid #425169;
}
.back_button:active {
  transform: scale(0.98);
  opacity: 0.9;
}
.back_button_text {
  font-family: 'Eastman Roman Trial';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #000000;
}

.arboviroses{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.arbo_definition{
  width: 90%;
  margin-bottom: 2%;
}
</style>
