<template>
  <div class="risk_factors_content">
    <b-row style="margin-top:0.5%;" class="symptomes_row">
      <b-col class="symptomes_question_checkbox" cols="5">
        <b-form-checkbox
          class="symptome_checkbox"
          v-model="diabete"
          name="symptome_checkbox"
          value="Oui"
          unchecked-value="Non"
        >
          <span class="checkbox_label">Diabète</span>
        </b-form-checkbox>
      </b-col>
      <b-col cols="1"></b-col>
      <b-col class="symptomes_question_checkbox" cols="5">
        <b-form-checkbox
          class="symptome_checkbox"
          v-model="asthme"
          name="symptome_checkbox"
          value="Oui"
          unchecked-value="Non"
        >
          <span class="checkbox_label">Asthme</span>
        </b-form-checkbox>
      </b-col>
    </b-row>
    <b-row style="margin-top:3.5%;" class="symptomes_row">
      <b-col class="symptomes_question_checkbox" cols="5">
        <b-form-checkbox
          class="symptome_checkbox"
          v-model="drepanocytose"
          name="symptome_checkbox"
          value="Oui"
          unchecked-value="Non"
        >
          <span class="checkbox_label">Drepanocytose</span>
        </b-form-checkbox>
      </b-col>
      <b-col cols="1"></b-col>
      <b-col class="symptomes_question_checkbox" cols="5">
        <b-form-checkbox
          class="symptome_checkbox"
          v-model="hta"
          name="symptome_checkbox"
          value="Oui"
          unchecked-value="Non"
        >
          <span class="checkbox_label">HTA</span>
        </b-form-checkbox>
      </b-col>
    </b-row>

    <b-row style="margin-top:3.5%;" class="symptomes_row">
      <b-col class="symptomes_question_checkbox" cols="5">
        <b-form-checkbox
          class="symptome_checkbox"
          v-model="malnutrition"
          name="symptome_checkbox"
          value="Oui"
          unchecked-value="Non"
        >
          <span class="checkbox_label">Malnutrition</span>
        </b-form-checkbox>
      </b-col>
      <b-col cols="1"></b-col>
      <b-col class="symptomes_question_checkbox" cols="5">
        <b-form-checkbox
          class="symptome_checkbox"
          v-model="obesite"
          name="symptome_checkbox"
          value="Oui"
          unchecked-value="Non"
        >
          <span class="checkbox_label">Obésité</span>
        </b-form-checkbox>
      </b-col>
    </b-row>

    <b-row style="margin-top:3.5%;" class="symptomes_row">
      <b-col class="symptomes_question_checkbox" cols="5">
        <b-form-checkbox
          class="symptome_checkbox"
          v-model="pneumopathie_chronique"
          name="symptome_checkbox"
          value="Oui"
          unchecked-value="Non"
        >
          <span class="checkbox_label">Pneumopathie Chronique</span>
        </b-form-checkbox>
      </b-col>
      <b-col cols="1"></b-col>
      <b-col class="symptomes_question_checkbox" cols="5">
        <b-form-checkbox
          class="symptome_checkbox"
          v-model="autre_facteurs"
          name="symptome_checkbox"
        >
          <span class="checkbox_label">Autres risques...</span>
        </b-form-checkbox>
      </b-col>
    </b-row>

    <b-row
      v-show="this.showOtherSymptomesInputField==true"
      style="margin-top:3%;"
      class="other_symptomes"
    >
      <b-col cols="3" class="first_name">
        <b-form-input
          class="other_symptomes_input input_field"
          v-model="autre_facteurs_input"
          type="text"
          name="other_symptomes"
          size="md"
          placeholder="Autres facteurs"
        ></b-form-input>
      </b-col>
    </b-row>
  </div>
</template>


<script>
import { mapState } from 'vuex';
import { eventBus } from '@/main.js';

export default {
  name: 'RiskFactorsSection',
  components: {},
  data: () => ({
    diabete: '',
    asthme: '',
    drepanocytose: '',
    hta: '',
    malnutrition: '',
    obesite: '',
    pneumopathie_chronique: '',
    autre_facteurs: '',
    autre_facteurs_input: '',
    showOtherSymptomesInputField: false
  }),
  computed: {
    ...mapState({})
  },
  watch: {
    autre_facteurs(val) {
      if (val === true) {
        this.showOtherSymptomesInputField = true;
      } else {
        this.showOtherSymptomesInputField = false;
      }
    },
    diabete(val) {
      this.$store.commit('setDiabete', val);
    },
    asthme(val) {
      this.$store.commit('setAsthme', val);
    },
    drepanocytose(val) {
      this.$store.commit('setDrepanocytose', val);
    },
    hta(val) {
      this.$store.commit('setHta', val);
    },
    malnutrition(val) {
      this.$store.commit('setMalnutrition', val);
    },
    obesite(val) {
      this.$store.commit('setObesite', val);
    },
    pneumopathie_chronique(val) {
      this.$store.commit('setPneumopathieChronique', val);
    },
    autre_facteurs_input(val) {
      this.$store.commit('setAutresRisques', val);
    }
  },
  methods: {
    goToNextSection() {
      eventBus.$emit('triggerVaccinesSection');
    }
  }
};
</script>

<style scoped>
.risk_factors_content {
  margin-bottom: 2%;
}
.symptomes_row {
  width: 60%;
  position: relative;
  left: 20%;
  top: -5%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.symptomes_question_checkbox {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 6px 15px;
  gap: 10px;
  background: #ebfcfd;
  border-radius: 5px;
}

.symptome_checkbox {
  transform: scale(1.1);
}

.checkbox_label {
  font-family: 'Eastman Roman Trial';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #425169;
  position: relative;
  left: 4%;
}

.other_symptomes {
  display: flex;
  margin-bottom: 1%;
  justify-content: center;
  width: 90%;
}

.input_field {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 10px 10px 20px;
  gap: 10px;
  background: #ffffff;
  border: 2px solid #425169;
  border-radius: 5px;
}
</style>