<template>
  <div class="diagnostic_and_prescription_content">
    <div class="heading">
      <div class="logos">
        <img class="logo img_logo4s" src="@/assets/msas_black_logo.png" />
        <img class="logo img_logoIpd" src="@/assets/ipd_black_logo.png" />
      </div>
      <div class="title">Diagnostic et prescription</div>
    </div>

    <hr style="width:100%;" />

    <div class="fever_and_diarrhee_infos_resume">
      <div class="fever_resume">
        <span class="resume_title">Fièvre</span>
        <span v-if="temperature_fievre!=''" class="resume_data">
          {{temperature_fievre}}
          <span class="additional_infos">°C</span>
        </span>
      </div>
      <div class="fever_duration_resume">
        <span class="resume_title">Durée de la fièvre</span>
        <span v-if="duree_fievre" class="resume_data">{{duree_fievre}}</span>
      </div>
      <div class="action">
        <b-button @click="modifyFeverInfos()" class="modify_fever_and_diarrhee_infos">
          <span class="btn_label">Modifier</span>
          <img class="modify_icon" src="@/assets/pencil.png" alt="pencil" />
        </b-button>
      </div>
    </div>

    <div v-show="temperature_fievre>=38">
      <Paludism />
    </div>
    <hr v-show="temperature_fievre>=38" style="width:100%; margin-top:0.5%;" />
    <hr v-show="temperature_fievre<38" style="width:100%; margin-top:10%;" />

    <b-row style="margin-top:4%;" class="next_btn_row">
      <b-button @click="goBack()" variant="light" class="back_button">
        <img class="back_right_icon" src="@/assets/back.png" />
        <span class="back_button_text">Retour</span>
      </b-button>
      <b-button @click="goToNextSection()" variant="light" class="next_button">
        <span class="next_button_text">Suivant</span>
        <img class="next_right_icon" src="@/assets/next.png" />
      </b-button>
    </b-row>
  </div>
</template>


<script>
import { mapState } from 'vuex';
import Paludism from '@/components/fiche-individuelle/suspicions-diagnostics/Paludism.vue';

export default {
  name: 'DiagnosticAndPrescription',
  components: { Paludism },
  data: () => ({}),
  computed: {
    ...mapState({
      fievre: 'fievre',
      temperature_fievre: 'temperature_fievre',
      duree_fievre: 'duree_fievre'
    })
  },
  watch: {},
  methods: {
    goToNextSection() {
      this.$store.commit('setShowDiagnosticAndPrescriptionSection', false);
      this.$store.commit('setShowSuspisionAndDiagnosticInfos', true);
    },
    modifyFeverInfos() {
      this.$store.commit('setShowSuspisionAndDiagnosticInfos', false);
      this.$store.commit('setShowSuspisionOnboardSection', true);
    },
    goBack() {
      this.$store.commit('setShowDiagnosticAndPrescriptionSection', false);
      this.$store.commit('setShowSuspisionOnboardSection', true);
    }
  }
};
</script>

<style scoped>
.diagnostic_and_prescription_content {
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}

.heading {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 2%;
}

.logos {
  width: 20%;
}
.logo {
  margin-left: 2%;
}

.img_logoIpd {
  margin-left: 6%;
}
.img_logo4s {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.title {
  margin-left: 18%;
  font-family: 'Eastman Roman Trial';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #425169;
}

.fever_and_diarrhee_infos_resume {
  width: 68%;
  margin-left: 18%;
  margin-top: 5%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px 40px;
  gap: 40px;
  background: #ebfcfd;
  border: 1px solid #c6ebed;
  border-radius: 5px;
}

.fever_resume {
  display: flex;
  flex-direction: column;
  border-right: 1px solid #dadada;
  width: 35%;
}

.fever_duration_resume {
  display: flex;
  flex-direction: column;
  width: 35%;
}

.action {
  width: 17%;
  transform: scale(1.1);
}

.resume_title {
  font-family: 'Eastman Roman Trial';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #231f20;
  opacity: 0.9;
}

.resume_data,
.diarrhee_infos {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 17px;
  color: #484ef7;
  margin-top: 5%;
}
.additional_infos {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: #425169;
}

.modify_fever_and_diarrhee_infos {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  background: #ebfcfd !important;
  border: 1px solid #c6ebed;
  border-radius: 5px;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.modify_fever_and_diarrhee_infos:hover,
.modify_fever_and_diarrhee_infos:focus {
  background: #ebfcfd !important;
}
.modify_fever_and_diarrhee_infos:active {
  transform: scale(0.95);
}

.modify_icon {
  position: relative;
  right: 65%;
}

.btn_label {
  position: relative;
  left: 20%;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #425169;
}

.next_btn_row {
  display: flex;
  justify-content: space-between;
  width: 98%;
  margin-bottom: 2%;
}
.next_button {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  gap: 10px;
  background: #484ef7 !important;
  border-radius: 5px;
  box-shadow: none !important;
}
.next_button:active {
  transform: scale(0.98);
  opacity: 0.9;
}
.next_button_text {
  font-family: 'Eastman Roman Trial';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
}
.chevron_right_icon {
  background-color: #484ef7;
  color: #484ef7;
}
.next_right_icon {
  position: relative;
  left: 10%;
}

.other_symptomes {
  display: flex;
  margin-bottom: 1%;
  justify-content: center;
  width: 95%;
}

.input_field {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 10px 10px 20px;
  gap: 10px;
  background: #ffffff;
  border: 2px solid #425169;
  border-radius: 5px;
}

.back_button {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  gap: 10px;
  background: #ffffff !important;
  border-radius: 5px;
  box-shadow: none !important;
  margin-left: 4%;
  border: 2px solid #425169;
}
.back_button:active {
  transform: scale(0.98);
  opacity: 0.9;
}
.back_button_text {
  font-family: 'Eastman Roman Trial';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #000000;
}
</style>