<template>
  <b-container fluid id="login_content" class="content">
    <b-row>
      <b-col>
        <b-row style="margin-left:3%;">
          <b-col class="textes">
            <b-col>
              <b-row class="section1">
                <b-col class="welcome" cols="9">
                  <p>Bienvenue sur le réseau 4S</p>
                </b-col>
                <b-col class="quote" cols="1">
                  <img src="@/assets/quote.svg" alt="quote" />
                </b-col>
                <b-col class="feedback_block" cols="6">
                  <p class="feedback">{{this.feedback}}</p>
                  <p class="endword">{{this.mot_de_fin}}</p>
                </b-col>
              </b-row>
            </b-col>
          </b-col>
        </b-row>
        <b-row style="margin-left:3%;">
          <b-col class="section2">
            <b-row class="msas">
              <b-col class="msas_block" cols="12">
                <img class="img_msas" src="@/assets/msas.svg" alt="msas" />
                <span class="msas_name">
                  Marie Khémesse Ngom Ndiaye
                  <span class="msas_function">Ministre de la santé</span>
                </span>
              </b-col>
            </b-row>
            <b-row class="logos">
              <img
                class="img-fluid img_logo_msas"
                style="width:10%;"
                src="@/assets/logo_msas.png"
                alt="msas"
              />
              <img class="img_logo_ipd" style="width:18%;" src="@/assets/logo_ipd.png" alt="4s" />
            </b-row>
          </b-col>
        </b-row>
      </b-col>

      <b-col class="login_form" cols="5">
        <LoginForm />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import LoginForm from '@/components/fiche-individuelle/general/LoginForm.vue';
import { eventBus } from '@/main.js';

export default {
  name: 'login',
  components: {
    LoginForm
  },
  data: () => ({
    feedback:
      'Nous sommes ravis et très reconnaissant de vous compter parmis les contributeurs actifs dans l’effort de la surveillance sanitaire au Sénégal.',
    mot_de_fin: 'Merci pour votre dévouement.'
  }),
  mounted() {
    eventBus.$on('allowOverflow', () => {
      document.getElementById('login_content').style.overflow = 'auto';
    });
  },

  methods: {}
};
</script>

<style scoped>
body,
html {
  padding: 0;
  margin: 0;
  height: 100%;
}

.content {
  overflow: hidden;
  background-image: url('../assets/background.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
}

.section1 {
  display: flex;
  flex-direction: column;
}

.img_logo_ipd {
  margin-top: 2%;
  display: inline;
  padding: 0;
  margin-left: 11%;
  height: 60%;
}

.img_logo_msas {
  margin-left: 6%;
  height: 80%;
}

.section2 {
  margin-top: 0;
  margin-left: 2%;
  display: flex;
  flex-direction: column;
}
.img_msas {
  transform: scale(1.3);
}

.msas_block {
  display: flex;
  flex-direction: row;
}
.msas {
  display: flex;
  margin-left: 5%;
  text-align: justify;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
}
.logos {
  position: relative;
  top: 22%;
  display: flex;
  margin-left: 2%;
}

.msas_name {
  margin-left: 3%;
  display: block;
  font-size: 18px;
}

.msas_function {
  display: block;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #979797;
}
.welcome {
  margin-left: 4%;
  margin-top: 8%;
  font-family: 'Helvetica', 'Arial', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  color: #0d1c2e;
  margin-bottom: 2%;
}

.quote {
  width: 9%;
  height: 18%;
  margin-left: 6.5%;
  margin-top: 0%;
  transform: scale(1.3);
}

.feedback_block {
  margin-left: 12%;
  margin-top: -1.5%;
  font-size: 14px;
  text-align: justify;
  font-style: normal;
  font-weight: 500;
}
.feedback,
.endword {
  font-family: 'Helvetica', 'Arial', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #0d1c2e;
  text-align: justify;
}

@media (min-width: 1025px) and (max-width: 1280px) {
  .content {
    overflow: hidden;
  }
}
</style>
