<template>
  <div class="record">
    <div class="infos">
      <span class="record_id">{{this.patient.record_id}}</span>
      <span class="record_other_stuffs">
        <b-icon
          icon="clipboard-check"
          class="clipboard-check"
          style="background-color:#ffffff;"
          variant="dark"
        ></b-icon>
        <span class="record_text">
          Fiche individuelle |
          <span class="in_progress">En cours</span>
        </span>
      </span>
    </div>
    <div class="modify_button">
      <b-button @click="Modifyrecord" id="btn_modify" size="sm" class="modify">
        <span class="modify_label">Modifier</span>
        <img class="modify_icon" src="@/assets/pencil.png" alt="pencil" />
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "RecordOverview",
  props: {
    patient: {}
  },
  mounted() {},
  methods: {
    setValue() {},
    Modifyrecord() {}
  }
};
</script>

<style scoped>
.record {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 10px 10px 0px;
  background: #ffffff;
  border: 1px solid #dfdfdf;
  border-radius: 10px;
  padding-left: 10px;
}

.infos {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 60%;
  margin-right: 25%;
}

#btn_modify {
  background-color: #ffffff;
  color: #000000;
  text-align: right;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border: 1px solid #425169;
  border-radius: 5px;
  padding: 5px 10px 5px 10px;
}

.modify_icon {
  position: relative;
  right: 85%;
}

.modify_label {
  position: relative;
  left: 25%;
}

#btn_modify:active {
  transform: scale(0.95);
}

.record_other_stuffs {
  width: 80%;
  display: flex;
  justify-content: flex-start;
  align-content: center;
  margin-top: 1.5%;
}

.record_text {
  font-family: "Eastman Roman Trial";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #425169;
  margin-left: 3%;
}

.in_progress {
  font-family: "Eastman Roman Trial";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #484ef7;
}
</style>