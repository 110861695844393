 
 <template>
  <b-form @submit.prevent class="login_form">
    <b-form-row style="margin-top:3%">
      <b-col name="logo_4s">
        <img class="img-fluid img_logo4s" src="@/assets/logo4s.svg" alt="4s platform" />
      </b-col>
    </b-form-row>

    <b-form-row class="scan_row">
      <b-button class="btn_scan">
        <img style="margin-right:10%;" class="scan_icon" src="@/assets/scan_icon.svg" alt="scan" />Scanner une carte
      </b-button>
    </b-form-row>

    <div class="hr_lines">
      <hr style="width:36%;text-align:left;" />
      <span class="ou">Ou</span>
      <hr style="width:36%;text-align:right;" />
    </div>

    <b-form-row class="known_identifiant_row" v-if="show_==true">
      <b-form-input
        autocomplete="off"
        id="identifiant_input"
        :readonly="true"
        class="known_identifiant_row_input"
        v-model="user.nom_utilisateur"
        type="text"
        name="known_identifiant"
        size="sm"
        ref="known_identifiant"
      ></b-form-input>
      <span @click="changeIdentifiant()" class="modify_known_identifiant">Modifier</span>
    </b-form-row>
    <!-- Identifiant -->
    <b-form-row v-else class="identifiant_row">
      <b-form-input
        autocomplete="off"
        class="identifiant_row_input"
        v-model="identifiant"
        :required="true"
        type="text"
        name="identifiant"
        placeholder="Identifiant"
        size="sm"
        ref="identifiant"
        :state="identifiantState()"
        @input="resetInvalidIdentifiant()"
      ></b-form-input>
      <!-- <b-form-invalid-feedback id="identifiant-feedback">entrer au minimum 3 lettres</b-form-invalid-feedback> -->
    </b-form-row>

    <!-- Password -->
    <b-form-row style="margin-top:1%;" class="password_row">
      <b-form-input
        id="pwd_input"
        class="password_row_input"
        v-model="password"
        :required="true"
        type="password"
        name="mot_de_passe"
        placeholder="Mot de passe"
        size="sm"
        ref="mot_de_passe"
        autocomplete="off"
        @input="resetInvalidPassword()"
      ></b-form-input>

      <img
        @click="togglePassword()"
        style="margin-right:10%;"
        class="pwd_icon"
        src="@/assets/pwd_icon.svg"
      />
    </b-form-row>

    <b-form-row style="margin-top:1%;" class="btn_connect_row">
      <vs-button
        ref="connect_button"
        @click="connectUser()"
        id="btn_connect"
        size="sm"
        class="pr-4 pl-4"
      >Me connecter</vs-button>
    </b-form-row>
    <!-- gestion des erreurs -->
    <div
      style="margin-top:2%; width:80%;"
      ref="errors"
      id="errors"
      class="alert alert-danger"
      v-if="errors.length"
    >
      <span v-for="(error,i) in errors" v-bind:key="i">{{ error }}</span>
    </div>
    <div
      ref="error_messages"
      style="margin-top:2%;"
      class="alert alert-success"
      v-if="error_messages.length"
    >
      <span v-for="(msg,i) in error_messages" v-bind:key="i">{{ msg }}</span>
    </div>

    <b-form-row style="width:90%;" class="forgot_pwd_row">
      <span class="forgot_pwd">Mot de passe oublié ?</span>
    </b-form-row>
    <b-form-row class="form_footer">
      <p class="form_footer_text">
        Un problème?
        <span style="color: #484ef7;">Contacter l’équipe de l’Institut Pasteur.</span>
      </p>
    </b-form-row>
  </b-form>
</template>
 
 <script>
import { mapState } from 'vuex';
import { eventBus } from '@/main.js';

export default {
  name: 'loginForm',
  components: {},
  data: () => ({
    password: '',
    identifiant: '',
    invalid_identifiant: false,
    invalid_password: false,
    errors: [],
    recovery_email: '',
    error_messages: [],
    show_: false
  }),
  mounted() {
    if (localStorage.getItem('nom_utilisateur') != null) {
      this.$store.commit('setUser', {
        nom_utilisateur: JSON.parse(
          JSON.stringify(localStorage.getItem('nom_utilisateur'))
        )
      });
      this.show_ = true;
    }
    let pwdInput = document.getElementById('pwd_input');
    pwdInput.addEventListener('click', function() {
      if (pwdInput === document.activeElement) {
        eventBus.$emit('allowOverflow');
      }
    });
  },

  watch: {
    errors: {
      handler(errors) {
        if (errors) {
          setTimeout(
            () => (document.getElementById('errors').style.display = 'none'),
            3000
          );
        }
      },
      deep: true
    },
    error_messages: {
      handler(error_messages) {
        if (error_messages) {
          setTimeout(
            () => (this.$refs['error_messages'].style.display = 'none'),
            3000
          );
        }
      },
      deep: true
    }
  },
  methods: {
    resetInvalidIdentifiant() {
      this.invalid_identifiant = null;
    },
    resetInvalidPassword() {
      this.invalid_password = null;
    },
    passwordState() {
      if (this.invalid_password == true) {
        return false;
      } else {
        const PasswordRegex = new RegExp('^[a-zA-Zç .-_@]{3,}$');
        if (this.password === '') {
          return null;
        } else {
          return PasswordRegex.test(this.password) ? true : false;
        }
      }
    },
    identifiantState() {
      if (this.invalid_identifiant == true) {
        return false;
      } else {
        const IdentifiantRegex = new RegExp('^[a-zA-Zç0-9 .-_@]{3,}$');
        if (this.identifiant === '') {
          return null;
        } else {
          return IdentifiantRegex.test(this.identifiant) ? true : false;
        }
      }
    },
    changeIdentifiant() {
      this.$store.commit('removeUser');
      localStorage.removeItem('user');
      this.show_ = false;
      document.getElementById('identifiant_input').focus();
    },
    togglePassword() {
      var pwdElt = document.getElementById('pwd_input');
      if (pwdElt.type === 'password') {
        pwdElt.type = 'text';
      } else {
        pwdElt.type = 'password';
      }
    },
    async connectUser() {
      this.openLoadingButton();
      this.errors = [];

      let formData = {
        identifiant: this.identifiant.toLowerCase(),
        password: this.password.toLowerCase()
      };

      if (this.identifiant == '') {
        if (this.user.nom_utilisateur != '') {
          formData['identifiant'] = this.user.nom_utilisateur;
        }
      }

      await this.axios
        .post('/api/authentication/', formData)
        .then(response => {
          //saving user informations
          localStorage.setItem('user', JSON.stringify(response.data.user));
          this.$store.commit('setUser', this.user);
          this.$store.commit('setIsAuthenticated', true);
          localStorage.setItem('isAuthenticated', true);
          localStorage.setItem('token', response.data.token);
          this.$store.commit('setToken', response.data.token);

          this.axios.defaults.headers.common['Authorization'] =
            'Token ' + response.data.token;

          //redirecting

          let role = response.data.user.role.nom_role;
          // let type_structure = response.data.user.structure_sanitaire.type_structure.libelle.toLowerCase();

          switch (role) {
            case 'ICP': {

              window.location.replace('/home');
              window.history.pushState(null, '', window.location.href);
              window.onpopstate = function() {
                window.history.pushState(null, '', window.location.href);
              };

              localStorage.setItem(
                'nom_district',
                response.data.user.structure_sanitaire.district.nom_district
              );
              localStorage.setItem(
                'code_district',
                response.data.user.structure_sanitaire.district.code_district
              );
              localStorage.setItem(
                'nom_site',
                response.data.user.structure_sanitaire.nom_structure
              );
              localStorage.setItem(
                'code_region',
                response.data.user.structure_sanitaire.region.code_region
              );
              localStorage.setItem(
                'nom_region',
                response.data.user.structure_sanitaire.region.nom_region
              );

              break;
            }

            case 'VIROLOGUE': {
              window.location.href = '/fiche-patient';
              history.pushState(null, null, location.href);
              // window.onpopstate = function() {
              //   history.go(1);
              // };
              window.addEventListener('popstate', function() {
                window.history.pushState(null, null, window.location.href);
                window.location.replace('/fiche-patient');
              });

              break;
            }
            case 'MCD': {
              window.location.href = '/dashboard-communautaire';
              history.pushState(null, null, location.href);
              // window.onpopstate = function() {
              //   history.go(1);
              // };
              window.addEventListener('popstate', function() {
                window.history.pushState(null, null, window.location.href);
                window.location.replace('/fiche-patient');
              });


              localStorage.setItem(
                'nom_district',
                response.data.user.structure_sanitaire.district.nom_district
              );
              localStorage.setItem(
                'code_district',
                response.data.user.structure_sanitaire.district.code_district
              );
              localStorage.setItem(
                'code_region',
                response.data.user.structure_sanitaire.region.code_region
              );
              localStorage.setItem(
                'nom_region',
                response.data.user.structure_sanitaire.region.nom_region
              );
              
              break;
            }
            case 'MCR': {
              window.location.href = '/dashboard-communautaire';
              history.pushState(null, null, location.href);
              // window.onpopstate = function() {
              //   history.go(1);
              // };
              window.addEventListener('popstate', function() {
                window.history.pushState(null, null, window.location.href);
                window.location.replace('/dashboard-communautaire');
              });

              localStorage.setItem(
                'code_region',
                response.data.user.structure_sanitaire.region.code_region
              );
              localStorage.setItem(
                'nom_region',
                response.data.user.structure_sanitaire.region.nom_region
              );
              
              break;
            }
            case 'SUPERSIVEUR': {

              window.location.href = '/dashboard-communautaire';
              history.pushState(null, null, location.href);

              window.addEventListener('popstate', function() {
                window.history.pushState(null, null, window.location.href);
                window.location.replace('/dashboard-communautaire');
              });
              
              break;
            }

            default: {
              window.location.href = '/home';
              history.pushState(null, null, location.href);
              // window.onpopstate = function() {
              //   history.go(1);
              // };
              window.addEventListener('popstate', function() {
                window.history.pushState(null, null, window.location.href);
                window.location.replace('/home');
              });

              localStorage.setItem(
                'nom_district',
                response.data.user.structure_sanitaire.district.nom_district
              );
              localStorage.setItem(
                'code_district',
                response.data.user.structure_sanitaire.district.code_district
              );
              localStorage.setItem(
                'code_region',
                response.data.user.structure_sanitaire.region.code_region
              );
              localStorage.setItem(
                'nom_region',
                response.data.user.structure_sanitaire.region.nom_region
              );
              break;
            }
          }

        })
        .catch(error => {
          this.errors.push(error.response.data.detail);
        });
    },
    openLoadingButton() {
      const loading = this.$vs.loading({
        target: this.$refs.connect_button,
        scale: '0.6',
        background: '#484ef7',
        opacity: 0.7,
        color: '#fff'
      });
      setTimeout(() => {
        loading.close();
      }, 1500);
    }
  },
  computed: {
    ...mapState({
      user: 'user'
    })
  }
};
</script>
  

<style scoped>
.login_form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  margin-top: 4%;
  background: #ffffff;
  border: 1px solid #dfdfdf;
  box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 85%;
  margin-left: 5%;
}

.img_logo4s {
  width: 100%;
  transform: scale(1.1);
}

.btn_scan {
  background-color: #fff;
  color: #000;
  flex: none;
  order: 1;
  flex-grow: 0;
  border: 2px solid #425169;
  border-radius: 5px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 20px;
  width: 90%;
}

.scan_row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 90%;
}

.hr_lines {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ou {
  font-family: 'Eastman Roman Trial';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 25px;
  text-align: center;
  color: rgba(66, 81, 105, 0.78);
  margin-left: 2%;
  margin-right: 2%;
}

.identifiant_row,
.password_row {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 46px;
}

.known_identifiant_row {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 46px;
  margin-bottom: 4%;
}

.known_identifiant_row_input {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 10px 10px 20px;
  gap: 10px;
  background: #ffffff;
  border-radius: 5px;
  height: 100%;
  font-size: 17px;
  width: 100%;
}
.modify_known_identifiant {
  font-family: 'Eastman Roman Trial';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  text-align: right;
  color: #484ef7;
  position: relative;
  top: -67%;
  left: 32%;
}
.pwd_icon {
  position: relative;
  top: 33%;
  left: 48%;
}

.identifiant_row_input,
.password_row_input {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 10px 10px 20px;
  gap: 10px;
  background: #ffffff;
  border: 2px solid #425169;
  border-radius: 5px;
  flex: none;
  order: 3;
  flex-grow: 0;
  height: 100%;
  font-size: 17px;
  width: 100%;
  margin-top: -6%;
}

#btn_connect {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 70%;
  height: 46px;
  background: #484ef7;
  border-radius: 5px;
  flex: none;
  order: 5;
  flex-grow: 0;
  font-size: 17px;
  width: 80%;
  margin-bottom: 2%;
}

.btn_connect_row {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 42px;
}
.forgot_pwd_row {
  margin-top: 3%;
  display: flex;
  align-content: center;
  justify-content: center;
  margin-bottom: 2%;
}

.forgot_pwd {
  width: 80%;
  font-family: 'Eastman Roman Trial';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #484ef7;
  flex: none;
  order: 6;
  flex-grow: 0;
}

.form_footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
  background: #ededed;
  border-radius: 0px 0px 10px 10px;
}

.form_footer_text {
  width: 50%;
  font-family: 'Eastman Roman Trial';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #425169;
  margin-top: 2%;
}
</style>