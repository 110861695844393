<template>
    <b-container fluid class="stats_content">

    <div class="row-1">

      <div class="row_elt total_patients">
        <span class="total_patients_value">
          {{total_patient_consultes}}
        </span>
        <span class="total_patients_label">
          Total patients consultés
        </span>

      </div>

      <div class="row_elt stat_value">
        <div class="left_infos">
          <span class="value">
            {{total_febriles_consultes}}
          </span>
          <span class="percentage">
            {{pourcentage_febriles_consultes}}% 
          </span>
        </div>
        <span class="total_label">
          Total fébriles consultés
        </span>

      </div>


      <div class="row_elt stat_value">
          <div class="left_infos">
            <span class="value">
              {{total_tdr_palu_realises}}
            </span>
            <span class="percentage">
              {{pourcentage_tdr_palu_realises}}% 
            </span>
          </div>
        <span class="total_label">
          Total TDR paludisme réalisés
        </span>

      </div>

      <div class="row_elt stat_value">
          <div class="left_infos">
            <span class="value">
              {{total_cas_palu_confirmes}}
            </span>
            <span class="percentage">
              {{pourcentage_cas_palu_confirmes}}% 
            </span>
          </div>
        <span class="total_label">
          Total cas de paludisme confirmés
        </span>

      </div>

      <div class="row_elt stat_value">
        <div class="left_infos">
          <span class="value">
            {{total_suspicions_arbos}}
          </span>
          <span class="percentage">
            {{pourcentage_suspicions_arbos}}% 
          </span>
        </div>
        <span class="total_label">
          Total suspicions des arboviroses
        </span>

      </div>

  </div>

  <div class="row-2">

      <div class="row_elt stat_value">
        <div class="left_infos">
          <span class="value">
            {{total_syndromes_grippaux}}
          </span>
          <span class="percentage">
            {{pourcentage_syndromes_grippaux}}% 
          </span>
        </div>
        <span class="total_label">
          Total syndrômes grippaux / Covid19
        </span>

      </div>

      <div class="row_elt stat_value">
        <div class="left_infos">
          <span class="value">
            {{total_cas_diarrhees}}
          </span>
          <span class="percentage">
            {{pourcentage_cas_diarrhees}}% 
          </span>
        </div>
        <span class="total_label">
          Total des cas de Diarrhées
        </span>

      </div>

      <div class="row_elt stat_value">
        <div class="left_infos">
          <span class="value">
            {{total_cas_diarrhees_febriles}}
          </span>
          <span class="percentage">
            {{pourcentage_cas_diarrhees_febriles}}% 
          </span>
        </div>
        <span class="total_label">
          Total des cas de Diarrhées fébriles
        </span>

      </div>

      <div class="row_elt stat_value">

        <div class="left_infos">
          <span class="value">
            {{total_cas_diarrhees_sanglantes}}
          </span>
          <span class="percentage">
            {{pourcentage_cas_diarrhees_sanglantes}}% 
          </span>
        </div>
        <span class="total_label">
          Total des cas de Diarrhées sanglantes
        </span>

      </div>

      <div style="visibility:hidden;" class="row_elt stat_value">


      </div>
    </div>

    </b-container>
</template>
  
  <script>
  
  import { mapState } from 'vuex';
  
  export default {
    name: 'Stats',
    components: { 

    },
    data() {
      return {
      };
    },
  
    methods: {
    },
    computed: {
      pourcentage_febriles_consultes: function () {
        if (this.total_patient_consultes!=0){
          return Math.round((this.total_febriles_consultes/this.total_patient_consultes)*1000)/10;
        }else{
          return 0;
        }
      },
      pourcentage_tdr_palu_realises: function () {
        if (this.total_febriles_consultes!=0){
          return Math.round((this.total_tdr_palu_realises/this.total_febriles_consultes)*1000)/10;
        }else{
          return 0;
        }
      },
      pourcentage_cas_palu_confirmes: function () {
        if (this.total_tdr_palu_realises!=0){
          return Math.round((this.total_cas_palu_confirmes/this.total_tdr_palu_realises)*1000)/10;
        }else{
          return 0;
        }
      },
      pourcentage_syndromes_grippaux: function () {
        if (this.total_febriles_consultes!=0){
          return Math.round((this.total_syndromes_grippaux/this.total_febriles_consultes)*1000)/10;
        }else{
          return 0;
        }
      },
      pourcentage_suspicions_arbos: function () {
        if (this.total_febriles_consultes!=0){
          return Math.round((this.total_suspicions_arbos/this.total_febriles_consultes)*1000)/10;
        }else{
          return 0;
        }
      },
      pourcentage_cas_diarrhees: function () {
        if (this.total_patient_consultes!=0){
          return Math.round((this.total_cas_diarrhees/this.total_patient_consultes)*1000)/10;
        }else{
          return 0;
        }
      },
      pourcentage_cas_diarrhees_febriles: function () {
        if (this.total_febriles_consultes!=0){
          return Math.round((this.total_cas_diarrhees_febriles/this.total_febriles_consultes)*1000)/10;
        }else{
          return 0;
        }
      },
      pourcentage_cas_diarrhees_sanglantes: function () {
        if (this.total_cas_diarrhees!=0){
          return Math.round((this.total_cas_diarrhees_sanglantes/this.total_cas_diarrhees)*1000)/10;
        }else{
          return 0
        }
      },
      ...mapState({
        total_patient_consultes: 'total_patient_consultes',
        total_febriles_consultes: 'total_febriles_consultes',
        total_tdr_palu_realises: 'total_tdr_palu_realises',
        total_cas_palu_confirmes: 'total_cas_palu_confirmes',
        total_syndromes_grippaux: 'total_syndromes_grippaux',
        total_suspicions_arbos: 'total_suspicions_arbos',
        total_cas_diarrhees: 'total_cas_diarrhees',
        total_cas_diarrhees_febriles: 'total_cas_diarrhees_febriles',
        total_cas_diarrhees_sanglantes: 'total_cas_diarrhees_sanglantes'
      })
    },
    async mounted() {
    },
  };
  </script>
  
  <style scoped>

  .stats_content{
    display: flex;
    flex-direction: column;
    margin-top: 2%; 
    justify-content: space-around;  
  }

  .row-1, .row-2{
    justify-content: flex-start;
    display: flex;
  }

  .row-2{
    margin-top: 2%;
  }

  .row_elt{
    margin-left: 2%;
    width: 28%;
  }

  .total_patients{
    border: 0.5px solid #dfe6e9;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 1.5% 1.5% 1.5% 1.5%; 
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  }

  .stat_value{
    border: 0.5px solid #dfe6e9;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    padding: 1.5% 1.5% 1.5% 1.5%; 
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  }

  .total_patients_value{
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 25px;
    color: #0D1C2E;
  }


  .value{
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 25px;
    color: #0D1C2E;
    text-align: left;
    width:50%;
    margin-left: 2%;
  }

  .total_patients_label{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 17px;
    text-align: center;
    color: #0D1C2E;
    margin-top: 10%;
  }

  .total_label{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 17px;
    text-align: center;
    color: #0D1C2E;
    width: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }

  .left_infos{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 45%;
  }

  .percentage{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 17px;
    text-align: center;
    color: #FFFFFF;
    background: #3CB691;
    border-radius: 30px;
    width: 80%;
    padding: 7px;
    margin-top: 25%;
  }

  .up_arrow_icon{
    margin-left: 2%;
  }

  </style>