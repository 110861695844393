<template>
  <div class="onboard_content">
    <div class="heading">
      <div class="logos">
        <img class="logo img_logo4s" src="@/assets/msas_black_logo.png" />
        <img class="logo img_logoIpd" src="@/assets/ipd_black_logo.png" />
      </div>
      <div class="title">Le patient porte t-il de la Fièvre?</div>
    </div>
    <b-row class="main_questions">
      <b-col cols="6" class="question">
        <span class="question_label">Température — °C</span>
        <div class="question_field">
          <b-form-input
            class="fever_temperature_input input_field"
            v-model="fever_temp"
            type="number"
            size="md"
            min="35"
            max="43"
            step=".1"
            placeholder="temp : °C"
          ></b-form-input>
        </div>
      </b-col>
      <b-col cols="6" class="question">
        <div class="question_label">Durée depuis le début de la fièvre</div>
        <div style="position:relative; left:4%;" class="question_field">
          <b-form-input
            class="fever_duration_input input_field"
            v-model="fever_duration"
            type="number"
            size="md"
            min="0"
            max="100"
            :formatter="formatDuration"
            placeholder="(Jours)"
          ></b-form-input>
        </div>
      </b-col>
    </b-row>

    <div style="margin-top:3%;" v-show="showDiarrheeQuestion==true">
      <b-row class="question_diarrhee_row">
        <span class="titre_question_diarrhee">le patient a-t-il de la diarrhée ?</span>
        <b-col cols="5" class="question_diarrhee">
          <SwitchBox
            :values="['Non','Oui']"
            :icons="['blank.png','blank.png']"
            :box_style="{label1Left:'-20%',label1Right:'-20%',label2Left:'0%',label2Right:'0%', boxMarginLeft:'0%',selectBoxWidth:'18.3%'}"
            :variableName="'diarrhee'"
            :null="false"
          />
        </b-col>
      </b-row>
    </div>

    <div v-show="showDiarrheeSanglanteQuestion==true">
      <b-row class="question_diarrhee_sanglante_row">
        <b-col cols="12" class="question_diarrhee">
          <b-form-checkbox
            ref="dsCheckbox"
            class="symptome_checkbox"
            v-model="diarrhee_sanglante"
            name="symptome_checkbox"
            value="Oui"
            unchecked-value="Non"
            size="lg"
          >
            <span class="checkbox_label">Diarrhée sanglante ?</span>
          </b-form-checkbox>
        </b-col>
      </b-row>
    </div>

    <hr v-show="showDiarrheeQuestion==false" style="width:100%; margin-top:15%;" />

    <div id="popup_msg_block" v-show="showPopUp==true" class="popup">
      <PopUp
        :title="'ALERTE'"
        :content="'La Température est inférieure à 38 °C Voulez vous continuez la consultation'"
        :command="'1'"
      />
    </div>

    <b-row class="next_btn_row">
      <b-button @click="goToPreviousSection()" variant="light" class="previous_button">
        <img class="previous_left_icon" src="@/assets/back_white.png" />
        <span class="previous_button_text">Précédent</span>
      </b-button>
      <b-button @click="goToNextSection()" variant="light" class="next_button">
        <span class="next_button_text">Suivant</span>
        <img class="next_right_icon" src="@/assets/next.png" />
      </b-button>
    </b-row>
  </div>
</template>


<script>
import { mapState } from 'vuex';
import { eventBus } from '@/main.js';
import PopUp from '@/components/fiche-individuelle/general/PopUp.vue';
import SwitchBox from '@/components/fiche-individuelle/general/SwitchBox.vue';

export default {
  name: 'DiagnosticAndDiagnosticOnboard',
  components: {
    PopUp,
    SwitchBox
  },
  data: () => ({
    fever_temp: '',
    fever_duration: '',
    showPopUp: false,
    showDiarrheeQuestion: false,
    diarrhee_sanglante: '',
  }),
  computed: {
    ...mapState({
      //informations générales
      consulting_date: 'consulting_date',
      user: 'user',
      epid: 'epid',

      //informations personnelles
      prenom: 'prenom',
      nom: 'nom',
      date_de_naissance: 'date_de_naissance',
      genre: 'genre',
      age_year: 'age_year',
      age_month: 'age_month',
      femme_enceinte: 'femme_enceinte',
      age_gestationnel: 'age_gestationnel',
      telephone1_patient: 'telephone1_patient',
      telephone2_patient: 'telephone2_patient',
      adresse_patient: 'adresse_patient',
      statut_matrimonial: 'statut_matrimonial',

      //diagnostic et prescription base infos

      fievre: 'fievre',
      diarrhee: 'diarrhee',
      temperature_fievre: 'temperature_fievre',
      duree_fievre: 'duree_fievre',
      individual_records_number: 'individual_records_number',
      showDiarrheeSanglanteQuestion : 'showDiarrheeSanglanteQuestion'
    })
  },
  mounted() {

    let self = this;
    document
      .querySelector('.fever_temperature_input')
      .addEventListener('focusout', function(e) {
        if (e.target.value < 38) {
          self.showDiarrheeQuestion = true;
        } else {
          self.showDiarrheeQuestion = false;
        }
      });

    eventBus.$on('triggerModifyFeverAndDiarrheeInfos', () => {
      this.$store.commit('setShowSuspisionSidebar', false);
      this.$store.commit('setShowDiagnosticAndPrescriptionSection', false);
    });

    eventBus.$on('triggerContinueConsulting', () => {
      this.showPopUp = false;
      this.$store.commit('setShowSuspisionOnboardSection', false);
      this.$store.commit('setShowDiagnosticAndPrescriptionSection', true);
    });
  },
  methods: {

    goToPreviousSection(){
      this.$store.commit('setShowSuspisionOnboardSection', false);
      this.$store.commit('setShowSuspisionSection', false);
      this.$store.commit('setShowPersonalInfosSection', true);
    },
    goToNextSection() {
      if (this.fever_temp < 38) {
        this.showPopUp = true;
      } else {
        this.$store.commit('setFievre', 'Oui');
        this.$store.commit('setShowSuspisionOnboardSection', false);
        this.$store.commit('setShowDiagnosticAndPrescriptionSection', true);
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      }

    },
    formatDuration(e) {
      return String(e).substring(0, 2);
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${year}-${month}-${day}`;
    },
    getAge(birthdate) {
      var today = new Date();
      var birthdateParts = birthdate.split("-");
      var birthyear = parseInt(birthdateParts[0], 10);
      var birthmonth = parseInt(birthdateParts[1], 10);
      var birthday = parseInt(birthdateParts[2], 10);

      var birthdateObj = new Date(birthyear, birthmonth - 1, birthday);
      var ageInMilliseconds = today - birthdateObj;
      var ageInYears = ageInMilliseconds / (365.25 * 24 * 60 * 60 * 1000);
      return Math.round(ageInYears);

    },
  },
  watch: {
    fever_temp(val) {
      this.$store.commit('setTemperatureFievre', val);
    },
    fever_duration(val) {
      this.$store.commit(
        'setFeverDuration',
        val > 1 ? val + ' jours' : val + ' jour'
      );
    },
    showDiarrheeSanglanteQuestion(val){
      if(val==false){
        this.diarrhee_sanglante='Non'
      }
    },
    diarrhee_sanglante(val) {
      this.$store.commit('setDiarrheeSanglante', val);
    }
  }
};
</script>

<style scoped>
.onboard_content {
  width: 100%;
  background: #ffffff;
  height: 98%;
  padding: 15px 15px;
  border-radius: 15px;
}
.next_btn_row {
  display: flex;
  justify-content: space-between;
  width: 98%;
  margin-bottom: 2%;
  margin-top: 1%;
}
.next_button {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  gap: 10px;
  background: #484ef7 !important;
  border-radius: 5px;
  box-shadow: none !important;
}

.next_button:active {
  transform: scale(0.98);
  opacity: 0.9;
}

.next_button_text {
  font-family: 'Eastman Roman Trial';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
}

.chevron_right_icon {
  background-color: #484ef7;
  color: #484ef7;
}

.next_right_icon {
  position: relative;
  left: 10%;
}

.previous_button {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  gap: 10px;
  background: #484ef7 !important;
  border-radius: 5px;
  box-shadow: none !important;
}

.previous_button:active {
  transform: scale(0.98);
  opacity: 0.9;
}

.previous_button_text {
  font-family: 'Eastman Roman Trial';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
}

.chevron_left_icon {
  background-color: #484ef7;
  color: #484ef7;
}

.next_left_icon {
  position: relative;
  left: 10%;
}

.previous_left_icon{
  transform: scale(0.5);
}

.heading {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.logos {
  width: 20%;
}
.logo {
  margin-left: 2%;
}

.img_logoIpd {
  margin-left: 6%;
}
.img_logo4s {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.title {
  margin-left: 20%;
  font-family: 'Eastman Roman Trial';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #425169;
}
.main_questions {
  width: 70%;
  margin-left: 15%;
  display: flex;
  margin-top: 3%;
  height: 150px;
}

.question {
  display: flex;
  flex-direction: row;
  padding: 0;
  justify-content: space-between;
  align-items: center;
}

.question_field {
  height: 45%;
  width: 40%;
  display: flex;
  justify-content: flex-start;
}

.question_label {
  font-family: 'Eastman Roman Trial';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: rgba(66, 81, 105, 0.78);
  opacity: 0.9;
  margin-bottom: 5%;
}

.input_field {
  width: 55%;
  height: 50px;
  margin-left: 2%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 10px 10px 20px;
  gap: 10px;
  background: #ffffff;
  border: 2px solid #425169;
  border-radius: 5px;
}

.popup {
  width: 28%;
  margin-left: 30%;
  position: absolute;
  bottom: 50%;
  z-index: 1000;
}

.question_diarrhee_row {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2%;
  margin-left: 4%;
}

.question_diarrhee_sanglante_row {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1%;
}

.titre_question_diarrhee {
  position: relative;
  right: 3%;
  margin-bottom: 2%;
  font-family: 'Eastman Roman Trial';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;
  color: rgba(66, 81, 105, 0.78);
}

.checkbox_label {
  font-family: 'Eastman Roman Trial';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  color: #425169;
  position: relative;
}
</style>