<template>
  <div id="app">
    <b-container style="margin:0; padding:0;" fluid tag="main" id="app_container" v-cloak>
      <router-view />
      <!-- <v-snackbar color="#484EF7" bottom right :value="updateExists" :timeout="2000">
        Une nouvelle version de l'application est disponible
        <v-btn
          style="color:#ffffff; font-family: 'Eastman Roman Trial'; font-size: 17px;"
          text
          @click="refreshApp"
        >Mise à jour...</v-btn>
      </v-snackbar>-->

    <b-modal id="refreshingModal" hide-header hide-header-close hide-footer title="">
      <p class="refreshing_message">Êtes-vous sûr de vouloir rafraichir la page ? les données saisies risquent d'être perdues.</p>

      <div class="actions">
        <b-button id="continueButton" class="cancel_button shadow-none">Annuler</b-button>
        <b-button id="stopButton" class="confirm_button shadow-none">Valider</b-button>
      </div>
    </b-modal>
    </b-container>
    <!-- <router-view /> -->
  </div>
</template>
<script>
import { mapState } from 'vuex';

// import update from './mixins/update';

export default {
  name: 'App',
  components: {},
  // mixins: [update],
  computed: {
    ...mapState({
      token: 'token',
      isAuthenticated: 'isAuthenticated',
      individual_records_number: 'individual_records_number',
      user: 'user',
      isConsulting: 'isConsulting'
    })
  },
  methods:{
  },

  async mounted() {

    document.addEventListener('keydown', function(event) {
      if (event.key === "Enter") {

        var activeElement = document.activeElement;

        activeElement.blur();
      }
    });

    this.$store.commit('initialize');

    let orientation = window.screen.orientation;

    async function rotate() {
      if (orientation.type.startsWith('portrait')) {
        document.getElementById('app').style.display = 'none';
        setTimeout(() => {
          alert("veuillez retourner l'appareil svp.");
        }, 1000);
      } else {
        document.getElementById('app').style.display = 'block';
      }
    }

    screen.orientation.addEventListener('change', rotate);
    window.addEventListener('load', rotate);
  }
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#refreshingModal{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 40px;
  gap: 10px;
  background: #0D1C2E;
  border-radius: 20px;
}

.leaving_message{
  font-family: 'Eastman Trial';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  color: #425169;
  text-align: center;
}

.actions{
  display: flex;
  flex-direction: row;
  justify-content: center;
}


.cancel_button{
  background: #FF6161 !important;
  color: #FFFFFF;
  border: None;
  margin-right: 1%;
}

.cancel_button:active{
  transform: scale(0.95);
}

.confirm_button{
  background: #3CB691 !important;
  color: #FFFFFF;
  border: None;
  margin-left: 1%;
}

.confirm_button:active{
  transform: scale(0.95);
}

html, body {
  overscroll-behavior: none;
}

#nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
