<template>
    <b-navbar toggleable="md" class="py-0 my-0 menu" type="light" variant="light">

      <b-nav-toggle target="dashboard_nav_collapse"></b-nav-toggle>
      <b-navbar-brand class="p-0 m-0" href="#">
        <img class="header_logo" width="70" src="@/assets/logo4s.png" />
      </b-navbar-brand>

      <div v-show="show_spinner==true" class="loading_db">
        <b-spinner variant="primary" label="Spinning"></b-spinner>
        <div class="loading_text">Exportation des données...</div>
      </div>
  
      <b-collapse is-nav id="dashboard_nav_collapse">

        <b-navbar-nav class="dashboard_navigation_items">

          <b-nav-item v-show="user_role=='ICP'" @click="triggerAccueil()" class="accueil">
            <span class="accueil_text">Accueil</span>
          </b-nav-item>

          <b-nav-item @click="triggerSurveillance()" class="surveillance">
            <span class="surveillance_text">Surveillance</span>
          </b-nav-item>

          <b-nav-item v-show="user_role=='SUPERVISEUR'" @click="exportDB()" class="export_db">
            <img class="db_export_img" src="@/assets/db_export.png" alt="exporter la base" />
            <span class="export_db_text">Extraire les données</span>
          </b-nav-item>


          <b-nav-item v-show="user_role=='SUPERVISEUR'" @click="exportStats()" class="export_stats">
            <img class="db_export_img" src="@/assets/db_export.png" alt="exporter la base" />
            <span class="export_db_text">Extraire le rapport</span>
          </b-nav-item>

        </b-navbar-nav>
  
        <b-navbar-nav class="user_infos">

          <b-nav-item class="avatar_infos">
            <!-- <img class="avatar_img" src="@/assets/avatar.png" alt="avatar" /> -->
            <div class="user_name">Salut, {{user_name}}</div>
          </b-nav-item>

          <!-- <b-nav-item class="avatar">
            <img class="settings_img" src="@/assets/settings.png" alt="settings" />
          </b-nav-item> -->

          <b-nav-item @click="logoutUser()"  class="logout_icn">
            <img class="logout_img" src="@/assets/logout_icon.png" alt="logout" />
          </b-nav-item>

        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
</template>
  
  <script>
  
  import { mapState } from 'vuex';
  import * as XLSX from 'xlsx'
  
  export default {
    name: 'Header',
    components: { 

    },
    data() {
      return {
        data : [],
        user_role : "",
        show_spinner : false,
        user_name : "",
        user : null
      };
    },
  
    methods: {
      initHeader(){
        let surveillanceElt = document.querySelector(".surveillance_text");
        surveillanceElt.style.color = "#484EF7";
        surveillanceElt.parentElement.style.borderBottom="2px solid #484EF7";
      },
      triggerSurveillance(){
        this.initHeader();
        this.resetSamples();
        this.$store.commit("setShowSurveillanceView",true)
      },
      triggerAccueil(){
        this.resetSurveillance();
        let surveillanceElt = document.querySelector(".accueil_text");
        surveillanceElt.style.color = "#484EF7";
        surveillanceElt.parentElement.style.borderBottom="2px solid #484EF7";
        this.$store.commit("setShowSurveillanceView",true);
        this.$router.push('/home').catch(() => {});
      },
      resetSurveillance(){
        let surveillanceElt = document.querySelector(".surveillance_text");
        surveillanceElt.style.color = "#425169";
        surveillanceElt.parentElement.style.borderBottom="none";
      },
      resetSamples(){
        let surveillanceElt = document.querySelector(".accueil_text");
        surveillanceElt.style.color = "#425169";
        surveillanceElt.parentElement.style.borderBottom="none";
      },
      flattenData(data) {
        let flattened = {};
        function flatten(obj, prefix = '') {
          for (let key in obj) {

            let newPrefix = prefix ? prefix + '.' + key : key;

            if (newPrefix.includes('.')) {
              const parts = newPrefix.split('.');
              const lastPart = parts[parts.length - 1];
              newPrefix=lastPart;
            }
            if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
              flatten(obj[key], newPrefix);
            } else {
              flattened[newPrefix] = obj[key];
            }
          }
        }
        flatten(data);
        return flattened;
      },
      async exportDB(){

        this.show_spinner=true;

        let data = [];
        let filename = "4s_database";

        let date_debut = this.filter_date_debut;
        let date_fin = this.filter_date_fin;

        await this.axios
          .get('/api/fiche-fievre/?date_debut='+date_debut+"&date_fin="+date_fin+'&limit=9999999')
            .then(response => {
              data = response.data.results
              setTimeout(() => {

                  const flattenedData = data.map(obj => this.flattenData(obj));

                  let allKeys = new Set();
                  flattenedData.forEach(obj => {
                    for (let key in obj) {
                      allKeys.add(key);
                    }
                  });
                  allKeys = Array.from(allKeys);

                  const dataArray = flattenedData.map(obj => allKeys.map(key => obj[key] || ''));
                  dataArray.unshift(allKeys);
                  const workbook = XLSX.utils.book_new();
                  const worksheet = XLSX.utils.aoa_to_sheet(dataArray);
                  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
                  const buffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
                  const blob = new Blob([buffer], { type: 'application/octet-stream' });

                  const link = document.createElement('a');
                  link.href = window.URL.createObjectURL(blob);
                  link.download = filename + '.xlsx';
                  link.click();

                  this.show_spinner=false;
              });
        })
        .catch(error => {
          this.errors.push(error.response.data.detail);
        });
    },
    exportStats(){

      let jsonData=[
        {
          'total_patient_consultes': this.total_patient_consultes,
          'total_febriles_consultes': this.total_febriles_consultes,
          'total_tdr_palu_realises': this.total_tdr_palu_realises,
          'total_cas_palu_confirmes': this.total_cas_palu_confirmes,
          'total_syndromes_grippaux': this.total_syndromes_grippaux,
          'total_suspicions_arbos': this.total_suspicions_arbos,
          'total_cas_diarrhees': this.total_cas_diarrhees,
          'total_cas_diarrhees_febriles': this.total_cas_diarrhees_febriles,
          'total_cas_diarrhees_sanglantes': this.total_cas_diarrhees_sanglantes
        }
      ];
      let filename="Rapport.xlsx";

        const worksheet = XLSX.utils.json_to_sheet(jsonData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
        const url = URL.createObjectURL(data);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    },
    async logoutUser() {
      await this.axios
        .post('/api/logout/', { identifiant: this.user.nom_utilisateur })
        .then(() => {
          this.$vs.notification({
            duration: 500,
            color: '#484EF7',
            position: 'bottom-left',
            title: 'Au revoir',
            text: '',
            buttonClose: false
          });
          //redirecting
          setTimeout(() => {
            window.location.href = '/login';
            window.history.pushState(null, '', window.location.href);
            window.onpopstate = function() {
              window.history.pushState(null, '', window.location.href);
            };
          }, 800);
          let lastest_identifiant = this.$store.state.user.nom_utilisateur;
          //saving user informations
          localStorage.removeItem('user');
          localStorage.setItem('nom_utilisateur', lastest_identifiant);
          this.$store.commit('setIsAuthenticated', false);
          localStorage.removeItem('isAuthenticated');
          localStorage.removeItem('token');
          this.$store.commit('setToken', null);
        })
        .catch(error => {
            console.log(JSON.stringify(error));
        });
    },
    },
    computed: {
      ...mapState({
        username: 'username',
        filter_date_debut : 'filter_date_debut',
        filter_date_fin : 'filter_date_fin',
        total_patient_consultes: 'total_patient_consultes',
        total_febriles_consultes: 'total_febriles_consultes',
        total_tdr_palu_realises: 'total_tdr_palu_realises',
        total_cas_palu_confirmes: 'total_cas_palu_confirmes',
        total_syndromes_grippaux: 'total_syndromes_grippaux',
        total_suspicions_arbos: 'total_suspicions_arbos',
        total_cas_diarrhees: 'total_cas_diarrhees',
        total_cas_diarrhees_febriles: 'total_cas_diarrhees_febriles',
        total_cas_diarrhees_sanglantes: 'total_cas_diarrhees_sanglantes'
      })
    },
    async mounted() {

      this.user = JSON.parse(localStorage.getItem("user"));
      this.user_role = this.user.role.nom_role;
      this.user_name = this.user.prenom + " " + this.user.nom;
      this.initHeader();
    },
  };
  </script>
  
  <style scoped>

  .menu {
    overflow-x: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 2%;
    padding-bottom: 1%;
    height: 15%;
    background: #ffffff;
  }

  .header_logo {
    border-radius: 70%;
    padding: 0;
    margin-top: 10%;
    display: block;
    margin-bottom: 10%;
    margin-left: 16%;
  }
  
  .user_infos {
    width: 40%;
    display: flex;
    justify-content: flex-end;
    margin-right: 4%;
  }
  
  .avatar {
    height: 40%;
    border-radius: 100%;
    margin-top: 0%;
  }
  
  .avatar_infos {
    margin-left: 1%;
    color: black;
    display: flex;
    flex-direction: row;
    text-align: start;
    align-items: center;
  }
  
  .avatar_img {
    transform: scale(0.95);
  }

  .dashboard_navigation_items{
    margin-top: 3%;
    margin-left: 2.5%;
    width: 75%; 
    display: flex;
    align-items: flex-end;
  }

  .surveillance, 
  .accueil,
  .export_db{
    padding: 0 !important;
  }

  .accueil,
  .export_db,
  .surveillance{
    margin-left: 10%;
  }

   .export_db,
   .export_stats{
    width: 35%;
   }

  .surveillance_text, 
  .accueil_text,
  .export_db_text{
    font-family: 'Eastman Roman Trial';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    color: #425169;
  }

  #dashboard_nav_collapse{
    display: flex;
    justify-content: space-between;
  }

  .navbar { 
    margin-bottom: 0;
  }

  .export_db:active{
    transform: scale(0.95); 
  }

  .db_export_img{
    transform: scale(0.7);
    margin-right: 1%;
  }

  .loading_db{
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .logout_img{
    background: #e74c3c;
    border-radius: 5px;
    transform: scale(1.5);
  }

  .user_name{
    position: relative;
    right: 5%;
    font-family: 'sans-serif';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 14px;
    color: #425169;
  }

  .logout_icn:active{
    transform: scale(0.9);
  }

  </style>