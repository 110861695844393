<template>
  <b-container fluid class="travel_content">
    <span class="travel_question_title">Le patient a voyagé dans 15 derniers jrs ?</span>
    <b-row class="travel">
      <b-col cols="5" class="travel_question">
        <SwitchBox
          :values="['Non','Oui']"
          :icons="['blank.png','blank.png']"
          :box_style="{label1Left:'-5%',label1Right:'0%',label2Left:'0%',label2Right:'0%',selectBoxHeight:'65%', boxMarginLeft:'0%',selectBoxWidth:'18.3%'}"
          :variableName="'voyage_15jrs'"
          :null="false"
        />
      </b-col>
    </b-row>
    <b-row v-show="voyage_15jrs=='Oui'" class="travel_infos">
      <b-col cols="4" class="travel_element">

        <span class="travel_subquestions_label">Lieu de voyage</span>
        <b-form-input
          class="input_field"
          v-model="lieu_voyage"
          type="text"
          name="other_symptomes"
          size="md"
          placeholder="Lieu de voyage"
        ></b-form-input>
      </b-col>
      <b-col cols="2"></b-col>
      <b-col cols="4" class="travel_element">
        <transition name="slide-fade" mode="out-in">
          <v-date-picker
            close-on-content-click
            id="travel_date_picker"
            color="#3CB691"
            locale="fr"
            class="travel_date_picker"
            v-model="travel_date"
            width="100%"
            no-title
          ></v-date-picker>
        </transition>

        <span class="travel_subquestions_label">Date de voyage</span>
        <b-form-input
          id="travel_date_input"
          @click="showDatePicker('travel_date_picker')"
          class="travel_date_input"
          readonly
          type="text"
          v-model="formatted_travel_date"
          size="md"
        >
        </b-form-input>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapState } from 'vuex';
import SwitchBox from '@/components/fiche-individuelle/general/SwitchBox.vue';

export default {
  name: 'TravelSection',
  components: {
    SwitchBox
  },
  data: (vm) => ({
    travel_date: new Date(new Date().getFullYear(),new Date().getMonth(),1).toISOString().substr(0, 10),
    formatted_travel_date: vm.formatDate(new Date(new Date().getFullYear(),new Date().getMonth(),1).toISOString().substr(0, 10)),
    lieu_voyage: null
  }),
  mounted() {

    this.$store.commit('setDateVoyage', this.date_voyage);
    window.addEventListener('click', function(e) {
      let datePickerElt = document.getElementById('travel_date_picker');
      if (datePickerElt != null) {
        if (document.getElementById('travel_date_picker').contains(e.target)) {
          // Clicked in box
        } else {
          if (document.getElementById('travel_date_input').contains(e.target)) {
            document.getElementById('travel_date_picker').style.display = 'block';
          } else {
            document.getElementById('travel_date_picker').style.display = 'none';
          }
        }
      }
    });
  },
  computed: {
    ...mapState({
      voyage_15jrs: 'voyage_15jrs'
    })
  },
  watch: {
    lieu_voyage(val) {
        this.$store.commit('setLieuVoyage', val);
    },
    travel_date(val){
      let dateElt = document.getElementById('travel_date_picker');
      setTimeout(() => {
        //
      }, 100);
      dateElt.style.display = 'none';
      this.formatted_travel_date = this.formatDate(val);
      this.travel_date = val;
      this.$store.commit('setDateVoyage', val);
    }
  },
  methods: {
    showDatePicker() {
      let dateElt = document.getElementById('travel_date_picker');
      dateElt.style.display = 'block';
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split('-');
      return `${month}/${day}/${year}`;
    }
  }
};
</script>

<style scoped>
.travel_content {
  margin-bottom: 2%;
}

.travel {
  display: flex;
  justify-content: center;
  align-items: center;
}

.travel_infos {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 62%;
  margin-left: 18%;
}

.travel_element {
  box-shadow: none !important;
  outline: 0 !important;
  -webkit-box-shadow: none !important;
  box-sizing: border-box !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: flex-start !important;
  align-items: center !important;
  padding: 2px 0px !important;
  gap: 5px !important;
  border-radius: 5px !important;
  margin-top: 2% !important;
  padding-left: 15px !important;
  transform: scale(1);
}

.treatment_element:active {
  border: none !important;
  box-shadow: none !important;
  outline: 0 !important;
  -webkit-box-shadow: none;
}

.travel_question_title {
  font-family: 'Eastman Roman Trial';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #425169;
  width: 93.5%;
  margin-bottom: 1%;
}

.input_field {
  width: 90%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 10px 10px 20px;
  gap: 10px;
  background: #ffffff;
  border: 2px solid #425169;
  border-radius: 5px;
  margin-left: 15%;
}

.slide-fade-enter-active {
  transition: all 0.4s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(20px);
  opacity: 0;
}

.travel_date_picker {
  position: absolute;
  top: -105%;
  left: 110%;
  width: 80%;
  box-sizing: border-box;
  border: 2px solid #979797;
  border-radius: 10px;
  color: #262847 !important;
  z-index: 10000000;
  display: none;
}

.travel_date_input{
  border: 2px solid blue;
  width: 100%;
  margin-left: 10%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 10px 10px 20px;
  gap: 10px;
  background: #ffffff;
  border: 2px solid #425169;
  border-radius: 5px;
  height: 85%;
  position: relative;
  left: -5%;
}

.travel_date_input[readonly]{
  background: #ffffff;
}

.travel_subquestions_label{
  position: relative;
  font-family: 'Comfortaa';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 13px;
  letter-spacing: 0.01em;
  color: #425169;
  margin-bottom: 1%;
}
</style>
