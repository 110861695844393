<template>
  <div class="symptomes_content">
    <b-row class="covid_symptomes">
      <span class="covid_symptomes_title">Covid-19/syndrome grippal</span>

      <span
        class="covid_symptomes_description"
      >Fièvre + Toux: Syndrome grippal / Apparition soudaine d’au moins trois signes ou symptômes suivants : Covid19</span>
    </b-row>

    <b-row class="symptomes_row">

      <b-col class="symptomes_question_checkbox" cols="2">
        <b-form-checkbox
          class="symptome_checkbox"
          v-model="toux"
          name="symptome_checkbox"
          value="Oui"
          unchecked-value="Non"
        >
          <span class="checkbox_label">Toux</span>
        </b-form-checkbox>
      </b-col>


      <b-col class="symptomes_question_checkbox" cols="2">
        <b-form-checkbox
          class="symptome_checkbox"
          v-model="dyspnee"
          name="symptome_checkbox"
          value="Oui"
          unchecked-value="Non"
        >
          <span class="checkbox_label">Dyspnée</span>
        </b-form-checkbox>
      </b-col>


      <b-col class="symptomes_question_checkbox" cols="2">
        <b-form-checkbox
          class="symptome_checkbox"
          v-model="ecoulement_nasal"
          name="symptome_checkbox"
          value="Oui"
          unchecked-value="Non"
        >
          <span class="checkbox_label">Ecoulement nasal</span>
        </b-form-checkbox>
      </b-col>

      <b-col class="symptomes_question_checkbox" cols="2">
        <b-form-checkbox
          class="symptome_checkbox"
          name="symptome_checkbox"
          v-model="mal_de_gorge"
          value="Oui"
          unchecked-value="Non"
        >
          <span class="checkbox_label">Mal de gorge</span>
        </b-form-checkbox>
      </b-col>


    </b-row>

    <b-row style="margin-top:2.5%;" class="symptomes_row">

      <b-col class="symptomes_question_checkbox" cols="2">
        <b-form-checkbox
          class="symptome_checkbox"
          v-model="perte_de_gout"
          name="symptome_checkbox"
          value="Oui"
          unchecked-value="Non"
        >
          <span class="checkbox_label">Perte de gout</span>
        </b-form-checkbox>
      </b-col>


      <b-col class="symptomes_question_checkbox" cols="2">
        <b-form-checkbox
          class="symptome_checkbox"
          v-model="perte_odorat"
          name="symptome_checkbox"
          value="Oui"
          unchecked-value="Non"
        >
          <span class="checkbox_label">Perte d’odorat</span>
        </b-form-checkbox>
      </b-col>

      
      <b-col class="symptomes_question_checkbox" cols="2">
        <b-form-checkbox
          class="symptome_checkbox"
          v-model="anorexie"
          name="symptome_checkbox"
          value="Oui"
          unchecked-value="Non"
        >
          <span class="checkbox_label">Anorexie</span>
        </b-form-checkbox>
      </b-col>
      
      <b-col class="symptomes_question_checkbox" cols="2">
        <b-form-checkbox
          class="symptome_checkbox"
          v-model="cephalees"
          name="symptome_checkbox"
          value="Oui"
          unchecked-value="Non"
        >
          <span class="checkbox_label">Céphalées</span>
        </b-form-checkbox>
      </b-col>

    </b-row>

    <b-row style="margin-top:2.5%;margin-bottom:2%; " class="symptomes_row">


      <b-col class="symptomes_question_checkbox" cols="2">
        <b-form-checkbox
          class="symptome_checkbox"
          v-model="nausees"
          name="symptome_checkbox"
          value="Oui"
          unchecked-value="Non"
        >
          <span class="checkbox_label">Nausées</span>
        </b-form-checkbox>
      </b-col>


      <b-col class="symptomes_question_checkbox" cols="2">
        <b-form-checkbox
          class="symptome_checkbox"
          v-model="vomissement"
          name="symptome_checkbox"
          value="Oui"
          unchecked-value="Non"
        >
          <span class="checkbox_label">Vomissement</span>
        </b-form-checkbox>
      </b-col>

      <b-col style="visibility:hidden;" class="symptomes_question_checkbox" cols="2">
        <b-form-checkbox
          class="symptome_checkbox"
          name="symptome_checkbox"
          value="Oui"
          unchecked-value="Non"
        >
          <span class="checkbox_label"></span>
        </b-form-checkbox>
      </b-col>
      <b-col style="visibility:hidden;" class="symptomes_question_checkbox" cols="2">
        <b-form-checkbox
          class="symptome_checkbox"
          name="symptome_checkbox"
          value="Oui"
          unchecked-value="Non"
        >
          <span class="checkbox_label"></span>
        </b-form-checkbox>
      </b-col>
    </b-row>
  </div>
</template>


<script>
import { mapState } from 'vuex';
import { eventBus } from '@/main.js';

export default {
  name: 'SymptomesSection',
  components: {},
  data: () => ({
    anorexie: '',
    cephalees: '',
    dyspnee: '',
    ecoulement_nasal: '',
    nausees: '',
    perte_odorat: '',
    perte_de_gout: '',
    toux: '',
    vomissement: '',
    mal_de_gorge: '',
    selected_symptomes: []
  }),
  computed: {
    ...mapState({
      covid19_percentage: 'covid19_percentage',
      influenza_percentage: 'influenza_percentage',
      tdr_covid: 'tdr_covid'
    })
  },
  watch: {
    anorexie(val) {
      this.$store.commit('setAnorexie', val);
      if (val == 'Oui') {
        this.selected_symptomes.push('anorexie');
      } else {
        let index = this.selected_symptomes.indexOf('anorexie');
        if (index > -1) {
          this.selected_symptomes.splice(index, 1);
        }
      }
    },
    cephalees(val) {
      this.$store.commit('setCephalees', val);
      if (val == 'Oui') {
        this.selected_symptomes.push('cephalées');
      } else {
        let index = this.selected_symptomes.indexOf('cephalées');
        if (index > -1) {
          this.selected_symptomes.splice(index, 1);
        }
      }
    },
    dyspnee(val) {
      this.$store.commit('setDyspnee', val);
      if (val == 'Oui') {
        this.selected_symptomes.push('dyspnee');
      } else {
        let index = this.selected_symptomes.indexOf('dyspnee');
        if (index > -1) {
          this.selected_symptomes.splice(index, 1);
        }
      }
    },
    ecoulement_nasal(val) {
      this.$store.commit('setEcoulementNasal', val);
      if (val == 'Oui') {
        this.selected_symptomes.push('écoulement nasal');
      } else {
        let index = this.selected_symptomes.indexOf('écoulement nasal');
        if (index > -1) {
          this.selected_symptomes.splice(index, 1);
        }
      }
    },
    nausees(val) {
      this.$store.commit('setNausees', val);
      if (val == 'Oui') {
        this.selected_symptomes.push('nausées');
      } else {
        let index = this.selected_symptomes.indexOf('nausées');
        if (index > -1) {
          this.selected_symptomes.splice(index, 1);
        }
      }
    },
    perte_odorat(val) {
      this.$store.commit('setPerteOdorat', val);
      if (val == 'Oui') {
        this.selected_symptomes.push("perte d'odorat");
      } else {
        let index = this.selected_symptomes.indexOf("perte d'odorat");
        if (index > -1) {
          this.selected_symptomes.splice(index, 1);
        }
      }
    },
    perte_de_gout(val) {
      this.$store.commit('setPerteDeGout', val);
      if (val == 'Oui') {
        this.selected_symptomes.push('perte de gout');
      } else {
        let index = this.selected_symptomes.indexOf('perte de gout');
        if (index > -1) {
          this.selected_symptomes.splice(index, 1);
        }
      }
    },
    toux(val) {
      this.$store.commit('setToux', val);
      if (val == 'Oui') {
        this.selected_symptomes.push('toux');
      } else {
        let index = this.selected_symptomes.indexOf('toux');
        if (index > -1) {
          this.selected_symptomes.splice(index, 1);
        }
      }
    },
    vomissement(val) {
      this.$store.commit('setVomissement', val);
      if (val == 'Oui') {
        this.selected_symptomes.push('vomissement');
      } else {
        let index = this.selected_symptomes.indexOf('vomissement');
        if (index > -1) {
          this.selected_symptomes.splice(index, 1);
        }
      }
    },
    mal_de_gorge(val) {
      this.$store.commit('setMalDeGorge', val);
      if (val == 'Oui') {
        this.selected_symptomes.push('mal de gorge');
      } else {
        let index = this.selected_symptomes.indexOf('mal de gorge');
        if (index > -1) {
          this.selected_symptomes.splice(index, 1);
        }
      }
    },
    selected_symptomes() {
      const index = this.selected_symptomes.indexOf('toux');
      if (index > -1 || this.selected_symptomes.length >= 3) {
        eventBus.$emit('triggerFluSuspision');
      } else {
        eventBus.$emit('hideFluSuspision');
      }
    }
  },
  methods: {}
};
</script>

<style scoped>
.symptomes_content {
  overflow: hidden;
  padding: 10px;
}
.covid_symptomes {
  display: flex;
  flex-direction: column;
  width: 96%;
  justify-content: center;
  align-items: center;
  margin-top: 1%;
}

.covid_symptomes_title {
  font-family: 'Eastman Roman Trial';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #425169;
  margin-bottom: 0.5%;
}

.covid_symptomes_description {
  font-family: 'Eastman Roman Trial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #425169;
  margin-bottom: 3%;
}

.symptomes_row {
  width: 95%;
  position: relative;
  left: 8%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.symptomes_question_checkbox {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 6px 15px;
  gap: 10px;
  background: #ebfcfd;
  border-radius: 5px;
  margin-right: 5%;
}

.symptome_checkbox {
  transform: scale(1.1);
}

.checkbox_label {
  font-family: 'Eastman Roman Trial';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #425169;
  position: relative;
  left: 4%;
}
</style>