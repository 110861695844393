<template>
  <b-container fluid class="content">

    <div v-show="show_loading_spinner==true" class="loading_section">
      <b-spinner variant="primary" label="Spinning"></b-spinner>
      <div class="loading_text">Envoi en cours...</div>
    </div>

    <b-row v-show="show_header==true" id="individual_form_header" class="individual_form_header">
      <b-col v-show="showCommandButtons" class="leaving_block" cols="3">
        <b-button
          id="leaving_button"
          variant="light"
          class="leaving_button"
          v-b-modal.leavingModal
          style="color: #FFFFFF !important;"
        >
          <b-icon
            class="chevron_left_icon"
            scale="1.15"
            font-scale="1"
            icon="chevron-left"
            variant="#fff"
          ></b-icon>
          <span class="leaving_button_text">Sortir</span>
        </b-button>
      </b-col>
      <b-col class="title_block" cols="6">
        <div class="title_infos">
          Formulaire individuel &nbsp;
          <span class="record_date">| {{this.datetime}} |</span>
          <span class="in_progress">&nbsp; En cours</span>
        </div>
        <div class="patient_num">
          <p>Patient N° {{this.epid}}</p>
        </div>
      </b-col>
      <b-col v-show="showCommandButtons" class="sending_block" cols="3">
        <b-button
          id="sendRecordBtn"
          ref="send_record_button"
          @click="handleEpidShowing()"
          variant="light"
          class="send_button"
          style="color: #FFFFFF !important;"
        >Envoyer</b-button>
      </b-col>
    </b-row>

    <b-modal id="leavingModal" hide-header hide-header-close hide-footer title="">
      <p class="leaving_message">En confirmant vous abandonnez la consultation et les données saisies seront supprimées.</p>

      <div class="actions">
        <b-button @click="continueConsulting()" class="cancel_button shadow-none">Annuler</b-button>
        <b-button @click="abortConsulting()" class="confirm_button shadow-none">Valider</b-button>
      </div>
    </b-modal>


    <b-modal id="sampleEpidModal" hide-header hide-header-close hide-footer title="">
      <p class="sample_epid_message">Numéro Epid du prélèvement fait : <span class="Epid_number">{{this.epid}}</span> </p>

      <div class="actions">
        <b-button @click="continueSending()" class="confirm_button shadow-none">Terminer</b-button>
      </div>
    </b-modal>

    <div class="individual_form_fever_block" v-show="isConsulting==true">
      <b-col v-show="showPersonalInfosSection==true" class="ff_personal_info_section">
        <div class="personal_infos_body">
          <PersonalInfos />
        </div>
      </b-col>
      <b-col v-show="showSuspisionSection==true" class="ff_suspision_and_diagnostic_section">
        <div v-show="showSuspisionOnboardSection==true" class="suspision_and_diagnostic_onboard">
          <SuspisionAndDiagnosticOnboard />
        </div>

        <div v-show="showSuspisionOnboardSection==false && showDiagnosticAndPrescriptionSection==true"
          class="supision_and_diagnostic_infos_body"
        >
          <DiagnosticAndPrescription />
        </div>
        <div
          v-show="showSuspisionOnboardSection==false && showSuspisionAndDiagnosticInfos==true"
          class="supision_and_diagnostic_infos_body"
        >
          <SuspisionAndDiagnostic />
        </div>
      </b-col>
    </div>
  </b-container>
</template>

<script>
import { mapState } from 'vuex';
// import { eventBus } from '@/main.js';

// import HealerInformations from '@/views/HealerInformations.vue';
import PersonalInfos from '@/views/PersonalInfos.vue';
import DiagnosticAndPrescription from '@/components/fiche-individuelle/suspicions-diagnostics/DiagnosticAndPrescription.vue';
import SuspisionAndDiagnostic from '@/views/SuspicionsInfos.vue';
import SuspisionAndDiagnosticOnboard from '@/components/fiche-individuelle/suspicions-diagnostics/Onboard.vue';

export default {
  name: 'IndividualRecord',
  components: {
    // HealerInformations,
    PersonalInfos,
    DiagnosticAndPrescription,
    SuspisionAndDiagnosticOnboard,
    SuspisionAndDiagnostic
  },
  data: () => ({
    datetime: '',
    show_header: false,
    show_loading_spinner: false
  }),
  mounted() {
    this.generateEpid();
    this.getCurrentDateTime();
    this.startConsulting();

      setTimeout(() => {
        this.show_header=true;
      }, 200);

    const button = document.getElementById('sendRecordBtn');
    
    button.addEventListener('click', () => {
      
      button.disabled = true;
      setTimeout(() => {
      button.disabled = false;

      }, 2000);
    });
  },
  computed: {
    ...mapState({
      isFeverForm: 'isFeverForm',
      isConsulting: 'isConsulting',
      showHealerSection: 'showHealerSection',
      showPersonalInfosSection: 'showPersonalInfosSection',
      showSuspisionSection: 'showSuspisionSection',
      showSuspisionOnboardSection: 'showSuspisionOnboardSection',
      showSuspisionAndDiagnosticInfos: 'showSuspisionAndDiagnosticInfos',
      showDiagnosticAndPrescriptionSection:
        'showDiagnosticAndPrescriptionSection',
      showSymptomalogieSection: 'showSymptomalogieSection',
      showConductSection: 'showConductSection',
      personalInfosCategories: 'personalInfosCategories',
      personalInfosSubCategories: 'personalInfosSubCategories',
      showCommandButtons: 'showCommandButtons',
      suspisionAndDiagnosticCategories: 'suspisionAndDiagnosticCategories',
      showSuspisionSidebar: 'showSuspisionSidebar',

      //informations générales
      consulting_date: 'consulting_date',
      user: 'user',
      epid: 'epid',
      no_registre: 'no_registre',

      //informations personnelles
      prenom: 'prenom',
      nom: 'nom',
      date_de_naissance: 'date_de_naissance',
      genre: 'genre',
      age_year: 'age_year',
      age_month: 'age_month',
      femme_enceinte: 'femme_enceinte',
      age_gestationnel: 'age_gestationnel',
      telephone1_patient: 'telephone1_patient',
      profession: 'profession',
      adresse_patient: 'adresse_patient',
      statut_matrimonial: 'statut_matrimonial',

      //diagnostic et prescription
      fievre: 'fievre',
      diarrhee: 'diarrhee',
      temperature_fievre: 'temperature_fievre',
      duree_fievre: 'duree_fievre',
      diarrhee_sanglante: 'diarrhee_sanglante',
      traitement_prescrit: 'traitement_prescrit',
      antibiotiques: 'antibiotiques',
      antipaludiques: 'antipaludiques',
      antipyretiques: 'antipyretiques',
      antihistaminiques: 'antihistaminiques',
      broncodilatateurs: 'broncodilatateurs',
      autres_traitements_prescrits: 'autres_traitements_prescrits',

      //paludisme
      tdr_palu: 'tdr_palu',
      resultat_tdr_palu: 'resultat_tdr_palu',

      //syndrome grippal
      tdr_covid: 'tdr_covid',
      resultat_covid_19: 'resultat_covid_19',
      tdr_covid_antigene_result: 'tdr_covid_antigene_result',
      tdr_covid_anticorps_result: 'tdr_covid_anticorps_result',
      igm_positif: 'igm_positif',
      iga_positif: 'iga_positif',
      igg_positif: 'igg_positif',

      //covid 19
      anorexie: 'anorexie',
      cephalees: 'cephalees',
      dyspnee: 'dyspnee',
      ecoulement_nasal: 'ecoulement_nasal',
      nausees: 'nausees',
      perte_odorat: 'perte_odorat',
      perte_de_gout: 'perte_de_gout',
      mal_de_gorge: 'mal_de_gorge',
      toux: 'toux',
      vomissement: 'vomissement',

      //arboviroses : NB ajouter cephalees
      arthralgies: 'arthralgies',
      eruption_cutanee: 'eruption_cutanee',
      douleurs_retro_orbitaires: 'douleurs_retro_orbitaires',
      myalgies: 'myalgies',
      manifestations_hemorragiques: 'manifestations_hemorragiques',

      //autres signes
      convulsion: 'convulsion',
      frisson: 'frisson',
      stridor: 'stridor',
      tirage_sous_costal: 'tirage_sous_costal',
      ictere: 'ictere',
      raideur_nuque: 'raideur_nuque',
      anemie_clinique: 'anemie_clinique',
      refus_de_teter: 'refus_de_teter',
      splenomegalie: 'splenomegalie',
      autres_symptomes: 'autres_symptomes',

      //facteurs de risque
      diabete: 'diabete',
      asthme: 'asthme',
      drepanocytose: 'drepanocytose',
      hta: 'hta',
      malnutrition: 'malnutrition',
      obesite: 'obesite',
      pneumopathie_chronique: 'pneumopathie_chronique',
      autres_risques: 'autres_risques',

      //vaccins
      vaccination_fj: 'vaccination_fj',
      date_vaccin_fj: 'date_vaccin_fj',
      etat_vaccin_fj: 'etat_vaccin_fj',
      etat_vaccin_covid: 'etat_vaccin_covid',
      vaccination_covid: 'vaccination_covid',
      type_vaccin: 'type_vaccin',
      first_dose_date: 'first_dose_date',
      second_dose_date: 'second_dose_date',
      third_dose_date: 'third_dose_date',
      voyage_15jrs: 'voyage_15jrs',
      lieu_voyage: 'lieu_voyage',
      date_voyage: 'date_voyage',

      //tests_et_prelevements
      prelevement: 'prelevement',
      prelevement_urgent: 'prelevement_urgent',
      type_prelevement: 'type_prelevement',
      date_prelevement: 'date_prelevement',

      //symptomalogie
      symptomalogie_entourage: 'symptomalogie_entourage',

      //conduite tenue
      conduite_tenue: 'conduite_tenue',

      individual_records_number: 'individual_records_number'
    })
  },
  methods: {
    abortConsulting(){
      this.$store.commit('setIsConsulting', false);
      // this.$store.commit('setShowHealerSection', true);
      this.$store.commit('setShowSuspisionOnboardSection', false);
      this.$store.commit('setShowDiagnosticAndPrescriptionSection', false);
      this.$store.commit('setShowSuspisionAndDiagnosticInfos', false);
      this.$store.commit('setShowPersonalInfosSection', true);
      this.$store.commit('setFemmeEnceinte', 'Non');
      this.$store.commit('setFemmeEnceinte', 'Non');
      this.$store.dispatch('resetVariables');
      const toPath = this.$route.query.to || '/home';
      this.$router.push(toPath).catch(() => {});
    },
    continueConsulting(){
      this.$bvModal.hide("leavingModal");
    },
    handleEpidShowing(){
      if(this.prelevement=='Oui'){
        this.$bvModal.show("sampleEpidModal");
      }else{
        this.sendRecord();
      }
    },
    continueSending(){
      this.$bvModal.hide("sampleEpidModal");
      this.sendRecord();
    },
    async generateEpid() {
      let nom_site = localStorage.getItem('nom_site');

      await this.axios
        .post('/api/generate-epid/', {
          nom_site: nom_site,
        })
        .then(response => {
          this.$store.commit('setEpid', response.data.epid);
        })
        .catch(error => {
          if (error.response) {
            console.log(JSON.stringify(error));
          }
        });
    },
    getAge(birthdate) {
      // Get the current date
      var today = new Date();

      // Split the birthdate into its component parts
      var birthdateParts = birthdate.split("-");
      var birthyear = parseInt(birthdateParts[0], 10);
      var birthmonth = parseInt(birthdateParts[1], 10);
      var birthday = parseInt(birthdateParts[2], 10);

      // Create a new date object for the person's birthdate
      var birthdateObj = new Date(birthyear, birthmonth - 1, birthday);

      // Get the difference between the current date and the person's birthdate, in milliseconds
      var ageInMilliseconds = today - birthdateObj;

      // Convert the difference from milliseconds to years
      var ageInYears = ageInMilliseconds / (365.25 * 24 * 60 * 60 * 1000);
      
      // Round age to the nearest whole number
      return Math.round(ageInYears);

    },
    async sendRecord() {

      this.show_header=false;

      this.show_loading_spinner=true;

      this.$store.commit("setIsConsulting",false);
      this.errors = [];

      let sending_date = this.formatDate(
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10)
      );
      
      let age;
      let month;
      let date_naissance;

      if(this.age_year=="" && this.date_de_naissance!="" && this.age_month==""){
        age=this.getAge(this.date_de_naissance);
      }else{
        age = this.age_year;
      }

      if(this.age_month==""){
        month=0;
      }else{
        month=this.age_month;
      }

      if(this.age_month=="" && this.age_year==""){
        date_naissance = this.date_de_naissance;
      }else{
        date_naissance = null;
      }

      const formData = {
        epid: this.epid,
        no_registre: this.no_registre,
        agent: { nom_utilisateur: this.user.nom_utilisateur },
        date_consultation: sending_date,
        date_envoie: sending_date,
        informations_personnelles: {
          civilite: {
            prenom: this.prenom,
            nom: this.nom,
            date_de_naissance: date_naissance,
            genre: this.genre,
            age_en_annee: age,
            age_en_mois: month,
            femme_enceinte: this.femme_enceinte,
            age_gestationnel: this.age_gestationnel
          },
          adresse_et_contact: {
            telephone1_patient: this.telephone1_patient,
            profession: this.profession,
            adresse_patient: this.adresse_patient
          },
          statut_matrimonial: {
            statut_matrimonial: this.statut_matrimonial
          }
        },
        suspicion_et_diagnostic: {
          diagnostic_et_prescription: {
            fievre: this.fievre,
            temperature_fievre: this.temperature_fievre,
            duree_fievre: this.duree_fievre
          },
          paludisme: {
            tdr_palu: this.tdr_palu,
            resultat_tdr_palu: this.resultat_tdr_palu
          },
          syndrome_grippal_covid19: {
            anorexie: this.anorexie,
            cephalees: this.cephalees,
            dyspnee: this.dyspnee,
            ecoulement_nasal: this.ecoulement_nasal,
            nausees: this.nausees,
            perte_odorat: this.perte_odorat,
            perte_de_gout: this.perte_de_gout,
            mal_de_gorge: this.mal_de_gorge,
            toux: this.toux,
            vomissement: this.vomissement,
            tdr_covid: this.tdr_covid,
            tdr_covid_resultat_antigenes: this.tdr_covid_antigene_result,
            tdr_covid_resultat_anticorps: this.tdr_covid_anticorps_result,
            igm_positif: this.igm_positif,
            iga_positif: this.iga_positif,
            igg_positif: this.igg_positif
          },
          arboviroses: {
            cephalees: this.cephalees,
            arthralgies: this.arthralgies,
            eruption_cutanee: this.eruption_cutanee,
            douleurs_retro_orbitaires: this.douleurs_retro_orbitaires,
            myalgies: this.myalgies,
            manifestations_hemorragiques: this.manifestations_hemorragiques
          },
          autres_syndromes: {
            diarrhee: this.diarrhee,
            diarrhee_sanglante: this.diarrhee_sanglante
          },
          autres_signes: {
            convulsions: this.convulsion,
            frissons: this.frisson,
            stridor: this.stridor,
            tirage_sous_costal: this.tirage_sous_costal,
            ictere: this.ictere,
            raideur_nuque: this.raideur_nuque,
            anemie_clinique: this.anemie_clinique,
            refus_de_teter: this.refus_de_teter,
            splenomegalie: this.splenomegalie,
            autres_symptomes: this.autres_symptomes
          },
          facteurs_de_risque: {
            diabete: this.diabete,
            asthme: this.asthme,
            drepanocytose: this.drepanocytose,
            hta: this.hta,
            malnutrition: this.malnutrition,
            obesite: this.obesite,
            pneumopathie_chronique: this.pneumopathie_chronique,
            autres_risques: this.autres_risques
          },
          vaccins: {
            vaccination_fievre_jaune: this.vaccination_fj,
            date_vaccin_fievre_jaune: this.date_vaccin_fj,
            infos_vaccin_fj: this.etat_vaccin_fj,
            infos_vaccin_covid: this.etat_vaccin_covid,
            vaccination_covid: this.vaccination_covid,
            type_vaccin_covid: this.type_vaccin,
            date_vaccination_premiere_dose: this.first_dose_date,
            date_vaccination_deuxieme_dose: this.second_dose_date,
            date_vaccination_troisieme_dose: this.third_dose_date
          },
          notion_de_voyage: {
            voyage_15jrs:this.voyage_15jrs,
            lieu_voyage: this.lieu_voyage,
            date_voyage: this.date_voyage
          },
          prelevement: {
            prelevement_fait: this.prelevement,
            prelevement_urgent: this.prelevement_urgent,
            type_prelevement: this.type_prelevement,
            date_prelevement: this.date_prelevement
          },
          traitements: {
            traitement_prescrit: this.traitement_prescrit,
            antibiotiques: this.antibiotiques,
            antipaludiques: this.antipaludiques,
            antipyretiques: this.antipyretiques,
            antihistaminiques: this.antihistaminiques,
            broncodilatateurs: this.broncodilatateurs,
            autres_traitements_prescrits: this.autres_traitements_prescrits
          }
        },
        symptomalogie_entourage: this.symptomalogie_entourage,
        conduite_tenue: this.conduite_tenue
      };


      await this.axios
        .post('/api/fiche-fievre/', formData)
        .then(response => {
          console.log(response.data);

          this.$store.dispatch('resetVariables');

          //redirection to success page
          let to = this.$route.query.to || '/success';
          this.$router.push(to).catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error;
            }
          });

          //wait 3s before redirecting to home page
          setTimeout(() => {
            let to = this.$route.query.to || '/home';
            this.$router.push(to).catch(() => {});
            this.$store.commit('setIsConsulting', false);
            this.$store.commit('setShowHealerSection', true);
            this.$store.commit('setShowSuspisionOnboardSection', false);
            this.$store.commit(
              'setShowDiagnosticAndPrescriptionSection',
              false
            );
            this.$store.commit('setShowSuspisionAndDiagnosticInfos', false);
            this.$store.commit('setShowPersonalInfosSection', true);
          }, 3000);

          this.show_loading_spinner=false;


        })
        .catch(error => {
          if (error.response) {
            for (const property in error.response.data) {
              this.errors.push(`${property}: ${error.response.data[property]}`);
            }
          } else {
            this.errors.push(
              'Quelque chose ne marche pas. Veuillez réessayer encore.'
            );
            console.log(JSON.stringify(error));
          }

          this.show_loading_spinner=false;

          this.$store.commit('setIsConsulting', false);
          const toPath = this.$route.query.to || '/home';
          this.$router.push(toPath).catch(() => {});
          this.$store.commit('setShowSuspisionOnboardSection', false);
          this.$store.commit('setShowDiagnosticAndPrescriptionSection', false);
          this.$store.commit('setShowSuspisionAndDiagnosticInfos', false);
          this.$store.commit('setShowPersonalInfosSection', true);
          this.$store.commit('setFemmeEnceinte', 'Non');
          this.$store.dispatch('resetVariables');
        });
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${year}-${month}-${day}`;
    },
    getCurrentDateTime() {
      var currentdate = new Date();
      this.datetime =
        currentdate.getDate() +
        '-' +
        (currentdate.getMonth() + 1) +
        '-' +
        currentdate.getFullYear() +
        ' , ' +
        currentdate.getHours() +
        ':' +
        currentdate.getMinutes() +
        ':' +
        currentdate.getSeconds();
    },
    async startConsulting() {
      await new Promise(resolve => setTimeout(resolve, 200));
      this.$store.commit('setIsConsulting', true);
      // this.$store.commit('setShowHealerSection', false);
      this.$store.commit('setShowPersonalInfosSection', true);
      document.getElementById('individual_form_header').style.height = '18%;';
    },
    goToRecapSection() {
      if (this.isConsulting == true) {
        this.$router.push('/form-recap').catch(() => {});
      }
    }
  }
};
</script>

<style scoped>
.content {
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0;
  overflow-x: scroll;
  background: linear-gradient(0deg, #eff2f7, #eff2f7),
    linear-gradient(180deg, #ffffff 61.09%, #eefbff 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
}

.individual_form_header {
  width: 100%;
  margin: 0;
  height: 15%;
  padding-top: 10px;
  background: #ffffff !important;
  overflow-y: hidden;
}

.title_block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.leaving_block {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.sending_block {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.title_infos {
  margin-top: 2%;
  display: flex;
  font-family: 'Eastman Roman Trial';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: #0d1c2e;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.record_date {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #425169;
  mix-blend-mode: normal;
  flex: none;
  order: 2;
  flex-grow: 0;
}

.in_progress {
  font-family: 'Eastman Roman Trial';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #484ef7;
  mix-blend-mode: normal;
  flex: none;
  order: 4;
  flex-grow: 0;
}

.patient_num {
  margin-top: 2%;
  display: flex;
  font-family: 'Eastman Roman Trial';
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 27px;
  color: #425169;
}

.command_buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.leaving_button {
  box-sizing: border-box !important;
  background: #ff6161 !important;
  border-radius: 5px;
  height: 50%;
  text-align: right;
  width: 40%;
  padding-left: 15px;
  box-shadow: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-family: 'Eastman Trial';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
}

.leaving_button:active {
  background-color: #ff6161 !important;
  border: none !important;
  box-shadow: none !important;
  color: #ffffff !important;
}

.leaving_button:hover {
  background-color: #ff6161 !important;
  border: none !important;
  box-shadow: none !important;
  color: #ffffff !important;
}

.leaving_button_text {
  position: relative;
  left: 10%;
  font-size: 1.05em;
}

.send_button:active {
  background-color: #3cb691;
  opacity: 0.8;
}

.send_button {
  box-sizing: border-box;
  background: #3cb691 !important;
  border-radius: 5px;
  height: 50%;
  width: 40%;
  color: #ffffff !important;
  margin-left: 6%;
  margin-right: 2%;
  font-family: 'Eastman Trial';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
}

.chevron_left_icon {
  position: relative;
  right: 5%;
  transform: scale(0.8);
}

.healer_block {
  margin-top: 2.5%;
  height: 75%;
  width: 80%;
  background: #ffffff;
  border-radius: 10px;
}

.individual_form_fever_block {
  height: 80%;
  width: 100%;
  background: linear-gradient(0deg, #eff2f7, #eff2f7),
    linear-gradient(180deg, #ffffff 61.09%, #eefbff 100%);
  margin-top: 0 !important;
  padding: 0px !important;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

.ff_nav {
  margin: 0 !important;
  width: 100%;
  padding: 0;
}

.ff_personal_info_section,
.ff_suspision_and_diagnostic_section {
  margin: 0 !important;
  background: linear-gradient(0deg, #eff2f7, #eff2f7),
    linear-gradient(180deg, #ffffff 61.09%, #eefbff 100%);
  padding: 15px;
  display: flex;
}

.sidebar {
  width: 25%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}

.personal_infos_body {
  width: 100%;
  margin-left: 2%;
  background: #ffffff;
  border-radius: 10px;
  height: 99%;
  overflow-y: scroll;
}

.supision_and_diagnostic_infos_body {
  width: 100%;
  margin-left: 2%;
  background: #ffffff;
  border-radius: 10px;
  height: 100%;
  overflow-y: scroll;
}

.suspision_and_diagnostic_onboard {
  width: 100%;
  overflow-y: scroll;
}

.modify_icon {
  position: relative;
  right: 85%;
}

.modify_label {
  position: relative;
  left: 25%;
}

#btn_modify {
  background-color: #ffffff;
  color: #000000;
  text-align: right;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border: 1px solid #425169;
  border-radius: 5px;
  padding: 10px 10px;
}

#btn_modify:active {
  transform: scale(0.95);
}

#leavingModal{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 40px;
  gap: 10px;
  background: #0D1C2E;
  border-radius: 20px;
}

#sampleEpidModal{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 40px;
  gap: 10px;
  background: #0D1C2E;
  border-radius: 20px;
}

.leaving_message{
  font-family: 'Eastman Trial';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  color: #425169;
  text-align: center;
}

.sample_epid_message{
  font-family: 'Eastman Trial';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  color: #425169;
  text-align: center;
}

.Epid_number{
  font-family: 'sans-serif';
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 18px;
  color: #3cb691;
  text-align: center;
}

.actions{
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.cancel_button{
  background: #FF6161 !important;
  color: #FFFFFF;
  border: None;
  margin-right: 2%;
}

.cancel_button:active{
  transform: scale(0.95);
}

.confirm_button{
  background: #3CB691 !important;
  color: #FFFFFF;
  border: None;
  margin-left: 2%;
}

.confirm_button:active{
  transform: scale(0.95);
}

.loading_section{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 10%;
    left: 37%;
    margin-top: 4%;
    width: 25%;
}

.loading_text{
    margin-left: 0.5%;
    font-family: 'Eastman Trial';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 14px;
    color: #425169;
}

</style>
