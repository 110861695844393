<template>
  <div class="total_bar">
    <div
      class="current_progress"
      v-bind:style="{minHeight:'10px',backgroundColor:'#575CE5', opacity:0.9, borderRadius:'2px', width:val+'%'}"
    ></div>
  </div>
</template>

<script>
export default {
  name: "ProgressBar",
  props: {
    val: Number
  },
  mounted() {},
  methods: {
    setValue() {}
  }
};
</script>

<style scoped>
.total_bar {
  width: 100%;
  height: 10px;
  margin-top: 3%;
  border-radius: 2px;
  background: #84c2d8;
}
</style>