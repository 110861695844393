<template>
  <b-container
    class="charts_content"
  >

    <div class="bar-chart">
      <div class="header">

        <div class="view_by header_item">
          <span class="view_by_item view_item_label">Vue par</span>
          <div @click="toggleViewPerWeek()" class="view_by_item view_by_week">Semaine</div>
          <div @click="toggleViewPerDay()"  class="view_by_item view_by_day">Jour</div>
        </div>

        <div class="switch_chart header_item">

          <div @click="toggleLineChart()" class="line chart_elt">
              <img v-show="show_line_chart==false" class="line_img" src="@/assets/line.svg" alt="line chart" />
              <img v-show="show_line_chart==true" class="line_img" src="@/assets/line_selected.svg" alt="line chart" />
          </div>

          <div @click="toggleBarChart()" class="bar chart_elt">
              <img v-show="show_bar_chart==false" class="line_img" src="@/assets/bar.svg" alt="line chart" />
              <img v-show="show_bar_chart==true" class="bar_img" src="@/assets/bar_selected.svg" alt="bar chart" />
          </div>
          
        </div>


        <div class="header_item">

            <b-dropdown no-caret variant="#FFFFFF" id="grouping_dropdown" ref="dropdown" class="m-md-2">
              <template #button-content>
              {{group_by}}
              <img class="arrow_up_icon" src="@/assets/arrow_up.svg" alt="regrouper par" />
              </template>
                  <b-form-radio class="radio grouping_item" v-model="group_by" name="group_by" value="sexe"> 
                    <span class="grouping_choice_label">Sexe</span>
                  </b-form-radio>
                
                  <b-form-radio class="radio grouping_item" v-model="group_by" name="group_by" value="age"> 
                    <span class="grouping_choice_label">Age</span>
                  </b-form-radio>

            </b-dropdown>
          
        </div>

        <!-- <div class="excel header_item">
          <img class="export_icon" src="@/assets/export.svg" alt="exporter le graphe" />
        </div> -->

        <div class="print header_item">
          <img class="print_icon" src="@/assets/print.svg" alt="imprimer le graphe" />
        </div>

      </div>

    <div class="charts">
          <img v-show="show_carousel_right_btn==true && show_bar_chart==true" @click="goNextBarSlide()" id="next-btn" class="carousel_icon_right" src="@/assets/carousel_arrow_right.svg" alt="précédent" />
          <img v-show="show_carousel_left_btn==true && show_bar_chart==true" @click="goPrevBarSlide()" id="prev-btn" class="carousel_icon_left" src="@/assets/carousel_arrow_left.svg" alt="suivant" />
          <img v-show="show_carousel_right_btn==true && show_line_chart==true" @click="goNextLineSlide()" id="next-btn" class="carousel_icon_right" src="@/assets/carousel_arrow_right.svg" alt="précédent" />
          <img v-show="show_carousel_left_btn==true && show_line_chart==true" @click="goPrevLineSlide()" id="prev-btn" class="carousel_icon_left" src="@/assets/carousel_arrow_left.svg" alt="suivant" />

      <!-- bar chart carousel -->
      <div id="bar_chart_carousel" v-show="show_bar_chart==true" class="bar_chart">
        <div v-for="(bar_chart, index) in bar_chart_list" :key="index" class="bar_chart_item">
          <Bar
            ref="barChart"
            :key="barChartKey"
            :data="bar_chart.data"
            :options="bar_chart.options"
          />
        </div>
      </div>


      <div id="line_chart_carousel" v-show="show_line_chart==true" class="line_chart">
        <div v-for="(line_chart, index) in line_chart_list" :key="index" class="line_chart_item">
          <LineChart
            ref="lineChart"
            :key="lineChartKey"
            :data="line_chart.data"
            :options="line_chart.options"
          />
        </div>
      </div>

  </div>
    </div>


  </b-container>
</template>

<script>
import { mapState } from "vuex";
import { Bar, Line as LineChart } from "vue-chartjs";
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement,LineElement, CategoryScale, LinearScale, PointElement,Filler } from 'chart.js'
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, PointElement, LineElement,Filler)
import { eventBus } from '@/main.js';


export default {
  name: "Charts",
  components: {
    Bar,
    LineChart
  },
  data() {
    return {
      show_line_chart : false,
      show_bar_chart : true,
      group_by:'sexe',
      barChartKey: 0,
      lineChartKey: 0,
      currentBarItem : 0,
      currentLineItem : 0,
    };
  },
  watch:{
    group_by(val){
      this.$refs.dropdown.hide(true)
      this.$store.commit("setChartGroupBy",val);
    },
    bar_chart_list: {
      handler() {
        this.barChartKey += 1;
        if (this.bar_chart_list.length<=1){
          this.$store.commit("setShowCarouselRightBtn",false);
          this.$store.commit("setShowCarouselLeftBtn",false);
        }else{
          this.$store.commit("setShowCarouselRightBtn",true);
          this.$store.commit("setShowCarouselLeftBtn",true);     
        }
      },
      deep: true
    },
    line_chart_list: {
      handler() {
        this.lineChartKey += 1;
        if (this.line_chart_list.length<=1){
          this.$store.commit("setShowCarouselRightBtn",false);
          this.$store.commit("setShowCarouselLeftBtn",false);
        }else{
          this.$store.commit("setShowCarouselRightBtn",true);
          this.$store.commit("setShowCarouselLeftBtn",true);     
        }
      },
      deep: true
    },
    show_line_chart(val){
      // line chart carousel config
      if (val==true){
        let line_items = document.querySelectorAll(".line_chart_item");
        line_items[this.currentLineItem].classList.add("active");

        if (this.line_chart_list.length<=1){
            this.$store.commit("setShowCarouselRightBtn",false);
            this.$store.commit("setShowCarouselLeftBtn",false);
        }else{
            this.$store.commit("setShowCarouselRightBtn",true);
            this.$store.commit("setShowCarouselLeftBtn",true);     
        }
      }
    }
  },
  methods: {
    toggleViewPerWeek(){
      let target = document.querySelector(".view_by_week");
      let vpdElt = document.querySelector(".view_by_day");

      vpdElt.style.background="#F8F9FA";
      vpdElt.style.border="none";
      vpdElt.style.color="#6A778A";

      target.style.background="#FFFFFF";
      target.style.border="1px solid #979797";
      target.style.color="#484EF7";

      this.$store.commit("setChartView","semaine");

    },
    toggleViewPerDay(){
      let target = document.querySelector(".view_by_day");
      let vpwElt = document.querySelector(".view_by_week");

      vpwElt.style.background="#F8F9FA";
      vpwElt.style.border="none";
      vpwElt.style.color="#6A778A";

      target.style.background="#FFFFFF";
      target.style.border="1px solid #979797";
      target.style.color="#484EF7";

      this.$store.commit("setChartView","jour");
    },
    unfocusChartItem(elt){

      elt.style.boxSizing = "none";
      elt.style.background = "#F8F9FA";
      elt.style.border = "none";
      elt.style.borderRadius = "0px";
      elt.style.padding = "0px";

    },
    toggleLineChart(){

      let target = document.querySelector(".line");
      let barChartElt = document.querySelector(".bar");

      target.style.boxSizing = "border-box";
      target.style.background = "#FFFFFF";
      target.style.border = "1px solid #979797";
      target.style.borderRadius = "5px";
      target.style.padding = "5px";

      this.unfocusChartItem(barChartElt);

      this.show_line_chart = true;
      this.show_bar_chart = false;
      
  },
  toggleBarChart(){

      let target = document.querySelector(".bar");

      target.style.boxSizing = "border-box";
      target.style.background = "#FFFFFF";
      target.style.border = "1px solid #979797";
      target.style.borderRadius = "5px";
      target.style.padding = "5px";

      let lineChartElt = document.querySelector(".line");

      this.unfocusChartItem(lineChartElt);

      this.show_line_chart = false;
      this.show_bar_chart = true;
    },
    updateBarChart() {
      this.barChartKey+=1;
    },
    updateLineChart() {
      this.lineChartKey+=1;
    },
    goPrevBarSlide(){
      let items = document.querySelectorAll(".bar_chart_item");
      items[this.currentBarItem].classList.remove("active");
      this.currentBarItem--;
      if (this.currentBarItem < 0) {
        this.currentBarItem = items.length - 1;
      }
      items[this.currentBarItem].classList.add("active");
    },
    goNextBarSlide(){
      let items = document.querySelectorAll(".bar_chart_item");
      items[this.currentBarItem].classList.remove("active");
      this.currentBarItem++;
      if (this.currentBarItem >= items.length) {
        this.currentBarItem = 0;
      }
      items[this.currentBarItem].classList.add("active");
    },
    goPrevLineSlide(){
      let items = document.querySelectorAll(".line_chart_item");
      items[this.currentLineItem].classList.remove("active");
      this.currentLineItem--;
      if (this.currentLineItem < 0) {
        this.currentLineItem = items.length - 1;
      }
      items[this.currentLineItem].classList.add("active");
    },
    goNextLineSlide(){
      let items = document.querySelectorAll(".line_chart_item");
      items[this.currentLineItem].classList.remove("active");
      this.currentLineItem++;
      if (this.currentLineItem >= items.length) {
        this.currentLineItem = 0;
      }
      items[this.currentLineItem].classList.add("active");
    }

  },
  computed: {
    ...mapState({
      bar_chart_list : "bar_chart_list",
      line_chart_list : "line_chart_list",
      show_carousel_left_btn: "show_carousel_left_btn",
      show_carousel_right_btn: "show_carousel_right_btn"
    })
  },
  mounted() {
    let self = this;

    let grouping_items = document.querySelectorAll(".grouping_item");

    for (let i = 0; i < grouping_items.length; i++) {
      const grouping_item = grouping_items[i];

      grouping_item.addEventListener("click", function () {
          self.$refs.dropdown.hide(true)
      })
      
    }

    eventBus.$on('updateBarChart', () => {
      this.updateBarChart();
    });

    eventBus.$on('updateLineChart', () => {
      this.updateLineChart();
    });


    // bar chart carousel config
    if (this.show_bar_chart==true){
      let bar_items = document.querySelectorAll(".bar_chart_item");

      bar_items[this.currentBarItem].classList.add("active");

      if (this.bar_chart_list.length<=1){
          this.$store.commit("setShowCarouselRightBtn",false);
          this.$store.commit("setShowCarouselLeftBtn",false);
      }else{
          this.$store.commit("setShowCarouselRightBtn",true);
          this.$store.commit("setShowCarouselLeftBtn",true);     
      }
    }
  },
};
</script>

<style scoped>

*{
  cursor: pointer;
}

.charts_content{
  display: flex;
  justify-content: center;
  align-items: center ;
  width: 60%;
}

.bar-chart{
  margin-top: 4%;
  width: 100%;
  box-sizing: border-box;
  background: #F8F9FA;
  border: 1px solid #DADADA;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}

.header{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 2%;
  padding: 5px;
  padding-top: 15px;
}

.view_by{
  width: 25%;
}

.view_by_item{
  margin-left: 8%;
}

.view_item_label{
  font-family: 'Eastman Roman Trial';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 14px;
  color: #6A778A;
}


.view_by_week, 
.view_by_day{
  font-family: 'Eastman Roman Trial';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #6A778A;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 8px;
}

.view_by_week{
  background: #FFFFFF;
  border: 1px solid #979797;
  color: #484EF7;
}

.header_item{
  display: flex;
  align-items: center;
}

.switch_chart{
  width: 10%;
  margin-left: 5%;
}

.chart_elt{
  margin-left: 3%;
  margin-right: 3%;
}

.bar{
  box-sizing: border-box;
  background: #FFFFFF;
  border: 1px solid #979797;
  border-radius: 5px;
  padding: 5px;
}

.line{
  box-sizing: border-box;
  border-radius: 5px;
  padding: 5px;
}

#grouping_dropdown{
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  background: #FFFFFF !important;
  border: 1px solid #979797;
  border-radius: 5px;
  width: 100%;
  }

.dropdown-menu {
   padding: 10px;
}

.current_value{
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 17px;
  color: #6A778A;
  width: 100%;
}

.arrow_up_icon{
  margin-left:5%;
}

.arrow_down_icon{
  margin-left:8%;
}

.excel{
  position: relative;
  right: 5%;
  box-sizing: border-box;
  background: #FFFFFF;
  border: 1px solid #979797;
  border-radius: 5px;
  padding: 4px;
  height: 60%;
  margin-top: 0.8%;
}

.excel:active{
  transform: scale(0.95);
}

.print{
  position: relative;
  right: 10%;
  box-sizing: border-box;
  background: #FFFFFF;
  border: 1px solid #979797;
  border-radius: 5px;
  padding: 4px;
  height: 60%;
  margin-top: 0.4%;
  transform: scale(0.8);
}

.print:active{
  transform: scale(0.75);
}

.dropdown_content{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 14px;
  position: absolute;
  bottom: -20%;
  width: 12%;
  background: #FFFFFF;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.dropdown_body{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.grouping_choice_label{
  font-family: 'Eastman Roman Trial';
  font-style: normal;
  font-weight: 500;
  font-size: 16.5px;
  line-height: 14px;
  color: #425169;
}

.age_choice_label{
  font-family: 'Eastman Roman Trial';
  font-style: normal;
  font-weight: 500;
  font-size: 16.5px;
  line-height: 14px;
  color: #425169;
}

.age_inputs{
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom_age_min_input{
  width: 38%;
  margin-right: 4%;
}

.custom_age_max_input{
   width: 38%; 
   margin-left: 4%;
}

.input_separator{
  border: 1px solid #000000;
  width: 5%;
  height: 0px;
}

.dropdown_divider{
  width: 90%;
  height: 0px;
  border: 1px solid #D9D9D9;
  margin-left: 4%;
  margin-top: 8%;
  margin-bottom: 5%;
}

.refresh_button{
  background: #FFFFFF !important;
  opacity: 0.5 !important;
  border: 1px solid #979797 !important;
  border-radius: 5px;
  transform: scale(0.7);
  margin-left: 2%;
}

.refresh_button:active{
  transform: scale(0.65);
}

.show_button{
  background: #484EF7 !important;
  border: 1px solid #979797 !important;
  border-radius: 5px;
  font-family: 'Eastman Roman Trial';
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 18px;
  text-align: center;
  color: #FFFFFF;
  transform: scale(1.1);
  margin-left: 10%;
  }

.show_button:active{
  transform: scale(1.05);
}

.custom_age_item{
  margin-top: 1%;
  margin-bottom: 2%;
}

.grouping_item{
  margin-top: 1%;
  margin-bottom: 2%;
  position: relative;
  left: 10%;
}

.carousel_icon_right{
  position: relative;
  left: 53%;
  z-index: 99999999;
  margin-top: -5%;

}

.carousel_icon_left{
  position: relative;
  right: 53%;
  z-index: 99999999;
  margin-top: -5%;
}

.carousel_icon_right:active{
  transform: scale(0.95);
  opacity: 0.9;
}

.carousel_icon_left:active{
  transform: scale(0.95);
  opacity: 0.9;
}

#bar_chart_carousel,
#line_chart_carousel{
  overflow: hidden;
}

.bar_chart_item:nth-child(2n),
.line_chart_item:nth-child(2n){
  width: 100%;
}

.bar_chart_item.active,
.line_chart_item.active{
  display: block;
}

.bar_chart_item:not(.active),
.line_chart_item:not(.active) {
  display: none;
}
.charts{
  min-height: 45vh;
}
</style>
