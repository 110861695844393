<template>
  <div class="searchContainer">
    <div v-show="show_spinner==true" style="margin-top:4%;" class="spinner text-center">
      <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner>
    </div>
    <div id="search" class="search">
      <input
        class="search_input"
        placeholder="Rechercher N° Epid"
        ref="input"
        readonly
        @click="openSearchModal"
      />

      <b-icon class="searchButton" icon="search" variant="dark"></b-icon>
    </div>

    <b-modal
      @shown="focus"
      body-class="p-0"
      content-class="search-modal"
      ref="search_modal"
      hide-header
      centered
      hide-footer
      id="search-modal"
    >
      <b-form @submit.prevent style="width:100%;">
        <b-form-row style="width:100%;">
          <input
            ref="modalInput"
            class="modal_search_input"
            placeholder="Saisissez le N° Epid du patient"
            v-model="searchedEpid"
            @keyup.enter="searchRecord"
          />

          <b-button @click="searchRecord" variant="light" class="modal_search_button">
            <b-icon font-scale="1.3" style="background-color:#ffffff;" icon="search" variant="dark"></b-icon>
          </b-button>
        </b-form-row>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { eventBus } from '@/main.js';

export default {
  name: 'SearchBar',
  components: {},
  data() {
    return {
      searchedEpid: '',
      show_spinner: false
    };
  },
  mounted() {
    let self = this;
    document.getElementById('search').addEventListener('click', function() {
      self.searchedEpid = '';
    });
  },
  methods: {
    searchRecord() {
      console.log(this.searchedEpid);
      if (this.searchedEpid != '') {
        this.show_spinner = true;
        this.$bvModal.hide('search-modal');
        this.axios
          .get('/api/fiche-fievre/?epid=' + this.searchedEpid)
          .then(response => {
            let record = response.data.results[0];
            console.log(record);
            if (response.data.results.length != 0) {
              setTimeout(this.hideSpinner, 1000);
              eventBus.$emit('showResult', record);
            } else {
              setTimeout(this.hideSpinner, 500);
              eventBus.$emit('showNoResult');
            }
            this.searchedEpid = '';
          })
          .catch(error => {
            setTimeout(this.hideSpinner, 500);
            this.searchedEpid = '';
            if (error.response) {
              console.log(error.response);
            } else {
              //TODO : gestion des erreurs
              console.log(JSON.stringify(error));
            }
          });
      } else {
        //TODO : gestion des erreurs
      }
    },

    hideSpinner() {
      this.show_spinner = false;
    },
    openSearchModal() {
      this.$bvModal.show('search-modal');
    },
    focus() {
      this.$refs.modalInput.focus();
    }
  },
  computed: {
    ...mapState({})
  }
};
</script>

<style>
.searchButton {
  position: relative;
  right: 15%;
  width: 5%;
  background-color: #ffffff;
  border: #ffffff;
}

.searchButton:hover {
  background-color: #ffffff;
}

.spinner {
  position: absolute;
  top: 102%;
  left: 50%;
}

.search_input {
  width: 92%;
  max-height: 45px;
  box-sizing: border-box;
  padding: 10px 10px 10px 20px;
  gap: 10px;
  left: 358px;
  top: 30px;
  background: #ffffff;
  border: 2px solid #425169;
  border-radius: 5px;
  max-height: 100%;
}

#search-modal {
  overflow: auto;
}
#search-modal > div > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1px 1px 1px 1px;
  background: #ffffff;
  border: 1px solid #979797;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.modal_search_input {
  margin-left: 4%;
  width: 85%;
  border: none;
  min-height: 55px;
  background: #ffffff;
  border-radius: 10px;
}

#search-modal > div > div .modal_search_button {
  background: #ffffff;
  border: none;
  box-shadow: none;
}

#search-modal > div > div .modal_search_button:active {
  transform: scale(0.9);
}
</style>