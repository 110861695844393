<template>
  <b-container fluid class="content">
    <Header :show_search="true" />
    <div class="home_content">
      <div class="aside_content">
        <div class="notification">
          <img style="user-select: none;" src="@/assets/bell.png" alt="Mes notifications" />
          <span style="user-select: none;" class="notification_text">Notifications</span>
          <span class="notification_number">{{this.notification_number}}</span>
        </div>

        <div @click="goToDashboard()" class="dashboard">
          <img style="user-select: none;" src="@/assets/icon_dashboard.png" alt="Dashboard 4s" />
          <span style="user-select: none;" class="dashboard_text">Dashboard</span>
        </div>

        <div @click="goToSampleResults()" class="sample_results">
          <img style="user-select: none;" class="sample_icn" src="@/assets/sample_icn.png" alt="résultats" />
          <span style="user-select: none;" class="sample_results_text">Résultats</span>
        </div>

        <div class="challenges">
          <div class="challenges_title_block">
            <span style="user-select: none;" class="challenge_title">Mes challenges</span>
            <img
              v-show="show_challenge_stack"
              class="challenge_stacks"
              src="@/assets/stacks.png"
              alt="challenges"
            />
          </div>
          <span class="challenges_desc">{{this.challenges[0]}}</span>
          <transition name="slide-fade" mode="out-in">
            <div v-show="show_challenge_box" class="challenge_box">
              <span style="user-select: none;" class="achieved">Challenges réussis</span>
              <div class="ranking">
                <img class="trophy" src="@/assets/trophy.png" alt="trophy" />

                <span style="margin-left:70%;" class="percentage">{{this.percentage_of_success}}%</span>

              </div>
              <ProgressBar :val="this.percentage_of_success" />
            </div>
          </transition>
          <div class="arrow_button">
            <b-button @click="showChallenges" variant="light" class="show_challenges_button">
              <b-icon
                :rotate="this.rotation_angle"
                class="chevron_up_icon"
                font-scale="1.3"
                style="background-color:#ffffff;"
                icon="chevron-up"
                variant="dark"
              ></b-icon>
            </b-button>
          </div>
        </div>
        <vs-button
          ref="logout_button"
          @click="logoutUser()"
          id="btn_logout"
          size="sm"
          class="logout"
        >
          Me déconnecter
          <img class="logout_arrow_icon" src="@/assets/logout_arrow.png" />
        </vs-button>
      </div>
      <div class="main_content">
        <span style="user-select: none;" class="latest_activity">Dernières activités</span>
        <div v-if="uncompleted_records.length" class="activities_block">
          <div
            class="patient_records"
            :key="i"
            v-for="(patient, i) in this.uncompleted_records.slice(0,2)"
            :data="patient"
          >
            <RecordOverview :patient="patient" />
          </div>
        </div>
        <div v-if="!uncompleted_records.length" class="activities_block_empty">
          <p style="user-select: none;" class="empty_records_text">Vous êtes à jour dans votre collecte.</p>
        </div>
        <div class="collect_rating">
          <img class="record_quality" src="@/assets/medical-report.png" />
          <div class="impression">
            <b-icon class="shield" font-scale="1" icon="shield-check" variant="success"></b-icon>
            <span class="great_work">Bon travail</span>
            <img class="icon_arm" src="@/assets/great_job.png" />
          </div>
        </div>
        <span style="user-select: none;" class="forms">Formulaires</span>
        <div class="forms_block">
          <div @click="goToIndividualForm()" class="individual_card">
            <div class="individual_card_img">
              <div class="individual_card_records_number">{{this.individual_records_number}}</div>
            </div>
            <span style="user-select: none;" class="record_title">Fiche fièvre individuelle</span>
          </div>
          <div class="agregated_card">
            <div class="agregated_card_img">
              <div class="agregated_card_records_number">{{this.agregated_records_number}}</div>
            </div>
            <span style="user-select: none;" class="record_title">Etat Récapitulatif Journalier</span>
          </div>
        </div>
      </div>
    </div>
  </b-container>
</template>


<script>
import { mapState } from 'vuex';
import ProgressBar from '@/components/fiche-individuelle/general/ProgressBar.vue';
import Header from '@/components/fiche-individuelle/general/Header.vue';
import RecordOverview from '@/components/fiche-individuelle/general/RecordOverview.vue';

export default {
  name: 'Home',
  components: {
    Header,
    ProgressBar,
    RecordOverview
  },
  async mounted() {
    this.$store.commit('initialize');

    this.getFormCount();

    setTimeout(() => {
      this.getFormCount();
    }, 3000);

  },
  methods: {
    async getFormCount(){
        await this.axios
      .post('/api/individual-form-count/', {
        identifiant_agent: this.user.nom_utilisateur
      })
      .then(response => {
        this.$store.commit(
          'setIndividualRecordsNumber',
          response.data.nombre_fiches
        );
      })
      .catch(error => {
        console.log(JSON.stringify(error));
      });
    },
    goToDashboard(){
      window.location.href = '/dashboard-communautaire';
    },
    goToSampleResults(){
      window.location.href = '/resultats-prelevements';
    },
    showChallenges() {
      this.nb_tap += 1;
      if (this.nb_tap == 1) {
        this.$store.commit('changeArrowAngle', 180);
        this.show_challenge_box = false;
        this.show_challenge_stack = true;
      } else if (this.nb_tap % 2 == 0) {
        this.$store.commit('changeArrowAngle', 0);
        this.show_challenge_box = true;
        this.show_challenge_stack = false;
      } else {
        this.$store.commit('changeArrowAngle', 180);
        this.show_challenge_box = false;
        this.show_challenge_stack = true;
      }
    },
    async logoutUser() {
      await this.axios
        .post('/api/logout/', { identifiant: this.user.nom_utilisateur })
        .then(() => {
          this.$vs.notification({
            duration: 500,
            color: '#484EF7',
            position: 'bottom-left',
            title: 'Au revoir',
            text: '',
            buttonClose: false
          });
          //redirecting
          setTimeout(() => {
            window.location.href = '/login';
            window.history.pushState(null, '', window.location.href);
            window.onpopstate = function() {
              window.history.pushState(null, '', window.location.href);
            };
          }, 800);
          let lastest_identifiant = this.$store.state.user.nom_utilisateur;
          //saving user informations
          localStorage.removeItem('user');
          localStorage.setItem('nom_utilisateur', lastest_identifiant);
          this.$store.commit('setIsAuthenticated', false);
          localStorage.removeItem('isAuthenticated');
          localStorage.removeItem('token');
          this.$store.commit('setToken', null);
        })
        .catch(error => {
            console.log(JSON.stringify(error));
        });
    },
    goToIndividualForm() {
      this.$store.commit("setIsConsulting",true);
      this.$router.push('/fiche-individuelle').catch(() => {});
    },
    goToAgregatedForm() {
      this.$router.push('/agregated_form').catch(() => {});
    }
  },
  computed: {
    ...mapState({
      token: 'token',
      user: 'user',
      username: 'username',
      notification_number: 'notification_number',
      challenges: 'challenges',
      total: 'total_challenges',
      percentage_of_success: 'percentage_of_success',
      rotation_angle: 'rotation_angle',
      uncompleted_records: 'uncompleted_records',
      individual_records_number: 'individual_records_number',
      agregated_records_number: 'agregated_records_number'
    })
  },
  data() {
    return {
      nb_tap: 0,
      show_challenge_box: true,
      show_challenge_stack: false
    };
  }
};
</script>

<style scoped >
.content {
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0;
  min-height: 640px;
  overflow: scroll;
  overflow-y: hidden;
  background: linear-gradient(0deg, #eff2f7, #eff2f7),
    linear-gradient(180deg, #ffffff 61.09%, #eefbff 100%);
}

.home_content {
  display: flex;
  align-items: unset;
}
.aside_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 30%;
  height: 60%;
}

.notification {
  width: 54%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10%;
  position: relative;
  right: 13%;
  cursor: pointer;
}

.notification:active{
  transform : scale(1.05);
}

.dashboard {
  width: 54%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10%;
  position: relative;
  right: 13%;
  cursor: pointer;
}

.dashboard:active{
  transform : scale(1.05);
}

.sample_results {
  width: 60%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10%;
  position: relative;
  right: 11%;
  cursor: pointer;
}

.sample_results:active{
  transform: scale(0.95);
}

.historique {
  width: 54%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10%;
  position: relative;
  right: 13%;
  cursor: pointer;
}

.historique:active{
  transform : scale(1.05);
}

.historique_text {
  margin-left: 6%;
  font-family: 'Eastman Roman Trial';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: #0d1c2e;
}

.notification_text {
  margin-left: 6%;
  font-family: 'Eastman Roman Trial';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: #0d1c2e;
  margin-right: 35%;
}

.dashboard_text {
  margin-left: 6%;
  font-family: 'Eastman Roman Trial';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: #0d1c2e;
  margin-right: 35%;
}

.sample_results_text{
  margin-left: 3%;
  font-family: 'Eastman Roman Trial';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: #0d1c2e;
  margin-right: 35%;
}

.notification_number {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;
  width: 23px;
  height: 23px;
  background: #484ef7;
  border-radius: 100%;
  color: #ffffff;
  position: relative;
  left: 35%;
}

.historique_item {
  display: hidden;
}

.challenges {
  width: 80%;
  margin-top: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 10px 0px;
  gap: 10px;
  background: #ffffff;
  border: 1px solid #979797;
  border-radius: 5px;
  padding-bottom: 2px;
}
.challenges_title_block {
  width: 60%;
  text-align: left;
}

.challenges_desc {
  margin-left: 4%;
}

.challenge_title {
  font-family: 'Eastman Roman Trial';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  color: #0d1c2e;
  margin-left: 6%;
}

.challenges_desc {
  margin-top: 4%;
  font-family: 'Eastman Roman Trial';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #425169;
}

.challenge_box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background: linear-gradient(90deg, #a8ffcb 33.96%, #6dd9ff 98.56%);
  mix-blend-mode: normal;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  width: 85%;
  margin-left: 4%;
  margin-top: 4%;
}

.achieved {
  font-family: 'Eastman Roman Trial';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
  color: #106432;
  margin-top: 4%;
  margin-left: 4%;
}

.ranking {
  margin-top: 3%;
  margin-left: 0%;
  width: 98%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.total_challenges {
  font-weight: 300;
}

.trophy {
  margin-left: 5%;
}

.percentage {
  text-align: right;
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 17px;
  text-align: right;
  color: #00000c;
}

.arrow_button {
  margin-top: 4%;
  width: 100%;
}

.show_challenges_button {
  background: #ffffff !important;
  border: none !important;
  box-shadow: none !important;
}

.show_challenges_button:active {
  transform: scale(0.9);
}

.logout {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 2px;
  gap: 5px;
  background: #484ef7;
  border-radius: 5px;
  width: 80%;
  font-size: 20px;
  margin-top: 4%;
}

.logout_arrow_icon {
  position: relative;
  left: 23%;
}

.main_content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
  padding: 0;
  width: 69%;
  height: 72vh;
}

.latest_activity,
.forms {
  font-family: 'Eastman Roman Trial';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #0d1c2e;
  width: 25%;
  text-align: left;
  margin-top: 1.5%;
}

.activities_block {
  margin-top: 2%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 30px 20px 0px;
  background: #f8f9fa;
  box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  height: 30%;
}

.activities_block_empty {
  margin-top: 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: center;
  padding: 10px 30px 20px 0px;
  background: #f8f9fa;
  box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  height: 45%;
}

.empty_records_text {
  font-family: 'Eastman Roman Trial';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 12px;
  text-align: center;
  color: #425169;
  margin-top: 6%;
  user-select: none;
}
.patient_records {
  width: 70%;
  margin-left: 3%;
}

.patient_records:nth-child(2) {
  margin-top: 1%;
}

.collect_rating {
  position: absolute;
  width: 18%;
  top: 25%;
  left: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 7px;
  background: #f8f9fa;
  border-radius: 5px;
  height: 15%;
}

.forms_block {
  margin-top: 2%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px 30px 0px 0px;
  background: #f8f9fa;
  box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  height: 80%;
}

.impression {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 45%;
  margin-top: 3%;
}

.great_work {
  font-family: 'Eastman Roman Trial';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 14px;
  text-align: center;
  color: #0d1c2e;
  margin-left: 12%;
}

.icon_arm {
  margin-left: 7%;
  transform: scale(1.2);
  position: relative;
  bottom: 3%;
}

.shield {
  position: relative;
  bottom: 3%;
  left: 5%;
}

.description {
  font-family: 'Eastman Roman Trial';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: #425169;
}
.individual_card:active,
.agregated_card:active {
  opacity: 0.9;
  transform: scale(0.99);
}

.individual_card,
.agregated_card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 10px 10px 0px;
  gap: 20px;
  background: #ffffff;
  border: 1px solid #979797;
  border-radius: 5px;
  width: 25%;
}

.individual_card,
.agregated_card {
  margin-left: 4%;
  margin-top: 2%;
  height: 85%;
}

.individual_card_img {
  width: 105%;
  height: 70%;
  background: url('../assets/diagnostic.png');
  opacity: 0.9;
  background-size: cover;
  background-repeat: no-repeat;
}

.agregated_card_img {
  width: 105%;
  height: 70%;
  background: url('../assets/lungs.png');
  opacity: 0.9;
  background-size: cover;
  background-repeat: no-repeat;
}

.individual_card_records_number,
.agregated_card_records_number {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;
  width: 15%;
  height: 15%;
  background: #ffffff;
  border-radius: 100px;
  font-weight: 700;
  position: relative;
  top: 10%;
  left: 10%;
}

.record_title {
  text-align: left;
  font-family: 'Eastman Roman Trial';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #0d1c2e;
  margin-top: 4%;
  margin-left: 2%;
  width: 90%;
  height: 30%;
  padding: 0px 10px 2px 10px;
}

.slide-fade-enter-active {
  transition: all 0.2s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(20px);
  opacity: 0;
}

.challenge_stacks {
  position: relative;
  bottom: 20%;
  left: 72%;
}
.sample_icn{
  transform: scale(0.8);
}
</style>