<template>
    <b-container fluid class="pie_content">

      <div class="pie_chart_block">
        <div class="title">
          <div class="chart_title">
            Rapport de surveillance
          </div>
            <div class="syndrome_label">
            {{current_syndrome}}
            </div>
        </div>

        <div class="chart_elts">
          <div class="pie_chart">
            <Pie
              :key="pieChartKey"
              :data="pie_chart.data"
              :options="pie_chart.options"
            />
          </div>

          <div class="legend">


            <div class="legend_colors">
              <div v-for="(element, index) in pie_chart.data.datasets[0].backgroundColor" :key="index" :style="{ backgroundColor: element }" class="color_box suspected_color">
              </div>
            </div>

            <div class="legend_label_elt">

              <div v-for="(element, index) in pie_chart.data.labels" :key="index" class="legend_label_text">
                  {{element}}
              </div>

            </div>



            <div class="legend_value_elt">

              <div v-for="(element, index) in pie_chart.data.datasets[0].data" :key="index" class="legend_value_text">
                  {{element}}
              </div>

            </div>

            <div class="legend_percentage_elt">

              <div v-for="(element, index) in pie_chart.data.datasets[0].data" :key="index" class="legend_percentage_text">
                  {{getPercentage(element)}} %
              </div>

            </div>

          </div>

        </div>

      </div>

      <div class="actions">

        <div class="header">
          <div class="header_item">

              <b-dropdown no-caret variant="#FFFFFF" id="grouping_dropdown" ref="dropdown" class="m-md-2">
                <template #button-content>
                {{group_by}}
                <img class="arrow_up_icon" src="@/assets/arrow_up.svg" alt="regrouper par" />
                </template>
                    <b-form-radio class="radio grouping_item" v-model="group_by" name="group_by" value="sexe"> 
                      <span class="grouping_choice_label">Sexe</span>
                    </b-form-radio>
                  
                    <b-form-radio class="radio grouping_item" v-model="group_by" name="group_by" value="age"> 
                      <span class="grouping_choice_label">Age</span>
                    </b-form-radio>

              </b-dropdown>
            
          </div>

          <!-- <div class="excel header_item">
            <img class="export_icon" src="@/assets/export.svg" alt="exporter le graphe" />
          </div> -->

          <div class="print header_item">
            <img class="print_icon" src="@/assets/print.svg" alt="imprimer le graphe" />
          </div>

        </div>

        <hr>
        <div class="urgent_samples">
          <div class="urgent_samples_title">
            <span class="urgent_samples_title_text">Prélèvements urgents </span>
            <img class="sample_icon" src="@/assets/sample.png"/>
          </div>
          <div v-show="role!='ICP'" v-for="(value, key) in total_urgent_samples" :key="key" class="urgent_samples_value">
            <span class="urgent_samples_district">{{key}}</span>
            <span class="urgent_samples_numbers">{{value.nb_prelevements_urgents}}</span>
          </div>
        </div>

      </div>
    </b-container>
</template>
  
  <script>
  
  import { mapState } from 'vuex';
  import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
  ChartJS.register(ArcElement, Tooltip, Legend)
  import { Pie } from "vue-chartjs";
  import { eventBus } from '@/main.js';


  export default {
    name: 'PieChart',
    components: { 
        Pie
    },
    data() {
      return {
        current_age_value : '5-14 ans',
        group_by: 'sexe',
        pieChartKey: 0,
        site_name: localStorage.getItem("nom_site"),
        role: ''
      };
    },
    watch:{
      group_by(val){
        this.$store.commit("setReportGroupBy",val);
      },
      pie_chart: {
      handler() {
        this.pieChartKey += 1;
      },
      deep: true
    },
    },
    methods: {

      updatePieChart() {
        this.pieChartKey+=1;
      },
      getPercentage(value){
        let total=0;
        for (let i = 0; i < this.pie_chart.data.datasets[0].data.length; i++) {
          total+=this.pie_chart.data.datasets[0].data[i];
        }

        return Math.round((value/total)*1000)/10;
      }

    },
    computed: {
      ...mapState({
        pie_chart : 'pie_chart',
        current_syndrome: 'current_syndrome',
        total_urgent_samples: 'total_urgent_samples'
      })
    },
    mounted() {
      let role = JSON.parse(localStorage.getItem("user")).role.nom_role;
      this.role = role;
      eventBus.$on('updatePieChart', () => {
        this.updatePieChart();
      });
    },
  };
  </script>
  
  <style scoped>

  .pie_content{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 65%;
    margin-bottom: 5%;
  }

  .pie_chart_block{
    margin-top: 4%;
    width: 45%;
    box-sizing: border-box;
    background: #F8F9FA;
    border: 1px solid #DADADA;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
  }

  .chart_elts{
    display: flex;
    flex-direction: column;
  }
  .pie_chart{
    width: 70%;
    margin-top:4%;
    margin-bottom: 8%;
    margin-left: 2%;
  }

  .title{
    display: flex;
    flex-direction: column;
    width: 70%;
    margin-left: 5%;
    margin-top: 5%;
  }

  .chart_title{
    font-family: 'Eastman Roman Trial';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    color: #6A778A;
    margin-bottom: 2%;
    text-align: left;

  }

  .syndrome_label{
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 25px;
    display: flex;
    color: #0D1C2E;
  }

  .legend{
    display: flex;
    flex-direction: row;
    padding: 10px 15px;
  }

  .color_box{
    box-sizing: border-box;
    width: 15px;
    height: 15px;
    border-radius: 2px;
  }

  .legend_label_text{
    font-family: 'Eastman Roman Trial';
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 18px;
    color: #6A778A;
    margin-top: 1%;
    margin-bottom: 3%;
  }

  .actions{
    width: 50%;
    background: #FFFFFF;
    border: 1px solid #DADADA;
    border-radius: 5px;  
    height: 100%;
    margin-top: 4%;
    display: flex;
    flex-direction: column;
  }

  .header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 2%;
    padding: 5px;
    padding-top: 15px;
  }

  .header_item{
    display: flex;
    align-items: center;
  }

  #grouping_dropdown{
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    background: #FFFFFF !important;
    border: 1px solid #979797;
    border-radius: 5px;
    width: 100%;
  }

  .arrow_up_icon{
    margin-left:5%;
  }

  .grouping_item{
    margin-top: 1%;
    margin-bottom: 2%;
    position: relative;
    left: 10%;
  }


  .excel{
    position: relative;
    right: 5%;
    box-sizing: border-box;
    background: #FFFFFF;
    border: 1px solid #979797;
    border-radius: 5px;
    padding: 4px;
    height: 60%;
    margin-top: 1.2%;
  }

  .excel:active{
    transform: scale(0.95);
  }

  .print{
    position: relative;
    right: 15%;
    box-sizing: border-box;
    background: #FFFFFF;
    border: 1px solid #979797;
    border-radius: 5px;
    padding: 4px;
    height: 60%;
    margin-top: 0.4%;
    transform: scale(0.8);
  }

  .print:active{
    transform: scale(0.75);
  }

  .legend_colors{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 5%;
  }

  .legend_label_elt{
    margin-left: 2%;
    display: flex;
    flex-direction: column;
  }

  .legend_value_elt{
    display: flex;
    flex-direction: column;
    margin-left: 8%;
    justify-content: space-around;
  }

  .legend_value_text{
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 20px;
    text-align: right;
    color: #6A778A;
  }

  .legend_percentage_elt{
    display: flex;
    flex-direction: column;
    margin-left: 8%;
    justify-content: space-around;
  }

  .legend_percentage_text{
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    text-align: right;
    color: #979797;
  }

  .urgent_samples{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 2%;
    padding: 10px;
  }

  .urgent_samples_title{
    margin-bottom: 2%;
    display: flex;
  }

  .urgent_samples_title_text{
    font-family: 'Eastman Trial';
    font-style: normal;
    font-weight: 800;
    font-size: 22px;
    line-height: 26px;
    display: flex;
    align-items: flex-end;
    color: #6A778A;
  }

  .urgent_samples_value{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-top: 2%;
  }

  .urgent_samples_district{
    widows: 100%;
    font-family: 'Eastman Trial';
    font-style: normal;
    font-weight: 800;
    font-size: 15px;
    line-height: 18px;
    color: #000000;
  }

  .urgent_samples_numbers{
    margin-left: 4%;
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    text-align: right;
    color: #FF0056;
  }

  .sample_icon{
    transform: scale(0.75);
    position: relative;
    top: 15px !important;
  }
  </style>