<template>
  <div class="influenza_content">
    <hr style="width:100%;" />

    <span class="tdr_covid_title">TDR Covid-19 effectué ?</span>
    <b-row class="tdr_covid">
      <b-col cols="5" class="tdr_covid_question">
        <SwitchBox
          :values="['Non','Oui']"
          :icons="['blank.png','blank.png']"
          :box_style="{label1Left:'-20%',label1Right:'-20%',label2Left:'0%',label2Right:'0%', boxMarginLeft:'0%',selectBoxWidth:'18.3%'}"
          :variableName="'tdr_covid'"
          :null="false"
        />
      </b-col>
    </b-row>

    <span v-show="tdr_covid=='Oui'" class="result_title">Résultats</span>

    <b-row v-show="tdr_covid=='Oui'" class="tdr_result_label_row">
      <b-col class cols="5">
        <span class="label_text">Antigene</span>
      </b-col>
      <b-col cols="1"></b-col>
      <b-col class cols="5">
        <span class="label_text">Anticorps</span>
      </b-col>
    </b-row>

    <b-row v-show="tdr_covid=='Oui'" class="tdr_result">

      <b-col class="tdr_question_switch" cols="5">
        <b-form-checkbox
          class="tdr_covid_switch"
          v-model="tdr_covid_antigene_result"
          value="Negatif"
        >
        <span class="tdr_covid_antigene_left_value">Négatif</span>
        </b-form-checkbox>

        <b-form-checkbox
          class="tdr_covid_switch"
          v-model="tdr_covid_antigene_result"
          value="Positif"
        >
        <span class="tdr_covid_antigene_right_value">Positif</span>
        </b-form-checkbox>
      </b-col>

      <b-col cols="1"></b-col>

      <b-col class="tdr_question_switch" cols="5">
        <b-form-checkbox
          class="tdr_covid_switch"
          v-model="tdr_covid_anticorps_result"
          value="Négatif"
        >
          <span class="tdr_covid_anticorps_left_value">Négatif</span>
        </b-form-checkbox>

        <b-form-checkbox
          class="tdr_covid_switch"
          v-model="tdr_covid_anticorps_result"
          value="Positif"
        >
          <span class="tdr_covid_anticorps_right_value">Positif</span>
        </b-form-checkbox>

      </b-col>

    </b-row>

    <b-row v-show="show_anticorps==true && tdr_covid=='Oui'" class="tdr_covid_anticorps">
      <b-col class="anticorps_elt" cols="10">
        <b-form-checkbox
          class="igm_checkbox"
          id="igm_checkbox"
          v-model="igm_positif"
          name="checkbox-igm"
          value="Oui"
          unchecked-value="Non"
          size="lg"
        >
          <span class="checkbox_text">Ig M Positif</span>
        </b-form-checkbox>
      </b-col>
    </b-row>
    <b-row v-show="show_anticorps==true" class="tdr_covid_anticorps">
      <b-col class="anticorps_elt" cols="10">
        <b-form-checkbox
          class="iga_checkbox"
          id="iga_checkbox"
          v-model="iga_positif"
          name="checkbox-iga"
          value="Oui"
          unchecked-value="Non"
          size="lg"
        >
          <span class="checkbox_text">Ig A Positif</span>
        </b-form-checkbox>
      </b-col>
    </b-row>
    <b-row v-show="show_anticorps==true" class="tdr_covid_anticorps">
      <b-col class="anticorps_elt" cols="10">
        <b-form-checkbox
          class="igg_checkbox"
          id="igg_checkbox"
          v-model="igg_positif"
          name="checkbox-igg"
          value="Oui"
          unchecked-value="Non"
          size="lg"
        >
          <span class="checkbox_text">Ig G Positif</span>
        </b-form-checkbox>
      </b-col>
    </b-row>
    <hr style="width:100%;" />
  </div>
</template>


<script>
import { mapState } from 'vuex';
import { eventBus } from '@/main.js';
import SwitchBox from '@/components/fiche-individuelle/general/SwitchBox.vue';

export default {
  name: 'InfluenzaSection',
  components: {
    SwitchBox
  },
  data: () => ({
    tdr_result: '',
    tdr_covid_antigene_result: '',
    tdr_covid_anticorps_result: '',
    show_anticorps: false,
    igm_positif: '',
    iga_positif: '',
    igg_positif: '',
    currentTestComment: ''
  }),
  computed: {
    ...mapState({
      tdr_covid: 'tdr_covid',
      tdr_palu: 'tdr_palu',
      is_tdr_covid_variable_updated: 'is_tdr_covid_variable_updated',
      influenza_percentage: 'influenza_percentage',
      suspision_percentage: 'suspision_percentage',
      covid19_percentage: 'covid19_percentage',
      suspisionAndDiagnosticCategories: 'suspisionAndDiagnosticCategories'
    })
  },
  watch: {
    tdr_covid_antigene_result(val) {
      let valueRightElt = document.querySelector(
        '.tdr_covid_antigene_right_value'
      );
      let valueLeftElt = document.querySelector(
        '.tdr_covid_antigene_left_value'
      );

      if (val == 'Positif') {
        valueLeftElt.style.color = '#6A778A';
        valueRightElt.style.color = '#484EF7';
      } else {
        valueLeftElt.style.color = '#484EF7';
        valueRightElt.style.color = '#6A778A';
      }

      this.$store.commit(
        'setTdrCovidAntigeneResult',
        this.tdr_covid_antigene_result
      );
    },
    tdr_covid_anticorps_result(val) {
      let valueRightElt = document.querySelector(
        '.tdr_covid_anticorps_right_value'
      );
      let valueLeftElt = document.querySelector(
        '.tdr_covid_anticorps_left_value'
      );

      if (val == 'Positif') {
        this.show_anticorps = true;
        valueLeftElt.style.color = '#6A778A';
        valueRightElt.style.color = '#484EF7';
      } else {
        this.show_anticorps = false;
        valueLeftElt.style.color = '#484EF7';
        valueRightElt.style.color = '#6A778A';
      }
      

      this.$store.commit(
        'setTdrCovidAnticorpsResult',
        this.tdr_covid_anticorps_result
      );
    },
    tdr_covid(val) {
      if (val != null) {
        if (this.is_tdr_covid_variable_updated == false) {
          this.$store.commit(
            'setInfluenzaPercentage',
            this.influenza_percentage + 80
          );
          this.$store.commit(
            'setCovid19Percentage',
            this.covid19_percentage + 80
          );
          this.$store.commit('setIsTdrCovidVariableUpdated', true);
          this.$store.commit(
            'setSuspisionPercentage',
            this.suspision_percentage + 10
          );
        }
      } else {
        if (this.is_tdr_covid_variable_updated == true) {
          this.$store.commit(
            'setInfluenzaPercentage',
            this.influenza_percentage - 80
          );
          this.$store.commit(
            'setCovid19Percentage',
            this.covid19_percentage - 80
          );
          this.$store.commit('setIsTdrCovidVariableUpdated', false);
          this.$store.commit(
            'setSuspisionPercentage',
            this.suspision_percentage - 10
          );
        }
      }
    },
    influenza_percentage(val) {
      if (val >= 80) {
        eventBus.$emit('activeInfluenzaSectionComplete');
        eventBus.$emit('activeCovid19SectionComplete');
      } else {
        eventBus.$emit('deactivateInfluenzaSectionComplete');
        eventBus.$emit('deactivateCovid19SectionComplete');
      }
    },
    igg_positif(val) {
      this.$store.commit('setIggPositif', val);
    },
    igm_positif(val) {
      this.$store.commit('setIgmPositif', val);
    },
    iga_positif(val) {
      this.$store.commit('setIgaPositif', val);
    }
  },
  methods: {
    goToNextSection() {
      eventBus.$emit('triggerCovid19Section');
    }
  }
};
</script>

<style scoped>
.influenza_content {
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}

.tdr_covid_title {
  font-family: 'Eastman Roman Trial';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #425169;
  width: 93%;
  margin-bottom: 1%;
  margin-top: 2%;
}

.tdr_covid {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.5%;
}

.result_title {
  font-family: 'Eastman Roman Trial';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #425169;
  position: relative;
  right: 2.5%;
}

.tdr_result_label_row {
  width: 60%;
  position: relative;
  left: 22%;
  margin-top: 1%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.tdr_result {
  width: 60%;
  position: relative;
  left: 22%;
  margin-top: 2%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 2%;
}

.tdr_question_switch {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 10px 0px;
  gap: 10px;
  background: #ebfcfd;
  border-radius: 5px;
}

.tdr_covid_antigene_left_value,
.tdr_covid_antigene_right_value,
.tdr_covid_anticorps_right_value,
.tdr_covid_anticorps_left_value {
  font-family: 'Eastman Roman Trial';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: #6a778a;
}

.tdr_covid_switch {
  transform: scale(1.25);
}

.label_text {
  font-family: 'Eastman Roman Trial';
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 18px;
  text-align: center;
  color: #6a778a;
}

.tdr_covid_anticorps {
  width: 25%;
  position: relative;
  left: 52%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1%;
}

.anticorps_elt {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 0px;
  gap: 5px;
  background: #ffebee;
  border-radius: 5px;
}

.checkbox_text {
  font-family: 'Eastman Roman Trial';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: #425169;
}
</style>