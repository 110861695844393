<template>
  <div v-bind:style="{marginLeft:this.box_style.boxMarginLeft}" class="content_box">
    <span class="label">{{label}}</span>
    <div class="switcher">
      <div
        v-bind:style="{height:box_style.selectBoxHeight,top:box_style.selectBoxTop,left:box_style.selectBoxLeft,width:box_style.selectBoxWidth}"
        v-show="this.null_state==false"
        class="select_box"
      ></div>
      <div @click="toggleSelectBoxToRight" class="select_elt_1">
        <img
          v-bind:style="{width:box_style.iconWidth,left:box_style.iconDistance}"
          v-if="icons[0]"
          class="icon_1"
          :src="require('@/assets/'+icons[0])"
        />
        <span
          v-bind:style="{left:box_style.label1Left,right:box_style.label1Right, color:this.null_state==true? '#425169':this.default_color}"
          class="value_1"
        >{{values[0]}}</span>
      </div>
      <div @click="toggleSelectBoxToLeft" class="select_elt_2">
        <img
          v-bind:style="{width:box_style.iconWidth, left:box_style.iconDistance}"
          v-if="icons[1]"
          class="icon_2"
          :src="require('@/assets/'+icons[1])"
        />
        <span
          v-bind:style="{left:box_style.label2Left,right:box_style.label2Right}"
          class="value_2"
        >{{values[1]}}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SwitchBox',
  props: {
    values: {},
    icons: {},
    label: {},
    box_style: {},
    variableName: {},
    blank: {},
    null: {}
  },
  data: () => ({
    null_state: false,
    default_color: '#ffffff'
  }),
  mounted() {
    this.null_state = this.null;
    if (this.blank !== 'icon_1') {
      let icons1 = document.getElementsByClassName('icon_1');
      for (let i = 0; i < icons1.length; i++) {
        icons1[i].style.filter = 'invert(100%)';
        icons1[i].style.opacity = '0.7';
      }
    }
  },
  watch: {
    null_state(val) {
      if (val == false) {
        this.default_color = '#425169';
      }
    }
  },
  methods: {
    toggleSelectBoxToRight(e) {
      this.null_state = false;
      let setterName;
      if (this.variableName.includes('_')) {
        let variable_parts = this.variableName.split('_');
        let part1 = this.capitalize(variable_parts[0]);
        let part2 = this.capitalize(variable_parts[1]);
        setterName = 'set' + part1 + part2;
      } else {
        setterName = 'set' + this.capitalize(this.variableName);
      }
      let selectElt = e.target.closest('.select_elt_1');
      let switcher = selectElt.parentNode.children[0];
      let icon1Elt = selectElt.parentNode.children[1].children[0];
      icon1Elt.style.filter = 'invert(100%)';
      icon1Elt.style.opacity = '0.7';
      let text1ELt = selectElt.parentNode.children[1].children[1];
      text1ELt.style.color = '#ffffff';
      let icon2Elt = selectElt.parentNode.children[2].children[0];
      let text2ELt = selectElt.parentNode.children[2].children[1];
      text2ELt.style.color = '#425169';
      icon2Elt.style.filter = 'invert(0%)';
      icon2Elt.style.opacity = '1';
      let eltOffset = selectElt.getBoundingClientRect();
      let switcherOffset = switcher.getBoundingClientRect();
      if (Math.abs(eltOffset.left - switcherOffset.left) > 50) {
        switcher.style.transform = 'translateX(0%)';
        this.$store.commit(setterName, this.values[0]);
      } else {
        if (this.null == true) {
          this.null_state = true;

          text1ELt.style.color = '#425169';
          text2ELt.style.color = '#425169';
          icon1Elt.style.filter = 'invert(0%)';
          icon2Elt.style.filter = 'invert(0%)';
          this.$store.commit(setterName, null);
        }
      }
    },
    toggleSelectBoxToLeft(e) {
      this.null_state = false;
      let setterName;
      if (this.variableName.includes('_')) {
        let variable_parts = this.variableName.split('_');
        let part1 = this.capitalize(variable_parts[0]);
        let part2 = this.capitalize(variable_parts[1]);
        setterName = 'set' + part1 + part2;
      } else {
        setterName = 'set' + this.capitalize(this.variableName);
      }
      let selectElt = e.target.closest('.select_elt_2');
      let switcher = selectElt.parentNode.children[0];
      let icon1Elt = selectElt.parentNode.children[1].children[0];
      let text1ELt = selectElt.parentNode.children[1].children[1];
      text1ELt.style.color = '#425169';
      icon1Elt.style.filter = 'invert(0%)';
      icon1Elt.style.opacity = '0.9';
      let icon2Elt = selectElt.parentNode.children[2].children[0];
      let text2ELt = selectElt.parentNode.children[2].children[1];
      text2ELt.style.color = '#ffffff';
      icon2Elt.style.filter = 'invert(100%)';
      icon2Elt.style.opacity = '1';
      let eltOffset = selectElt.getBoundingClientRect();
      let switcherOffset = switcher.getBoundingClientRect();
      if (Math.abs(eltOffset.left - switcherOffset.left) > 50) {
        switcher.style.transform = 'translateX(110%)';
        this.$store.commit(setterName, this.values[1]);
      } else {
        if (this.null == true) {
          this.null_state = true;
          text1ELt.style.color = '#425169';
          text2ELt.style.color = '#425169';
          icon1Elt.style.filter = 'invert(0%)';
          icon2Elt.style.filter = 'invert(0%)';
          this.$store.commit(setterName, null);
        }
      }
    },
    capitalize(value) {
      return (
        value
          .toString()
          .charAt(0)
          .toUpperCase() + value.toString().slice(1)
      );
    }
  }
};
</script>

<style scoped>

.content_box {
  display: flex;
  height: 82px;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  margin-left: 2%;
}

.switcher {
  border: 2px solid #979797;
  border-radius: 5px;
  width: 44%;
  height: 100%;
  margin-right: 2%;
  display: flex;
}

.label {
  font-family: 'Eastman Trial';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: rgba(66, 81, 105, 0.78);
  width: 20%;
  text-align: justify;
}

.select_box {
  background: #484ef7;
  border-radius: 5px;
  margin-top: 1%;
  margin-left: 1%;
  height: 62%;
  position: absolute;
  width: 72px;
  z-index: 1;
  opacity: 0.95;
}

.select_elt_1,
.select_elt_2 {
  display: flex;
  flex-direction: column;
  z-index: 100;
  justify-content: space-between;
  width: 100%;
}
.select_elt_1 {
  position: relative;
}
.select_elt_2 {
  position: relative;
}
.icon_1,
.icon_2 {
  height: 55%;
  display: flex;
  transform: scale(0.8);
  position: relative;
  top: 10%;
}

.value_2,
.value_1 {
  font-family: 'Eastman Roman Trial';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  z-index: 1000;
  position: relative;
  bottom: 8%;
  position: absolute;
}
</style>