<template>
  <div class="success_content">
    <div class="confitis"></div>
    <div class="overlay_message">
      <p class="bravo">Bravo</p>
      <span class="message1">Votre rapport journalier a bien été envoyé</span>
      <p class="points">+50 pts</p>
      <p class="message2">De points challenge gagné</p>
      <p class="message3">Merci pour votre engagement</p>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'SendSuccessfully',
  components: {},
  data: () => ({
    datetime: ''
  }),
  mounted() {},
  computed: {
    ...mapState({})
  },
  methods: {}
};
</script>

<style scoped>
.success_content {
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
}

.confitis {
  height: 65%;
  overflow: hidden;
  background-image: url('../assets/confetis.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  margin: 0;
}

.overlay_message {
  width: 50%;
  position: absolute;
  left: 25%;
  top: 48%;
  height: 45%;
}

.bravo {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 84px;
  line-height: 98px;
  text-align: center;
  color: #0d1c2e;
  opacity: 0.8;
}

.message1 {
  font-family: 'Eastman Roman Trial';
  font-style: normal;
  font-weight: 600;
  font-size: 23px;
  line-height: 28px;
  text-align: center;
  color: #0d1c2e;
  opacity: 0.7;
}

.points {
  margin-top: 4%;
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 41px;
  line-height: 47px;
  text-align: center;
  color: #3cb691;
}
.message2 {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;
  text-align: center;
  color: #6a778a;
}

.message3 {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #0d1c2e;
  opacity: 0.8;
}
</style>
