<template>
  <div class="prelevments_content">
    <b-row class="main_questions">
      <b-col cols="12" class="question">
        <div class="question_label">Prélèvement fait</div>
        <div class="question_field">
          <SwitchBox
            :values="['Non','Oui']"
            :icons="['blank.png','blank.png']"
            :box_style="{label1Left:'-5%',label1Right:'0%', boxMarginLeft:'9%', label2Left:'0%',label2Right:'0%',iconWidth:'25%',iconDistance:'0%',selectBoxHeight:'47%',selectBoxWidth:'17.5%'}"
            :variableName="'prelevement'"
            :null="false"
          />
        </div>
      </b-col>
    </b-row>

    <b-row v-show="prelevement=='Oui' && suggest_nasal_sampling==true" style="margin-top:2%;" class="prelevement_row">
      <b-col class="prelevement_question_checkbox" cols="5">
        <b-form>
          <b-form-checkbox
            class="prelevement_checkbox check1"
            v-model="ecouvillonage_nasal"
            value="Ecouvillonage nasal"
          >
            <span class="checkbox_label">Ecouvillonage nasal</span>
          </b-form-checkbox>
        </b-form>
      </b-col>
      <b-col cols="1"></b-col>
      <b-col class="prelevement_date" cols="6">
        <span style="margin-bottom:2%;" class="prelevement_label">Date Prélèvement</span>
        <transition name="slide-fade" mode="out-in">
          <v-date-picker
            close-on-content-click
            id="prelevement_nasal_date_picker"
            color="#3CB691"
            locale="fr"
            class="prelevement_nasal_date_picker"
            v-model="date_prelevement_nasal"
            width="100%"
            no-title
          ></v-date-picker>
        </transition>
        <b-form-input
          id="prelevement_nasal_date_input"
          @click="showDatePicker('prelevement_nasal_date_picker')"
          class="prelevement_nasal_date_input"
          readonly
          type="text"
          v-model="formatted_prelevement_nasal_date"
          size="md"
        ></b-form-input>
        <img @click="showDatePicker" class="date_picker_icon" src="@/assets/date_picker.png" />
      </b-col>
    </b-row>

    <b-row v-show="prelevement=='Oui' && suggest_throat_sampling==true" style="margin-top:2%;" class="prelevement_row">
      <b-col class="prelevement_question_checkbox" cols="5">
        <b-form>
          <b-form-checkbox
            id="check2"
            class="prelevement_checkbox"
            v-model="ecouvillonage_gorge"
            value="Ecouvillonage gorge"
          >
            <span class="checkbox_label">Ecouvillonage gorge</span>
          </b-form-checkbox>
        </b-form>
      </b-col>
      <b-col cols="1"></b-col>
      <b-col class="prelevement_date" cols="6">
        <span style="margin-bottom:2%;" class="prelevement_label">Date Prélèvement</span>
        <transition name="slide-fade" mode="out-in">
          <v-date-picker
            close-on-content-click
            id="prelevement_gorge_date_picker"
            color="#3CB691"
            locale="fr"
            class="prelevement_gorge_date_picker"
            v-model="date_prelevement_gorge"
            width="100%"
            no-title
          ></v-date-picker>
        </transition>
        <b-form-input
          id="prelevement_gorge_date_input"
          @click="showDatePicker('prelevement_gorge_date_picker')"
          class="prelevement_gorge_date_input"
          readonly
          type="text"
          v-model="formatted_prelevement_gorge_date"
          size="md"
        ></b-form-input>
        <img @click="showDatePicker" class="date_picker_icon" src="@/assets/date_picker.png" />
      </b-col>
    </b-row>

    <b-row v-show="prelevement=='Oui' && suggest_blood_sampling==true" style="margin-top:2%;" class="prelevement_row">
      <b-col class="prelevement_question_checkbox" cols="5">
        <b-form>
          <b-form-checkbox class="prelevement_checkbox" v-model="sanguin" value="Sanguin">
            <span class="checkbox_label">Sanguin</span>
          </b-form-checkbox>
        </b-form>
      </b-col>
      <b-col cols="1"></b-col>
      <b-col class="prelevement_date" cols="6">
        <span style="margin-bottom:2%;" class="prelevement_label">Date Prélèvement</span>
        <transition name="slide-fade" mode="out-in">
          <v-date-picker
            close-on-content-click
            id="prelevement_sanguin_date_picker"
            color="#3CB691"
            locale="fr"
            class="prelevement_sanguin_date_picker"
            v-model="date_prelevement_sanguin"
            width="100%"
            no-title
          ></v-date-picker>
        </transition>
        <b-form-input
          id="prelevement_sanguin_date_input"
          @click="showDatePicker('prelevement_sanguin_date_picker')"
          class="prelevement_nasal_date_input"
          readonly
          type="text"
          v-model="formatted_prelevement_sanguin_date"
          size="md"
        ></b-form-input>
        <img @click="showDatePicker" class="date_picker_icon" src="@/assets/date_picker.png" />
      </b-col>
    </b-row>


    <b-form v-show="prelevement=='Oui'">
          <b-form-checkbox
            class="prelevement_urgent"
            v-model="prelevement_urgent"
            value="Oui"
          >
            <span class="prelevement_urgent_text">Il s'agit d'un prélèvement urgent.</span> 
          </b-form-checkbox>
    </b-form>

  </div>
</template>


<script>
import { mapState } from 'vuex';
import { eventBus } from '@/main.js';
import SwitchBox from '@/components/fiche-individuelle/general/SwitchBox.vue';

export default {
  name: 'VaccinesSection',
  components: { SwitchBox },
  data: vm => ({
    type_prelevement: '',
    ecouvillonage_nasal: '',
    ecouvillonage_gorge: '',
    sanguin: '',
    prelevement_urgent:'',
    selected_covid_symptomes:[],
    selected_arbo_symptomes:[],
    date_prelevement_nasal: new Date(
      Date.now() - new Date().getTimezoneOffset() * 60000
    )
      .toISOString()
      .substr(0, 10),
    formatted_prelevement_nasal_date: vm.formatDate(
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10)
    ),
    date_prelevement_gorge: new Date(
      Date.now() - new Date().getTimezoneOffset() * 60000
    )
      .toISOString()
      .substr(0, 10),
    formatted_prelevement_gorge_date: vm.formatDate(
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10)
    ),
    date_prelevement_sanguin: new Date(
      Date.now() - new Date().getTimezoneOffset() * 60000
    )
      .toISOString()
      .substr(0, 10),
    formatted_prelevement_sanguin_date: vm.formatDate(
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10)
    )
  }),
  mounted() {
    window.addEventListener('click', e =>
      this.handlePicker(
        'prelevement_nasal_date_picker',
        'prelevement_nasal_date_input',
        e
      )
    );

    window.addEventListener('click', e =>
      this.handlePicker(
        'prelevement_gorge_date_picker',
        'prelevement_gorge_date_input',
        e
      )
    );

    window.addEventListener('click', e =>
      this.handlePicker(
        'prelevement_sanguin_date_picker',
        'prelevement_sanguin_date_input',
        e
      )
    );
  },
  computed: {
    ...mapState({
      is_tests_percentage_updated: 'is_tests_percentage_updated',
      tests_percentage: 'tests_percentage',
      prelevement: 'prelevement',
      suggest_nasal_sampling: 'suggest_nasal_sampling',
      suggest_throat_sampling: 'suggest_throat_sampling',
      suggest_blood_sampling: 'suggest_blood_sampling',

      // variables de l'algorithme de suggestion des prélèvements

      temperature_fievre: 'temperature_fievre',

      // covid
      anorexie: 'anorexie',
      cephalees: 'cephalees',
      dyspnee: 'dyspnee',
      ecoulement_nasal: 'ecoulement_nasal',
      nausees: 'nausees',
      perte_odorat: 'perte_odorat',
      perte_de_gout: 'perte_de_gout',
      toux: 'toux',
      vomissement: 'vomissement',
      mal_de_gorge:'mal_de_gorge',

      // arbo
      arthralgies: 'arthralgies',
      eruption_cutanee: 'eruption_cutanee',
      douleurs_retro_orbitaires: 'douleurs_retro_orbitaires',
      myalgies: 'myalgies',
      manifestations_hemorragiques: 'manifestations_hemorragiques'
    })
  },
  watch: {
    date_prelevement_nasal(val) {
      let dateElt = document.getElementById('prelevement_nasal_date_picker');
      setTimeout(() => {
        //
      }, 100);
      dateElt.style.display = 'none';
      this.formatted_prelevement_nasal_date = this.formatDate(val);
      this.$store.commit('setDatePrelevement', this.date_prelevement_nasal);
    },
    date_prelevement_gorge(val) {
      let dateElt = document.getElementById('prelevement_gorge_date_picker');
      setTimeout(() => {
        //
      }, 100);
      dateElt.style.display = 'none';
      this.formatted_prelevement_gorge_date = this.formatDate(val);
      this.$store.commit('setDatePrelevement', this.date_prelevement_gorge);
    },
    date_prelevement_sanguin(val) {
      let dateElt = document.getElementById('prelevement_sanguin_date_picker');
      setTimeout(() => {
        //
      }, 100);
      dateElt.style.display = 'none';
      this.formatted_prelevement_sanguin_date = this.formatDate(val);
      this.$store.commit('setDatePrelevement', this.date_prelevement_sanguin);
    },
    prelevement_urgent(val){
      this.$store.commit("setPrelevementUrgent",val);
    },
    ecouvillonage_nasal() {
      if (this.ecouvillonage_gorge != '') {
        this.type_prelevement = 'Ecouvillonage nasopharyngé';
      } else if (this.ecouvillonage_gorge == '') {
        this.type_prelevement = 'Ecouvillonage nasal';
      }
    },
    ecouvillonage_gorge() {
      if (this.ecouvillonage_nasal != '') {
        this.type_prelevement = 'Ecouvillonage nasopharyngé';
      } else if (this.ecouvillonage_nasal == '') {
        this.type_prelevement = 'Ecouvillonage gorge';
      }
    },
    sanguin() {
      this.type_prelevement = 'Sanguin';
    },
    type_prelevement() {
      if (this.type_prelevement == 'Ecouvillonage nasopharyngé') {
        this.$store.commit('setTypePrelevement', 'Ecouvillonage nasopharyngé');
        this.$store.commit('setDatePrelevement', this.date_prelevement_nasal);
      } else if (this.type_prelevement == 'Ecouvillonage gorge') {
        this.$store.commit('setTypePrelevement', 'Ecouvillonage gorge');
        this.$store.commit('setDatePrelevement', this.date_prelevement_gorge);
      } else if (this.type_prelevement == 'Ecouvillonage nasal') {
        this.$store.commit('setTypePrelevement', 'Ecouvillonage nasal');
        this.$store.commit('setDatePrelevement', this.date_prelevement_nasal);
      } else if (this.type_prelevement == 'Sanguin') {
        this.$store.commit('setTypePrelevement', 'Sanguin');
        this.$store.commit('setDatePrelevement', this.date_prelevement_sanguin);
      }
      this.$store.commit('setTypePrelevement', this.type_prelevement);
    },

    //watchers pour l'algorithme de suggestion des prélèvements

    anorexie(val){
      if(val=='Oui'){
        if(!this.selected_covid_symptomes.includes('anorexie')){
          this.selected_covid_symptomes.push("anorexie");
        }
      }else if(val=='Non'){
        if(this.selected_covid_symptomes.includes('anorexie')){
          this.selected_covid_symptomes.pop('anorexie');
        }
      }

      this.isCovidSuspected(this.selected_covid_symptomes);
    },
    cephalees(val){
      if(val=='Oui'){
        if(!this.selected_covid_symptomes.includes('cephalees')){
          this.selected_covid_symptomes.push("cephalees");
        }
      }else if(val=='Non'){
        let index = this.selected_arbo_symptomes.indexOf('cephalees');
        if (index > -1) {
          this.selected_arbo_symptomes.splice(index, 1);
        }
      }

      this.isCovidSuspected(this.selected_covid_symptomes);

      if(val=='Oui'){
        if(!this.selected_arbo_symptomes.includes('cephalees')){
          this.selected_arbo_symptomes.push("cephalees");
        }
      }else if(val=='Non'){
        if(this.selected_arbo_symptomes.includes('cephalees')){
          this.selected_arbo_symptomes.pop('cephalees');
        }
      }
      this.isArboSuspected(this.selected_arbo_symptomes);
    },
    dyspnee(val){
      if(val=='Oui'){
        if(!this.selected_covid_symptomes.includes('dyspnee')){
          this.selected_covid_symptomes.push("dyspnee");
        }
      }else if(val=='Non'){
        if(this.selected_covid_symptomes.includes('dyspnee')){
          this.selected_covid_symptomes.pop('dyspnee');
        }
      }

      this.isCovidSuspected(this.selected_covid_symptomes);
    },
    ecoulement_nasal(val){
      if(val=='Oui'){
        if(!this.selected_covid_symptomes.includes('ecoulement_nasal')){
          this.selected_covid_symptomes.push("ecoulement_nasal");
        }
      }else if(val=='Non'){
        if(this.selected_covid_symptomes.includes('ecoulement_nasal')){
          this.selected_covid_symptomes.pop('ecoulement_nasal');
        }
      }

      this.isCovidSuspected(this.selected_covid_symptomes);
    },
    nausees(val){
      if(val=='Oui'){
        if(!this.selected_covid_symptomes.includes('nausees')){
          this.selected_covid_symptomes.push("nausees");
        }
      }else if(val=='Non'){
        if(this.selected_covid_symptomes.includes('nausees')){
          this.selected_covid_symptomes.pop('nausees');
        }
      }

      this.isCovidSuspected(this.selected_covid_symptomes);
    },
    perte_odorat(val){
      if(val=='Oui'){
        if(!this.selected_covid_symptomes.includes('perte_odorat')){
          this.selected_covid_symptomes.push("perte_odorat");
        }
      }else if(val=='Non'){
        if(this.selected_covid_symptomes.includes('perte_odorat')){
          this.selected_covid_symptomes.pop('perte_odorat');
        }
      }

      this.isCovidSuspected(this.selected_covid_symptomes);
    },
    perte_de_gout(val){
      if(val=='Oui'){
        if(!this.selected_covid_symptomes.includes('perte_de_gout')){
          this.selected_covid_symptomes.push("perte_de_gout");
        }
      }else if(val=='Non'){
        if(this.selected_covid_symptomes.includes('perte_de_gout')){
          this.selected_covid_symptomes.pop('perte_de_gout');
        }
      }

      this.isCovidSuspected(this.selected_covid_symptomes);
    },
    toux(val){
      if(val=='Oui'){
        if(!this.selected_covid_symptomes.includes('toux')){
          this.selected_covid_symptomes.push("toux");
        }
      }else if(val=='Non'){
        if(this.selected_covid_symptomes.includes('toux')){
          this.selected_covid_symptomes.pop('toux');
        }
      }

      this.isCovidSuspected(this.selected_covid_symptomes);
    },
    vomissement(val){
      if(val=='Oui'){
        if(!this.selected_covid_symptomes.includes('vomissement')){
          this.selected_covid_symptomes.push("vomissement");
        }
      }else if(val=='Non'){
        if(this.selected_covid_symptomes.includes('vomissement')){
          this.selected_covid_symptomes.pop('vomissement');
        }
      }

      this.isCovidSuspected(this.selected_covid_symptomes);
    },
    mal_de_gorge(val){
      if(val=='Oui'){
        if(!this.selected_covid_symptomes.includes('mal_de_gorge')){
          this.selected_covid_symptomes.push("mal_de_gorge");
        }
      }else if(val=='Non'){
        if(this.selected_covid_symptomes.includes('mal_de_gorge')){
          this.selected_covid_symptomes.pop('mal_de_gorge');
        }
      }

      this.isCovidSuspected(this.selected_covid_symptomes);
    },
    arthralgies(val){
      if(val=='Oui'){
        if(!this.selected_arbo_symptomes.includes('arthralgies')){
          this.selected_arbo_symptomes.push("arthralgies");
        }
      }else if(val=='Non'){
        let index = this.selected_arbo_symptomes.indexOf('arthralgies');
        if (index > -1) {
          this.selected_arbo_symptomes.splice(index, 1);
        }
      } 
      
      this.isArboSuspected(this.selected_arbo_symptomes);
    },
    eruption_cutanee(val){
      if(val=='Oui'){
        if(!this.selected_arbo_symptomes.includes('eruption_cutanee')){
          this.selected_arbo_symptomes.push("eruption_cutanee");
        }
      }else if(val=='Non'){
        let index = this.selected_arbo_symptomes.indexOf('eruption_cutanee');
        if (index > -1) {
          this.selected_arbo_symptomes.splice(index, 1);
        }
      }    

      this.isArboSuspected(this.selected_arbo_symptomes);
    },
    douleurs_retro_orbitaires(val){
      if(val=='Oui'){
        if(!this.selected_arbo_symptomes.includes('douleurs_retro_orbitaires')){
          this.selected_arbo_symptomes.push("douleurs_retro_orbitaires");
        }
      }else if(val=='Non'){
        let index = this.selected_arbo_symptomes.indexOf('douleurs_retro_orbitaires');
        if (index > -1) {
          this.selected_arbo_symptomes.splice(index, 1);
        }
      } 
      this.isArboSuspected(this.selected_arbo_symptomes);   
    },
    myalgies(val){
      if(val=='Oui'){
        if(!this.selected_arbo_symptomes.includes('myalgies')){
          this.selected_arbo_symptomes.push("myalgies");
        }
      }else if(val=='Non'){
        let index = this.selected_arbo_symptomes.indexOf('myalgies');
        if (index > -1) {
          this.selected_arbo_symptomes.splice(index, 1);
        }
      }
      this.isArboSuspected(this.selected_arbo_symptomes);
    },
    manifestations_hemorragiques(val){
      if(val=='Oui'){
        if(!this.selected_arbo_symptomes.includes('manifestations_hemorragiques')){
          this.selected_arbo_symptomes.push("manifestations_hemorragiques");
        }
      }else if(val=='Non'){
        let index = this.selected_arbo_symptomes.indexOf('manifestations_hemorragiques');
        if (index > -1) {
          this.selected_arbo_symptomes.splice(index, 1);
        }
      }

      this.isArboSuspected(this.selected_arbo_symptomes);
    }
  },
  methods: {
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${month}/${day}/${year}`;
    },
    showDatePicker(pickerName) {
      // this.handlePicker();
      let dateElt = document.getElementById(pickerName);
      dateElt.style.display = 'block';
    },
    handlePicker(pickerName, inputName, e) {
      let datePickerElt = document.getElementById(pickerName);
      if (datePickerElt != null) {
        if (document.getElementById(pickerName).contains(e.target)) {
          // Clicked in box
        } else {
          if (document.getElementById(inputName).contains(e.target)) {
            document.getElementById(pickerName).style.display = 'block';
          } else {
            document.getElementById(pickerName).style.display = 'none';
          }
        }
      }
    },
    goToNextSection() {
      eventBus.$emit('triggerSymptomalogieSection');
    },
    isCovidSuspected(selected_covid_symptomes){

      let symptomes_list = [
        "toux",
        "anorexie",
        "cephalees",
        "dyspnee",
        "ecoulement_nasal",
        "nausees",
        "perte_odorat",
        "perte_de_gout",
        "vomissement",
        "mal_de_gorge"
      ]

      let commonElements = symptomes_list.filter(function(el) {
          return selected_covid_symptomes.includes(el);
      });

      if ((commonElements.length >= 3 && this.temperature_fievre>='38') || (selected_covid_symptomes.includes('toux') && this.temperature_fievre>='38') ) {
          this.$store.commit("setSuggestNasalSampling",true);
          this.$store.commit("setSuggestThroatSampling",true);
      }else{
          this.$store.commit("setSuggestNasalSampling",false);
          this.$store.commit("setSuggestThroatSampling",false);      
      }

    },
    isArboSuspected(selected_arbo_symptomes){

      let symptomes_list1 = [
        "arthralgies",
        "eruption_cutanee",
        "douleurs_retro_orbitaires",
        "myalgies",
        "cephalees" 
      ];

      let symptomes_list2 = [
        "manifestations_hemorragiques"
      ];

      let commonElements1 = symptomes_list1.filter(function(el) {
          return selected_arbo_symptomes.includes(el);
      });

      let commonElements2 = symptomes_list2.filter(function(el) {
          return selected_arbo_symptomes.includes(el);
      });

      if ((commonElements1.length >= 2 && this.temperature_fievre>='38') || (commonElements2.length >= 1 && this.temperature_fievre>='38')) {
          this.$store.commit("setSuggestBloodSampling",true);
      }else{
          this.$store.commit("setSuggestBloodSampling",false);
      }

    }
  }
};
</script>

<style scoped>
.prelevments_content {
  margin-bottom: 2%;
}
.prelevement_row {
  width: 60%;
  position: relative;
  left: 20%;
  top: -5%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.prelevement_question_checkbox {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 15px;
  gap: 10px;
  background: #ebfcfd;
  border-radius: 5px;
  margin-top: 5.5%;
}

.prelevement_date {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 15px;
  gap: 10px;
  border-radius: 5px;
}

.prelevement_checkbox {
  transform: scale(1.1);
}

.checkbox_label {
  font-family: 'Eastman Roman Trial';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #425169;
  position: relative;
  left: 4%;
}

.date_picker_icon {
  position: absolute;
  bottom: 20%;
  right: 10%;
  z-index: 200;
  transform: scale(0.75);
}

.prelevement_label {
  font-family: 'Eastman Roman Trial';
  font-style: normal;
  font-size: 16px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #425169;
}

.slide-fade-enter-active {
  transition: all 0.4s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(20px);
  opacity: 0;
}

.prelevement_nasal_date_picker {
  position: absolute;
  top: 60%;
  right: 105%;
  width: 90%;
  box-sizing: border-box;
  border: 2px solid #979797;
  border-radius: 10px;
  color: #262847 !important;
  z-index: 10000000;
  display: none;
}
.prelevement_gorge_date_picker {
  position: absolute;
  top: -50%;
  right: 105%;
  width: 90%;
  box-sizing: border-box;
  border: 2px solid #979797;
  border-radius: 10px;
  color: #262847 !important;
  z-index: 10000000;
  display: none;
}
.prelevement_sanguin_date_picker {
  position: absolute;
  bottom: -85%;
  right: 105%;
  width: 90%;
  box-sizing: border-box;
  border: 2px solid #979797;
  border-radius: 10px;
  color: #262847 !important;
  z-index: 10000000;
  display: none;
}

.prelevement_nasal_date_input,
.prelevement_gorge_date_input,
.prelevement_sanguin_date_input {
  border: 2px solid blue;
  width: 100%;
  margin-left: 10%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 10px 10px 20px;
  gap: 10px;
  background: #ffffff;
  border: 2px solid #425169;
  border-radius: 5px;
  height: 85%;
  position: relative;
  left: -5%;
}

.prelevement_nasal_date_input[readonly],
.prelevement_gorge_date_input[readonly],
.prelevement_sanguin_date_input[readonly] {
  background: #ffffff;
}

.main_questions {
  width: 40%;
  margin-left: 28%;
  display: flex;
  margin-top: 1%;
  height: 150px;
}

.question {
  display: flex;
  flex-direction: column;
  width: 40%;
  height: 100%;
  padding: 0;
  justify-content: center;
  align-items: center;
}
.question_field {
  height: 60%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.question_label {
  font-family: 'Eastman Roman Trial';
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 21px;
  text-align: center;
  color: #000000;
  opacity: 0.8;
  margin-bottom: 5%;
  position: relative;
  right: 3%;
}

.prelevement_urgent{
  margin-top: 5%;
  margin-right: 3%;
}

.prelevement_urgent_text{
  font-family: 'Eastman Trial';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  color: #FF6161;
}
</style>