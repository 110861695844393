import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import Vuesax from 'vuesax'
import Axios from 'axios'
import axios from 'axios'
import VueAxios from 'vue-axios'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vuesax/dist/vuesax.css'
import Textra from 'vue-textra'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'
import 'leaflet/dist/leaflet.css';
import L from 'leaflet'


// eslint-disable-next-line  
delete L.Icon.Default.prototype._getIconUrl  
// eslint-disable-next-line  
L.Icon.Default.mergeOptions({  
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),  
  iconUrl: require('leaflet/dist/images/marker-icon.png'),  
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')  
})

// import './registerServiceWorker'



Vue.use(Vuetify)
Vue.use(Vuesax)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueAxios, Axios)
Vue.use(Textra);
// Vue.use(Datepicker)

Vue.config.productionTip = false


Axios.defaults.baseURL = process.env.VUE_APP_ROOT_API

//use the token in all requests

axios.interceptors.request.use(config => {
  const token = localStorage.getItem("token");
  if(token){
      config.headers["Authorization"] = "Token "+token;
  }
  return config;
});


axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const { status } = error.response;
      if (status === 401) {
        console.log("session expirée.")
        console.log(store.state.token);
        localStorage.removeItem("token");
        localStorage.removeItem("isAuthenticated");
        store.commit("removeToken");
        store.commit("setIsAuthenticated",false);
        axios.defaults.headers.common["Authorization"] = "";
        window.location.href = '/login';
        window.history.pushState(null, '', window.location.href);
        window.onpopstate = function() {
          window.history.pushState(null, '', window.location.href);
        };
      }
      return Promise.reject(error);
  }
);

export const eventBus = new Vue();

new Vue({
  router,
  store,
  vuetify: new Vuetify(),
  created () {
    this.$vuetify.theme.dark = true
  },
  render: h => h(App)
}).$mount('#app')

