<template>
  <div class="personal_infos_content">
    <p class="title">Informations personnelles</p>
    <div class="civility_and_contacts">
      <div class="civility_section">
        <Civilty />
      </div>
      <div class="contacts_section">
        <AdressAndContact />
      </div>
    </div>
    <div class="status_section">
      <MatrimonialStatus />
    </div>
    <hr style="width:100%;" />
    <div class="next_btn_row">
      <b-button @click="goToNextSection()" variant="light" class="next_button">
        <span class="next_button_text">Suivant</span>

        <img class="next_right_icon" src="@/assets/next.png" />
      </b-button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Civilty from '@/components/fiche-individuelle/infos-personnelles/Civility.vue';
import AdressAndContact from '@/components/fiche-individuelle/infos-personnelles/AdressAndContact.vue';
import MatrimonialStatus from '@/components/fiche-individuelle/infos-personnelles/MatrimonialStatus.vue';

export default {
  name: 'PersonalInfos',
  components: {
    Civilty,
    AdressAndContact,
    MatrimonialStatus
  },
  data: () => ({}),
  mounted() {},
  computed: {
    ...mapState({
      showSuspisionOnboardSection: 'showSuspisionOnboardSection'
    })
  },
  methods: {
    goToNextSection() {
      this.$store.commit('setShowPersonalInfosSection', false);
      this.$store.commit('setShowSuspisionOnboardSection', true);
      this.$store.commit('setShowSuspisionSection', true);
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }
  }
};
</script>

<style scoped>
.personal_infos_content {
  overflow: scroll;
  width: 100%;
}

.title {
  font-family: 'Eastman Trial';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #425169;
  justify-content: center;
  margin-top: 3%;
}

.civility_and_contacts {
  display: flex;
}
.civility_section {
  width: 70%;
  /* border-left: 2px solid #eff2f6; */
}

.contacts_section {
  width: 28%;
}

.next_btn_row {
  display: flex;
  justify-content: flex-end;
  width: 98%;
  margin-bottom: 2%;
  margin-top: 1%;
}
.next_button {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  gap: 10px;
  background: #484ef7 !important;
  border-radius: 5px;
  box-shadow: none !important;
}
.next_button:active {
  transform: scale(0.98);
  opacity: 0.9;
}
.next_button_text {
  font-family: 'Eastman Roman Trial';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
}
.chevron_right_icon {
  background-color: #484ef7;
  color: #484ef7;
}
.next_right_icon {
  position: relative;
  left: 10%;
}
</style>
