<template>
  <div class="other_symptomes_content">
    <b-row style="margin-top:2%;" class="symptomes_row">
      <b-col class="symptomes_question_checkbox" cols="5">
        <b-form-checkbox
          class="symptome_checkbox"
          v-model="frisson"
          name="symptome_checkbox"
          value="Oui"
          unchecked-value="Non"
        >
          <span class="checkbox_label">Frisson</span>
        </b-form-checkbox>
      </b-col>
      <b-col cols="1"></b-col>
      <b-col class="symptomes_question_checkbox" cols="5">
        <b-form-checkbox
          class="symptome_checkbox"
          v-model="convulsion"
          name="symptome_checkbox"
          value="Oui"
          unchecked-value="Non"
        >
          <span class="checkbox_label">Convulsion</span>
        </b-form-checkbox>
      </b-col>
    </b-row>
    <b-row style="margin-top:3.5%;" class="symptomes_row">
      <b-col class="symptomes_question_checkbox" cols="5">
        <b-form-checkbox
          class="symptome_checkbox"
          v-model="stridor"
          name="symptome_checkbox"
          value="Oui"
          unchecked-value="Non"
        >
          <span class="checkbox_label">Stridor</span>
        </b-form-checkbox>
      </b-col>
      <b-col cols="1"></b-col>
      <b-col class="symptomes_question_checkbox" cols="5">
        <b-form-checkbox
          class="symptome_checkbox"
          v-model="tirage_sous_costal"
          name="symptome_checkbox"
          value="Oui"
          unchecked-value="Non"
        >
          <span class="checkbox_label">Tirage sous costal</span>
        </b-form-checkbox>
      </b-col>
    </b-row>

    <b-row style="margin-top:3.5%;" class="symptomes_row">
      <b-col class="symptomes_question_checkbox" cols="5">
        <b-form-checkbox
          class="symptome_checkbox"
          v-model="ictere"
          name="symptome_checkbox"
          value="Oui"
          unchecked-value="Non"
        >
          <span class="checkbox_label">Ictère</span>
        </b-form-checkbox>
      </b-col>
      <b-col cols="1"></b-col>
      <b-col class="symptomes_question_checkbox" cols="5">
        <b-form-checkbox
          class="symptome_checkbox"
          v-model="raideur_nuque"
          name="symptome_checkbox"
          value="Oui"
          unchecked-value="Non"
        >
          <span class="checkbox_label">Raideur de la nuque</span>
        </b-form-checkbox>
      </b-col>
    </b-row>

    <b-row style="margin-top:3.5%;" class="symptomes_row">
      <b-col class="symptomes_question_checkbox" cols="5">
        <b-form-checkbox
          class="symptome_checkbox"
          v-model="anemie_clinique"
          name="symptome_checkbox"
          value="Oui"
          unchecked-value="Non"
        >
          <span class="checkbox_label">Anémie clinique</span>
        </b-form-checkbox>
      </b-col>
      <b-col cols="1"></b-col>
      <b-col class="symptomes_question_checkbox" cols="5">
        <b-form-checkbox
          class="symptome_checkbox"
          v-model="refus_de_teter"
          name="symptome_checkbox"
          value="Oui"
          unchecked-value="Non"
        >
          <span class="checkbox_label">Refus de téter</span>
        </b-form-checkbox>
      </b-col>
    </b-row>

    <b-row style="margin-top:3.5%;" class="symptomes_row">
      <b-col class="symptomes_question_checkbox" cols="5">
        <b-form-checkbox
          class="symptome_checkbox"
          v-model="splenomegalie"
          name="symptome_checkbox"
          value="Oui"
          unchecked-value="Non"
        >
          <span class="checkbox_label">Splénomégalie</span>
        </b-form-checkbox>
      </b-col>
      <b-col cols="1"></b-col>
      <b-col class="symptomes_question_checkbox" cols="5">
        <b-form-checkbox
          class="symptome_checkbox"
          v-model="autres_symptomes"
          name="symptome_checkbox"
        >
          <span class="checkbox_label">Autres symptomes...</span>
        </b-form-checkbox>
      </b-col>
    </b-row>
    <b-row
      v-show="this.showOtherSymptomesInputField==true"
      style="margin-top:3%;"
      class="other_symptomes"
    >
      <b-col cols="3" class="first_name">
        <b-form-input
          class="other_symptomes_input input_field"
          v-model="autre_symptomes_input"
          type="text"
          name="other_symptomes"
          size="md"
          placeholder="Autres symptomes"
        ></b-form-input>
      </b-col>
    </b-row>
  </div>
</template>


<script>
import { mapState } from 'vuex';

export default {
  name: 'OtherSymptomesSection',
  components: {},
  data: () => ({
    convulsion: '',
    frisson: '',
    stridor: '',
    tirage_sous_costal: '',
    ictere: '',
    raideur_nuque: '',
    anemie_clinique: '',
    refus_de_teter: '',
    splenomegalie: '',
    autres_symptomes: '',
    autre_symptomes_input: '',
    showOtherSymptomesInputField: false
  }),
  computed: {
    ...mapState({})
  },
  watch: {
    autres_symptomes(val) {
      if (val === true) {
        this.showOtherSymptomesInputField = true;
      } else {
        this.showOtherSymptomesInputField = false;
      }
    },
    convulsion(val) {
      this.$store.commit('setConvulsion', val);
    },
    frisson(val) {
      this.$store.commit('setFrisson', val);
    },
    stridor(val) {
      this.$store.commit('setStridor', val);
    },
    tirage_sous_costal(val) {
      this.$store.commit('setTirageSousCostal', val);
    },
    ictere(val) {
      this.$store.commit('setIctere', val);
    },
    raideur_nuque(val) {
      this.$store.commit('setRaideurNuque', val);
    },
    anemie_clinique(val) {
      this.$store.commit('setAnemieClinique', val);
    },
    refus_de_teter(val) {
      this.$store.commit('setRefusDeTeter', val);
    },
    splenomegalie(val) {
      this.$store.commit('setSplenomegalie', val);
    },
    autre_symptomes_input(val) {
      this.$store.commit('setAutresSymptomes', val);
    }
  },
  methods: {}
};
</script>

<style scoped>
.other_symptomes_content {
  margin-bottom: 3%;
}
.symptomes_row {
  width: 60%;
  position: relative;
  left: 20%;
  top: -5%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.symptomes_question_checkbox {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 6px 15px;
  gap: 10px;
  background: #ebfcfd;
  border-radius: 5px;
}

.symptome_checkbox {
  transform: scale(1.1);
}

.checkbox_label {
  font-family: 'Eastman Roman Trial';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #425169;
  position: relative;
  left: 4%;
}

.other_symptomes {
  display: flex;
  margin-bottom: 1%;
  justify-content: center;
  width: 90%;
}

.input_field {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 10px 10px 20px;
  gap: 10px;
  background: #ffffff;
  border: 2px solid #425169;
  border-radius: 5px;
}
</style>