<template>
  <div class="matrimonial_status_section_content">
    <b-row class="row_elt">
      <b-col style="position: relative; left:2%;" class="status_block" cols="2">
        <span class="status_label">Statut matrimonial</span>
      </b-col>
      <b-col class="status_block" cols="3">
        <b-form-select class="select_input" v-model="statut_matrimonial" :options="options"></b-form-select>
      </b-col>

      <b-col style="position: relative; left:4%;" class="status_block" cols="2">
        <span class="status_label">Numéro de régistre</span>
      </b-col>
      
      <b-col cols="3">
          <b-form-input
            autocomplete="off"
            class="input_field"
            v-model="no_registre"
            type="text"
            size="md"
            placeholder="Numéro de régistre"
          ></b-form-input>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'MatrimonialStatusSection',
  components: {},
  data: () => ({
    statut_matrimonial: null,
    options: [
      { value: null, text: 'Statut matrimonial du patient' },
      { value: 'Indéfini', text: 'Indéfini' },
      { value: 'Célibataire', text: 'Célibataire' },
      { value: 'Marié(e)', text: 'Marié(e)' },
      { value: 'Divorcé(e)', text: 'Divorcé(e)' },
      { value: 'Veuf/veuve', text: 'Veuf/Veuve' }
    ]
  }),
  watch: {
    statut_matrimonial() {
      this.$store.commit('setStatutMatrimonial', this.statut_matrimonial);
    }
  },
  mounted() {},
  computed: {
    ...mapState({}),
    no_registre : {
      get() {
        return this.$store.state.no_registre;
      },
      set(value){
        this.$store.commit('setNoRegistre', value);
      }
    },
  },
  methods: {
    formatChildren(e) {
      return String(e).substring(0, 2);
    }
  }
};
</script>

<style scoped>
.matrimonial_status_section_content {
  height: 100%;
}

.status_label {
  font-family: 'Eastman Trial';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: rgba(66, 81, 105, 0.78);
}

.row_elt {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2%;
  position: relative;
  right: 5%;
}

.status_block {
  display: flex;
  justify-content: center;
  align-items: center;
}

.select_input {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  gap: 185px;
  border: 2px solid #425169;
  border-radius: 5px;
  height: 110%;
}

.input_field{
  width: 90%;
  height: 120%;
  margin-left: 10%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 10px 10px 20px;
  gap: 10px;
  background: #ffffff;
  border: 2px solid #425169;
  border-radius: 5px;
}
</style>
