<template>
    <b-container fluid class="pie_content">
      <div class="filter_block">
        <div class="date_row">

          <transition class="slide-fade" name="slide-fade" mode="out-in">
            <v-date-picker
              close-on-content-click
              id="date_debut_picker"
              color="#3CB691"
              locale="fr"
              class="date_debut_picker"
              v-model="date_debut"
              width="100%"
              no-title
            ></v-date-picker>
          </transition>

          <b-form-input
            id="date_debut_input"
            @click="showDateDebutPicker"
            class="date_debut_input shadow-none"
            readonly
            type="text"
            v-model="date_debut_formatted"
            name="date_debut"
            size="md"
            placeholder="Date de debut"
          ></b-form-input>

          <div class="date_delimiter"></div>

          <transition class="slide-fade" name="slide-fade" mode="out-in">
            <v-date-picker
              close-on-content-click
              id="date_fin_picker"
              color="#3CB691"
              locale="fr"
              class="date_fin_picker"
              v-model="date_fin"
              width="100%"
              no-title
            ></v-date-picker>
          </transition>

          <b-form-input
            id="date_fin_input"
            @click="showDateFinPicker"
            class="date_fin_input shadow-none"
            readonly
            type="text"
            v-model="date_fin_formatted"
            name="date_fin"
            size="md"
            placeholder="Aujourd'hui"
          ></b-form-input>
          <img class="date_picker_icon" src="@/assets/dashboard_date_picker.svg" />
      </div>

      <div class="district_choice">
        <b-dropdown no-caret variant="#FFFFFF" class="shadow-none" id="district_dropdown" ref="districts_dropdown" no-close-on-select>
          <template #button-content>
              <span class="district_dropdown_label" >District(s)</span>
              <span class="number_of_districts">
                {{number_of_districts}}
              </span>
            <img class="arrow_icon" src="@/assets/dropdown_white.svg" alt="districts surveillés" />
          </template>
          <div class="dropdown_content">
            <b-dropdown-item class="dropdown_item district_dropdown_item" @click.capture.native.stop="handleDistrictClick" v-for="district in districts_list" :key="district">
                {{ district }}
            </b-dropdown-item>
          </div>
          <b-dropdown-divider></b-dropdown-divider>
          <div class="dropdown_action_btn">
            <b-button @click="selectAllDistricts()" class="select_all_disctricts_btn shadow-none" variant="outline-primary">Tous</b-button>
            <b-button @click="validateSelectedDistricts()" class="validate_selected_districts shadow-none" variant="primary">OK</b-button>
          </div>
        </b-dropdown>
      </div>

      <div class="site_choice">
        <b-dropdown no-caret variant="#FFFFFF" class="shadow-none" id="site_dropdown" ref="sites_dropdown" no-close-on-select>
          <template #button-content>
              <span class="site_dropdown_label" >Site(s)</span>
              <span class="number_of_sites">
                {{number_of_sites}}
              </span>
            <img class="arrow_icon" src="@/assets/dropdown_white.svg" alt="sites surveillés" />
          </template>
          <div class="dropdown_content">
            <b-dropdown-item class="dropdown_item site_dropdown_item" @click.capture.native.stop="handleSiteClick" v-for="site in sites_list" :key="site.id">
                {{ site.nom }}
            </b-dropdown-item>
          </div>
          <b-dropdown-divider></b-dropdown-divider>
          <div class="dropdown_action_btn">
            <b-button @click="selectAllSites()" class="select_all_sites_btn shadow-none" variant="outline-primary">Tous</b-button>
            <b-button @click="validateSelectedSites()" class="validate_selected_sites shadow-none" variant="primary">OK</b-button>
          </div>
        </b-dropdown>
      </div>

      <div class="age_choice">
        <b-dropdown no-caret variant="#FFFFFF" id="age_interval_dropdown" ref="age_dropdown" class="age_dropdown" no-close-on-select>
            <template #button-content>
              <span class="age_dropdown_label">Ages</span>
              <span class="current_age">
                {{current_age_value}}
              </span>
              <img class="arrow_up_icon" src="@/assets/arrow_up.svg" alt="intervalle age" />
            </template>
            <div class="dropdown_header">
                <b-dropdown-item @click.capture.native.stop="handleCustomAgeClick" class="custom_age_item">                
                  <b-form-radio class="radio" v-model="is_customing_age" name="custom_age" value="true"> <span class="age_custom_label">Personnalisé (ans)</span></b-form-radio>
                </b-dropdown-item>
            </div>

            <div class="dropdown_body">
                <div class="age_inputs">
                      <b-form-input class="custom_age_min_input" :formatter="formatMinAge" v-model="custom_age_min" placeholder="min" type="number" :readonly="!is_customing_age"></b-form-input>
                      <div class="age_input_delimiter"></div>
                      <b-form-input class="custom_age_max_input" :formatter="formatMaxAge" v-model="custom_age_max" placeholder="max" type="number" :readonly="!is_customing_age"></b-form-input>
                </div>

                <b-dropdown-divider></b-dropdown-divider>

                <b-dropdown-item @click.capture.native.stop="handleAgeChoice" v-for="item in age_interval_list" :key="item.id">
                  <b-form-radio class="radio" v-model="custom_age" name="custom_age" :value="item.value"> 
                    <span class="age_choice_label">{{item.value}} </span>  
                  </b-form-radio>
                </b-dropdown-item>
                
                <b-dropdown-divider></b-dropdown-divider>

            </div>

            <div class="dropdown_footer">
                <b-button @click="createNewAgeInterval()" class="validate_button shadow-none"> <span class="validate_btn_text">Valider</span></b-button>
                <b-button @click="removeAge()" class="remove_button shadow-none"> <b-icon style="color:#FFFFFF;" icon="trash" ></b-icon> </b-button>
            </div>

          </b-dropdown>
      </div>

      <b-dropdown no-caret variant="#FFFFFF" id="gender_dropdown" ref="gender_dropdown">
        <template #button-content>
          <span class="gender_dropdown_label">Genre</span>
          <span class="current_gender">
            {{gender}}
          </span>
          <img class="arrow_up_icon" src="@/assets/arrow_up.svg" alt="genre" />
        </template>

          <b-dropdown-item @click="handleGenderClick" class="gender_item">                
            <span class="gender_custom_label">Homme</span>
          </b-dropdown-item>

          <b-dropdown-item @click="handleGenderClick" class="gender_item">                
            <span class="gender_custom_label">Femme</span>
          </b-dropdown-item>

          <b-dropdown-item @click="handleGenderClick" class="gender_item">                
            <span class="gender_custom_label">Homme/Femme</span>
          </b-dropdown-item>
      </b-dropdown>

      <div class="syndromes_choice">
        <b-dropdown no-caret variant="#FFFFFF" class="shadow-none" id="syndrome_dropdown" ref="syndrome_dropdown" no-close-on-select>
        <b-dropdown-header>Syndrômes</b-dropdown-header>

          <template #button-content>
            <span class="syndrome_dropdown_label">{{current_syndrome}}</span>
            <img class="arrow_icon" src="@/assets/dropdown_white.svg" alt="syndromes surveillés" />
          </template>

          <div class="dropdown_content">
            <b-dropdown-item class="dropdown_item" @click="handleSyndromeSelect" v-for="syndrome in syndromes_list" :key="syndrome.id">
              {{ syndrome.nom }}
            </b-dropdown-item>
          </div>

        </b-dropdown>
      </div>

      <div class="filter_action_btn">
        <b-button @click="filter(true)"  class="filter_button shadow-none"> <span class="filter_btn_text">Filtrer</span></b-button>
      </div>

    </div>

    </b-container>
</template>
  
  <script>
  
  import { mapState } from 'vuex';
  import { eventBus } from '@/main.js';

  export default {
    name: 'DashboardFilter',
    components: { 

    },
    data : vm => ({
      date_debut : new Date(new Date().getFullYear(),new Date().getMonth(),1).toISOString().substr(0, 10),
      date_fin : new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      date_debut_formatted : vm.formatDate(new Date(new Date().getFullYear(),new Date().getMonth(),1).toISOString().substr(0, 10)),
      date_fin_formatted : vm.formatDate(new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)),
      // selected_districts:[],
      number_of_districts: 0,
      ellipsis:"...",
      show_district_ellipsis:false,
      // selected_sites:[],
      test: [],
      number_of_sites: 0,
      show_site_ellipsis:false,
      current_age_value : 'non définie',
      custom_age: '',
      custom_age_min: '',
      custom_age_max: '',
      is_customing_age : false,
      gender:'Homme/Femme',
      current_syndrome:'Paludisme',
      isMounted: false
  }),
    methods: {
      checkMounted() {
      if (this.isMounted) {
        return true;
      } else {
        this.$nextTick(() => {
          this.checkMounted();
        });
      }
      },
      removeAge(){
        let indexToRemove = this.age_interval_list.findIndex(element => element.value === this.current_age_value);
        if (indexToRemove !== -1) {
          this.age_interval_list.splice(indexToRemove, 1);
          let current_age_interval_list = JSON.parse(localStorage.getItem("age_interval_list"));
          current_age_interval_list.splice(indexToRemove, 1);
          localStorage.setItem("age_interval_list", JSON.stringify(current_age_interval_list));
          this.$store.commit("initialize");
        }
        this.$refs.age_dropdown.hide(true);
      },
      showDateDebutPicker() {
        let dateElt = document.getElementById('date_debut_picker');
        dateElt.style.display = 'block';
      },
      showDateFinPicker() {
        let dateElt = document.getElementById('date_fin_picker');
        dateElt.style.display = 'block';
      },
      formatDate(date) {
        if (!date) return null;
        const [year, month, day] = date.split('-');
        return `${month}/${day}/${year}`;
      },
      formatMinAge(e) {
      return String(e).substring(0, 2);
      },
      formatMaxAge(e) {
      return String(e).substring(0, 2);
      },
      handleDistrictClick(e){

        if(e.target=='_self'){
          let i = this.districts_to_filter.indexOf(e.textContent.trim());        
          if (i >= 0) {
            if(this.districts_to_filter.length>1){
              this.districts_to_filter.splice(i, 1);
              this.number_of_districts-=1;
              e.style.background="#ffffff";
              e.style.color="#262847";
            }
          } else {
            this.districts_to_filter.push(e.textContent.trim());
            this.number_of_districts+=1;
            e.style.background="#007bff";
            e.style.color="#ffffff";
          }
        }
        else{
          let i = this.districts_to_filter.indexOf(e.target.textContent.trim());
          if (i >= 0) {
            if(this.districts_to_filter.length>1){
              this.districts_to_filter.splice(i, 1);
              this.number_of_districts-=1;
              e.target.style.background="#ffffff";
              e.target.style.color="#262847";
            }
          } else {
            this.districts_to_filter.push(e.target.textContent.trim());
            this.number_of_districts+=1;
            e.target.style.background="#007bff";
            e.target.style.color="#ffffff";
          }
        }
      },
      selectAllDistricts(){
        let districtElts;

        this.$nextTick(() => {
          districtElts = document.querySelectorAll(".district_dropdown_item");

          districtElts.forEach(district => {
            let i = this.districts_to_filter.indexOf(district.firstChild.textContent.trim());
            if(i<0){
              this.handleDistrictClick(district.firstChild);
            }
          });
          this.$refs.districts_dropdown.hide(true)
        });
      },
      validateSelectedDistricts(){
        this.$refs.districts_dropdown.hide(true)
      },
      handleSiteClick(e){

        if(e.target=='_self'){

          let i = this.sites_to_filter.indexOf(e.textContent.trim());
          if (i >= 0) {
            if(this.districts_to_filter.length>1){
              this.sites_to_filter.splice(i, 1);
              this.number_of_sites-=1;
              e.style.background="#ffffff";
              e.style.color="#262847";
            }
          } else {
              this.sites_to_filter.push(e.textContent.trim());
              this.number_of_sites+=1;
              e.style.background="#007bff";
              e.style.color="#ffffff";
          }
        }else{

          let i = this.sites_to_filter.indexOf(e.target.textContent.trim());
          if (i >= 0) {
            if(this.districts_to_filter.length>1){
              this.sites_to_filter.splice(i, 1);
              this.number_of_sites-=1;
              e.target.style.background="#ffffff";
              e.target.style.color="#262847";
            }
          } else {
              this.sites_to_filter.push(e.target.textContent.trim());
              this.number_of_sites+=1;
              e.target.style.background="#007bff";
              e.target.style.color="#ffffff";
          }
        }
      },
      selectAllSites(){

        let siteElts;

        this.$nextTick(() => {
          siteElts = document.querySelectorAll(".site_dropdown_item");

          siteElts.forEach(site => {
              let i = this.sites_to_filter.indexOf(site.firstChild.textContent.trim());
              if(i<0){
                this.handleSiteClick(site.firstChild);
              }
          });
          this.$refs.sites_dropdown.hide(true)
        });
      },
      validateSelectedSites(){
        this.$refs.sites_dropdown.hide(true)
      },
      handleAgeChoice(e){
        this.is_customing_age=false;
        let targetElt = e.target;

        if(targetElt.classList.contains("age_choice_label")){
          let ageValue = targetElt.textContent;
          this.custom_age = ageValue;
        }else{
          if(typeof targetElt.children[1] != 'undefined' && targetElt.children[1].hasChildNodes()){
            let ageValue = targetElt.children[1].firstChild.textContent;
            this.custom_age = ageValue;
          }
        }
      },
      handleCustomAgeClick(){
      },
      createNewAgeInterval(){
        let min = this.custom_age_min;
        let max = this.custom_age_max;

        if(min!='' && max!=''){
          let newAgeVal = min + ' - ' + max + ' ans';
          let ageElts = document.querySelectorAll(".age_choice_label");

          let alreadyExists = false;

          ageElts.forEach(element => {
            if(element.textContent == newAgeVal){
              alreadyExists=true;
            }
          });

          if(alreadyExists==false){
            let eltToAdd = {id:this.age_interval_list.length, value:newAgeVal}
            this.age_interval_list.push(eltToAdd);
            this.custom_age_min='';
            this.custom_age_max='';
            let current_age_interval_list = JSON.parse(localStorage.getItem("age_interval_list"));
            current_age_interval_list.push({id:current_age_interval_list.length, value :newAgeVal});
            localStorage.setItem("age_interval_list", JSON.stringify(current_age_interval_list));
            this.$store.commit("initialize");
          }
          this.$refs.age_dropdown.hide(true)
      }else{
          this.$refs.age_dropdown.hide(true)
      }
      },
      handleGenderClick(e){
        this.gender = e.target.firstChild.textContent;
      },
      handleSyndromeSelect(e){
        this.current_syndrome = e.target.firstChild.textContent;
      },
      async filter(filterMap){

        this.$store.commit("setIsLoading",true);


        eventBus.$emit('getIndicators');

        let parameters;

        let site_surveille;
        let date_debut = this.date_debut;
        let date_fin = this.date_fin;
        let districts = this.districts_to_filter;
        let sites = this.sites_to_filter;
        let age = this.current_age_value;
        let genre = this.gender.trim().toLowerCase();
        let syndrome = this.current_syndrome.trim().toLowerCase();
        let chart_group_by = this.chart_group_by.trim().toLowerCase();
        let report_group_by = this.report_group_by.trim().toLowerCase();
        let view = this.chart_view.trim().toLowerCase();
        let age_interval_list = JSON.parse(localStorage.getItem("age_interval_list"));
        let age_interval_list_values = [];

        age_interval_list.forEach(element => {
          age_interval_list_values.push(element.value);
        });


        if(localStorage.getItem("nom_site")){
          site_surveille = localStorage.getItem("nom_site");
        }else{
            let user = JSON.parse(localStorage.getItem("user"));
            let user_role = user.role.nom_role;

            if(user_role=="SUPERVISEUR"){
              site_surveille = "SENEGAL";
            }
        }

        if(view==''){
          view = 'semaine';
        }

        if(chart_group_by==''){
          chart_group_by = 'sexe';
        }

        if(report_group_by==''){
          report_group_by = 'sexe';
        }

        if(chart_group_by=='sexe' && report_group_by=='sexe'){
          parameters = {
          date_debut: date_debut,
          date_fin: date_fin,
          districts: districts,
          sites: sites,
          age: age,
          genre: genre,
          syndrome: syndrome,
          group_by: chart_group_by,
          view_per: view,
          age_interval_list: [],
          site_surveille: site_surveille,
          report_group_by: report_group_by
          }
        }else if(chart_group_by=='age' && report_group_by=='sexe'){
            parameters = {
            date_debut: date_debut,
            date_fin: date_fin,
            districts: districts,
            sites: sites,
            age: age,
            genre: genre,
            syndrome: syndrome,
            group_by: chart_group_by,
            view_per: view,
            age_interval_list: age_interval_list_values,
            site_surveille: site_surveille,
            report_group_by: report_group_by
            }
        }else if(chart_group_by=='sexe' && report_group_by=='age'){
            parameters = {
            date_debut: date_debut,
            date_fin: date_fin,
            districts: districts,
            sites: sites,
            age: age,
            genre: genre,
            syndrome: syndrome,
            group_by: chart_group_by,
            view_per: view,
            age_interval_list: age_interval_list_values,
            site_surveille: site_surveille,
            report_group_by: report_group_by
        }
        }

        console.log(parameters);

        await this.axios
        .post('/api/filter/', parameters)
        .then(response => {

            if(filterMap==true){
            // construct map data structure

            let map_result_data = response.data.map_dataset;
            this.$store.commit('setSelectedSites', []);
            let new_selected_sites = [];

            for (let key in map_result_data) {
                this.sites_list.forEach(site => {
                  if(site.nom==key){
                    site.total_suspecte = map_result_data[key].total_suspecte;
                    new_selected_sites.push(site);
                  }
                });
            }
            this.$store.commit('setSelectedSites', new_selected_sites);
            eventBus.$emit('updateMap');
            }

            // construct bar chart and line data structure

            let bar_chart_result_data = response.data.bar_chart_dataset.data;
            let bar_chart_result_labels = response.data.bar_chart_dataset.labels;
            bar_chart_result_labels = [...new Set(bar_chart_result_labels)];
            
            let line_chart_result_data = response.data.line_chart_dataset.data;
            let line_chart_result_labels = response.data.line_chart_dataset.labels;
            line_chart_result_labels = [...new Set(line_chart_result_labels)];

            if(chart_group_by=="sexe"){

              // bar chart config

              this.$store.state.bar_chart_list=[];

              for (let key1 in bar_chart_result_data) {
                let chart_title = "Répartition par sexe du site de " + key1;
                let result_elt = bar_chart_result_data[key1];
                let chart_ctx = {
                        data: {
                          labels: bar_chart_result_labels,
                          datasets: []
                        },
                        options: {
                          plugins:{
                            title: {
                              display: true,
                              text: chart_title
                            },
                            tooltips: {
                              mode: 'index',
                              intersect: true
                            },
                            responsive: true,
                            legend: {
                              display: true,
                              position: 'bottom'
                              },
                            scales: {
                              x: {
                                stacked: true,
                              },
                              y: {
                                stacked: true
                              }
                            }	
                        }
                        }
                      }
                for (let key2 in result_elt){
                  if(key2 == 'hommes_suspectes'){
                    let data_elt = {
                      label: 'Hommes suspectés',
                      backgroundColor: '#8E8BF4',
                      stack: 'Stack 0',
                      data: result_elt[key2]
                    }
                  chart_ctx.data.datasets.push(data_elt);
                }else if(key2 == 'femmes_suspectees'){
                   let data_elt = {
                    label: 'femmes suspectées',
                    backgroundColor: '#BEBCFF',
                    stack: 'Stack 0',
                    data: result_elt[key2]
                  }
                  chart_ctx.data.datasets.push(data_elt);
                }
              }
              this.$store.state.bar_chart_list.push(chart_ctx);
            }
            eventBus.$emit('updateBarChart');


          // line chart config

          this.$store.state.line_chart_list=[];

              for (let key1 in line_chart_result_data) {
                let chart_title = "Courbe d'évolution par sexe du site de " + key1;
                let result_elt = line_chart_result_data[key1];
                let chart_ctx = {
                        data: {
                          labels: line_chart_result_labels,
                          datasets: []
                        },
                        options: {
                          fill: true,
                          plugins:{
                            responsive: true,
                            maintainAspectRatio : false,
                            legend: {
                              "position" : 'bottom'
                              },
                            title: {
                              display: true,
                              text: chart_title
                            },
                            tooltips: {
                              mode: 'index',
                              intersect: false
                            },
                            hover: {
                              mode: 'nearest',
                              intersect: true
                            },
                            scales: {
                              xAxes: [{
                                labelAngle: 0,
                                ticks: {
                                  padding: 0,
                                },
                                labelTextAlign: 'center',
                                display: true,
                                scaleLabel: {
                                  display: false,
                                  labelString: 'x_label'
                                },
                                align:'center',
                                gridLines: {
                                  offsetGridLines: false,
                                  drawOnChartArea: false,
                                }
                              }],
                              yAxes: [{
                                display: true,
                                scaleLabel: {
                                  display: false,
                                  labelString: 'y_label'
                                }
                              }]		
                            }	
                        }
                        }
                      }
                for (let key2 in result_elt){
                  if(key2 == 'hommes_suspectes'){
                    let data_elt = {
                      label: 'hommes suspectés',
                      backgroundColor: '#BEBCFF',
                      borderColor: '#8E8BF4',
                      data: result_elt[key2],
                      fill: true,
                    }
                  chart_ctx.data.datasets.push(data_elt);
                }else if(key2 == 'femmes_suspectees'){
                    let data_elt = {
                      label: 'femmes suspectées',
                      backgroundColor: '#FFBFD5',
                      borderColor: '#FE89B1',
                      data: result_elt[key2],
                      fill: true,
                    }
                  chart_ctx.data.datasets.push(data_elt);
                }
              }
              this.$store.state.line_chart_list.push(chart_ctx);
            }
            eventBus.$emit('updateLineChart');
        }else if(chart_group_by=="age"){

          let colors_list = [
            "#BEBCFF",
            "#FFBFD5",
            "#74b9ff",
            "#a5b1c2",
            "#34495e",
            "#ccae62",
            "#f7f1e3",
            "#2c2c54",
            "#227093",
            "#ffb8b8"
          ]

          let color_index = 0;

          // bar chart config

          this.$store.state.bar_chart_list=[];

          for (let key1 in bar_chart_result_data) {

            let chart_title = "Répartition par age du site de " + key1;
            let result_elt = bar_chart_result_data[key1];
            let chart_ctx = {
                      data: {
                          labels: bar_chart_result_labels,
                          datasets: []
                      },
                      options: {
                          plugins:{
                            title: {
                              display: true,
                              text: chart_title
                            },
                            tooltips: {
                              mode: 'index',
                              intersect: true
                            },
                            responsive: true,
                            legend: {
                              display: true,
                              position: 'bottom'
                              },
                            scales: {
                              x: {
                                stacked: true,
                              },
                              y: {
                                stacked: true
                              }
                            }	
                        }
                        }
                      }
                for (let key2 in result_elt){
                    let data_elt = {
                      label: key2,
                      backgroundColor: colors_list[color_index],
                      data: result_elt[key2]
                    }
                  chart_ctx.data.datasets.push(data_elt);
                  if (color_index<9){
                    color_index+=1;
                  }else{
                    color_index=0;
                  }
              }
              this.$store.state.bar_chart_list.push(chart_ctx);
            }
            eventBus.$emit('updateBarChart');
            color_index=0;

          // line chart config

          this.$store.state.line_chart_list=[];

              for (let key1 in line_chart_result_data) {
                let chart_title = "Courbe d'évolution par age du site de " + key1;
                let result_elt = line_chart_result_data[key1];
                let chart_ctx = {
                        data: {
                          labels: line_chart_result_labels,
                          datasets: []
                        },
                        options: {
                          fill: true,
                          plugins:{
                            responsive: true,
                            maintainAspectRatio : false,
                            legend: {
                              "position" : 'bottom'
                              },
                            title: {
                              display: true,
                              text: chart_title
                            },
                            tooltips: {
                              mode: 'index',
                              intersect: false
                            },
                            hover: {
                              mode: 'nearest',
                              intersect: true
                            },
                            scales: {
                              xAxes: [{
                                labelAngle: 0,
                                ticks: {
                                  padding: 0,
                                },
                                labelTextAlign: 'center',
                                display: true,
                                scaleLabel: {
                                  display: false,
                                  labelString: 'x_label'
                                },
                                align:'center',
                                gridLines: {
                                  offsetGridLines: false,
                                  drawOnChartArea: false,
                                }
                              }],
                              yAxes: [{
                                display: true,
                                scaleLabel: {
                                  display: false,
                                  labelString: 'y_label'
                                }
                              }]		
                            }	
                        }
                        }
                      }
                for (let key2 in result_elt){
                    let data_elt = {
                      label: key2,
                      backgroundColor: colors_list[color_index],
                      borderColor: colors_list[color_index],
                      data: result_elt[key2],
                      fill: true,
                    }
                  chart_ctx.data.datasets.push(data_elt);
                  if (color_index<9){
                    color_index+=1;
                  }else{
                    color_index=0;
                  }  
              }
              this.$store.state.line_chart_list.push(chart_ctx);         
            }
            eventBus.$emit('updateLineChart');
            color_index=0;

        }

        // config pie chart

        let pie_chart_colors = [
          "#956E20",
          "#FF5D73",
          "#F0B7B3",
          "#A833B9",
          "#41337A",
          "#C2EFEB",
          "#1C5253",
          "#9E7B9B",
          "#EFD780",
          "#6B2D5C",
          "#FF66B3",
          "#007CBE",
          "#24BA73"
        ];

        let selected_colors = [];
        let n = response.data.pie_chart_dataset.labels.length;

        let indices = Array.from({length: n}, (_, i) => i);

        for (let i = indices.length - 1; i > 0; i--) {
          let j = Math.floor(Math.random() * (i + 1));
          [indices[i], indices[j]] = [indices[j], indices[i]];
        }

        let selectedIndices = indices.slice(0, n);

        for (let i = 0; i < selectedIndices.length; i++) {
          let index = selectedIndices[i];
          selected_colors.push(pie_chart_colors[index]);
        }


        let pie_chart_result_data = response.data.pie_chart_dataset.data;
        let pie_chart_result_labels = response.data.pie_chart_dataset.labels;
        pie_chart_result_labels = [...new Set(pie_chart_result_labels)];

        this.$store.state.pie_chart.data.labels = pie_chart_result_labels;
        this.$store.state.pie_chart.data.datasets[0].data = pie_chart_result_data;
        this.$store.state.pie_chart.data.datasets[0].backgroundColor = selected_colors;
        this.$store.state.pie_chart.options.plugins.title.text= site_surveille;
        eventBus.$emit('updatePieChart');

        this.$store.commit("setIsLoading",false);

        })
        .catch(error => {
          console.log(JSON.stringify(error));
          this.$store.commit("setIsLoading",false);
        });

      }
    },
    watch:{
      date_debut(val) {
        let dateElt = document.getElementById('date_debut_picker');
        setTimeout(() => {
          //
        }, 100);
        dateElt.style.display = 'none';
        this.date_debut_formatted = this.formatDate(val);
        this.$store.commit('setFilterDateDebut', val);
      },
      date_fin(val) {
        let dateElt = document.getElementById('date_fin_picker');
        setTimeout(() => {
          //
        }, 100);
        dateElt.style.display = 'none';
        this.date_fin_formatted = this.formatDate(val);
        this.$store.commit('setFilterDateFin', val);
      },
      districts_to_filter(){
        if(this.districts_to_filter.length>2){
          this.show_district_ellipsis=true
        }else{
          this.show_district_ellipsis=false
        }
      },
      sites_to_filter(){
        if(this.sites_to_filter.length>2){
          this.show_site_ellipsis=true
        }else{
          this.show_site_ellipsis=false
        }
      },
      current_syndrome(val){
        this.$store.commit("setCurrentSyndrome",val);
      },
      custom_age(val){
        this.current_age_value=val;
      },
      districts_list(val){
        if(val.length>0){
          this.$store.commit("setIsHealthStructureLoaded",true);
        }
      },
      // Opération N°4 : choisir la structure sanitaire du user et sont site (dépend de l'opération 2) :ok
      isHealthStructureLoaded(val){
        if(val==true){
          this.$nextTick(() => {
            let agent_district = localStorage.getItem("nom_district");
            let agent_site = localStorage.getItem("nom_site");
            let districtsElt = document.querySelectorAll(".district_dropdown_item");
            let sitesElt = document.querySelectorAll(".site_dropdown_item");

              districtsElt.forEach(district => {
                let val = district.children[0].firstChild.textContent.trim()
                if(val==agent_district){
                  this.handleDistrictClick(district.firstChild);
                }
              });

            sitesElt.forEach(site => {
              let val = site.children[0].firstChild.textContent.trim();
              if(val==agent_site){
                this.handleSiteClick(site.firstChild);
              }
            });
            // this.$store.commit("setIsFilterLoaded",true);
        });
        }
      },
      isStructuresLoaded(val){

        if(val==true){
          this.selectAllDistricts();
          this.selectAllSites();
          this.$store.commit("setIsStructuresSelected",true);
        }
      },
      isStructuresSelected(val){
        if(val==true){

          let is_mounted = this.checkMounted();

          if(is_mounted==true){

            this.$nextTick(() => {
              document.querySelector(".filter_button").click();
            });
          }
          this.$store.commit("setIsLoading",false);
        }
      }

    },
    computed: {
      ...mapState({
        sites_list:'sites_list',
        selected_sites_from_state:'selected_sites',
        syndromes_list:'syndromes_list',
        districts_list:'districts_list',
        age_interval_list:'age_interval_list',
        chart_view: 'chart_view',
        chart_group_by: 'chart_group_by',
        report_group_by: 'report_group_by',
        bar_chart_list: 'bar_chart_list',
        isHealthStructureLoaded: 'isHealthStructureLoaded',
        isFilterLoaded: 'isFilterLoaded',
        isStructuresLoaded: 'isStructuresLoaded',
        isStructuresSelected: 'isStructuresSelected',
        sites_to_filter: 'sites_to_filter',
        districts_to_filter: 'districts_to_filter'
      })
    },
    mounted() {

    this.$nextTick(() => {
      this.isMounted = true;
    });

      window.addEventListener('click', function(e) {
        let datePickerElt = document.getElementById('date_debut_picker');
        if (datePickerElt != null) {
          if (document.getElementById('date_debut_picker').contains(e.target)) {
            // Clicked in box
          } else {
            if (document.getElementById('date_debut_input').contains(e.target)) {
              document.getElementById('date_debut_picker').style.display = 'block';
            } else {
              document.getElementById('date_debut_picker').style.display = 'none';
            }
          }
        }
      });

      window.addEventListener('click', function(e) {
        let datePickerElt = document.getElementById('date_fin_picker');
        if (datePickerElt != null) {
          if (document.getElementById('date_fin_picker').contains(e.target)) {
            // Clicked in box
          } else {
            if (document.getElementById('date_fin_input').contains(e.target)) {
              document.getElementById('date_fin_picker').style.display = 'block';
            } else {
              document.getElementById('date_fin_picker').style.display = 'none';
            }
          }
        }
      });
    },
  };
  </script>
  <style scoped>

.filter_content{
    display: flex;
    justify-content: flex-start;
}

.filter_block{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 10px;
    gap: 8px;
    background: #425169;
    border: 1px solid #D9D9D9;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
}

.slide-fade-enter-active {
  transition: all 0.4s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(20px);
  opacity: 0;
}

.date_debut_picker,
.date_fin_picker {
  position: absolute;
  top: 0%;
  left: 100%;
  width: 80%;
  box-sizing: border-box;
  border: 2px solid #979797;
  border-radius: 10px;
  color: #262847 !important;
  z-index: 9999999999999;
  display: none;
}

.input_field,
.date_debut_input,
.date_fin_input {
  width: 100%;
  height: 120%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #425169;
  color: #ffffff !important;
  border: none;
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 17px;
}

#date_debut_input{
  display: flex !important;
  justify-content: flex-end !important;
}

#date_fin_input{
  display: flex;
  justify-content: center;
}

.date_debut_input[readonly],
.date_fin_input[readonly] {
  background: #425169;
  border: none !important;
  color: #ffffff !important;
}

.date_row{
  width: 100%;
  display: flex;
  align-items: center;
}

.date_delimiter{
  border: 1px solid #ffffff;
  height: 10px;
  transform: rotate(90deg);
  position: relative;
  right: 5%;
}

#district_dropdown,
#site_dropdown,
#syndrome_dropdown,
#age_interval_dropdown{
  margin: 0%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start !important;
  border: none !important;
  box-shadow: none !important;
}

#district_dropdown:active{
  border: none !important;
  box-shadow: none !important;
}

.district_dropdown_label,
.site_dropdown_label,
.age_dropdown_label,
.gender_dropdown_label,
.syndrome_dropdown_label{
  font-family: 'Eastman Roman Trial';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: #FFFFFF;
}

.arrow_icon{
  margin-left: 10%;
}

.number_of_districts,
.number_of_sites{
  border: 1px solid #CED0FF;
  margin: 0;
  border-radius: 50%;
  margin-left: 10%;
  padding: 2px 3px;
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  color: #FFFFFF;
}

.dropdown_item{
  height: 20%;
  margin-top: 4%;
  background: #ffffff !important;
  font-family: 'Eastman Roman Trial';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: #FFFFFF;
}

.dropdown_item:active{
  background: #ffffff !important;
}

.selected_districts,
.selected_sites{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 4%;
}

.district_elt,
.site_elt, 
.ellipsis{
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 17px;
  color: #C7C9FF;
}

.dropdown_action_btn{
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.select_all_disctricts_btn,
.select_all_sites_btn{
  color: #007bff;
}

.validate_selected_districts,
.validate_selected_sites{
  color: #ffffff;
}

.select_all_disctricts_btn:active,
.validate_selected_districts:active{
  transform: scale(0.95);
}

.dropdown_content{
  max-height: 200px; 
  overflow-y: scroll
}

.current_age,
.current_gender{
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 17px;
  color: #C7C9FF;
  margin-left: 10%;
}

.age_custom_label,
.gender_custom_label{
  font-family: 'Eastman Trial';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: #425169;
}

.age_choice_label{
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 17px;
  color: #425169;
}

.dropdown_body{
  padding: 5px;
}

.age_inputs{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.custom_age_min_input,
.custom_age_max_input{
  width: 40%;
}

.age_input_delimiter{
  border: 1px solid #000000;
  height: 8px;
  transform: rotate(90deg);
  margin-left: 4%;
  margin-right: 4%;
}

.dropdown_footer{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.validate_button{
  box-sizing: border-box;
  background: #484EF7 !important;
  border-radius: 5px;
  transform: scale(0.95);
  box-shadow: none !important;
}

.validate_button:active{
  transform: scale(0.9);
}


.remove_button{
  box-sizing: border-box;
  background: #FF6161 !important;
  border-radius: 5px;
  transform: scale(0.95);
  box-shadow: none !important;
}

.remove_button:active{
  transform: scale(0.9);
}

.validate_btn_text{
  font-family: 'Eastman Trial';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #FFFFFF;
}

.filter_button{
  background: #D1CEFF !important;
  border-radius: 5px;
  width: 90%;
}

.filter_button:active{
  transform: scale(0.95);
}

.filter_btn_text{
  font-family: 'Eastman Trial';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: #425169;
}

.filter_action_btn{
  width: 100%;
}

.radio{
  display: flex;
  flex-direction: row;
  width: 110%;
}
  </style>