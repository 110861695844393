<template>
    <b-container fluid class="map_content">
      <div class="action_btn">
          <b-button class="print_btn shadow-none" variant="outline-primary">
            <span class="print_btn_text">Imprimer</span>
            <img class="print_icon" src="@/assets/print.svg" alt="imprimer le graphe" />
          </b-button>
      </div>
      <l-map
        id="map"
        ref="map"
        :zoom="zoom"
        :center="center"
        :options="{attributionControl: false}">
        <l-tile-layer ref="mapLayer" :url="url"></l-tile-layer>

        <l-circle
        v-for="circle in circles" :key="circle.id" 
        :lat-lng="circle.latLng" 
        :radius="circle.radius" 
        :color="circle.color"
        class="circles"
        ref="circles"
        >
          <l-popup class="custom-popup">
            <div class="l-popup-content">
              <span class="popup_title">Site: {{circle.nom_site}}</span>
              <div class="popup_data">
                <span class="popup_data_elt">
                  <span class="popup_data_value">
                      suspecté : {{circle.total_suspecte}}
                  </span>
                </span>
                <span class="popup_data_elt">
                  <span class="popup_data_value">
                      confirmé : {{circle.total_confirme}}
                  </span>
                </span>
              </div>
            </div>
          </l-popup>
        </l-circle>
      </l-map>
    </b-container>
</template>
  
  <script>
  
  import { mapState } from 'vuex';
  import { LMap, LTileLayer, LCircle, LPopup } from "vue2-leaflet";
  import $ from 'jquery';
  import { eventBus } from '@/main.js';

  
  export default {
    name: 'Map',
    components: { 
      LMap,
      LTileLayer,
      LCircle,
      LPopup
    },
    data() {
      return {
        url:
          "https://api.mapbox.com/styles/v1/frouty/ckttyl6rv0sfm18lp2ik0tlu8/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiZnJvdXR5IiwiYSI6ImNrdHR5NGZrZDF1eWIybm1wbHE1anEwMmkifQ.hdvcG_84hG2O8fUo3hcd8w",
        zoom: 7,
        bounds: null,
        markerLatLng: [],
        show_markers: false,
        center: [13.98333, -14.8],
        circles: [],
      };
    },
    watch:{
      // Opération N°3 : initialiser la carte (dépend de l'opération 2) :ok
      isHealthStructureLoaded(val){
        if(val==true){
          this.initMapCircles();
        }
      }
    },
    methods: {

      async loadGeoJson(){
        $.getJSON('/dashboardData/senegal.geojson', function(data) {
          const geoJsondata = data;
          console.log(geoJsondata)
        });
      },
      initMapCircles(){
        this.selected_sites.forEach(site =>{
          let n = site.total_suspecte + site.total_confirme;
          this.circles.push({
            id: site.id, 
            latLng: [site.lat,site.lng],
            radius: n <=100 ? 500*(1+n): n<=500? 1000*(1+n) : 500*(1+n),
            color: '#0984e3',
            nom_site: site.nom,
            total_suspecte: site.total_suspecte, 
            total_confirme: site.total_confirme
            })
        });
        this.$refs.map.mapObject.invalidateSize();
      }
      
    },
    computed: {
      ...mapState({
        sites_list: 'sites_list',
        selected_sites: 'selected_sites',
        isHealthStructureLoaded: 'isHealthStructureLoaded'
      })
    },
    mounted() {

        eventBus.$on('updateMap', () => {
          this.circles=[];
          this.initMapCircles();
        });
    },
  };
  </script>
  
  <style scoped>

  .map_content{
    margin-top: 2%;
    box-sizing: border-box;
    width: 59%;
    height: 57vh; 
    overflow: scroll;
    border: 1px solid #DADADA;
    border-radius: 10px;
    padding: 0;
  }

  #map {
    display: flex;
    height: 50vh; 
    border-radius:5px;
  }

  .l-popup-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .popup_title{
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 17px;
    color: #6A778A;
  }

  .popup_data{
    display: flex;
    flex-direction: column;
    margin-top: 2%;
    justify-content: center;
    align-items: flex-start;
  }

  .popup_data_elt{
    margin-top: 5%;
    width: 100%;
  }

  .popup_data_value{
    font-family: 'Eastman Roman Trial';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 14px;
    color: #6A778A;
  }

  .action_btn{
    height: 7vh; 
    display: flex;
    justify-content: flex-end;
    padding: 15px;
  }

  .print_btn{
    box-sizing: border-box;
    background: #FFFFFF !important;
    border: 1px solid #979797;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .print_btn:active{
    transform: scale(0.95);
  }

  .print_btn_text{
    font-family: 'Eastman Roman Trial';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    color: #425169;
  }

  .print_icon{
    position: relative;
    left: 5%;
  }

  </style>