<template>
  <div class="symptomalogie_content">
    <b-row class="symptomalogie">
      <b-col cols="5" class="tdr_palu_question">
        <SwitchBox
          :values="['Non','Oui']"
          :icons="['blank.png','blank.png']"
          :box_style="{label1Left:'-20%',label1Right:'-20%',label2Left:'0%',label2Right:'0%',selectBoxHeight:'65%', boxMarginLeft:'0%',selectBoxWidth:'18.3%'}"
          :variableName="'symptomalogie_entourage'"
          :null="false"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import SwitchBox from '@/components/fiche-individuelle/general/SwitchBox.vue';
import { eventBus } from '@/main.js';

export default {
  name: 'Symptomalogie',
  components: {
    SwitchBox
  },
  data: () => ({}),
  mounted() {},
  computed: {
    ...mapState({})
  },
  methods: {
    goToNextSection() {
      eventBus.$emit('triggerConductHeldSection');
    }
  }
};
</script>

<style scoped>
.symptomalogie {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.5%;
  margin-left: 2%;
}
</style>
