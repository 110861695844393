<template>
  <b-container fluid class="content">
    <p style="font-size:1.5em; color:#CA7900;">Page non trouvé!!</p>
  </b-container>
</template>


<script>
export default {
  name: "PageNotFound",
  components: {},
  methods: {},
  computed: {},
  data() {
    return {};
  }
};
</script>

<style scoped >
</style>