<template>
  <b-navbar toggleable="md" class="py-0 my-0 menu" type="light" variant="light">
    <b-nav-toggle target="nav_collapse"></b-nav-toggle>
    <b-navbar-brand class="p-0 m-0" href="#">
      <img class="header_logo" width="70" src="@/assets/logo4s.png" />
    </b-navbar-brand>

    <b-collapse is-nav id="nav_collapse">
      <b-navbar-nav v-show="show_search==true" class="search_item">
        <b-nav-item style="margin-bottom:1%;" class="searchBar">
          <SearchBar />
        </b-nav-item>
      </b-navbar-nav>

      <b-navbar-nav v-show="show_search==true" class="user_infos">
        <b-nav-item class="avatar">
          <img class="avatar_img" src="@/assets/avatar.png" alt="avatar" />
        </b-nav-item>
        <b-nav-item class="avatar_infos">
          <div>
            <span class="welcoming">Hey, {{this.username}}</span>
            <img class="leading_arrow" src="@/assets/logout_arrow.png" alt="avatar" />
          </div>
          <span class="welcoming_message">Content de te revoir</span>
        </b-nav-item>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import SearchBar from '@/components/fiche-individuelle/general/SearchBar.vue';

import { mapState } from 'vuex';

export default {
  name: 'Header',
  components: { SearchBar },
  props: {
    show_search: Boolean
  },
  data() {
    return {
      data: [],
      show_spinner: false
    };
  },

  methods: {},
  computed: {
    ...mapState({
      username: 'username'
    })
  }
};
</script>

<style scoped>
.menu {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 2%;
  padding-bottom: 1%;
  height: 15%;
  background: #ffffff;
}
.header_logo {
  border-radius: 70%;
  padding: 0;
  margin-top: 10%;
  display: block;
  margin-bottom: 10%;
  margin-left: 16%;
}

.search_item {
  display: flex;
  margin-left: 30%;
  padding: 0;
  width: 28%;
}

.searchBar {
  width: 100%;
}

.user_infos {
  margin-left: 20%;
  width: 24%;
}

.avatar {
  height: 40%;
  border-radius: 100%;
  margin-top: 0%;
}

.avatar_infos {
  margin-left: 1%;
  color: black;
  display: flex;
  flex-direction: column;
  text-align: start;
  align-items: center;
}

.avatar_img {
  transform: scale(0.95);
}
.welcoming {
  font-family: 'sans-serif';
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 21px;
  color: #425169;
}

.leading_arrow {
  color: #425169;
  font-weight: 900;
  margin-left: 10%;
}
</style>