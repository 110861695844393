<template>
  <div class="paludism_content">
    <span class="tdr_palu_title">TDR Palu effectué ?</span>
    <b-row class="tdr_palu">
      <b-col cols="5" class="tdr_palu_question">
        <SwitchBox
          :values="['Non','Oui']"
          :icons="['blank.png','blank.png']"
          :box_style="{label1Left:'-20%',label1Right:'-20%',label2Left:'0%',label2Right:'0%', boxMarginLeft:'0%',selectBoxWidth:'18.3%'}"
          :variableName="'tdr_palu'"
          :null="false"
        />
      </b-col>
    </b-row>
    <span v-show="tdr_palu=='Oui'" class="result_title">Résultat</span>
    <b-row v-show="tdr_palu=='Oui'" class="tdr_result">

      <b-col class="tdr_question_switch" cols="10">
        <b-form-checkbox class="tdr_palu_switch" value="Negatif" v-model="tdr_result" name="tdr-palu-switch">
            <span class="tdr_palu_left_value">Négatif</span>
        </b-form-checkbox>
        <b-form-checkbox class="tdr_palu_switch" value="Positif" v-model="tdr_result" name="tdr-palu-switch">
          <span class="tdr_palu_right_value">Positif</span>
        </b-form-checkbox>
      </b-col>

    </b-row>
  </div>
</template>


<script>
import { mapState } from 'vuex';
import SwitchBox from '@/components/fiche-individuelle/general/SwitchBox.vue';

export default {
  name: 'PaludismSection',
  components: {
    SwitchBox
  },
  data: () => ({
    tdr_result: '',
    currentTestComment: ''
  }),
  computed: {
    ...mapState({
      tdr_palu: 'tdr_palu',
      tdr_covid: 'tdr_covid',
      is_tdr_palu_variable_updated: 'is_tdr_palu_variable_updated',
      paludism_percentage: 'paludism_percentage',
      suspision_percentage: 'suspision_percentage',
      suspisionAndDiagnosticCategories: 'suspisionAndDiagnosticCategories'
    })
  },
  watch: {
    tdr_result(val) {
      let valueRightElt = document.querySelector('.tdr_palu_right_value');
      let valueLeftElt = document.querySelector('.tdr_palu_left_value');

      this.$store.commit('setResultatTdrPalu', val);

      if (val == 'Positif') {
        valueLeftElt.style.color = '#6A778A';
        valueRightElt.style.color = '#484EF7';
      } else {
        valueLeftElt.style.color = '#484EF7';
        valueRightElt.style.color = '#6A778A';
      }
    },
    tdr_palu(val) {
      this.$store.commit('setTdrPalu', val);
    }
  },
  methods: {}
};
</script>

<style scoped>
.paludism_content {
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}

.title {
  margin-left: 22%;
  font-family: 'Eastman Roman Trial';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #425169;
}

.tdr_palu_title {
  font-family: 'Eastman Roman Trial';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #425169;
  width: 93%;
  margin-bottom: 1%;
  margin-top: 5%;
  margin-left: 2%;
}

.tdr_palu {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.5%;
  margin-left: 2%;
}

.result_title {
  font-family: 'Eastman Roman Trial';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #425169;
  position: relative;
  right: 2.5%;
}

.tdr_result {
  width: 30%;
  position: relative;
  left: 34%;
  margin-top: 2%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1%;
}

.tdr_question_switch {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 10px 0px;
  gap: 10px;
  background: #ebfcfd;
  border-radius: 5px;
}

.tdr_palu_left_value,
.tdr_palu_right_value {
  font-family: 'Eastman Roman Trial';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: #6a778a;
}

.tdr_palu_switch {
  transform: scale(1.35);
}
</style>